import React, { Dispatch, useEffect, useState } from "react";

import Warning from "@material-ui/icons/Warning";
import {
  dangerColor,
  primaryColor
} from "assets/jss/material-dashboard-pro-react";
import NightsStay from "@material-ui/icons/NightsStay";

const NightIcon: React.FC<NightIconProps> = ({
  start,
  finish,
  breakTime,
  setTotalHrs
}) => {
  const [nightIcon, setNightIcon] = useState(false);
  const [exclIcon, setExclIcon] = useState(false);

  // runs when start, finish or break times change
  useEffect(() => {
    const maxAllowedNegative = 14;
    // get time difference
    let hourDiff = finish.getHours() - start.getHours();
    let minDiff = (finish.getMinutes() - start.getMinutes()) / 60;
    let totalDiff = hourDiff + minDiff;

    if (totalDiff > 0) {
      // normal entry where start time is earlier than finish time
      totalDiff = Math.round(totalDiff * 100) / 100;
      totalDiff = totalDiff - breakTime;
      setExclIcon(false);
      setNightIcon(false);
    } else if (totalDiff < 0 && totalDiff + 24 <= maxAllowedNegative) {
      // overnight entry
      setNightIcon(true);
      setExclIcon(false);
      totalDiff = totalDiff + 24 - breakTime;
    } else {
      // entry where finish time is higher than start time and times are close (invalid entry)
      setExclIcon(true);
      setNightIcon(false);
      totalDiff = 0;
    }

    // use the above calculation to calculate total hours for parent element (if required)
    if (setTotalHrs) {
      totalDiff >= 0 ? setTotalHrs(totalDiff) : setTotalHrs(0);
    }
  }, [start, finish, breakTime, setTotalHrs]);

  return (
    <>
      {exclIcon && (
        <Warning style={{ color: dangerColor[1], marginTop: "32px" }} />
      )}
      {nightIcon && (
        <NightsStay style={{ color: primaryColor[3], marginTop: "32px" }} />
      )}
    </>
  );
};

export default NightIcon;

interface NightIconProps {
  start: Date;
  finish: Date;
  breakTime: number;
  setTotalHrs: Dispatch<number>;
}
