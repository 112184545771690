import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// icon
import { ListAlt } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  manageHolidayAllowancesButton: {
    backgroundColor: "#ff9800",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#ffcb7f" },
    marginTop: 10,
    marginRight: 10
  }
}));

const ManageHolidayAllowancesButton: React.FC<
  ManageHolidayAllowancesButtonType
> = ({ handleAddClick, tooltip }) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip}>
      <IconButton
        className={classes.manageHolidayAllowancesButton}
        onClick={handleAddClick}
      >
        <ListAlt style={{ color: "#f9f9f9" }} />
      </IconButton>
    </Tooltip>
  );
};

export default ManageHolidayAllowancesButton;

type ManageHolidayAllowancesButtonType = {
  tooltip: string;
  handleAddClick: () => void;
};
