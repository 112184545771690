import React, { useCallback, useEffect, useState } from "react";

import { useMediaQuery } from "react-responsive";

import axios from "axios";

// redux
import store from "../../../redux/store";
import { getTokenConfig } from "../../../global_functions/redux_action_creators/auth/getTokenConfig";
// custom functions and hooks
import { getDialogProps } from "global_functions/_common/getDialogProps";
import useFetchDocument from "../../../global_functions/_common/useFetchDocument";
import formatDate from "../../../global_functions/_common/formatDate";
// template
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "../../../components/CustomInput/CustomInput";
import CustomLoadingSpinner from "../../../components/_Custom/_Common/CustomLoadingSpinner";
// @material-ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Clear } from "@material-ui/icons";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import formsStyles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { customTheme } from "../../../global_functions/_common/customTheme";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles(styles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useFormStyles = makeStyles({
  ...formsStyles,
  prefillButton: {
    backgroundColor: "#4caf50",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#a4d7a6" },
    marginTop: 10,
    marginRight: 10
  },
  clearButton: {
    backgroundColor: "#f44336",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#f9a19a" },
    marginTop: 32,
    marginRight: 10
  }
});

const DeliveryNoteParamsDialog = ({
  modal,
  setModal,
  projectId,
  projectNumber,
  clientId
}) => {
  const classes = useStyles();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [reportParams, setReportParams] = useState("");

  const [clientInfoLoading, setClientInfoLoading] = useState(false);
  const [projectInfoLoading, setProjectInfoLoading] = useState(false);

  const [noteDate, setNoteDate] = useState(new Date());

  const [instructions, setInstructions] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [address4, setAddress4] = useState("");
  const [fao, setFao] = useState("");

  // used for pre-filling address and FAO fields from client details
  const [clientAddress1, setClientAdrress1] = useState("");
  const [clientAddress2, setClientAdrress2] = useState("");
  const [clientAddress3, setClientAdrress3] = useState("");
  const [clientAddress4, setClientAdrress4] = useState("");
  const [clientContact, setClientContact] = useState("");

  // used for pre-filling address and FAO fields from project details
  const [projectAddress1, setProjectAdrress1] = useState("");
  const [projectAddress2, setProjectAdrress2] = useState("");
  const [projectAddress3, setProjectAdrress3] = useState("");
  const [projectAddress4, setProjectAdrress4] = useState("");
  const [projectContact, setProjectContact] = useState("");

  // shortcuts to check if any parts of client or project address were provided
  const clientAddressProvided =
    clientAddress1 || clientAddress2 || clientAddress3 || clientAddress4;
  const projectAddressProvided =
    projectAddress1 || projectAddress2 || projectAddress3 || projectAddress4;

  const formClasses = useFormStyles();

  const handleFormSubmit = e => {
    e.preventDefault();
    setReportParams({
      // prettier-ignore
      url: `${apiUrl}documents/?dataset=warehouse_delivery_note&proj_id=${projectId}&fao=${fao}&address1=${address1}&address2=${address2}&address3=${address3}&address4=${address4}&instructions=${instructions}&date=${formatDate(noteDate, "dd MM yyyy")}`,
      fileName: `Delivery Note ${projectNumber} (${new Date()
        .toISOString()
        .replace(/T.*/, "")
        .split("-")
        .reverse()
        .join("-")})`,
      fetchMsg: "Fetching delivery note"
    });
  };

  const getClientDetails = useCallback(() => {
    setClientInfoLoading(true);
    axios
      .get(`${apiUrl}clients/${clientId}`, getTokenConfig(store.getState))
      .then(res => {
        setClientInfoLoading(false);
        setClientAdrress1(
          res.data.address.line_1 ? res.data.address.line_1 : ""
        );
        setClientAdrress2(
          res.data.address.line_2 ? res.data.address.line_2 : ""
        );
        setClientAdrress3(
          res.data.address.line_3 ? res.data.address.line_3 : ""
        );
        setClientAdrress4(
          res.data.address.line_4 ? res.data.address.line_4 : ""
        );
        setClientContact(res.data.contact_name ? res.data.contact_name : "");
      });
  }, [clientId]);

  const getProjectDetails = useCallback(() => {
    setProjectInfoLoading(true);
    axios
      .get(`${apiUrl}projects/${projectId}`, getTokenConfig(store.getState))
      .then(res => {
        setProjectInfoLoading(false);
        setProjectAdrress1(
          res.data.address.address_1 ? res.data.address.address_1 : ""
        );
        setProjectAdrress2(
          res.data.address.address_2 ? res.data.address.address_2 : ""
        );
        setProjectAdrress3(
          res.data.address.address_3 ? res.data.address.address_3 : ""
        );
        setProjectAdrress4(
          res.data.address.address_4 ? res.data.address.address_4 : ""
        );
        setProjectContact(res.data.site_contact ? res.data.site_contact : "");
      });
  }, [projectId]);

  const handleClearAllFieldsClick = () => {
    setInstructions("");
    setAddress1("");
    setAddress2("");
    setAddress3("");
    setAddress4("");
    setFao("");
  };

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (modal) {
      setInstructions("");
      setAddress1("");
      setAddress2("");
      setAddress3("");
      setAddress4("");
      setFao("");
      setNoteDate(new Date());
    }
  }, [modal]);

  useEffect(() => {
    if (modal) document.addEventListener("keydown", captureKeyDown, false);
    else document.removeEventListener("keydown", captureKeyDown, false);
  }, [modal, captureKeyDown]);

  // on modal opening load project and client address details
  useEffect(() => {
    if (modal) {
      getProjectDetails();
      getClientDetails();
    }
  }, [modal, getProjectDetails, getClientDetails]);

  useFetchDocument(reportParams);

  // prefill functions
  const handlePrefillFromClientAddress = e => {
    e.preventDefault();
    setAddress1(clientAddress1);
    setAddress2(clientAddress2);
    setAddress3(clientAddress3);
    setAddress4(clientAddress4);
  };

  const handlePrefillFromClientContact = e => {
    e.preventDefault();
    setFao(clientContact);
  };

  const handlePrefillFromProjectContact = e => {
    e.preventDefault();
    setFao(projectContact);
  };

  const handlePrefillFromProjectAddress = e => {
    e.preventDefault();
    setAddress1(projectAddress1);
    setAddress2(projectAddress2);
    setAddress3(projectAddress3);
    setAddress4(projectAddress4);
  };

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Generate Delivery Note</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <p>
            Fill in fields below to generate a delivery note. All fields are
            optional.
          </p>
          {clientInfoLoading || projectInfoLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "400px",
                width: "500px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <>
              <form id="deliveryNoteForm" onSubmit={handleFormSubmit}>
                <GridContainer>
                  <GridItem xs={2} />
                  <GridItem xs={6}>
                    <ThemeProvider theme={customTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          style={{ float: "left" }}
                          margin="normal"
                          label="Date"
                          format="dd/MM/yyyy"
                          value={noteDate}
                          onChange={date => setNoteDate(date)}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                  </GridItem>
                  {/*show a clear button if there are values in any form fields*/}
                  <GridItem xs={4}>
                    {(fao ||
                      instructions ||
                      address1 ||
                      address2 ||
                      address3 ||
                      address4) && (
                      <Tooltip title="Clear all fields">
                        <IconButton
                          className={formClasses.clearButton}
                          onClick={handleClearAllFieldsClick}
                          size="small"
                        >
                          <Clear style={{ color: "#f9f9f9" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={4}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      For the attention of
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={clientContact || projectContact ? 4 : 8}>
                    <CustomInput
                      id="fao"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helpText="FAO"
                      inputProps={{
                        type: "text",
                        value: fao,
                        onChange: e => setFao(e.target.value),
                        // required: true,
                        maxLength: 30
                      }}
                    />
                  </GridItem>
                  {clientContact || projectContact ? (
                    <GridItem xs={4}>
                      <p style={{ marginTop: "32px" }}>
                        {clientContact && (
                          <a href="/" onClick={handlePrefillFromClientContact}>
                            Client HQ Contact
                          </a>
                        )}
                        {clientContact && projectContact && <br />}
                        {projectContact && (
                          <a href="/" onClick={handlePrefillFromProjectContact}>
                            Site Contact
                          </a>
                        )}
                      </p>
                    </GridItem>
                  ) : (
                    <></>
                  )}
                </GridContainer>
                <GridContainer>
                  <GridItem xs={4}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      Instructions
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8}>
                    <CustomInput
                      id="fao"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helpText="FAO"
                      inputProps={{
                        type: "text",
                        value: instructions,
                        onChange: e => setInstructions(e.target.value),
                        // required: true,
                        maxLength: 100
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={4}>
                    <FormLabel className={formClasses.labelHorizontal}>
                      Address Line 1
                    </FormLabel>
                  </GridItem>
                  <GridItem
                    xs={clientAddressProvided || projectAddressProvided ? 4 : 8}
                  >
                    <CustomInput
                      id="clientAddress1"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: address1,
                        onChange: e => setAddress1(e.target.value),
                        maxLength: 50
                      }}
                      helpText=""
                    />
                  </GridItem>
                  {clientAddressProvided || projectAddressProvided ? (
                    <GridItem xs={4}>
                      <p style={{ marginTop: "32px" }}>
                        {clientAddressProvided && (
                          <a href="/" onClick={handlePrefillFromClientAddress}>
                            Client HQ Address
                          </a>
                        )}
                        {clientAddressProvided && clientAddressProvided && (
                          <br />
                        )}
                        {projectAddressProvided && (
                          <a href="/" onClick={handlePrefillFromProjectAddress}>
                            Site Address
                          </a>
                        )}
                      </p>
                    </GridItem>
                  ) : (
                    <></>
                  )}
                </GridContainer>
                <GridContainer>
                  <GridItem xs={4}>
                    <FormLabel className={formClasses.labelHorizontal}>
                      Address Line 2
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8}>
                    <CustomInput
                      id="clientAddress2"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: address2,
                        onChange: e => setAddress2(e.target.value),
                        maxLength: 50
                      }}
                      helpText=""
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={4}>
                    <FormLabel className={formClasses.labelHorizontal}>
                      Address Line 3
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8}>
                    <CustomInput
                      id="clientAddress3"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: address3,
                        onChange: e => setAddress3(e.target.value),
                        maxLength: 50
                      }}
                      helpText=""
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={4}>
                    <FormLabel className={formClasses.labelHorizontal}>
                      Address Line 4
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8}>
                    <CustomInput
                      id="clientAddress4"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: address4,
                        onChange: e => setAddress4(e.target.value),
                        maxLength: 50
                      }}
                      helpText=""
                    />
                  </GridItem>
                </GridContainer>
              </form>
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button color="primary" simple type="submit" form="deliveryNoteForm">
            Generate
          </Button>
          <Button color="transparent" onClick={() => setModal(false)} simple>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
};

export default DeliveryNoteParamsDialog;
