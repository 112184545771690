import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const deleteButton = { icon: "Delete", tooltip: "Delete Request" };
const editButton = { icon: "Edit", tooltip: "Edit Request" };
const urlButton = { icon: "Link", tooltip: "Copy Link" };

export const procurementReqUser = {
  section: "procurementRequests",
  reduxTableName: "procurementReqMain",
  refreshURL: "procurement-requests/",
  filterTabValues: [
    "?status=1",
    "?status=2",
    "?status=3",
    "?status=6",
    "?status=5",
    "?status=4"
  ],
  filterTabNames: [
    "Pending",
    "Approved",
    "Rejected",
    "Purchased",
    "Received",
    "Deleted"
  ],
  tableParams: {
    autoWidth: true,
    responsive: true,
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    buttonsColumn: {
      id: 9,
      genIcons: rowData => {
        const btnArray = [];
        if (rowData.url) btnArray.push(urlButton);
        if (rowData.editable_from_form) btnArray.push(editButton);
        if (rowData.deletable) btnArray.push(deleteButton);

        // add receipt icon
        if (!rowData.file_count && rowData.user_can_upload_file) {
          // only show the no receipts button when user can upload
          btnArray.push({ icon: "FilterNone", tooltip: "Manage uploads" });
        } else if (rowData.file_count && rowData.file_count <= 9) {
          btnArray.push({
            icon: "Filter" + rowData.file_count.toString(),
            tooltip: rowData.user_can_upload_file
              ? "Manage uploads"
              : "View Uploads"
          });
        } else if (rowData.file_count) {
          btnArray.push({
            icon: "Filter9Plus",
            tooltip: rowData.user_can_upload_file
              ? "Manage uploads"
              : "View Uploads"
          });
        }
        return btnArray;
      }
    },
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    columns: [
      {
        data: "id",
        title: "ID",
        responsivePriority: 1,
        width: 50,
        className: "dt-body-center"
      }, // 0
      {
        title: "Created Date",
        data: { _: "created_time.display", sort: "created_time.@data-order" },
        width: 100
      }, // 1
      {
        data: "project",
        title: "Project",
        responsivePriority: 5,
        width: 300,
        className: "dt-body-center"
      }, // 2
      {
        data: "description",
        title: "Description",
        responsivePriority: 5,
        width: 300,
        className: "dt-body-center"
      }, // 3
      {
        data: "vendor",
        title: "Vendor",
        responsivePriority: 5,
        width: 200,
        className: "dt-body-center"
      }, // 4
      {
        data: "status_str",
        title: "Status",
        responsivePriority: 5,
        width: 50,
        className: "dt-body-center"
      }, // 5
      {
        data: "qty",
        title: "Qty.",
        responsivePriority: 5,
        width: 300,
        className: "dt-body-center"
      }, // 6
      {
        data: "totals_string",
        title: "Unit Price",
        responsivePriority: 5,
        width: 100,
        className: "dt-body-center"
      }, // 7
      {
        data: "last_change_str",
        title: "Last Change",
        responsivePriority: 5,
        width: 300,
        className: "dt-body-center"
      }, // 8
      {
        data: null,
        title: "Actions",
        className: "dt-body-center",
        width: 200,
        responsivePriority: 1
      } // 9
    ],
    aaSorting: [[1, "-asc"]]
  }
};
