import React, { useEffect, useState } from "react";
// template
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
// material-ui components
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import ProjectSelect from "../../components/_Custom/_Common/ProjectSelect";
// styles
import { makeStyles } from "@material-ui/core/styles";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);

const AddEditSHEQDocumentForm = props => {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  const [projId, setProjId] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [sheqDocCategory, setSheqDocCategory] = useState(null);
  const [sheqDocName, setSheqDocName] = useState("");

  const [reqRead, setReqRead] = useState(false);

  useEffect(() => {
    if (props.editData && props.open) {
      setSheqDocName(props.editData.name);
      setSheqDocCategory(props.editData.category);
      setReqRead(props.editData.read_confirmation);
      // edit is only possible from within project so client and projId become irrelevant
      setClientId(null);
      setProjId(null);
    } else if (!props.editData && props.open) {
      setProjId(null);
      setSheqDocName("");
      setSheqDocCategory(null);
      setClientId(null);
      setReqRead(false);
    }
  }, [props.editData, props.open]);

  useEffect(() => {
    props.setformData({
      name: sheqDocName,
      category: sheqDocCategory,
      read_confirmation: reqRead,
      proj_id: props.parentProjId ? props.parentProjId : projId // when parentProjectId is passed use it, otherwise use what is selected from dropdown
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheqDocName, projId, sheqDocCategory, reqRead]);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "120px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            {!props.parentProjId ? ( // only show when project is not passed (when adding from top level page)
              <ProjectSelect
                clientList={props.clientList}
                formLoading={props.formLoading}
                clientID={clientId}
                setClientID={setClientId}
                statusFilters={[1, 3, 5]}
                projID={projId}
                setProjID={setProjId}
                lhsFieldWidth={2}
                rhsFieldWidth={10}
              />
            ) : (
              <></>
            )}
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Category
                </FormLabel>
              </GridItem>
              <GridItem xs={10}>
                <CustomSelect
                  options={props.formChoiceFields.sheq_categories}
                  isDisabled={props.formLoading}
                  value={sheqDocCategory}
                  setValue={setSheqDocCategory}
                  required={true}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Name
                </FormLabel>
              </GridItem>
              <GridItem xs={10}>
                <CustomInput
                  id="bundleName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Bundle name"
                  inputProps={{
                    type: "text",
                    value: sheqDocName,
                    onChange: e => {
                      setSheqDocName(e.target.value);
                    },
                    required: true,
                    maxLength: 30
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                >
                  Requires read confirmation?
                </FormLabel>
              </GridItem>
              <GridItem xs={6}>
                <div
                  className={
                    formClasses.checkboxAndRadio +
                    " " +
                    formClasses.checkboxAndRadioHorizontal
                  }
                  style={{ float: "left" }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={reqRead === true}
                        onChange={() => setReqRead(true)}
                        value={true}
                        name="radio button enabled"
                        aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={formClasses.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={formClasses.radioChecked}
                          />
                        }
                        classes={{
                          checked: formClasses.radio,
                          root: formClasses.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot
                    }}
                    label="Yes"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={reqRead === false}
                        onChange={() => setReqRead(false)}
                        value={false}
                        name="radio button enabled"
                        aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={formClasses.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={formClasses.radioChecked}
                          />
                        }
                        classes={{
                          checked: formClasses.radio,
                          root: formClasses.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot
                    }}
                    label="No"
                  />
                </div>
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
};

export default AddEditSHEQDocumentForm;
