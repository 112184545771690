import React, { useEffect, useState } from "react";

import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";

import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormLabel from "@material-ui/core/FormLabel";

import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { customTheme } from "global_functions/_common/customTheme";
import CustomSelect from "components/CustomSelect/CustomSelect";

const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);

export default function AddTsBundleAdminForm(props) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  // form fields
  const [selectedUser, setSelectedUser] = useState("");
  const [date, setDate] = React.useState(new Date());

  useEffect(() => {
    if (props.open) {
      setDate(new Date());
      setSelectedUser("");
    }
  }, [props.open]);

  useEffect(() => {
    props.setformData({
      user: selectedUser,
      year: date.getFullYear(),
      month: date.getMonth() + 1
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, selectedUser]);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "120px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            <GridContainer>
              <GridItem xs={2} />
              <GridItem xs={5}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={customTheme}>
                    <KeyboardDatePicker
                      style={{ float: "left" }}
                      views={["year", "month"]}
                      margin="normal"
                      label="Date"
                      value={date}
                      onChange={date => setDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </GridItem>
              <GridItem xs={5} />
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  User
                </FormLabel>
              </GridItem>
              <GridItem xs={10}>
                <CustomSelect
                  options={props.formChoiceFields.user_list}
                  isDisabled={props.formLoading}
                  value={selectedUser}
                  setValue={setSelectedUser}
                  required={true}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
}
