import {
  SET_CLIENT_DASHBOARD_VIEW_ITEMS,
  SET_CLIENT_DASHBOARD_VIEW_PROJECTS,
  SET_CLIENT_DASHBOARD_PROJ_DT_DT_PAGE,
  SET_CLIENT_DASHBOARD_PROJ_DT_DT_TRIG_REFRESH,
  SET_CLIENT_DASHBOARD_PROJ_DT_DT_ROW,
  SET_CLIENT_DASHBOARD_PROJ_DT_FILTER,
  SET_CLIENT_DASHBOARD_PROJ_DT_REFRESH_URL,
  SET_CLIENT_DASHBOARD_ITEM_DT_REFRESH_URL,
  SET_CLIENT_DASHBOARD_ITEM_DT_FILTER,
  SET_CLIENT_DASHBOARD_ITEM_DT_ROW,
  SET_CLIENT_DASHBOARD_ITEM_DT_TRIG_REFRESH,
  SET_CLIENT_DASHBOARD_ITEM_DT_PAGE
} from "../../../dispatch_types";

const initialState = {
  viewingItems: 0,
  itemProjId: 0,
  tables: {
    clientDashboardProjects: {
      selectedRow: null,
      triggerDtRefresh: {},
      viewedPage: 0,
      refreshURL: "",
      pageFilter: 0
    },
    clientDashboardItems: {
      selectedRow: null,
      triggerDtRefresh: {},
      viewedPage: 0,
      refreshURL: "",
      pageFilter: 0
    }
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CLIENT_DASHBOARD_VIEW_ITEMS:
      return {
        ...state,
        viewingItems: 1,
        itemProjId: action.payload
      };
    case SET_CLIENT_DASHBOARD_VIEW_PROJECTS:
      return {
        ...state,
        viewingItems: 0,
        itemProjId: null
      };
    case SET_CLIENT_DASHBOARD_PROJ_DT_FILTER:
      return {
        ...state,
        tables: {
          ...state.tables,
          clientDashboardProjects: {
            ...state.tables.clientDashboardProjects,
            pageFilter: action.payload,
            viewedPage: 0
          }
        }
      };
    case SET_CLIENT_DASHBOARD_PROJ_DT_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          clientDashboardProjects: {
            ...state.tables.clientDashboardProjects,
            selectedRow: action.payload
          }
        }
      };
    case SET_CLIENT_DASHBOARD_PROJ_DT_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          clientDashboardProjects: {
            ...state.tables.clientDashboardProjects,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_CLIENT_DASHBOARD_PROJ_DT_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          clientDashboardProjects: {
            ...state.tables.clientDashboardProjects,
            viewedPage: action.payload
          }
        }
      };
    case SET_CLIENT_DASHBOARD_PROJ_DT_REFRESH_URL:
      return {
        ...state,
        tables: {
          ...state.tables,
          clientDashboardProjects: {
            ...state.tables.clientDashboardProjects,
            refreshURL: action.payload
          }
        }
      };
    case SET_CLIENT_DASHBOARD_ITEM_DT_FILTER:
      return {
        ...state,
        tables: {
          ...state.tables,
          clientDashboardItems: {
            ...state.tables.clientDashboardItems,
            pageFilter: action.payload,
            viewedPage: 0
          }
        }
      };
    case SET_CLIENT_DASHBOARD_ITEM_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          clientDashboardItems: {
            ...state.tables.clientDashboardItems,
            selectedRow: action.payload
          }
        }
      };
    case SET_CLIENT_DASHBOARD_ITEM_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          clientDashboardItems: {
            ...state.tables.clientDashboardItems,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_CLIENT_DASHBOARD_ITEM_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          clientDashboardItems: {
            ...state.tables.clientDashboardItems,
            viewedPage: action.payload
          }
        }
      };
    case SET_CLIENT_DASHBOARD_ITEM_DT_REFRESH_URL:
      return {
        ...state,
        tables: {
          ...state.tables,
          clientDashboardItems: {
            ...state.tables.clientDashboardItems,
            refreshURL: action.payload
          }
        }
      };
    default:
      return state;
  }
}
