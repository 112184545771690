import React, { useEffect, useState } from "react";

import axios from "axios";
// redux
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// custom components
import { WidgetTemplate } from "../WidgetTemplate";
// icons
import { LocalShipping } from "@material-ui/icons";

const VehicleTrackingWidgetAdmin: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState({
    expired_certificates: 0
  });
  const apiUrl = process.env.REACT_APP_API_V1_URL;

  // initial load
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${apiUrl}datasets/?type=vehicle_tracking_widget_admin`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setCardData(res.data);
        setIsLoading(false);
      });
  }, [apiUrl]);

  return (
    <WidgetTemplate
      bottomText="Vehicle Tracking"
      bottomUrl="/#/admin/vehicle-tracking/"
      icon={<LocalShipping />}
      iconColor="danger"
      isLoading={isLoading}
      statsText=" cert. expired"
      title="Vehicle Tracking"
      value={cardData.expired_certificates}
    />
  );
};

export default VehicleTrackingWidgetAdmin;
