// used when item containers for one project are viewed (project name not included)
import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const editIcon = { icon: "Edit", tooltip: "Edit container" };
const deleteIcon = { icon: "Delete", tooltip: "Delete container" };
const viewIcon = { icon: "Visibility", tooltip: "" };
const qrIcon = { icon: "CropFree", tooltip: "Generate QR code" };
const reportIcon = {
  icon: "Assignment",
  tooltip: "Generate Container Context"
};

export const warehouseItemContainersMain = {
  section: "warehouseItemContainers",
  reduxTableName: "warehouseItemContainersMain",
  filterTabValues: [
    "?filter=6",
    "?filter=1",
    "?filter=2",
    "?filter=5",
    "?filter=3",
    "?filter=4"
  ],
  filterTabNames: [
    "All",
    "Pre-Check-in",
    "Checked In",
    "Pre-Check-Out",
    "Checked Out",
    "Deleted"
  ],
  tableParams: {
    autoWidth: true,
    responsive: true,
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    buttonsColumn: {
      id: 7,
      genIcons: () => {
        return [viewIcon, editIcon, deleteIcon, qrIcon, reportIcon];
      }
    },
    columns: [
      {
        data: "id",
        title: "Container ID",
        width: 100,
        className: "dt-body-center"
      }, // 0
      {
        data: "container_type_disp",
        title: "Container Type",
        responsivePriority: 1,
        className: "dt-body-center",
        width: 100
      }, // 1
      {
        data: "name",
        title: "Container Name",
        responsivePriority: 1,
        className: "dt-body-center",
        width: 100
      }, // 2
      {
        data: "no_items",
        title: "No. Items",
        responsivePriority: 1,
        className: "dt-body-center",
        width: 100
      }, // 3
      {
        data: "location_str",
        title: "Location",
        width: 200,
        className: "dt-body-center"
      }, // 4
      {
        data: "comments",
        title: "Comments",
        responsivePriority: 1,
        className: "dt-body-center",
        width: 200
      }, // 5
      {
        data: "status_str",
        title: "Status",
        responsivePriority: 1,
        className: "dt-body-center",
        width: 300
      }, // 6
      {
        data: null,
        title: "Actions",
        className: "dt-body-center",
        width: 100,
        responsivePriority: 1
      } // 7
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[0, "asc"]]
  }
};
