import React, { useState, useEffect } from "react";
import axios from "axios";

import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";

import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";

const useStyles = makeStyles(styles);
const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ConsistencyDialog(props) {
  const classes = useStyles();

  const [missingDays, setMissingDays] = useState({});
  const [excessDays, setExcessDays] = useState({});

  const [loadingInProgress, setLoadingInProgress] = useState(false);

  useEffect(() => {
    if (props.consistencyDialog) {
      setLoadingInProgress(true);
      axios
        .get(
          apiUrl + "datasets/?type=holiday_consistency",
          getTokenConfig(store.getState)
        )
        .then(res => {
          setMissingDays(res.data[0]);
          setExcessDays(res.data[1]);
        })
        .then(() => setLoadingInProgress(false));
    }
  }, [props.consistencyDialog]);

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        fullWidth={true}
        open={props.consistencyDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.setConsistencyDialog(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => props.setConsistencyDialog(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Holiday Consistency</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <p>
            This dialog shows consistency between approved holiday request dates
            and holidays that are in the diary.
          </p>
          {loadingInProgress ? (
            <CustomLoadingSpinner />
          ) : (
            <>
              <br />
              {missingDays && Object.keys(missingDays).length ? (
                <ul>
                  {Object.entries(missingDays).map(i => {
                    return (
                      <li key={`list-item${i[0]}`}>
                        {/*list entry starts with user name and request id*/}
                        {missingDays[i[0]]["req_info"].user} request ID {i[0]}{" "}
                        for&nbsp;
                        {/*if start date and end date are equal, only keep one of them, else include both*/}
                        {missingDays[i[0]]["req_info"]["start_date"] ===
                        missingDays[i[0]]["req_info"]["end_date"] ? (
                          <>
                            {missingDays[i[0]]["req_info"]["start_date"]}
                            {/*if only one date and not a full day booking, include time of the day (morning/afternoon)*/}
                            {missingDays[i[0]]["req_info"]["time"] !==
                              "Full Day(s)" && (
                              <>
                                &nbsp;({missingDays[i[0]]["req_info"]["time"]})
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {missingDays[i[0]]["req_info"]["start_date"]} -{" "}
                            {missingDays[i[0]]["req_info"]["end_date"]}
                          </>
                        )}
                        {/*at the end of the request detail string, add colon*/}
                        :<br />
                        {/*loop through missing days if any*/}
                        {missingDays[i[0]]["Missing"] && (
                          <>
                            Missing:
                            <br />
                            {missingDays[i[0]]["Missing"].map((item, index) => {
                              return (
                                <React.Fragment key={`missing-days-${index}`}>
                                  {item}
                                  <br />
                                </React.Fragment>
                              );
                            })}
                          </>
                        )}
                        {/*loop through duplicated days if any*/}
                        {missingDays[i[0]]["Duplicated"] && (
                          <>
                            Duplicated:
                            <br />
                            {missingDays[i[0]]["Duplicated"] &&
                              missingDays[i[0]]["Duplicated"].map(
                                (item, index) => {
                                  return (
                                    <React.Fragment
                                      key={`duplicated-days-${index}`}
                                    >
                                      {item}
                                      <br />
                                    </React.Fragment>
                                  );
                                }
                              )}
                          </>
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p>No missing resource planner holiday bookings found</p>
              )}

              {excessDays && Object.keys(excessDays).length ? (
                <ul>
                  {Object.keys(excessDays).map(user => {
                    return (
                      <React.Fragment key={`excess-item-${user}`}>
                        <li>
                          {user}:<br />
                          Excess:
                          <br />
                          {excessDays[user].map((item, index) => {
                            return (
                              <React.Fragment key={`excess-${index}`}>
                                {`${item[0]} (${item[1]} - ${item[2]})`}
                                <br />
                              </React.Fragment>
                            );
                          })}
                        </li>
                        <br />
                      </React.Fragment>
                    );
                  })}
                </ul>
              ) : (
                <p>No excess resource planner holiday bookings found</p>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="transparent"
            onClick={() => props.setConsistencyDialog(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
