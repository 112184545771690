import React from "react";

import { useDispatch } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// icon
import { Assignment } from "@material-ui/icons";
import showNotification from "global_functions/redux_action_creators/showNotification";

const useStyles = makeStyles(theme => ({
  reportButton: {
    backgroundColor: "#26c6da",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#90e1ec" },
    marginTop: 10,
    marginRight: 10
  },
  reportButtonGreyed: {
    backgroundColor: "#bdbdbd",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#dedede" },
    marginTop: 10,
    marginRight: 10
  }
}));

export default function ReportButton({ handleReportClick, greyedOut, tooltip }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      {!greyedOut ? (
        <Tooltip title={tooltip}>
          <IconButton
            className={classes.reportButton}
            onClick={handleReportClick}
          >
            <Assignment style={{ color: "#f9f9f9" }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={tooltip}>
          <IconButton
            className={classes.reportButtonGreyed}
            onClick={() => dispatch(showNotification("Empty bundle", "info"))}
          >
            <Assignment style={{ color: "#f9f9f9" }} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
