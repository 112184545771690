import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const historyIcon = { icon: "History", tooltip: "View item history" };

export const clientDashboardItems = {
  section: "clientDashboard",
  reduxTableName: "clientDashboardItems",
  filterTabValues: ["?filter=2,5", "?filter=3"],
  filterTabNames: ["In Warehouse", "Checked Out"],
  tableParams: {
    autoWidth: true,
    responsive: true,
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    buttonsColumn: {
      id: 10,
      genIcons: () => {
        return [historyIcon];
      }
    },
    columns: [
      { data: "id", title: "ID", className: "dt-body-center", width: 100 }, // 0
      { data: "manufacturer", title: "Manufacturer", width: 500 }, // 1
      { data: "model", title: "Model", width: 500 }, // 2
      { data: "serial_no", title: "Serial", width: 500 }, // 3
      {
        data: "type_str",
        title: "Type",
        width: 500,
        className: "dt-body-center"
      }, // 4
      {
        data: { _: "container.display", sort: "container.@data-order" },
        title: "Container",
        width: 200,
        className: "dt-body-center"
      }, // 5
      { data: "qty", title: "Qty", width: 200, className: "dt-body-center" }, // 6
      {
        data: "location_str",
        title: "Location",
        width: 500,
        className: "dt-body-center"
      }, // 7
      { data: "comments", title: "Comments", width: 750 }, // 8
      {
        data: "status",
        title: "Status",
        className: "dt-body-center",
        width: 200
      }, // 9
      {
        data: null,
        title: "Item History",
        className: "dt-body-center",
        width: 1200,
        responsivePriority: 1
      } // 10
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },

    aaSorting: [[0, "asc"]]
  }
};
