import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const editDocIcon = { icon: "Edit", tooltip: "Edit document" };
const deleteDocIcon = { icon: "Delete", tooltip: "Delete document" };
const markAsReadIcon = { icon: "CheckBoxOutline", tooltip: "" };
const markAsUnreadIcon = { icon: "CheckBox", tooltip: "" };
const viewReadReceiptsIcon = {
  icon: "People",
  tooltip: "View read confirmations"
};
const noUploadsIcon = { icon: "FilterNone", tooltip: "Manage uploaded files" };
const manyUploadsIcon = {
  icon: "Filter9Plus",
  tooltip: "Manage uploaded files"
};

export const sheqDocsMain = {
  section: "sheqDocs",
  reduxTableName: "sheqDocsMain",
  filterTabValues: ["all", "rams", "inductions_training", "other"],
  filterTabNames: ["All", "RAMS", "Inductions & Training", "Other"],
  tableParams: {
    autoWidth: true,
    buttonsColumn: {
      id: 4,
      genIcons: rowData => {
        let return_array = [];
        // add view read receipts for entries that require read receipts (admin only) and have read receipts
        if (
          rowData.read_confirmation &&
          rowData.is_hrhs_admin &&
          rowData.read_receipt_no
        )
          return_array.push(viewReadReceiptsIcon);
        // add read/unread icons to all items that require read confirmation
        if (rowData.read_confirmation && rowData.read_status === true)
          return_array.push(markAsUnreadIcon);
        else if (rowData.read_confirmation && rowData.read_status === false)
          return_array.push(markAsReadIcon);
        // get uploaded file number icons
        if (!rowData.upload_count) {
          return_array.push(noUploadsIcon);
        } else if (rowData.upload_count <= 9) {
          return_array.push({
            icon: "Filter" + rowData.upload_count.toString(),
            tooltip: "Manage uploaded fiels"
          });
        } else {
          return_array.push(manyUploadsIcon);
        }
        // add edit and delete buttons to admin and super users
        if (rowData.user_is_admin_or_super)
          return_array.push(editDocIcon, deleteDocIcon);
        return return_array;
      }
    },
    responsive: true,
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    columns: [
      { data: "id", title: "ID", className: "dt-body-center" }, // 0
      { data: "name", title: "Document Name", className: "dt-body-center" }, // 1
      {
        data: "category_str",
        title: "Category",
        className: "dt-body-center"
      }, // 2
      { data: "date_str", title: "Date", className: "dt-body-center" }, // 3
      {
        data: null,
        title: "Actions",
        className: "dt-body-center",
        responsivePriority: 1
      } // 4
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "asc"]]
  }
};
