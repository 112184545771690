import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

export const tsEntriesAdminMain = {
  section: "tsEntriesAdmin",
  reduxTableName: "tsEntriesAdminMain",
  tableParams: {
    autoWidth: true,
    responsive: true,
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    columns: [
      { data: "id", title: "DB IDs", visible: false, searchable: false }, // 0
      {
        data: { _: "job_date.display", sort: "job_date.@data-order" },
        title: "Job Date",
        responsivePriority: 2,
        width: 170
      }, // 1
      { data: "sub_user", title: "User", responsivePriority: 1, width: 250 }, // 2
      { data: "number", title: "Job", responsivePriority: 3, width: 450 }, // 3
      { data: "location", title: "Location" }, // 4
      { data: "function", title: "Function" }, // 5
      { data: "hours", title: "Hours" }, // 6
      { data: "start", title: "Start" }, // 7
      { data: "finish", title: "Finish" }, // 8
      { data: "breaks", title: "Break" }, // 9
      { data: "comments", title: "Comments", width: 300 } // 10
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },

    aaSorting: [[1, "desc"], [7, "desc"]]
  }
};
