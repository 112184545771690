import React, { useEffect, useState } from "react";

import axios from "axios";

// redux
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";

// custom
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";

const apiUrl = process.env.REACT_APP_API_V1_URL;

const AsyncImage: React.FC<AsyncImageProps> = ({ url }) => {
  const [fetchedImage, setFetchedImage] = useState(undefined);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    setImageLoading(true);

    let config = getTokenConfig(store.getState);
    // @ts-ignore
    config.responseType = "arraybuffer";

    if (apiUrl) {
      axios
        .get(apiUrl.slice(0, -1) + url, config) // removing the trailing slash from api URL (need prettier fix?)
        .then(response => {
          let data = `data:${
            response.headers["content-type"]
          };base64,${new Buffer(response.data, "binary").toString("base64")}`;
          // @ts-ignore
          setFetchedImage(data);
          setImageLoading(false);
        });
    }
  }, [url]);

  return (
    <>
      {imageLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <img src={fetchedImage} alt="" />
      )}
    </>
  );
};

export default AsyncImage;

interface AsyncImageProps {
  url: string;
}
