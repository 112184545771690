import axios from "axios";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const apiURL = process.env.REACT_APP_API_V1_URL;

// export functions
async function useDataTableRefresh(dataTable, tableParams) {
  let reduxSection = tableParams.section;

  const reduxPageProps = useSelector(
    state => state[reduxSection]["tables"][tableParams.reduxTableName]
  );

  const FILTER_TAB_VALUES = tableParams.filterTabValues;

  useEffect(() => {
    if (dataTable) {
      dataTable.processing(true);

      let refreshURL;
      if (tableParams.refreshURL) {
        // big sections have fixed URLs which are specified in table params
        refreshURL = apiURL + tableParams.refreshURL;
      } else if (reduxPageProps.refreshURL) {
        // detail sections have 'dynamic' URLs that are added to redux
        refreshURL = apiURL + reduxPageProps.refreshURL;
      } else return;

      if (reduxPageProps.pageFilter || reduxPageProps.pageFilter === 0) {
        refreshURL += FILTER_TAB_VALUES[reduxPageProps.pageFilter];
      }

      axios
        .get(refreshURL, getTokenConfig(store.getState))
        .then(res => {
          dataTable.clear();
          dataTable.rows.add(res.data).draw();
        })
        .then(() => {
          // select datatable row as specified in redux store
          if (reduxPageProps.selectedRow != null) {
            let rowNo = dataTable.rows().count();
            for (let i = 0; i < rowNo; i++) {
              // select row that was added/edited
              let idInTable = dataTable.rows(i).data()[0].id;
              if (reduxPageProps.selectedRow === idInTable) {
                dataTable.rows(i).select();
                dataTable
                  .row(i)
                  .show()
                  .draw(false); // go to correct page
                break; // break the for loop
              }
            }
          }

          // select datatable page as specified in redux store
          else if (reduxPageProps.viewedPage) {
            if (reduxPageProps.viewedPage <= dataTable.page.info().pages - 1) {
              dataTable.page(reduxPageProps.viewedPage).draw("page");
            } else {
              dataTable.page(reduxPageProps.viewedPage - 1).draw("page"); // page before if page no longer exists
            }
          }
        })
        .then(() => dataTable.processing(false));
    }
    // triggerDtRefresh change triggers useEffect as it is an object (selectedRow will have same value as triggerDtRefresh)
    // eslint-disable-next-line
    }, [reduxPageProps.pageFilter, reduxPageProps.triggerDtRefresh, reduxPageProps.refreshURL, dataTable])
}

export default useDataTableRefresh;
