import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_FILTER,
  SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_PAGE,
  SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_ROW,
  SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_TRIG_REFRESH
} from "redux/dispatch_types";
// custom components
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import ViewAllButton from "components/CustomButtons/ViewAllButton";
import AddButton from "../../../../components/CustomButtons/AddButton";
import AddEditDtEntryDialog from "../../../Dialogs/_Common/AddEditDtEntryDialog";
import AddEditWarehouseItemForm from "../../../Forms/AddEditWarehouseItemForm";
// custom functions
import useEventListener from "global_functions/_common/useEventListener";
import identifyClick from "global_functions/_common/identifyClick";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
// core components
import TableFilter from "components/_Custom/_Common/TableFilter";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "../../../../components/Card/CardFooter";
// table
import { projectsWithWarehouseItemsMain } from "views/DataTables/Warehouse/Items/projectsWithWarehouseItemsMain";
// styles
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};
const useStyles = makeStyles(styles);

export default function ProjectsWithWarehouseItemsPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [dataTable, setDataTable] = useState("");

  const [addEditModal, setAddEditModal] = useState(false);

  const reduxPageProps = useSelector(state => state.projectsWithWarehouseItems);

  const handleAddClick = () => {
    setAddEditModal(true);
  };

  useDataTableRefresh(dataTable, projectsWithWarehouseItemsMain);

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({
            type: SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_ROW,
            payload: selectedRow
          });
          break;
        case "pagination":
          dispatch({
            type: SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
          dataTable.rows().deselect();
          switch (clickRelevantType.params[0]) {
            case "Visibility":
              history.push(`/admin/projects-with-warehouse-items/${dtData.id}`);
              break;
            default:
              return false;
          }
          break;
        default:
          return false;
      }
    }
  };

  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>
                  Projects with Warehouse Items
                </h4>
                <h4 className={classes.cardCategoryWhite}>
                  Click on &apos;View&apos; icon to see items
                </h4>
              </CardText>
              <GridContainer>
                <GridItem xs={11} />
                <GridItem xs={1}>
                  <ViewAllButton
                    handleViewAllClick={() => {
                      history.push(`/admin/projects-with-warehouse-items/0`);
                    }}
                    tooltip="View All Warehouse Items"
                    extraStyleProps={{ float: "right" }}
                  />
                </GridItem>
              </GridContainer>
              {dataTable ? (
                <TableFilter
                  color="primary"
                  activeFilter={
                    reduxPageProps.tables.projectsWithWarehouseItemsMain.pageFilter
                  }
                  dispatchType={SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_FILTER}
                  dataTable={dataTable}
                  tabs={projectsWithWarehouseItemsMain.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                tableParams={projectsWithWarehouseItemsMain}
                setDataTable={setDataTable}
              />
            </CardBody>
            <CardFooter>
              <div className={classes.cardContentRight}>
                <AddButton
                  handleAddClick={handleAddClick}
                  tooltip="Add Warehouse Item"
                />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={0}
          dataTable={dataTable}
          detailURLSuffix="projects/0/items/"
          tableRefreshType={SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_TRIG_REFRESH}
          addTitle="Add Item"
          editTitle="Edit Item"
          filterInForm="filter_active_only"
          choiceFieldForm="warehouse_items_form"
        >
          <AddEditWarehouseItemForm projectId={0} />
        </AddEditDtEntryDialog>
      </GridContainer>
    </div>
  );
}
