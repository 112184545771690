import React, { useEffect } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { SET_DASHBOARD_WIDGET_TYPE } from "redux/dispatch_types";
// material ui
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { makeStyles } from "@material-ui/core/styles";
// templates
import GridItem from "components/Grid/GridItem";
// types
import { RootStateType } from "redux/reducers";
// styles
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import GridContainer from "components/Grid/GridContainer";
// @ts-ignore
const useFormStyles = makeStyles(formsStyles);

const WidgetSwitcher: React.FC = () => {
  const formClasses = useFormStyles();

  const dispatch = useDispatch();

  const { viewedWidgetType } = useSelector(
    (state: RootStateType) => state.userAdminDashboard
  );

  const authUser = useSelector((state: RootStateType) => state.auth).user;

  const adminPermissionsNo = authUser
    ? authUser.userprofile.admin_permissions.length
    : 0;

  const userPermissionsNo = authUser
    ? authUser.userprofile.user_permissions.length
    : 0;

  useEffect(() => {
    if (adminPermissionsNo >= userPermissionsNo)
      dispatch({
        type: SET_DASHBOARD_WIDGET_TYPE,
        payload: "admin"
      });
    else
      dispatch({
        type: SET_DASHBOARD_WIDGET_TYPE,
        payload: "user"
      });
  }, [adminPermissionsNo, userPermissionsNo, dispatch]);

  const handleRadioClick = (e: React.FormEvent<HTMLInputElement>) => {
    dispatch({
      type: SET_DASHBOARD_WIDGET_TYPE,
      payload: e.currentTarget.value
    });
  };

  return adminPermissionsNo && userPermissionsNo ? ( // only showing switcher when both dashboards are available
    <GridContainer>
      <GridItem>
        <div
          className={
            formClasses.checkboxAndRadio +
            " " +
            formClasses.checkboxAndRadioHorizontal
          }
          style={{ float: "left" }}
        >
          <FormControlLabel
            control={
              <Radio
                checked={viewedWidgetType === "user"}
                onChange={handleRadioClick}
                value="user"
                name="radio button enabled"
                aria-label="A"
                icon={
                  <FiberManualRecord className={formClasses.radioUnchecked} />
                }
                checkedIcon={
                  <FiberManualRecord className={formClasses.radioChecked} />
                }
                classes={{
                  checked: formClasses.radio,
                  root: formClasses.radioRoot
                }}
              />
            }
            classes={{
              label: formClasses.label,
              root: formClasses.labelRoot
            }}
            label="User Dashboard"
          />
          <FormControlLabel
            control={
              <Radio
                checked={viewedWidgetType === "admin"}
                onChange={handleRadioClick}
                value="admin"
                name="radio button enabled"
                aria-label="B"
                icon={
                  <FiberManualRecord className={formClasses.radioUnchecked} />
                }
                checkedIcon={
                  <FiberManualRecord className={formClasses.radioChecked} />
                }
                classes={{
                  checked: formClasses.radio,
                  root: formClasses.radioRoot
                }}
              />
            }
            classes={{
              label: formClasses.label,
              root: formClasses.labelRoot
            }}
            label="Admin Dashboard"
          />
        </div>
      </GridItem>
    </GridContainer>
  ) : (
    <></>
  );
};

export default WidgetSwitcher;
