import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useEventListener from "global_functions/_common/useEventListener";

import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import { makeStyles } from "@material-ui/core/styles";
// custom dialog
import ConfirmationDialog from "views/Dialogs/_Common/ConfirmationDialog";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import TableFilter from "components/_Custom/_Common/TableFilter";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import CompletionDialog from "views/Dialogs/_Common/CompletionDialog";

import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import showNotification from "global_functions/redux_action_creators/showNotification";
import identifyClick from "global_functions/_common/identifyClick";
import useFetchDocument from "global_functions/_common/useFetchDocument";

import {
  SET_TS_BUNDLE_USER_DT_PAGE,
  SET_TS_BUNDLE_USER_DT_TRIG_REFRESH,
  SET_TS_BUNDLE_USER_DT_ROW,
  SET_TS_BUNDLE_USER_FILTER
} from "redux/dispatch_types";
// table
import { tsBundleUserDBMain } from "../../DataTables/Timesheets/tsBundleUserMain";

const apiUrl = process.env.REACT_APP_API_V1_URL;

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

export default function TsBundleUserPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const reduxPageProps = useSelector(state => state.tsBundleUser);

  const [dataTable, setDataTable] = useState("");

  const [cancelConfirmationDialog, setCancelConfirmationDialog] = useState(
    false
  );
  const [submitConfirmationDialog, setSubmitConfirmationDialog] = useState(
    false
  );
  const [completionDialog, setCompletionDialog] = useState(false);

  const [tsBundleId, setTsBundleId] = useState(0);

  const [confirmURL, setConfirmURL] = useState("");

  const [reportParams, setReportParams] = useState("");

  useDataTableRefresh(dataTable, tsBundleUserDBMain);

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_TS_BUNDLE_USER_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_TS_BUNDLE_USER_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
          dataTable.rows().deselect();
          switch (clickRelevantType.params[0]) {
            case "Publish":
              setConfirmURL(`timesheet-bundles/${dtData.id}`);
              setSubmitConfirmationDialog(true);
              break;
            case "Visibility":
            case "Edit":
              history.push(`/user/my-timesheets/${dtData.id}`);
              break;
            case "Cancel":
              setConfirmURL(`timesheet-bundles/${dtData.id}`);
              setCancelConfirmationDialog(true);
              break;
            case "Assignment":
              dataTable.rows().deselect();

              setReportParams({
                url:
                  apiUrl +
                  "documents/?dataset=timesheet_monthly_report&ts_bundle_id=" +
                  dtData.id,
                fileName: `${dtData.sub_user} timesheet report for ${dtData.sub_month.display}.pdf`,
                fetchMsg: "Fetching timesheet report..."
              });

              break;
            case "AssignmentGreyedOut":
            case "AssessmentGreyedOut":
              dispatch(showNotification("Empty bundle", "info"));
              break;
            case "Assessment":
              setCompletionDialog(true);
              setTsBundleId(dtData.id);
              break;
            default:
              return false;
          }
          break;
        default:
          return false;
      }
    }
  };

  // add event listener for datatable clicks
  useEventListener("click", handleClickEvent, window);

  useFetchDocument(reportParams);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Timesheet Bundles</h4>
                <h4 className={classes.cardCategoryWhite}>
                  Manage your timesheets
                </h4>
              </CardText>
              {dataTable ? (
                <TableFilter
                  color="primary"
                  activeFilter={
                    reduxPageProps.tables.tsBundleUserDBMain.pageFilter
                  }
                  dispatchType={SET_TS_BUNDLE_USER_FILTER}
                  dataTable={dataTable}
                  tabs={tsBundleUserDBMain.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setDataTable}
                tableParams={tsBundleUserDBMain}
              />
            </CardBody>
          </Card>
        </GridItem>
        {/*cancel bundle submission confirmation*/}
        <ConfirmationDialog
          confirmationDialog={cancelConfirmationDialog}
          setConfirmationDialog={setCancelConfirmationDialog}
          confirmationMessage="Are you sure you would like to unsubmit this bundle?"
          confirmationTitle="Cancel Submission"
          confirmURL={confirmURL}
          confirmPayload={{ new_status: 1 }}
          successMsg="Bundle Unsubmitted"
          errorMsg="Error submitting bundle."
          tableRefreshType={SET_TS_BUNDLE_USER_DT_TRIG_REFRESH}
        />
        {/*submit bundle confirmation*/}
        <ConfirmationDialog
          confirmationDialog={submitConfirmationDialog}
          setConfirmationDialog={setSubmitConfirmationDialog}
          confirmationMessage="Are you sure you would like to submit this bundle?"
          confirmationTitle="Submit Bundle"
          confirmURL={confirmURL}
          confirmPayload={{ new_status: 2 }}
          successMsg="Bundle Submitted"
          errorMsg="Error submitting bundle."
          tableRefreshType={SET_TS_BUNDLE_USER_DT_TRIG_REFRESH}
        />
        <CompletionDialog
          showDialog={completionDialog}
          setShowDialog={setCompletionDialog}
          tsBundleId={tsBundleId}
        />
      </GridContainer>
    </div>
  );
}
