import React, { useEffect, useState } from "react";

import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";

import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormLabel from "@material-ui/core/FormLabel";
import CustomInput from "components/CustomInput/CustomInput";
import { makeStyles } from "@material-ui/core/styles";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";

const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);

export default function AddEditClientForm(props) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  // form fields
  const [clientName, setClientName] = useState("");
  const [clientLegalName, setClientLegalName] = useState("");
  const [clientContact, setClientContact] = useState("");
  const [clientContactNo, setClientContactNo] = useState("");
  const [clientAddress1, setClientAddress1] = useState("");
  const [clientAddress2, setClientAddress2] = useState("");
  const [clientAddress3, setClientAddress3] = useState("");
  const [clientAddress4, setClientAddress4] = useState("");

  useEffect(() => {
    if (props.editData && props.open) {
      setClientName(props.editData.name);
      setClientLegalName(props.editData.legal_name);
      setClientContact(props.editData.contact_name);
      setClientContactNo(props.editData.contact_no);
      setClientAddress1(props.editData.address.line_1);
      setClientAddress2(props.editData.address.line_2);
      setClientAddress3(props.editData.address.line_3);
      setClientAddress4(props.editData.address.line_4);
    } else if (!props.editData && props.open) {
      setClientName("");
      setClientLegalName("");
      setClientContact("");
      setClientContactNo("");
      setClientAddress1("");
      setClientAddress2("");
      setClientAddress3("");
      setClientAddress4("");
    }
  }, [props.editData, props.open]);

  useEffect(() => {
    props.setformData({
      name: clientName,
      legal_name: clientLegalName,
      contact_name: clientContact,
      contact_no: clientContactNo,
      address_1: clientAddress1,
      address_2: clientAddress2,
      address_3: clientAddress3,
      address_4: clientAddress4
    });
    // eslint-disable-next-line
    }, [clientName, clientLegalName, clientContact, clientContactNo, clientAddress1, clientAddress2, clientAddress3,
    clientAddress4
  ]);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "540px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Name
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <CustomInput
                  id="clientName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Client name"
                  inputProps={{
                    type: "text",
                    value: clientName,
                    onChange: e => {
                      setClientName(e.target.value);
                    },
                    required: true,
                    maxLength: 30
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Legal Name (If Different)
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <CustomInput
                  id="clientLegalName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Client name"
                  inputProps={{
                    type: "text",
                    value: clientLegalName,
                    onChange: e => {
                      setClientLegalName(e.target.value);
                    },
                    required: false,
                    maxLength: 30
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Contact
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <CustomInput
                  id="clientContact"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: clientContact,
                    onChange: e => {
                      setClientContact(e.target.value);
                    },
                    maxLength: 50
                  }}
                  helpText=""
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3} lg={3}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Contact No
                </FormLabel>
              </GridItem>
              <GridItem xs={9} lg={3}>
                <CustomInput
                  id="clientContactNo"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: clientContactNo,
                    onChange: e => {
                      setClientContactNo(e.target.value);
                    },
                    maxLength: 50
                  }}
                  helpText=""
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Address Line 1
                </FormLabel>
              </GridItem>
              <GridItem xs={8}>
                <CustomInput
                  id="clientAddress1"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: clientAddress1,
                    onChange: e => {
                      setClientAddress1(e.target.value);
                    },
                    maxLength: 50
                  }}
                  helpText=""
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Address Line 2
                </FormLabel>
              </GridItem>
              <GridItem xs={8}>
                <CustomInput
                  id="clientAddress2"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: clientAddress2,
                    onChange: e => {
                      setClientAddress2(e.target.value);
                    },
                    maxLength: 50
                  }}
                  helpText=""
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Address Line 3
                </FormLabel>
              </GridItem>
              <GridItem xs={8}>
                <CustomInput
                  id="clientAddress3"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: clientAddress3,
                    onChange: e => {
                      setClientAddress3(e.target.value);
                    },
                    maxLength: 50
                  }}
                  helpText=""
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Address Line 4
                </FormLabel>
              </GridItem>
              <GridItem xs={8}>
                <CustomInput
                  id="clientAddress4"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: clientAddress4,
                    onChange: e => {
                      setClientAddress4(e.target.value);
                    },
                    maxLength: 50
                  }}
                  helpText=""
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
}
