import { TOGGLE_SPINNER_CURSOR } from "redux/dispatch_types";

const initialState = { show_spinning_cursor: 0 };

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SPINNER_CURSOR:
      return (state = {
        show_spinning_cursor: action.payload
      });
    default:
      return state;
  }
}
