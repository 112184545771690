import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

import Tooltip from "@material-ui/core/Tooltip";

// color
import { primaryColor } from "assets/jss/material-dashboard-pro-react";

// icon
import AllInclusive from "@material-ui/icons/AllInclusive";

const useStyles = makeStyles(theme => ({
  editButton: {
    backgroundColor: primaryColor[0],
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#a1b5be" },
    marginTop: 10,
    marginRight: 10
  }
}));

export default function ViewAllButton({
  handleViewAllClick,
  tooltip,
  extraStyleProps
}) {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip}>
      <IconButton className={classes.editButton} onClick={handleViewAllClick}>
        <AllInclusive style={{ color: "#f9f9f9", ...extraStyleProps }} />
      </IconButton>
    </Tooltip>
  );
}
