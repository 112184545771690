import axios from "axios";

import showNotification from "global_functions/redux_action_creators/showNotification";
import { LOGIN_FAIL, LOGIN_SUCCESS } from "redux/dispatch_types";
import showAuthError from "../showAuthError";

import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
const apiUrl = process.env.REACT_APP_API_V1_URL;

export const loginUser = (username, password) => (dispatch, getState) => {
  // Request Body
  const body = JSON.stringify({ username, password });

  axios
    .post(apiUrl + "accounts/login/", body, getTokenConfig(getState))
    .then(res => {
      dispatch(showNotification("Welcome back!", "success"));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
      // return <Redirect from="/" to="/admin/dashboard" />;
    })
    .catch(err => {
      dispatch(showAuthError(err));
      dispatch({
        type: LOGIN_FAIL
      });
    });
};
