import React from "react";

import GridItem from "components/Grid/GridItem";

// material ui
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

// styles
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useFormStyles = makeStyles(formsStyles);

const ScanTypeSelector = ({ scanAction, setScanAction }) => {
  const formClasses = useFormStyles();

  return (
    <GridItem>
      <div
        className={
          formClasses.checkboxAndRadio +
          " " +
          formClasses.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          style={{ float: "left" }}
          control={
            <Radio
              checked={scanAction === 0}
              onChange={e => setScanAction(parseInt(e.target.value))}
              value={0}
              name="radio button enabled"
              aria-label="A"
              icon={
                <FiberManualRecord className={formClasses.radioUnchecked} />
              }
              checkedIcon={
                <FiberManualRecord className={formClasses.radioChecked} />
              }
              classes={{
                checked: formClasses.radio,
                root: formClasses.radioRoot
              }}
            />
          }
          classes={{
            label: formClasses.label,
            root: formClasses.labelRoot
          }}
          label="Get Info"
        />
        <FormControlLabel
          style={{ float: "left" }}
          control={
            <Radio
              checked={scanAction === 1}
              onChange={e => setScanAction(parseInt(e.target.value))}
              value={1}
              name="radio button enabled"
              aria-label="A"
              icon={
                <FiberManualRecord className={formClasses.radioUnchecked} />
              }
              checkedIcon={
                <FiberManualRecord className={formClasses.radioChecked} />
              }
              classes={{
                checked: formClasses.radio,
                root: formClasses.radioRoot
              }}
            />
          }
          classes={{
            label: formClasses.label,
            root: formClasses.labelRoot
          }}
          label="Change Status"
        />
        <FormControlLabel
          style={{ float: "left" }}
          control={
            <Radio
              checked={scanAction === 2}
              onChange={e => setScanAction(parseInt(e.target.value))}
              value={2}
              name="radio button enabled"
              aria-label="A"
              icon={
                <FiberManualRecord className={formClasses.radioUnchecked} />
              }
              checkedIcon={
                <FiberManualRecord className={formClasses.radioChecked} />
              }
              classes={{
                checked: formClasses.radio,
                root: formClasses.radioRoot
              }}
            />
          }
          classes={{
            label: formClasses.label,
            root: formClasses.labelRoot
          }}
          label="Change Location"
        />
        <FormControlLabel
          style={{ float: "left" }}
          control={
            <Radio
              checked={scanAction === 3}
              onChange={e => setScanAction(parseInt(e.target.value))}
              value={3}
              name="radio button enabled"
              aria-label="A"
              icon={
                <FiberManualRecord className={formClasses.radioUnchecked} />
              }
              checkedIcon={
                <FiberManualRecord className={formClasses.radioChecked} />
              }
              classes={{
                checked: formClasses.radio,
                root: formClasses.radioRoot
              }}
            />
          }
          classes={{
            label: formClasses.label,
            root: formClasses.labelRoot
          }}
          label="Scan To/From Container"
        />
      </div>
    </GridItem>
  );
};

export default ScanTypeSelector;