import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
// redux
import { useDispatch } from "react-redux";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import { SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH } from "redux/dispatch_types";
import showNotification from "global_functions/redux_action_creators/showNotification";
// custom components & functions
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import { getDialogProps } from "global_functions/_common/getDialogProps";
//material-ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import regularFormStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import FormLabel from "@material-ui/core/FormLabel";
import CustomSelect from "components/CustomSelect/CustomSelect";
const useStyles = makeStyles(styles);
const useRegularFormStyles = makeStyles(regularFormStyle);
// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const apiUrl = process.env.REACT_APP_API_V1_URL;

export default function MultiCopyDialog({
  multiCopyDialog,
  setMultiCopyDialog,
  refreshUrl,
  itemId
}) {
  const classes = useStyles();
  const formClasses = useRegularFormStyles();
  const dispatch = useDispatch();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [copyNumber, setCopyNumber] = useState(0);

  const [formLoading, setFormLoading] = useState(false);

  // set to 0 upon opening
  useEffect(() => {
    if (multiCopyDialog) {
      setCopyNumber(0);
    }
  }, [multiCopyDialog]);

  // runs once to initialize options array
  const copyNumberOptions = useMemo(() => {
    let optArray = [];

    for (let i = 1; i <= 50; i++) {
      optArray.push({ value: i, label: i });
    }

    return optArray;
  }, []);

  const handleFormSubmit = () => {
    setFormLoading(true);
    axios
      .post(
        apiUrl + refreshUrl,
        {
          id: itemId,
          duplicate: true,
          number: copyNumber
        },
        getTokenConfig(store.getState)
      )
      .then(res => {
        setMultiCopyDialog(false);
        dispatch(showNotification(res.data.statusText, "success"));
        dispatch({
          type: SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH,
          payload: { id: null }
        });
        setFormLoading(false);
      })
      .catch(err => {
        dispatch(showNotification(err.response.data.errors[0], "error"));
        setFormLoading(false);
      });
  };

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={multiCopyDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setMultiCopyDialog(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setMultiCopyDialog(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Copy Item</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {formLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <form id="multiCopyForm" onSubmit={handleFormSubmit}>
              <GridContainer>
                <GridItem xs={12}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Entries created via this dialog will be created with a
                    'Pre-Check-In' status
                  </FormLabel>
                </GridItem>
                <GridItem xs={2} />
                <GridItem xs={5}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Number of new copies
                  </FormLabel>
                </GridItem>
                <GridItem xs={3}>
                  <CustomSelect
                    options={copyNumberOptions}
                    isDisabled={formLoading}
                    value={copyNumber}
                    setValue={setCopyNumber}
                    required={true}
                  />
                </GridItem>
                <GridItem xs={2} />
              </GridContainer>
            </form>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="primary"
            simple
            type="submit"
            form="multiCopyForm"
            disabled={formLoading}
          >
            Save
          </Button>
          <Button
            color="transparent"
            onClick={() => setMultiCopyDialog(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
