import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  arrowButton: {
    backgroundColor: "#607d8b",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#adbec6" },
    marginTop: 10,
    marginRight: 10
  },
  arrowButtonGreyed: {
    backgroundColor: "#bdbdbd",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#bdbdbd" },
    marginTop: 10,
    marginRight: 1
  }
}));

const ArrowButton: React.FC<ArrowButton> = ({
  direction,
  onClick,
  tooltip,
  fontSize,
  disabled
}) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip} style={{ margin: 0 }}>
      <IconButton
        className={disabled ? classes.arrowButtonGreyed : classes.arrowButton}
        onClick={
          disabled
            ? () => {
                return null;
              }
            : onClick
        }
        disableRipple={disabled}
      >
        {direction === "left" ? (
          <ArrowLeft fontSize={fontSize} style={{ color: "#f9f9f9" }} />
        ) : direction === "right" ? (
          <ArrowRight fontSize={fontSize} style={{ color: "#f9f9f9" }} />
        ) : (
          <></>
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ArrowButton;

interface ArrowButton {
  direction: "left" | "right";
  onClick: () => void;
  tooltip: string;
  fontSize: "large" | "small";
  disabled: boolean;
}
