// takes the table params from constants' file, checks if there should be button(s) in a row, if there should, then
// adds those buttons to the specified column, if not, returns the params as is

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { buttonParams } from "../../components/_Custom/_Common/DataTables/DATATABLE_BUTTONS";
import Tooltip from "@material-ui/core/Tooltip";
// styles
import { makeStyles } from "@material-ui/core/styles";
// import { renderToString } from 'react-dom/server'
export const rowButtonStyles = {
  button: {
    backgroundColor: "transparent",
    margin: "0 0 0 0px",
    padding: "0px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px"
    },
    fontSize: "0.875rem",
    minWidth: "28px",
    boxSizing: "border-box",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "500",
    lineHeight: "1.75",
    borderRadius: "4px",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    border: 0,
    cursor: "pointer"
  },
  icon: {
    verticalAlign: "middle",
    top: "-1px",
    position: "relative",
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    flexShrink: "0"
  },
  label: {
    width: "100%",
    display: "inherit",
    alignItems: "inherit",
    justifyContent: "inherit"
  }
};
const useStyles = makeStyles(rowButtonStyles);

export default function useTablePrams(table) {
  // eslint-disable-next-line no-unused-vars
  const rowButtonClasses = useStyles(); // needed to add classes to the system for buttons

  // buttons render faster when in jsx instead of below, find jsx by uncommenting then add to BTN_JSX.js
  // // console.log(renderToString(
  // //     <Button style={{color: dangerColor[1]}} className={rowButtonClasses.actionButton}>
  // //         <Delete className={rowButtonClasses.icon} />
  // //     </Button>
  // // ));

  const TABLE_PARAMS = table.tableParams;

  useEffect(() => {
    // find which column has a button (if any)
    if (TABLE_PARAMS.buttonsColumn) {
      let buttonsColumnId = TABLE_PARAMS.buttonsColumn.id;
      let genIcons = TABLE_PARAMS.buttonsColumn.genIcons;

      TABLE_PARAMS.columnDefs = [];
      TABLE_PARAMS.columnDefs.push({
        targets: [buttonsColumnId],
        createdCell: (td, cellData, rowData, row, col) => {
          ReactDOM.render(
            <>
              {genIcons(rowData).map((iconObj, index) => {
                return (
                  <Tooltip title={iconObj.tooltip} key={iconObj.icon + index}>
                    <button
                      className={rowButtonClasses.button}
                      tabIndex="0"
                      type="button"
                      style={{ color: buttonParams[iconObj.icon].color }}
                      data-id={`dticon-${iconObj.icon}-${row}`}
                    >
                      <span className={rowButtonClasses.label}>
                        <svg
                          className={rowButtonClasses.icon}
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d={buttonParams[iconObj.icon].pathD} />
                        </svg>
                      </span>
                    </button>
                  </Tooltip>
                );
              })}
            </>,
            td
          );
        }
      });
    }
  }, [TABLE_PARAMS, rowButtonClasses]);

  return TABLE_PARAMS;
}
