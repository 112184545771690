import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

// icon constants
const reportButtonIcon = {
  icon: "Assignment",
  tooltip: "Generate expense report"
};
const submitBundleIcon = { icon: "Publish", tooltip: "Submit bundle" };
const submitBundleGreyedIcon = {
  icon: "PublishGreyedOut",
  tooltip: "Submit bundle"
};
const editBundleIcon = { icon: "Edit", tooltip: "" };
const deleteBundleIcon = { icon: "Delete", tooltip: "" };
const viewBundleIcon = { icon: "Visibility", tooltip: "" };
const cancelBundleSubmissionIcon = {
  icon: "Cancel",
  tooltip: "Unsubmit bundle"
};
const reportGreyedIcon = {
  icon: "AssignmentGreyedOut",
  tooltip: "Generate expense report"
};

export const expBundlesUserMain = {
  section: "expBundlesUser",
  reduxTableName: "expBundlesUserMain",
  refreshURL: "expense-bundles/?page=user_expense_bundles&filter=",
  filterTabValues: [1, 2, 3],
  filterTabNames: ["Open", "Submitted", "Approved"],
  tableParams: {
    autoWidth: true,
    responsive: true,
    buttonsColumn: {
      id: 7,
      genIcons: rowData => {
        let return_array;
        switch (rowData.status) {
          case "Open":
          case "Rejected":
            if (rowData.has_entries)
              return_array = [submitBundleIcon, editBundleIcon];
            else return_array = [submitBundleGreyedIcon, editBundleIcon];
            break;
          case "Submitted":
            return_array = [viewBundleIcon, cancelBundleSubmissionIcon];
            break;
          case "Approved":
            return_array = [viewBundleIcon];
            break;
          default:
            return false;
        }

        // add report button to bundles that have entries
        if (rowData.has_entries) return_array.push(reportButtonIcon);
        else return_array.push(reportGreyedIcon);

        // add delete button to open and rejected bundles
        if (rowData.status === "Open" || rowData.status === "Rejected")
          return_array.push(deleteBundleIcon);

        return return_array;
      }
    },
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    columns: [
      { data: "id", title: "ID", width: 75 }, // 0
      {
        data: { _: "date_open.display", sort: "date_open.@data-order" },
        title: "Date Open",
        width: 150
      }, // 1
      { data: "name", title: "Bundle Name", width: 180 }, // 2
      { data: "status", title: "Status", width: 150 }, // 3
      {
        data: {
          _: "date_submitted.display",
          sort: "date_submitted.@data-order"
        },
        title: "Date Submitted",
        width: 150
      }, // 4
      {
        data: { _: "date_approved.display", sort: "date_approved.@data-order" },
        title: "Date Approved",
        width: 150
      }, // 5
      { data: "totals_string", title: "Totals", width: 150 }, // 6
      {
        data: null,
        title: "Actions",
        width: 200,
        responsivePriority: 2,
        className: "dt-body-center"
      } // 7
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "desc"]]
  }
};
