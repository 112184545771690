import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interaction from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";

export const calendarParams = {
  plugins: [resourceTimelinePlugin, interaction, dayGridPlugin],
  schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
  //events: "/ajax/refresh-bookings-timesheets",
  aspectRatio: 1.8,
  businessHours: {
    // days of week. an array of zero-based day of week integers (0=Sunday)
    daysOfWeek: [1, 2, 3, 4, 5], // Monday - Thursday

    startTime: "8:00", // a start time (10am in this example)
    endTime: "17:00" // an end time (6pm in this example)
  },
  defaultDate: new Date(),
  defaultView: "resourceTimelineWeek",
  editable: true,
  eventDurationEditable: true,
  eventStartEditable: true,
  eventResourceEditable: true, // enable dragging between resources
  header: {
    left: "today prev,next",
    center: "title",
    right: "resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth"
  },
  locale: "en-gb",
  navLinks: true,
  resourceGroupField: "department",
  resourceAreaWidth: "15%",
  resourceLabelText: "Staff",
  resourceOrder: "department, title",
  // resourceRender: function (renderInfo) {
  //     renderInfo.el.addEventListener("click", function () {
  //         showPersonalCalendar(renderInfo);
  //     });
  // },
  scrollTime: "00:00",
  selectable: true,
  slotLabelFormat: [
    { day: "numeric", weekday: "short", month: "short" },
    { hour: "numeric" }
  ],
  timezone: "local",
  titleFormat: { day: "numeric", month: "long", year: "numeric" },
  views: {
    resourceTimelineDay: {
      buttonText: "day",
      slotDuration: "00:30"
    },
    resourceTimelineWeek: {
      slotDuration: "3:00"
    },
    resourceTimelineMonth: {
      buttonText: "month",
      slotDuration: "3:00"
    }
  }
};
