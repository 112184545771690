import React, { useEffect, useState } from "react";

import axios from "axios";
// redux
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// custom components
import { WidgetTemplate } from "../WidgetTemplate";
// icons
import Person from "@material-ui/icons/Person";

const UsersWidgetAdmin: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState({
    new_users: 0
  });
  const apiUrl = process.env.REACT_APP_API_V1_URL;

  // initial load
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        apiUrl + "datasets/?type=users_widget_admin",
        getTokenConfig(store.getState)
      )
      .then(res => {
        setCardData(res.data);
        setIsLoading(false);
      });
  }, [apiUrl]);

  return (
    <WidgetTemplate
      bottomText="User Section"
      bottomUrl="/#/databases/user-database/"
      icon={<Person />}
      iconColor="primary"
      isLoading={isLoading}
      statsText=" new"
      title="Users"
      value={cardData.new_users}
    />
  );
};

export default UsersWidgetAdmin;
