import React, { useEffect, useState } from "react";
import axios from "axios";
// redux
import { useDispatch, useSelector } from "react-redux";
import store from "redux/store";
import {
  SET_SHEQ_DOCS_FILTER,
  SET_SHEQ_DOCS_DT_PAGE,
  SET_SHEQ_DOCS_DT_ROW,
  SET_SHEQ_DOCS_DT_TRIG_REFRESH,
  SET_SHEQ_DOCS_REFRESH_URL
} from "redux/dispatch_types";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import showNotification from "global_functions/redux_action_creators/showNotification";
// custom functions
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import identifyClick from "global_functions/_common/identifyClick";
import useEventListener from "global_functions/_common/useEventListener";
// template components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "../../../components/Card/CardFooter";
// custom components
import AddButton from "../../../components/CustomButtons/AddButton";
import CustomLoadingSpinner from "../../../components/_Custom/_Common/CustomLoadingSpinner";
import AddEditSHEQDocumentForm from "../../Forms/AddEditSHEQDocumentForm";
import AddEditDtEntryDialog from "../../Dialogs/_Common/AddEditDtEntryDialog";
import BackButton from "../../../components/CustomButtons/BackButton";
import DeleteDialog from "../../Dialogs/_Common/DeleteDialog";
import ManageImgPdfUploadsDialog from "../../Dialogs/Uploads/ManageImgPdfUploadsDialog";
import ReadConfirmationsDialog from "../../Dialogs/HRHS/ReadConfirmationsDialog";
// datatables
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import { sheqDocsMain } from "../../DataTables/HRHS/sheqDocsMain";
import TableFilter from "../../../components/_Custom/_Common/TableFilter";
// styles
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

const SHEQDocsPage = props => {
  const parentProjectId = props.match.params.slug;

  const dispatch = useDispatch();
  const classes = useStyles();

  const [dataTable, setDataTable] = useState("");
  const [editDelId, setEditDelId] = useState(null);

  // modals
  const [addEditModal, setAddEditModal] = useState(false);
  const [readConfirmationsDialog, setReadConfirmationsDialog] = useState(false);
  const [manageUploadsDialog, setManageUploadsDialog] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");

  const [uploadParentDocId, setUploadParentDocId] = useState();

  // project details at the top of the page
  const [projectDetailsLoading, setProjectDetailsLoading] = useState(false);
  const [projectDescription, setProjectDescription] = useState("");
  const [clientName, setClientName] = useState("");

  const reduxPageProps = useSelector(state => state.sheqDocs);
  const currentUser = useSelector(state => state.auth.user);

  useDataTableRefresh(dataTable, sheqDocsMain);

  const handleAddClick = e => {
    e.stopPropagation();
    e.preventDefault();
    setEditDelId(0);
    setAddEditModal(true);
  };

  const handleReadStatusChange = (newStatus, docId) => {
    axios
      .put(
        `${apiUrl}projects/${parentProjectId}/sheq_docs/${docId}`,
        {
          read_status_change: true,
          new_read_status: newStatus
        },
        getTokenConfig(store.getState)
      )
      .then(() => {
        dispatch(
          showNotification(
            newStatus ? "Item marked as read" : "Item marked as unread",
            "success"
          )
        );
        dispatch({
          type: SET_SHEQ_DOCS_DT_TRIG_REFRESH,
          payload: { id: null }
        });
      })
      .catch(() => {
        dispatch(
          showNotification(
            "Read status change failed. Please check your internet connection and try again.",
            "error"
          )
        );
      });
  };

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_SHEQ_DOCS_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_SHEQ_DOCS_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
          dataTable.rows().deselect();
          if (clickRelevantType.params[0] === "Edit") {
            setEditDelId(dtData.id);
            setAddEditModal(true);
          } else if (clickRelevantType.params[0] === "Delete") {
            dataTable.rows().deselect();
            setEditDelId(dtData.id);
            setDeleteName(`${dtData.name} (${dtData.category_str})`);
            setDeleteModal(true);
          } else if (clickRelevantType.params[0].substring(0, 6) === "Filter") {
            setManageUploadsDialog(true);
            setUploadParentDocId(
              dataTable.rows(clickRelevantType.params[1]).data()[0].id
            );
          } else if (clickRelevantType.params[0] === "CheckBoxOutline") {
            handleReadStatusChange(true, dtData.id);
          } else if (clickRelevantType.params[0] === "CheckBox") {
            handleReadStatusChange(false, dtData.id);
          } else if (clickRelevantType.params[0] === "People") {
            dataTable.rows().deselect();
            setEditDelId(dtData.id);
            setReadConfirmationsDialog(true);
          }
          break;
        default:
          return false;
      }
    }
  };

  // get project details to display on top of the page
  useEffect(() => {
    setProjectDetailsLoading(true);
    axios
      .get(
        apiUrl + "projects/" + parentProjectId,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setProjectDescription(res.data.description);
        setClientName(res.data.client_name);
        setProjectDetailsLoading(false);
      });
  }, [parentProjectId]);

  // sets refresh url for page on load
  useEffect(() => {
    dispatch({
      type: SET_SHEQ_DOCS_REFRESH_URL,
      payload: `projects/${parentProjectId}/sheq_docs/?filter=`
    });
  }, [parentProjectId, dispatch]);

  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>
                  SHEQ Documents for{" "}
                  {projectDetailsLoading ? "..." : projectDescription}
                </h4>
                <h4 className={classes.cardCategoryWhite}>
                  {projectDetailsLoading ? "..." : clientName}
                </h4>
              </CardText>
              <GridContainer>
                <GridItem xs={12}>
                  <BackButton />
                </GridItem>
              </GridContainer>
              {dataTable ? (
                <TableFilter
                  color="primary"
                  activeFilter={reduxPageProps.tables.sheqDocsMain.pageFilter}
                  dispatchType={SET_SHEQ_DOCS_FILTER}
                  dataTable={dataTable}
                  tabs={sheqDocsMain.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                tableParams={sheqDocsMain}
                setDataTable={setDataTable}
              />
            </CardBody>
            <CardFooter>
              {currentUser.userprofile.is_hrhs_admin ? (
                <AddButton
                  handleAddClick={e => handleAddClick(e)}
                  tooltip="Add SHEQ document"
                />
              ) : (
                <></>
              )}
            </CardFooter>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelId}
          dataTable={dataTable}
          tableRefreshType={SET_SHEQ_DOCS_DT_TRIG_REFRESH}
          addTitle="Add SHEQ Document"
          filterInForm="filter_active_and_completed"
          choiceFieldForm="add_sheq_document_form"
          urlSuffix={`projects/${parentProjectId}/sheq_docs/`}
        >
          <AddEditSHEQDocumentForm parentProjId={parentProjectId} />
        </AddEditDtEntryDialog>
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="this project's documents?"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={editDelId}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_SHEQ_DOCS_DT_TRIG_REFRESH}
          urlSuffix={`projects/${parentProjectId}/sheq_docs/`}
        />
        <ManageImgPdfUploadsDialog
          manageUploadsDialog={manageUploadsDialog}
          setManageUploadsDialog={setManageUploadsDialog}
          refreshUrl={`projects/${parentProjectId}/sheq_docs/`}
          tableRefreshType={SET_SHEQ_DOCS_DT_TRIG_REFRESH}
          parentId={uploadParentDocId}
          showControls={currentUser.userprofile.is_hrhs_admin}
          title="Manage Uploaded Documents"
        />
        <ReadConfirmationsDialog
          readConfirmationsDialog={readConfirmationsDialog}
          setReadConfirmationsDialog={setReadConfirmationsDialog}
          docId={editDelId}
        />
      </GridContainer>
    </div>
  );
};

export default SHEQDocsPage;
