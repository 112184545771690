import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// actions
import { registerUser } from "global_functions/redux_action_creators/auth/registerUser";
// redux
import { useDispatch } from "react-redux";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function RegisterPage() {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [dept, setDept] = React.useState("2");
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");

  const history = useHistory();
  const classes = useStyles();

  function handleFormSubmit() {
    dispatch(
      registerUser(
        firstName,
        lastName,
        email,
        dept,
        password,
        password2,
        history
      )
    );
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Register</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={8} md={10}>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          First Name
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <CustomInput
                          id="firstName"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            fontSize: "16px",
                            type: "text",
                            onChange: e => setFirstName(e.target.value)
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Last Name
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <CustomInput
                          id="lastName"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            fontSize: "16px",
                            type: "text",
                            onChange: e => setLastName(e.target.value)
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Email
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <CustomInput
                          id="email_adress"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            fontSize: "16px",
                            type: "email",
                            onChange: e => setEmail(e.target.value)
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel
                          className={
                            classes.labelHorizontal +
                            " " +
                            classes.labelHorizontalRadioCheckbox
                          }
                        >
                          Department
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={dept === "2"}
                                onChange={e => setDept(e.target.value)}
                                value="2"
                                name="radio button demo"
                                aria-label="B"
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                              root: classes.labelRoot
                            }}
                            label="Engineering"
                          />
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={dept === "1"}
                                onChange={e => setDept(e.target.value)}
                                value="1"
                                name="radio button demo"
                                aria-label="A"
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                              root: classes.labelRoot
                            }}
                            label="Programming & Design"
                          />
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={dept === "3"}
                                onChange={e => setDept(e.target.value)}
                                value="3"
                                name="radio button demo"
                                aria-label="B"
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                              root: classes.labelRoot
                            }}
                            label="Subcontractors"
                          />
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={dept === "4"}
                                onChange={e => setDept(e.target.value)}
                                value="4"
                                name="radio button demo"
                                aria-label="B"
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                              root: classes.labelRoot
                            }}
                            label="Management"
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Password
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <CustomInput
                          id="pass"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            fontSize: "16px",
                            type: "password",
                            autoComplete: "off",
                            onChange: e => setPassword(e.target.value)
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Repeat Password
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <CustomInput
                          id="passRepeat"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            fontSize: "16px",
                            type: "password",
                            autoComplete: "off",
                            onChange: e => setPassword2(e.target.value)
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button
                type="submit"
                color="primary"
                simple
                size="lg"
                onClick={handleFormSubmit}
                block
              >
                Register
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
