import React, { useEffect, useState } from "react";

import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import CustomSelect from "components/CustomSelect/CustomSelect";

import { customTheme } from "global_functions/_common/customTheme";

import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormLabel from "@material-ui/core/FormLabel";

import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import formatDate from "global_functions/_common/formatDate";

const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);

export default function AddEditCertificateForm(props) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  const userId = props.userId;

  // form fields
  const [certId, setCertId] = useState("");
  const [expiryDate, setExpiryDate] = useState(new Date());

  useEffect(() => {
    if (props.editData && props.open) {
      setCertId(props.editData.name);
    } else if (!props.editData && props.open) {
      setCertId("");
    }
  }, [props.editData, props.open]);

  useEffect(() => {
    props.setformData({
      name: certId,
      expiry_date: formatDate(expiryDate, "yyyy-mm-dd"),
      user: userId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certId, expiryDate]);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "120px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            <GridContainer>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Certificate
                  </FormLabel>
                </GridItem>
                <GridItem xs={8}>
                  <CustomSelect
                    options={props.formChoiceFields.cert_type_list}
                    isDisabled={props.formLoading}
                    value={certId}
                    setValue={setCertId}
                    required={true}
                  />
                </GridItem>
                <GridItem xs={1} />
              </GridContainer>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Expiry Date
                </FormLabel>
              </GridItem>
              <GridItem xs={6}>
                <ThemeProvider theme={customTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ float: "left" }}
                      margin="normal"
                      label="Date"
                      format="dd/MM/yyyy"
                      value={expiryDate}
                      onChange={date => setExpiryDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
}
