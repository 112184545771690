import React, { useEffect, useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
// custom components
import Button from "../../components/CustomButtons/Button";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
// material-ui
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import Checkbox from "@material-ui/core/Checkbox";
import { Clear } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// template
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// custom functions
import formatDate from "../../global_functions/_common/formatDate";
import padNumber from "../../global_functions/_common/padNumber";
// styles
import { customTheme } from "../../global_functions/_common/customTheme";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import { primaryColor } from "../../assets/jss/material-dashboard-pro-react";

const rowButtonStyles = {
  actionButton: {
    minWidth: "30px",
    margin: "35px 0 0 0px",
    padding: "0px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px"
    }
  },
  icon: {
    verticalAlign: "middle",
    width: "20px",
    height: "20px",
    top: "-1px",
    position: "relative"
  }
};

const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);
const useButtonStyles = makeStyles(rowButtonStyles);

const AddEditVehicleForm = props => {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();
  const rowButtonClasses = useButtonStyles();

  // form fields
  const [regNo, setRegNo] = useState("");
  const [makeModel, setMakeModel] = useState("");
  const [fuelPin, setFuelPin] = useState("");
  const [taxExpiryDate, setTaxExpiryDate] = useState(new Date());
  const [motExpiryDate, setMotExpiryDate] = useState(new Date());
  const [nextServiceDate, setNextServiceDate] = useState(new Date());
  const [dartRegistered, setDartRegistered] = useState(false);
  const [ccRegistered, setCcRegistered] = useState(false);
  const [trackerInstalled, setTrackerInstalled] = useState(false);
  const [assignedToUser, setAssignedToUser] = useState(null);

  useEffect(() => {
    if (props.editData && props.open) {
      setRegNo(props.editData.reg_no);
      setMakeModel(props.editData.make_model);
      setFuelPin(
        props.editData.fuel_pin ? padNumber(props.editData.fuel_pin, 4) : null
      );
      setDartRegistered(props.editData.dart_registered);
      setCcRegistered(props.editData.cc_registered);
      setTrackerInstalled(props.editData.tracker_installed);
      setNextServiceDate(
        props.editData.next_service
          ? new Date(props.editData.next_service)
          : null
      );
      setMotExpiryDate(
        props.editData.mot_expiry ? new Date(props.editData.mot_expiry) : null
      );
      setTaxExpiryDate(
        props.editData.tax_expiry ? new Date(props.editData.tax_expiry) : null
      );
      setAssignedToUser(props.editData.assigned_to);
    } else if (!props.editData && props.open) {
      setRegNo("");
      setMakeModel("");
      setFuelPin(null);
      setDartRegistered(false);
      setCcRegistered(false);
      setTrackerInstalled(false);
      setTaxExpiryDate(null);
      setMotExpiryDate(null);
      setNextServiceDate(null);
      setAssignedToUser(null);
    }
  }, [props.editData, props.open]);

  useEffect(() => {
    props.setformData({
      make_model: makeModel,
      reg_no: regNo,
      fuel_pin: fuelPin ? fuelPin : null,
      dart_registered: dartRegistered,
      cc_registered: ccRegistered,
      tracker_installed: trackerInstalled,
      tax_expiry: taxExpiryDate
        ? formatDate(taxExpiryDate, "yyyy-mm-dd")
        : null,
      mot_expiry: motExpiryDate
        ? formatDate(motExpiryDate, "yyyy-mm-dd")
        : null,
      next_service: nextServiceDate
        ? formatDate(nextServiceDate, "yyyy-mm-dd")
        : null,
      assigned_to: assignedToUser
    });
    // eslint-disable-next-line
    }, [regNo, makeModel, fuelPin, taxExpiryDate, motExpiryDate, nextServiceDate, ccRegistered, trackerInstalled,
    dartRegistered,
    assignedToUser
  ]);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "540px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Make & Model
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <CustomInput
                  id="makeModel"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Client name"
                  inputProps={{
                    type: "text",
                    value: makeModel,
                    onChange: e => {
                      setMakeModel(e.target.value);
                    },
                    required: true,
                    maxLength: 30
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Registration Number
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  id="regNo"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Client name"
                  inputProps={{
                    type: "text",
                    value: regNo,
                    onChange: e => {
                      setRegNo(e.target.value);
                    },
                    required: true,
                    maxLength: 10
                  }}
                />
              </GridItem>
              <GridItem xs={3}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Fuel PIN
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  id="fuelPin"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    value: fuelPin,
                    onChange: e => {
                      setFuelPin(e.target.value);
                    },
                    max: 9999,
                    min: 0
                  }}
                  helpText=""
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Assigned To
                </FormLabel>
              </GridItem>
              <GridItem xs={7}>
                <CustomSelect
                  options={props.formChoiceFields.user_list}
                  isDisabled={props.formLoading}
                  value={assignedToUser}
                  setValue={setAssignedToUser}
                  required={false}
                />
              </GridItem>
              <GridItem xs={1}>
                <Button
                  className={rowButtonClasses.actionButton}
                  style={{ backgroundColor: primaryColor[1] }}
                  onClick={() => setAssignedToUser(null)}
                >
                  <Clear className={rowButtonClasses.icon} />
                </Button>
              </GridItem>
              <GridItem xs={1} />
            </GridContainer>
            <GridContainer>
              <GridItem xs={6}>
                <div
                  className={formClasses.inlineChecks}
                  style={{ marginTop: "25px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="singleDayCheck"
                        tabIndex={-1}
                        onClick={e => setDartRegistered(e.target.checked)}
                        checked={dartRegistered}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot
                    }}
                    label="Dart Registered"
                  />
                </div>
              </GridItem>
              <GridItem xs={6}>
                <div
                  className={formClasses.inlineChecks}
                  style={{ marginTop: "25px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="singleDayCheck"
                        tabIndex={-1}
                        onClick={e => setCcRegistered(e.target.checked)}
                        checked={ccRegistered}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot
                    }}
                    label="CC Registered"
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6}>
                <div
                  className={formClasses.inlineChecks}
                  style={{ marginTop: "25px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="singleDayCheck"
                        tabIndex={-1}
                        onClick={e => setTrackerInstalled(e.target.checked)}
                        checked={trackerInstalled}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot
                    }}
                    label="Tracker Installed"
                  />
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={6}>
                <ThemeProvider theme={customTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ float: "left" }}
                      margin="normal"
                      label="Tax Expiry Date"
                      format="dd/MM/yyyy"
                      value={taxExpiryDate}
                      onChange={date => setTaxExpiryDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </GridItem>
              <GridItem xs={6}>
                <ThemeProvider theme={customTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ float: "left" }}
                      margin="normal"
                      label="MOT Expiry Date"
                      format="dd/MM/yyyy"
                      value={motExpiryDate}
                      onChange={date => setMotExpiryDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6}>
                <ThemeProvider theme={customTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ float: "left", marginTop: "36px" }}
                      margin="normal"
                      label="Next Service Date"
                      format="dd/MM/yyyy"
                      value={nextServiceDate}
                      onChange={date => setNextServiceDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
};

export default AddEditVehicleForm;
