import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";

import { useMediaQuery } from "react-responsive";
import { getDialogProps } from "global_functions/_common/getDialogProps";

import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
// styles
import { makeStyles } from "@material-ui/core/styles";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";

import { Chart, Doughnut, HorizontalBar } from "react-chartjs-2";
// @material-ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import DialogActions from "@material-ui/core/DialogActions";

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useNotifyStyles = makeStyles(notificationStyles);

const apiURL = process.env.REACT_APP_API_V1_URL;

// custom initializer for doughnut chart (to put a label in the niddle)
let originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function() {
    originalDoughnutDraw.apply(this);
    let chart = this.chart;
    let width = chart.chart.width;
    let height = chart.chart.height;
    let ctx = chart.chart.ctx;

    let fontSize = (height / 114).toFixed(2);

    ctx.font = fontSize + "em sans-serif";
    ctx.textBaseline = "middle";

    let text = chart.config.options.options.centerText;
    let textX = Math.round((width - ctx.measureText(text).width) / 2);
    let textY = height / 2;

    ctx.fillText(text, textX, textY);
  }
});

export default function CompletionDialog(props) {
  const notifyClasses = useNotifyStyles();

  const [isLoading, setIsLoading] = useState(false);

  const [doughnutData, setDoughnutData] = useState({});
  const [doughnutParams, setDoughnutParams] = useState({});

  const [barChartData, setBarChartData] = useState({});
  const [barChartParams, setBarChartParams] = useState({});

  const [missingHours, setMissingHours] = useState("");
  const [missingList, setMissingList] = useState("");
  const [overtimeHours, setOvertimeHours] = useState("");
  const [overtimeList, setOvertimeList] = useState("");

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  // pull completion info from backend when loading
  useEffect(() => {
    if (props.showDialog && props.tsBundleId) {
      setIsLoading(true);
      document.addEventListener("keydown", captureKeyDown, false);
      axios
        .get(
          apiURL +
            "timesheet-bundles/?gen_completion=true&ts_bundle_id=" +
            props.tsBundleId,
          getTokenConfig(store.getState)
        )
        .then(res => {
          // updating bar chart
          let graphsData = res.data[0].Data.graphs_data;
          setBarChartParams({
            scales: {
              yAxes: [
                {
                  stacked: true,
                  gridLines: {
                    display: true,
                    color: "rgba(255,99,132,0.2)"
                  }
                }
              ],
              xAxes: [
                {
                  gridLines: {
                    display: false
                  }
                }
              ]
            },
            elements: {
              center: {
                text: "" //set as you wish
              }
            },
            legend: {
              display: false
            },
            maintainAspectRatio: false
          });

          setBarChartData(graphsData);

          // updating doughnut chart
          let summData = res.data[0].Data.ts_summary;
          let instanceHours = summData["Hours"];
          let instancePercHours = summData["percHours"];

          let totalsData = res.data[0].Data.date_totals;
          // let instanceDays = summData["Days"];
          // let instancePercDays = summData["percDays"];

          let hrDiff = totalsData.workingHours - instanceHours;
          hrDiff < 0 && (hrDiff = 0);

          setMissingHours(summData["missing_total"]);
          setMissingList(summData["missing_days"]);
          setOvertimeHours(summData["overtime_total"]);
          setOvertimeList(summData["overtime_days"]);

          setDoughnutParams({
            type: "doughnut",
            legend: {
              display: false
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              centerText: instancePercHours
            }
          });

          setDoughnutData({
            labels: ["Incomplete Hours", "Complete Hours"],
            datasets: [
              {
                data: [hrDiff, instanceHours],
                backgroundColor: ["#FF6384", "#36A2EB"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB"]
              }
            ]
          });

          setIsLoading(false);
        });
    } else {
      document.removeEventListener("keydown", captureKeyDown, false);
    }
  }, [props.showDialog, props.tsBundleId, captureKeyDown]);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} className={notifyClasses.center}>
        <Dialog
          {...getDialogProps(isTabletOrMobile, notifyClasses)}
          fullWidth={true}
          open={props.showDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => props.setShowDialog(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={notifyClasses.modalHeader}
          >
            <Button
              justIcon
              className={notifyClasses.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => props.setShowDialog(false)}
            >
              <Close className={notifyClasses.modalClose} />
            </Button>
            <h4 className={notifyClasses.modalTitle}>
              Timesheet Bundle Completion
            </h4>
          </DialogTitle>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "300px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              ) : (
                <Doughnut data={doughnutData} options={doughnutParams} />
              )}
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={1} />
            <GridItem xs={5}>
              <h5>Missing Hours:</h5>
              <h5>{missingHours}</h5>
              <h5>Missing Days:</h5>
              <p>
                {missingList ? (
                  missingList.map((listObj, index) => {
                    return (
                      <React.Fragment key={`mh${index}`}>
                        {`${listObj.day} - ${listObj.hours} hours`}
                        <br />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <>Loading...</>
                )}
              </p>
            </GridItem>
            <GridItem xs={5}>
              <h5>Overtime Hours:</h5>
              <h5>{overtimeHours}</h5>
              <h5>Overtime Days:</h5>
              <p>
                {overtimeList ? (
                  overtimeList.map((listObj, index) => {
                    return (
                      <React.Fragment key={`ot${index}`}>
                        {`${listObj.day} - ${listObj.hours} hours`}
                        <br />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <>Loading...</>
                )}
              </p>
            </GridItem>
            <GridItem xs={1} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <HorizontalBar
                data={barChartData}
                options={barChartParams}
                height={290}
              />
            </GridItem>
          </GridContainer>
          <DialogActions className={notifyClasses.modalFooter}>
            <Button
              onClick={() => props.setShowDialog(false)}
              color="transparent"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}
