import {
  DataTableParamsType,
  DatatableIconType
} from "../../../global_functions/types/dataTables";

import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const editBundleIcon = { icon: "Edit", tooltip: "Edit quote" };
const calculatorIcon = {
  icon: "FormatListNumberedIcon",
  tooltip: "Add/edit breakdown"
};
const deleteBundleIcon = { icon: "Delete", tooltip: "Delete quote" };
const generateReportButton = { icon: "Assignment", tooltip: "Generate quote" };
const markAsSoldButton = { icon: "CheckCircle", tooltip: "Mark as sold" };
const markAsDeclinedButton = { icon: "Cancel", tooltip: "Mark as declined" };

const blueUploadButton = { icon: "CloudUpload", tooltip: "Upload PO" };
const greenUploadButton = { icon: "CloudUploadGreen", tooltip: "Upload PO" };
const redUploadButton = { icon: "CloudUploadRed", tooltip: "Upload PO" };

export const salesPipelineMain: DataTableParamsType = {
  section: "salesPipeline",
  reduxTableName: "salesPipelineMain",
  refreshURL: "quotes/?filter=",
  filterTabValues: [0, 2, 3, 6, 7],
  filterTabNames: ["All", "Quote Sent", "PO Received", "Declined", "Archived"],
  tableParams: {
    autoWidth: true,
    responsive: true,
    buttonsColumn: {
      id: 9,
      genIcons: (rowData: SalesPipelineDatatableRow): DatatableIconType[] => {
        // add buttons based on quote status
        let btnArr: DatatableIconType[] = [];

        if (rowData.status_int === 2)
          btnArr = [markAsSoldButton, markAsDeclinedButton];
        else if (rowData.status_int === 3) btnArr = [markAsDeclinedButton];
        else if (rowData.status_int === 6) btnArr = [markAsSoldButton];

        // set upload button color
        let uploadButton;
        if (rowData.po_upload_no > 0)
          // if there are uploads then always green
          uploadButton = greenUploadButton;
        else if (rowData.status_int === 3)
          // if there are no uploads and the status is 'po received' then red
          uploadButton = redUploadButton;
        else uploadButton = blueUploadButton;

        if (rowData.view_ready) {
          return [
            generateReportButton,
            editBundleIcon,
            ...btnArr,
            calculatorIcon,
            uploadButton,
            deleteBundleIcon
          ];
        } else {
          // same as above but without generate report button
          return [
            editBundleIcon,
            ...btnArr,
            calculatorIcon,
            uploadButton,
            deleteBundleIcon
          ];
        }
      }
    },
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    columns: [
      { data: "id", title: "Quote ID", responsivePriority: 1, width: 100 }, //0
      {
        data: { _: "date.display", sort: "date.@data-order" },
        title: "Date",
        width: 500
      }, // 1
      {
        data: "number",
        title: "Project No.",
        responsivePriority: 1,
        width: 300
      }, // 2
      // {
      //   data: "variation",
      //   title: "Variation",
      //   responsivePriority: 1,
      //   width: 50
      // },
      { data: "client", title: "Client", width: 500 }, // 3
      { data: "proj_name", title: "Project Name", width: 500 }, // 4
      // { data: "pm", title: "Project Manager", width: 200 },
      { data: "service", title: "Service(s)", width: 500 }, // 5
      {
        data: "service_description",
        title: "Service Description",
        width: 500,
        visible: false // 6
      },
      { data: "price", title: "Price", width: 50, className: "dt-body-right" }, // 7
      // {
      //   data: "outstanding",
      //   title: "Outstanding",
      //   width: 50,
      //   className: "dt-body-right"
      // },
      // {
      //   data: "po_number",
      //   title: "Po Number",
      //   width: 400,
      //   className: "dt-body-right"
      // },
      {
        data: "status",
        title: "Status",
        width: 300,
        className: "dt-body-center"
      }, // 8
      {
        data: null,
        title: "Actions",
        width: 800,
        responsivePriority: 1,
        className: "dt-body-center"
      } // 9
    ],
    aaSorting: [[1, "asc"]]
  }
};

type SalesPipelineDatatableRow = {
  status_int: number;
  view_ready: boolean;
  po_upload_no: number;
};
