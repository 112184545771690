import React, { useEffect, useState } from "react";

import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import CustomInput from "components/CustomInput/CustomInput";

import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomSelect from "components/CustomSelect/CustomSelect";

import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);

export default function AddEditCertificateTypeForm(props) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  // form fields
  const [certTypeIssuer, setCertTypeIssuer] = useState("");
  const [certTypeName, setCertTypeName] = useState("");
  const [certTypeDuration, setCertTypeDuration] = useState("");
  const [certTypeCurrent, setCertTypeCurrent] = useState("");
  const [reqPositions, setReqPositions] = useState([]);

  useEffect(() => {
    if (props.editData && props.open) {
      setCertTypeIssuer(props.editData.issuer);
      setCertTypeName(props.editData.name);
      setCertTypeDuration(props.editData.duration);
      setReqPositions(props.editData.req_pos);

      // set current type
      if (props.editData.current) {
        setCertTypeCurrent("Current");
      } else {
        setCertTypeCurrent("Obsolete");
      }
    } else if (!props.editData && props.open) {
      setCertTypeIssuer("");
      setCertTypeName("");
      setCertTypeDuration("");
      setCertTypeCurrent("Current");
      setReqPositions([]);
    }
  }, [props.editData, props.open]);

  useEffect(() => {
    // get certificate current status in boolean
    let isCurrent;
    certTypeCurrent === "Obsolete" ? (isCurrent = 0) : (isCurrent = 1);

    props.setformData({
      name: certTypeName,
      issuer: certTypeIssuer,
      duration: certTypeDuration,
      current: isCurrent,
      req_pos: reqPositions
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    certTypeName,
    certTypeIssuer,
    certTypeDuration,
    certTypeCurrent,
    reqPositions
  ]);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "120px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Issuer
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <CustomInput
                  id="certTypeIssuer"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Certificate type issuer"
                  inputProps={{
                    type: "text",
                    value: certTypeIssuer,
                    onChange: e => {
                      setCertTypeIssuer(e.target.value);
                    },
                    required: true,
                    maxLength: 30
                  }}
                />
              </GridItem>
              <GridItem xs={1} />
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Name
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <CustomInput
                  id="certTypeName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Certificate type name"
                  inputProps={{
                    type: "text",
                    value: certTypeName,
                    onChange: e => {
                      setCertTypeName(e.target.value);
                    },
                    required: true,
                    maxLength: 30
                  }}
                />
              </GridItem>
              <GridItem xs={1} />
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Duration (months)
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  id="certTypeDuration"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Certificate type duration"
                  inputProps={{
                    type: "number",
                    value: certTypeDuration,
                    onChange: e => {
                      setCertTypeDuration(e.target.value);
                    },
                    required: true,
                    maxLength: 30,
                    min: 1,
                    max: 240
                  }}
                />
              </GridItem>
              <GridItem xs={6}>
                <div
                  className={
                    formClasses.checkboxAndRadio +
                    " " +
                    formClasses.checkboxAndRadioHorizontal
                  }
                  style={{ float: "left" }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={certTypeCurrent === "Current"}
                        onChange={e => setCertTypeCurrent(e.target.value)}
                        value="Current"
                        name="radio button enabled"
                        aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={formClasses.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={formClasses.radioChecked}
                          />
                        }
                        classes={{
                          checked: formClasses.radio,
                          root: formClasses.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot
                    }}
                    label="Current"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={certTypeCurrent === "Obsolete"}
                        onChange={e => setCertTypeCurrent(e.target.value)}
                        value="Obsolete"
                        name="radio button enabled"
                        aria-label="B"
                        icon={
                          <FiberManualRecord
                            className={formClasses.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={formClasses.radioChecked}
                          />
                        }
                        classes={{
                          checked: formClasses.radio,
                          root: formClasses.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot
                    }}
                    label="Obsolete"
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Required for Positions
                  </FormLabel>
                </GridItem>
                <GridItem xs={8}>
                  <CustomSelect
                    isMulti={true}
                    options={props.formChoiceFields.positions_list}
                    isDisabled={props.formLoading}
                    value={reqPositions}
                    setValue={setReqPositions}
                  />
                </GridItem>
                <GridItem xs={1} />
              </GridContainer>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
}
