import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  SET_PROJ_WITH_SHEQ_FILTER,
  SET_PROJ_WITH_SHEQ_DT_PAGE,
  SET_PROJ_WITH_SHEQ_DT_ROW,
  SET_PROJ_WITH_SHEQ_DT_TRIG_REFRESH
} from "redux/dispatch_types";
// custom functions
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import identifyClick from "global_functions/_common/identifyClick";
import useEventListener from "global_functions/_common/useEventListener";
// template components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "../../../components/Card/CardFooter";
// custom components
import AddButton from "../../../components/CustomButtons/AddButton";
import CustomLoadingSpinner from "../../../components/_Custom/_Common/CustomLoadingSpinner";
import AddEditSHEQDocumentForm from "../../Forms/AddEditSHEQDocumentForm";
import AddEditDtEntryDialog from "../../Dialogs/_Common/AddEditDtEntryDialog";
// datatables
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import { projectsWithSheqDocumentsMain } from "../../DataTables/HRHS/projectsWithSheqDocumentsMain";
import TableFilter from "../../../components/_Custom/_Common/TableFilter";
// styles
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

const ProjectsWithSHEQDocsPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const history = useHistory();

  const [addEditModal, setAddEditModal] = useState(false);
  const [dataTable, setDataTable] = useState("");
  const [editDelId, setEditDelId] = useState(null);

  const reduxPageProps = useSelector(state => state.projectsWithSheqDocuments);
  const currentUser = useSelector(state => state.auth.user);

  useDataTableRefresh(dataTable, projectsWithSheqDocumentsMain);

  const handleAddClick = e => {
    e.stopPropagation();
    e.preventDefault();
    setEditDelId(0);
    setAddEditModal(true);
  };

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_PROJ_WITH_SHEQ_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_PROJ_WITH_SHEQ_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
          if (clickRelevantType.params[0] === "Visibility") {
            history.push(`/admin/hrhs-projects-with-sheq-items/${dtData.id}`);
          }
          break;
        default:
          return false;
      }
    }
  };

  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>
                  Projects with SHEQ Documents
                </h4>
                <h4 className={classes.cardCategoryWhite}>
                  Click the &apos;View&apos; icon to see items
                </h4>
              </CardText>
              {dataTable ? (
                <TableFilter
                  color="primary"
                  activeFilter={
                    reduxPageProps.tables.projectsWithSheqDocumentsMain
                      .pageFilter
                  }
                  dispatchType={SET_PROJ_WITH_SHEQ_FILTER}
                  dataTable={dataTable}
                  tabs={projectsWithSheqDocumentsMain.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                tableParams={projectsWithSheqDocumentsMain}
                setDataTable={setDataTable}
              />
            </CardBody>
            <CardFooter>
              {currentUser.userprofile.is_hrhs_admin ? (
                <AddButton
                  handleAddClick={e => handleAddClick(e)}
                  tooltip="Add SHEQ document"
                />
              ) : (
                <></>
              )}
            </CardFooter>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelId}
          dataTable={dataTable}
          tableRefreshType={SET_PROJ_WITH_SHEQ_DT_TRIG_REFRESH}
          addTitle="Add SHEQ Document"
          filterInForm="filter_active_and_completed"
          choiceFieldForm="add_sheq_document_form"
          urlSuffix="projects/0/sheq_docs/"
        >
          <AddEditSHEQDocumentForm parentProjId={0} />
        </AddEditDtEntryDialog>
      </GridContainer>
    </div>
  );
};

export default ProjectsWithSHEQDocsPage;
