import React, { useEffect, useState } from "react";

import DateFnsUtils from "@date-io/date-fns";

import { useHistory } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  SET_HOLS_ADMIN_MAIN_DT_FILTER,
  SET_HOLS_ADMIN_MAIN_DT_PAGE,
  SET_HOLS_ADMIN_MAIN_DT_TRIG_REFRESH,
  SET_HOLS_ADMIN_MAIN_DT_ROW,
  SET_HOLS_ADMIN_SICKIE_DT_PAGE,
  SET_HOLS_ADMIN_SICKIE_DT_ROW,
  SET_HOLS_ADMIN_STATS_DT_PAGE,
  SET_HOLS_ADMIN_STATS_DT_ROW,
  SET_HOLS_ADMIN_STATS_DT_TRIG_REFRESH,
  SET_HOLS_ADMIN_STATS_REFRESH_URL,
  SET_HOLS_ADMIN_SICKIE_REFRESH_URL
} from "redux/dispatch_types";
// custom functions
import useFetchDocument from "../../../global_functions/_common/useFetchDocument";
import useEventListener from "global_functions/_common/useEventListener";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
// material ui
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
// custom components
import ReportButton from "../../../components/CustomButtons/ReportButton";
import ConflictStats from "components/_Custom/PageSpecific/Hols/ConflictStats";
import DeleteDialog from "views/Dialogs/_Common/DeleteDialog";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import TableFilter from "components/_Custom/_Common/TableFilter";
import AddHolsAdminForm from "views/Forms/AddHolsAdminForm";
import ConfirmationDialog from "views/Dialogs/_Common/ConfirmationDialog";
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import ConsistencyDialog from "views/Dialogs/Holidays/ConsistencyDialog";
import ConsistencyCheckButton from "../../../components/_Custom/PageSpecific/Hols/ConsistencyCheckButton";
import ManageHolidayAllowancesButton from "../../../components/_Custom/PageSpecific/Hols/ManageHolidayAllowancesButton";
import AddButton from "../../../components/CustomButtons/AddButton";
// custom functions
import identifyClick from "global_functions/_common/identifyClick";
import formatDate from "../../../global_functions/_common/formatDate";
// tables
import { holsAdminMain } from "../../DataTables/Holidays/holsAdminMain";
import { holsAdminSummary } from "views/DataTables/Holidays/holsAdminSummary";
import { holsAdminSickLeave } from "views/DataTables/Holidays/holsAdminSickLeave";
// template components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import CardFooter from "components/Card/CardFooter";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
// types
import { ConfirmationParamsType } from "../ProcurementRequestPage";
import { RootStateType } from "../../../redux/reducers";
import { ReportParams } from "../../../global_functions/types/shared";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
// styles
import {
  cardTitle,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";
import { customTheme } from "../../../global_functions/_common/customTheme";

const styles = {
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardContentRight: {
    padding: "15px 20px 15px 0px",
    position: "relative"
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  }
};
// @ts-ignore
const useStyles = makeStyles(styles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

const HolsAdminPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const reduxPageProps = useSelector((state: RootStateType) => state.holsAdmin);

  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [consistencyDialog, setConsistencyDialog] = useState(false);
  const [addRequestDialog, setAddRequestDialog] = useState(false);

  // datatables
  const [mainDataTable, setMainDataTable] = useState<
    DataTables.Api | undefined
  >(undefined);
  const [summDataTable, setSummDataTable] = useState<
    DataTables.Api | undefined
  >(undefined);
  const [sickieDataTable, setSickieDataTable] = useState<
    DataTables.Api | undefined
  >(undefined);

  const [confirmURL, setConfirmURL] = useState("");
  const [confirmationParams, setConfirmationParams] = useState<
    ConfirmationParamsType
  >(["", "", "", "", null]);

  const [conflictID, setConflictID] = useState(0);
  const [conflictModal, setConflictModal] = useState(false);

  const [statsDate, setStatsDate] = useState<MaterialUiPickersDate>(new Date());

  const [pdfParams, setPdfParams] = useState<ReportParams>({
    url: "",
    fileName: "",
    fetchMsg: ""
  });

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [deleteId, setDeleteId] = useState(null); // usually selected row is used for delete id, but this time we
  // are calling delete modal from button on datatable which deselects the row upon click so need a separate variable

  useDataTableRefresh(summDataTable, holsAdminSummary);
  useDataTableRefresh(mainDataTable, holsAdminMain);
  useDataTableRefresh(sickieDataTable, holsAdminSickLeave);

  // every time stats date changes, update refresh URLs for stats tables
  useEffect(() => {
    dispatch({
      type: SET_HOLS_ADMIN_STATS_REFRESH_URL,
      payload: statsDate ? statsDate.getFullYear() : null
    });
    dispatch({
      type: SET_HOLS_ADMIN_SICKIE_REFRESH_URL,
      payload: statsDate ? statsDate.getFullYear() : null
    });
  }, [statsDate, dispatch]);

  const handleClickEvent = (e: React.MouseEvent) => {
    const clickRelevantType = identifyClick(e);
    let tableID;

    if (
      mainDataTable &&
      summDataTable &&
      sickieDataTable &&
      clickRelevantType &&
      "type" in clickRelevantType
    ) {
      switch (clickRelevantType.type) {
        case "cellClick":
          tableID = clickRelevantType.tableID;
          // eslint-disable-next-line
          let selectedRow;
          if (tableID === "holsAdminMain") {
            if (mainDataTable.rows({ selected: true }).data()[0])
              selectedRow = mainDataTable.rows({ selected: true }).data()[0].id;
            else selectedRow = null;
            dispatch({
              type: SET_HOLS_ADMIN_MAIN_DT_ROW,
              payload: selectedRow
            });
          } else if (tableID === "holsAdminSummary") {
            if (summDataTable.rows({ selected: true }).data()[0])
              selectedRow = summDataTable.rows({ selected: true }).data()[0].id;
            else selectedRow = null;
            dispatch({
              type: SET_HOLS_ADMIN_STATS_DT_ROW,
              payload: selectedRow
            });
          } else if (tableID === "holsAdminSickLeave") {
            if (sickieDataTable.rows({ selected: true }).data()[0])
              selectedRow = sickieDataTable.rows({ selected: true }).data()[0]
                .id;
            else selectedRow = null;
            dispatch({
              type: SET_HOLS_ADMIN_SICKIE_DT_ROW,
              payload: selectedRow
            });
          }
          break;
        case "pagination":
          tableID = clickRelevantType.tableID;
          if (tableID === "holsAdminMain") {
            dispatch({
              type: SET_HOLS_ADMIN_MAIN_DT_PAGE,
              payload: mainDataTable.page.info().page
            });
          } else if (tableID === "holsAdminSummary") {
            dispatch({
              type: SET_HOLS_ADMIN_STATS_DT_PAGE,
              payload: summDataTable.page.info().page
            });
          } else if (tableID === "holsAdminSickLeave") {
            dispatch({
              type: SET_HOLS_ADMIN_SICKIE_DT_PAGE,
              payload: sickieDataTable.page.info().page
            });
          }
          break;
        case "dtButton":
          if (
            "params" in clickRelevantType &&
            Array.isArray(clickRelevantType["params"])
          ) {
            // eslint-disable-next-line
            const dtData = mainDataTable
              .rows(clickRelevantType.params[1])
              .data()[0];
            mainDataTable.rows().deselect();
            setConfirmURL(`holiday-requests/${dtData.id}`);

            if (clickRelevantType.params[0] === "CheckCircle") {
              setConfirmationParams([
                "Are you sure you would like to approve this request?",
                "Approve Request",
                "Request approved",
                "Error approving request.",
                2,
                true
              ]);
              setConfirmationDialog(true);
            } else if (clickRelevantType.params[0] === "Cancel") {
              setConfirmationParams([
                "Are you sure you would like to reject this request?",
                "Reject Request",
                "Request rejected",
                "Error rejecting request.",
                3,
                true
              ]);
              setConfirmationDialog(true);
            } else if (clickRelevantType.params[0] === "Block") {
              setConfirmationParams([
                "Are you sure you would like to cancel this request?",
                "Cancel Request",
                "Request cancelled",
                "Error cancelling request.",
                4,
                false
              ]);
              setConfirmationDialog(true);
            } else if (clickRelevantType.params[0] === "Delete") {
              setDeleteId(dtData.id);
              setDeleteName("request ID " + dtData.id);
              setDeleteModal(true);
            } else if (clickRelevantType.params[0] === "Info") {
              setConflictID(dtData.id);
              setConflictModal(true);
            }
          }
          break;
        default:
          return false;
      }
    }
  };

  const handleReportClick = () => {
    setPdfParams({
      url: `${apiUrl}documents/?dataset=sick_days_monthly_report&year=${
        statsDate ? statsDate.getFullYear() : null
      }`,
      fileName: `Monthly Sick Days Report for Year ${
        statsDate ? statsDate.getFullYear() : "UNDEFINED"
      }, generated on ${formatDate(new Date(), "dd MM yyyy")}`,
      fetchMsg: "Fetching sick days' report..."
    });
  };

  useFetchDocument(pdfParams);

  // add event listener for datatable clicks
  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Holidays & Leave</h4>
                <h4 className={classes.cardCategoryWhite}>
                  Manage holiday requests
                </h4>
              </CardText>
              {mainDataTable ? (
                <TableFilter
                  color="primary"
                  activeFilter={reduxPageProps.tables.holsAdminMain.pageFilter}
                  dispatchType={SET_HOLS_ADMIN_MAIN_DT_FILTER}
                  dataTable={mainDataTable}
                  tabs={holsAdminMain.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setMainDataTable}
                tableParams={holsAdminMain}
              />
            </CardBody>
            <CardFooter>
              <div className={classes.cardContentRight}>
                <AddButton
                  handleAddClick={() => setAddRequestDialog(true)}
                  tooltip="Add Holiday Request"
                />
                <ConsistencyCheckButton
                  tooltip="Check holiday booking consistency"
                  handleAddClick={() => setConsistencyDialog(true)}
                />
                <ManageHolidayAllowancesButton
                  tooltip="Manage holiday allowances"
                  handleAddClick={() =>
                    history.push("/admin/holiday-allowances")
                  }
                />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <DeleteDialog
          dataTable={mainDataTable}
          deleteFrom="holiday requests"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={deleteId}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_HOLS_ADMIN_MAIN_DT_TRIG_REFRESH}
          urlSuffix={"holiday-requests/"}
        />
        <ConfirmationDialog
          confirmationDialog={confirmationDialog}
          setConfirmationDialog={setConfirmationDialog}
          confirmationMessage={confirmationParams[0]}
          confirmationTitle={confirmationParams[1]}
          confirmURL={confirmURL}
          confirmPayload={{ new_status: confirmationParams[4] }}
          successMsg={confirmationParams[2]}
          errorMsg={confirmationParams[3]}
          tableRefreshType={[
            SET_HOLS_ADMIN_MAIN_DT_TRIG_REFRESH,
            SET_HOLS_ADMIN_STATS_DT_TRIG_REFRESH
          ]}
          addMessage={confirmationParams[5]}
        />
        <ConflictStats
          showModal={conflictModal}
          setShowModal={setConflictModal}
          reqID={conflictID}
        />
        <ConsistencyDialog
          setConsistencyDialog={setConsistencyDialog}
          consistencyDialog={consistencyDialog}
        />
        <AddEditDtEntryDialog
          addEditModal={addRequestDialog}
          setAddEditModal={setAddRequestDialog}
          tableRefreshType={SET_HOLS_ADMIN_MAIN_DT_TRIG_REFRESH}
          addTitle="Add Holiday Request"
          urlSuffix="holiday-requests/"
          choiceFieldForm="add_hols_admin"
        >
          <AddHolsAdminForm />
        </AddEditDtEntryDialog>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={customTheme}>
              <KeyboardDatePicker
                style={{ float: "left" }}
                views={["year"]}
                minDate="2019-01-01"
                margin="normal"
                label="Stats Date"
                value={statsDate}
                onChange={date => setStatsDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </GridItem>
        <GridItem xs={12} lg={6} xl={4}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Holiday Summary</h4>
                <h4 className={classes.cardCategoryWhite}>All holiday data</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setSummDataTable}
                tableParams={holsAdminSummary}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} lg={4} xl={4}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Sick Days</h4>
                <h4 className={classes.cardCategoryWhite}>Sick day stats</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <ReportButton
                tooltip={`Generate Monthly Sick Days Report for Year ${
                  statsDate ? statsDate.getFullYear() : null
                }`}
                handleReportClick={handleReportClick}
                greyedOut={false}
              />
              <Datatable
                setDataTable={setSickieDataTable}
                tableParams={holsAdminSickLeave}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default HolsAdminPage;
