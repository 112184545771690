import {
  SET_COMPANY_SERVICES_DB_DT_PAGE,
  SET_COMPANY_SERVICES_DB_DT_TRIG_REFRESH,
  SET_COMPANY_SERVICES_DB_DT_ROW
} from "../../../dispatch_types";

const initialState = {
  tables: {
    companyServicesMain: {
      selectedRow: null,
      triggerDtRefresh: {},
      viewedPage: 0
    }
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_COMPANY_SERVICES_DB_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          companyServicesMain: {
            ...state.tables.companyServicesMain,
            selectedRow: action.payload
          }
        }
      };
    case SET_COMPANY_SERVICES_DB_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          companyServicesMain: {
            ...state.tables.companyServicesMain,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_COMPANY_SERVICES_DB_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          companyServicesMain: {
            ...state.tables.companyServicesMain,
            viewedPage: action.payload
          }
        }
      };
    default:
      return state;
  }
}
