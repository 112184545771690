import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";

import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import { makeStyles } from "@material-ui/core/styles";

import BackButton from "components/CustomButtons/BackButton";
import ReportButton from "components/CustomButtons/ReportButton";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
// table
import { expEntriesAdminMain } from "views/DataTables/Expenses/expEntriesAdminMain";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";

import {
  SET_EXP_ENTRIES_ADMIN_DT_PAGE,
  SET_EXP_ENTRIES_ADMIN_DT_ROW,
  SET_EXP_ENTRIES_ADMIN_REFRESH_URL,
  SET_EXP_ENTRIES_ADMIN_DT_TRIG_REFRESH
} from "redux/dispatch_types";

import identifyClick from "global_functions/_common/identifyClick";
import ManageImgPdfUploadsDialog from "views/Dialogs/Uploads/ManageImgPdfUploadsDialog";
import useFetchDocument from "global_functions/_common/useFetchDocument";
import useEventListener from "global_functions/_common/useEventListener";

import ConfirmationDialog from "views/Dialogs/_Common/ConfirmationDialog";
import ApproveButton from "components/CustomButtons/ApproveButton";
import RejectButton from "components/CustomButtons/RejectButton";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const apiUrl = process.env.REACT_APP_API_V1_URL;

const useStyles = makeStyles(styles);

export default function ExpEntriesAdminPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const reduxPageProps = useSelector(state => state.expEntriesAdmin);

  const [receiptExpID, setReceiptExpID] = useState(0);
  const [manageReceiptsDialog, setManageReceiptsDialog] = useState(false);

  const expBundleID = props.match.params.slug;

  const [dataTable, setDataTable] = useState("");

  const [bundleHasEntries, setBundleHasEntries] = useState(false);
  const [bundleStatus, setBundleStatus] = useState("");
  const [bundleName, setBundleName] = useState("");
  const [bundleUser, setBundleUser] = useState("");

  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [confirmationParams, setConfirmationParams] = useState(false);

  const [reportParams, setReportParams] = useState("");

  useEffect(() => {
    // sets refresh url for page on load
    dispatch({
      type: SET_EXP_ENTRIES_ADMIN_REFRESH_URL,
      payload: "expense-bundles/" + expBundleID + "/expenses/"
    });

    // get bundle details
    axios
      .get(
        apiUrl + "expense-bundles/" + expBundleID,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setBundleStatus(res.data.status);
        setBundleName(res.data.name);
        setBundleUser(res.data.user);
        setBundleHasEntries(res.data.has_entries);
      });
  }, [expBundleID, dispatch]);

  // when trigger table refresh is set (after approving/rejecting bundle), refresh stats
  useEffect(() => {
    // get bundle details
    axios
      .get(
        apiUrl + "expense-bundles/" + expBundleID,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setBundleStatus(res.data.status);
        setBundleName(res.data.name);
        setBundleUser(res.data.user);
        setBundleHasEntries(res.data.has_entries);
      });
  }, [reduxPageProps.tables.expEntriesAdminMain.triggerDtRefresh, expBundleID]);

  useDataTableRefresh(dataTable, expEntriesAdminMain);

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({
            type: SET_EXP_ENTRIES_ADMIN_DT_ROW,
            payload: selectedRow
          });
          break;
        case "pagination":
          dispatch({
            type: SET_EXP_ENTRIES_ADMIN_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          setReceiptExpID(
            dataTable.rows(clickRelevantType.params[1]).data()[0].id
          );
          if (clickRelevantType.params[0].substring(0, 6) === "Filter") {
            setManageReceiptsDialog(true);
          }
          break;
        default:
          return false;
      }
    }
  };

  const handleReportClick = e => {
    e.stopPropagation();
    setReportParams({
      url: `${apiUrl}documents/?dataset=expenses_report&exp_bundle_id=${expBundleID}`,
      fileName: `Expenses report for ${bundleUser} (bundle '${bundleName}').pdf`,
      fetchMsg: "Fetching expenses' report..."
    });
  };

  const handleApprove = () => {
    setConfirmationParams([
      "Are you sure you would like to approve this bundle?",
      "Approve Bundle",
      "Bundle Approved",
      "Error approving bundle.",
      3
    ]);
    setConfirmationDialog(true);
  };

  const handleReject = () => {
    setConfirmationParams([
      "Are you sure you would like to reject this bundle?",
      "Reject Bundle",
      "Bundle Rejected",
      "Error rejecting bundle.",
      4
    ]);
    setConfirmationDialog(true);
  };

  useFetchDocument(reportParams);

  // add event listener for datatable clicks
  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>{bundleName}</h4>
                <h4 className={classes.cardCategoryWhite}>
                  {`Status: ${bundleStatus}`}
                </h4>
                <h4 className={classes.cardCategoryWhite}>
                  {`User: ${bundleUser}`}
                </h4>
              </CardText>
              <GridContainer>
                <GridItem xs={12}>
                  <BackButton />
                  {bundleStatus === "Submitted" && (
                    <ApproveButton handleApprove={handleApprove} />
                  )}
                  {(bundleStatus === "Submitted" ||
                    bundleStatus === "Approved") && (
                    <RejectButton
                      onClick={handleReject}
                      tooltip="Reject expenses bundle"
                    />
                  )}
                  <ReportButton
                    tooltip="Generate expenses report"
                    handleReportClick={handleReportClick}
                    greyedOut={!bundleHasEntries}
                  />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setDataTable}
                tableParams={expEntriesAdminMain}
              />
            </CardBody>
          </Card>
        </GridItem>
        <ManageImgPdfUploadsDialog
          manageUploadsDialog={manageReceiptsDialog}
          setManageUploadsDialog={setManageReceiptsDialog}
          refreshUrl={reduxPageProps.tables.expEntriesAdminMain.refreshURL}
          tableRefreshType={SET_EXP_ENTRIES_ADMIN_DT_TRIG_REFRESH}
          parentId={receiptExpID}
          showControls={false}
          title="View Receipts"
        />
        <ConfirmationDialog
          confirmationDialog={confirmationDialog}
          setConfirmationDialog={setConfirmationDialog}
          confirmationMessage={confirmationParams[0]}
          confirmationTitle={confirmationParams[1]}
          confirmURL={`expense-bundles/${expBundleID}`}
          confirmPayload={{ new_status: confirmationParams[4] }}
          successMsg={confirmationParams[2]}
          errorMsg={confirmationParams[3]}
          tableRefreshType={SET_EXP_ENTRIES_ADMIN_DT_TRIG_REFRESH}
          addMessage={true}
        />
      </GridContainer>
    </div>
  );
}
