import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const acceptDeliveryIcon = { icon: "ArrowForward", tooltip: "Accept delivery" };
const moveBackFromWarehouseIcon = {
  icon: "ArrowBack",
  tooltip: "Move back to delivery shelves (or to 'Awaiting')"
};
const moveBothWaysIcon = {
  icon: "SwapHoriz",
  tooltip: "Move to warehouse or back to 'awaiting'"
};

const editButtonIcon = { icon: "Edit", tooltip: "Edit schedule item" };
const deleteIcon = { icon: "Delete", tooltip: "Delete schedule item" };
const infoIcon = { icon: "InfoBlue", tooltip: "View delivery information" };

export const warehouseDeliveryScheduleMain = {
  section: "warehouseDeliverySchedule",
  reduxTableName: "warehouseDeliveryScheduleMain",
  refreshURL: "warehouse-delivery-schedule/?location=",
  filterTabValues: [0, 2, 1],
  filterTabNames: ["Awaiting", "Delivered", "Processed"],
  tableParams: {
    autoWidth: true,
    responsive: true,
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    buttonsColumn: {
      id: 8,
      genIcons: inst => {
        if (!inst.location) {
          // awaiting
          return [acceptDeliveryIcon, editButtonIcon, deleteIcon];
        } else if (inst.location >= 2) {
          // delivery shelves (various)
          return [moveBothWaysIcon, editButtonIcon, deleteIcon, infoIcon];
        } else if (inst.location === 1) {
          // processed
          return [
            moveBackFromWarehouseIcon,
            editButtonIcon,
            deleteIcon,
            infoIcon
          ];
        }
      }
    },
    columns: [
      { data: "id", title: "ID", className: "dt-body-center", width: 100 }, // 0
      { data: "addressee", title: "Addressee", width: 300 }, // 1
      { data: "sender", title: "Sender", width: 300 }, // 2
      { data: "details", title: "Details", width: 500 }, // 3
      { data: "project", title: "Project", width: 700 }, // 4
      {
        data: {
          _: "expected_or_actual_delivery_date.display",
          sort: "expected_or_actual_delivery_date.@data-order"
        },
        title: "Delivery Date",
        width: 500
      }, // 5
      {
        data: "reference",
        title: "Reference",
        className: "dt-body-center",
        width: 200
      }, // 6
      {
        data: "location_disp",
        title: "Location",
        className: "dt-body-center",
        width: 100
      }, // 7
      {
        data: null,
        title: "Actions",
        className: "dt-body-center",
        width: 1000,
        responsivePriority: 1
      } // 8
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },

    aaSorting: [[0, "asc"]]
  }
};
