import React, { useEffect, useState } from "react";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
// template
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
// material ui
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
// styles
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);

export default function AddEditQuoteBreakdownEntryForm(props) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  const [itemPrice, setItemPrice] = useState("");
  const [itemQty, setItemQty] = useState("");
  const [itemTotal, setItemTotal] = useState(0);
  const [itemComment, setItemComment] = useState("");
  const [companyService, setCompanyService] = useState(0);

  useEffect(() => {
    if (props.editData && props.open) {
      setItemPrice(props.editData.price);
      setItemQty(props.editData.qty);
      setItemComment(props.editData.comment);
      setCompanyService(props.editData.company_service);
    } else if (!props.editData && props.open) {
      setItemPrice("");
      setItemQty("");
      setItemComment("");
      setCompanyService(0);
    }
  }, [props.editData, props.open]);

  useEffect(() => {
    props.setformData({
      price: parseFloat(itemPrice),
      qty: parseFloat(itemQty),
      comment: itemComment,
      // below both included because put and post serializers require different keys
      company_service: companyService,
      company_service_id: companyService
    });
    // eslint-disable-next-line
    }, [itemPrice, itemQty, itemComment, companyService]);

  // auto calculation of total value in breakdown item line
  useEffect(() => {
    setItemTotal((itemQty * itemPrice).toFixed(2));
  }, [itemQty, itemPrice]);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "140px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Service
                </FormLabel>
              </GridItem>
              <GridItem xs={10}>
                <CustomSelect
                  options={props.formChoiceFields.SERVICE_CHOICES}
                  isDisabled={props.formLoading}
                  value={companyService}
                  setValue={setCompanyService}
                  required={true}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Notes
                </FormLabel>
              </GridItem>
              <GridItem xs={10}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    // placeholder: "Notes",
                    value: itemComment,
                    onChange: e => {
                      setItemComment(e.target.value);
                    },
                    required: false,
                    maxLength: 200
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Price
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    // placeholder: "Price",
                    value: itemPrice,
                    onChange: e => {
                      setItemPrice(e.target.value);
                    },
                    required: true,
                    maxLength: 2,
                    min: 0,
                    step: 0.01
                  }}
                />
              </GridItem>
              <GridItem xs={1}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Qty
                </FormLabel>
              </GridItem>
              <GridItem xs={2}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    // placeholder: "Qty",
                    value: itemQty,
                    onChange: e => {
                      setItemQty(e.target.value);
                    },
                    required: true,
                    maxLength: 2,
                    min: 0.1,
                    step: 0.1
                  }}
                />
              </GridItem>
              <GridItem xs={1}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Total
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: itemTotal,
                    disabled: true,
                    type: "number",
                    // placeholder: "Total",
                    required: true,
                    maxLength: 2,
                    min: 0.01,
                    step: 0.01
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
}
