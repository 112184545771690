import {
  SET_HOLS_USER_DT_FILTER,
  SET_USER_HOLS_DT_PAGE,
  SET_USER_HOLS_DT_TRIG_REFRESH,
  SET_USER_HOLS_DT_ROW
} from "redux/dispatch_types";

const initialState = {
  tables: {
    holsUserMain: {
      selectedRow: null,
      triggerDtRefresh: {},
      viewedPage: 0,
      pageFilter: 0
    }
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_HOLS_USER_DT_FILTER:
      return {
        ...state,
        tables: {
          ...state.tables,
          holsUserMain: {
            ...state.tables.holsUserMain,
            pageFilter: action.payload,
            viewedPage: 0
          }
        }
      };
    case SET_USER_HOLS_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          holsUserMain: {
            ...state.tables.holsUserMain,
            selectedRow: action.payload
          }
        }
      };
    case SET_USER_HOLS_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          holsUserMain: {
            ...state.tables.holsUserMain,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_USER_HOLS_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          holsUserMain: {
            ...state.tables.holsUserMain,
            viewedPage: action.payload
          }
        }
      };
    default:
      return state;
  }
}
