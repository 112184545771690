import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const deleteButton = { icon: "Delete", tooltip: "Delete Service" };
const editButton = { icon: "Edit", tooltip: "Edit Service" };

export const companyServicesMain = {
  section: "companyServices",
  reduxTableName: "companyServicesMain",
  refreshURL: "company-services/",
  tableParams: {
    autoWidth: true,
    responsive: true,
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    buttonsColumn: {
      id: 2,
      genIcons: () => {
        return [editButton, deleteButton];
      }
    },
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    columns: [
      {
        data: "id",
        title: "Service ID",
        responsivePriority: 1,
        width: 100,
        className: "dt-body-center"
      }, //0
      {
        data: "name",
        title: "Service Name",
        responsivePriority: 1,
        width: 800,
        className: "dt-body-center"
      }, //1
      { data: null, title: "Actions", className: "dt-body-center" } // 2
    ],
    aaSorting: [[0, "asc"]]
  }
};
