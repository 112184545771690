import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

export const holsUserMain = {
  section: "holsUser",
  reduxTableName: "holsUserMain",
  refreshURL: "holiday-requests/?page=user_holidays&page_filter=",
  filterTabValues: [1, 2, 3],
  filterTabNames: ["Pending", "Approved", "Declined"],
  tableParams: {
    autoWidth: true,
    responsive: true,
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    searching: false,
    bInfo: false,
    columns: [
      { data: "id", title: "ID", responsivePriority: 1 }, // 0
      {
        data: "submission_date",
        title: "Submission Date",
        visible: false,
        searchable: false
      }, // 1
      {
        data: { _: "start_date.display", sort: "start_date.@data-order" },
        title: "Start Date",
        responsivePriority: 2
      }, // 2
      {
        data: { _: "end_date.display", sort: "end_date.@data-order" },
        title: "End Date",
        responsivePriority: 3
      }, // 3
      { data: "time_of_day", title: "Time of Day" }, // 4
      {
        data: "working_days",
        title: "Working Days",
        className: "dt-body-center"
      }, // 5
      { data: "comments", title: "Comments" }, // 6
      { data: "status", title: "Status" } // 7
    ],
    aaSorting: [[0, "desc"]]
  }
};
