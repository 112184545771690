import React, { useState } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  SET_WAREHOUSE_DELIVERY_DT_PAGE,
  SET_WAREHOUSE_DELIVERY_DT_TRIG_REFRESH,
  SET_WAREHOUSE_DELIVERY_DT_ROW,
  SET_WAREHOUSE_DELIVERY_FILTER
} from "redux/dispatch_types";
// custom functions
import useEventListener from "global_functions/_common/useEventListener";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import identifyClick from "global_functions/_common/identifyClick";
// custom components
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import AddButton from "components/CustomButtons/AddButton";
import TableFilter from "components/_Custom/_Common/TableFilter";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// custom dialog & form
import AcceptDeliveryDialog from "views/Dialogs/Warehouse/AcceptDeliveryDialog";
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import DeliveryInfoDialog from "views/Dialogs/Warehouse/DeliveryInfoDialog";
import AddEditWarehouseScheduleEntryForm from "views/Forms/AddEditWarehouseScheduleEntryForm";
import DeleteDialog from "views/Dialogs/_Common/DeleteDialog";
import MoveDeliveryDialog from "views/Dialogs/Warehouse/MoveDeliveryDialog";
import ConfirmationDialog from "views/Dialogs/_Common/ConfirmationDialog";
// table
import { warehouseDeliveryScheduleMain } from "views/DataTables/Warehouse/warehouseDeliveryScheduleMain";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
// styles
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};
const useStyles = makeStyles(styles);

export default function WarehouseDeliverySchedulePage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const reduxPageProps = useSelector(state => state.warehouseDeliverySchedule);

  const [addEditModal, setAddEditModal] = useState(false);
  const [moveDeliveryModal, setMoveDeliveryModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [acceptDeliveryModal, setAcceptDeliveryModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [deleteName, setDeleteName] = useState(false);
  const [dataTable, setDataTable] = useState("");
  const [editDelId, setEditDelId] = useState(null);

  // handlers
  const handleAddClick = e => {
    e.stopPropagation();
    setEditDelId(0);
    setAddEditModal(true);
  };

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({
            type: SET_WAREHOUSE_DELIVERY_DT_ROW,
            payload: selectedRow
          });
          break;
        case "pagination":
          dispatch({
            type: SET_WAREHOUSE_DELIVERY_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
          dataTable.rows().deselect();

          switch (clickRelevantType.params[0]) {
            case "Edit":
              setEditDelId(dtData.id);
              setAddEditModal(true);
              break;
            case "Delete":
              setEditDelId(dtData.id);
              setDeleteName(`entry with id ${dtData.id}`);
              setDeleteModal(true);
              break;
            case "ArrowForward":
              setEditDelId(dtData.id);
              setAcceptDeliveryModal(true);
              break;
            case "ArrowBack":
            case "SwapHoriz":
              setEditDelId(dtData.id);
              setMoveDeliveryModal(true);
              break;
            case "InfoBlue":
              setEditDelId(dtData.id);
              setInfoModal(true);
              break;
            default:
              break;
          }
          break;
        default:
          return false;
      }
    }
  };

  useDataTableRefresh(dataTable, warehouseDeliveryScheduleMain);

  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Deliveries</h4>
                <h4 className={classes.cardCategoryWhite}>
                  Warehouse Delivery Schedule
                </h4>
              </CardText>
              {dataTable ? (
                <TableFilter
                  color="primary"
                  activeFilter={
                    reduxPageProps.tables.warehouseDeliveryScheduleMain
                      .pageFilter
                  }
                  dispatchType={SET_WAREHOUSE_DELIVERY_FILTER}
                  dataTable={dataTable}
                  tabs={warehouseDeliveryScheduleMain.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setDataTable}
                tableParams={warehouseDeliveryScheduleMain}
              />
            </CardBody>
            <CardFooter>
              <AddButton
                handleAddClick={e => handleAddClick(e)}
                tooltip="Add schedule item"
              />
            </CardFooter>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelId}
          dataTable={dataTable}
          urlSuffix="warehouse-delivery-schedule/"
          tableRefreshType={SET_WAREHOUSE_DELIVERY_DT_TRIG_REFRESH}
          addTitle="Add Schedule Entry"
          editTitle="Edit Schedule Entry"
          filterInForm="filter_active_only"
        >
          <AddEditWarehouseScheduleEntryForm />
        </AddEditDtEntryDialog>
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="the delivery schedule"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={
            reduxPageProps.tables.warehouseDeliveryScheduleMain.selectedRow
          }
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_WAREHOUSE_DELIVERY_DT_TRIG_REFRESH}
          urlSuffix="warehouse-delivery-schedule/"
        />
        <AcceptDeliveryDialog
          acceptDeliveryModal={acceptDeliveryModal}
          setAcceptDeliveryModal={setAcceptDeliveryModal}
          deliveryId={editDelId}
        />
        <DeliveryInfoDialog
          deliveryInfoDialog={infoModal}
          setDeliveryInfoDialog={setInfoModal}
          deliveryId={editDelId}
          setEditDialog={setAcceptDeliveryModal}
        />
        <MoveDeliveryDialog
          setMoveDeliveryModal={setMoveDeliveryModal}
          moveDeliveryModal={moveDeliveryModal}
          deliveryId={editDelId}
          setConfirmationDialog={setConfirmationModal}
        />
        <ConfirmationDialog
          confirmationDialog={confirmationModal}
          setConfirmationDialog={setConfirmationModal}
          confirmationMessage={
            "Moving delivery back to 'awaiting' will clear its delivery information and it" +
            " will need to be re-entered. This should only be done for deliveries where delivery information " +
            "was entered mistakenly. Are you sure you would like to proceed?"
          }
          confirmationTitle={"Move Back to Awaiting"}
          confirmURL={`warehouse-delivery-schedule/${editDelId}`}
          confirmPayload={{ location: 0, form: "move_to_await_conf_dialog" }}
          successMsg={"Delivery successfully moved to awaiting."}
          errorMsg={"Unable to move the delivery."}
          tableRefreshType={SET_WAREHOUSE_DELIVERY_DT_TRIG_REFRESH}
        />
      </GridContainer>
    </div>
  );
}
