import React from "react";
// redux
import { useSelector } from "react-redux";
// custom components
import ClientDashboard from "../../components/_Custom/PageSpecific/Dashboard/ClientDashboard/ClientDashboard";
import UserAdminDashboard from "../../components/_Custom/PageSpecific/Dashboard/UserAdminDashboard/UserAdminDashboard";

// types
import {RootStateType} from "../../redux/reducers";

const DashboardPage: React.FC = () => {
  const authUser = useSelector((state: RootStateType) => state.auth).user;

  return (
      authUser?.is_client ?
          <ClientDashboard/>
          :
          <UserAdminDashboard />
  );
}

export default DashboardPage;
