import React, { useEffect, useState } from "react";

import axios from "axios";

// types
import { RouteComponentProps } from "react-router-dom";
// redux
import store from "redux/store";
import { useDispatch, useSelector } from "react-redux";
import { getTokenConfig } from "../../../global_functions/redux_action_creators/auth/getTokenConfig";
import {
  SET_POSITIONS_DT_PAGE,
  SET_POSITIONS_DT_ROW,
  SET_POSITIONS_DT_TRIG_REFRESH,
  SET_POSITIONS_REFRESH_URL
} from "../../../redux/dispatch_types";
// custom components
import AddButton from "../../../components/CustomButtons/AddButton";
import BackButton from "../../../components/CustomButtons/BackButton";
import Datatable from "../../../components/_Custom/_Common/DataTables/DataTable";
import AddEditDtEntryDialog from "../../Dialogs/_Common/AddEditDtEntryDialog";
import DeleteDialog from "../../Dialogs/_Common/DeleteDialog";
import AddEditPositionForm from "../../Forms/AddEditPositionForm";
// datatable params
import { positionsMain } from "../../DataTables/positionsMain";
// custom functions
import useDataTableRefresh from "../../../global_functions/dataTables/useDatatableRefresh";
import identifyClick from "../../../global_functions/_common/identifyClick";
import useEventListener from "../../../global_functions/_common/useEventListener";
// template
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardText from "../../../components/Card/CardText";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
// types
import { RootStateType } from "../../../redux/reducers";
// styles
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardContentRight: {
    padding: "15px 20px 15px 0px",
    position: "relative"
  }
};
// @ts-ignore
const useStyles = makeStyles(styles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

const PositionsPage: React.FC<MatchProps> = ({ match }) => {
  const classes = useStyles();

  const departmentId = match.params.slug;

  const [departmentName, setDepartmentName] = useState("");
  const [dataTable, setDataTable] = useState<DataTables.Api | undefined>(
    undefined
  );

  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [editDelId, setEditDelId] = useState(0);

  const { refreshURL } = useSelector(
    (state: RootStateType) => state.positions.tables.positionsMain
  );

  const dispatch = useDispatch();

  // runs on every load
  useEffect(() => {
    // set refresh url for page on load
    dispatch({
      type: SET_POSITIONS_REFRESH_URL,
      payload: `departments/${departmentId}/positions/`
    });

    // get department details to display above table
    axios
      .get(
        `${apiUrl}departments/${departmentId}`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setDepartmentName(res.data.name);
      });
  }, [departmentId, dispatch]);

  useDataTableRefresh(dataTable, positionsMain);

  const handleClickEvent = (e: React.MouseEvent): void => {
    const clickRelevantType = identifyClick(e);

    if (dataTable && clickRelevantType && "type" in clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_POSITIONS_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_POSITIONS_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          if (
            "params" in clickRelevantType &&
            Array.isArray(clickRelevantType["params"])
          ) {
            const dtData = dataTable
              .rows(clickRelevantType.params[1])
              .data()[0];
            if (clickRelevantType.params[0] === "Edit") {
              setEditDelId(dtData.id);
              setAddEditModal(true);
              dataTable.rows().deselect();
              break;
            } else if (clickRelevantType.params[0] === "Delete") {
              setEditDelId(dtData.id);
              setDeleteName(`${dtData.name}`);
              setDeleteModal(true);
              dataTable.rows().deselect();
            }
          }
          break;
        default:
          return;
      }
    }
  };

  useEventListener("click", handleClickEvent, window);

  return (
    <GridContainer>
      <GridItem>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitleWhite}>
                {departmentName} Positions
              </h4>
              <h4 className={classes.cardCategoryWhite}>
                Manage Job Titles for The {departmentName} Department
              </h4>
            </CardText>
            <GridContainer>
              <GridItem xs={12}>
                <BackButton />
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <Datatable
              tableParams={positionsMain}
              setDataTable={setDataTable}
            />
          </CardBody>
          <CardFooter>
            <div className={classes.cardContentRight}>
              <AddButton
                handleAddClick={() => {
                  setEditDelId(0);
                  setAddEditModal(true);
                }}
                tooltip="Add a Position"
              />
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <AddEditDtEntryDialog
        addEditModal={addEditModal}
        setAddEditModal={setAddEditModal}
        editDelID={editDelId}
        tableRefreshType={SET_POSITIONS_DT_TRIG_REFRESH}
        urlSuffix={refreshURL}
        filterInForm={false}
        editTitle="Edit Position"
        addTitle="Add a Position"
      >
        {/*@ts-ignore*/}
        <AddEditPositionForm />
      </AddEditDtEntryDialog>
      <DeleteDialog
        dataTable={dataTable}
        deleteFrom={`${departmentName} department positions`}
        deleteModal={deleteModal}
        deleteName={deleteName}
        selectedRow={editDelId}
        setDeleteModal={setDeleteModal}
        tableRefreshType={SET_POSITIONS_DT_TRIG_REFRESH}
        urlSuffix={refreshURL}
      />
    </GridContainer>
  );
};

export default PositionsPage;

interface MatchParams {
  slug: string;
}

type MatchProps = RouteComponentProps<MatchParams>;
