import React from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  SET_RES_PLANNER_ALL_HRS_VIEW,
  SET_RES_PLANNER_INC_TS_VIEW
} from "redux/dispatch_types";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Chip from "@material-ui/core/Chip";

import GridItem from "../../../Grid/GridItem";
import GridContainer from "../../../Grid/GridContainer";

import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// import buttonStyles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import { makeStyles } from "@material-ui/core/styles";

// import Button from "components/CustomButtons/Button";
// import Assignment from "@material-ui/icons/Assignment";
//
// import useFetchDocument from "global_functions/_common/useFetchDocument";

const useFormStyles = makeStyles(formsStyles);
// const useButtonStyles = makeStyles(buttonStyles);

// const apiUrl = process.env.REACT_APP_API_V1_URL;

export default function CalendarToolbar() {
  const dispatch = useDispatch();
  const formClasses = useFormStyles();
  // const buttonClasses = useButtonStyles();

  // const [reportParams, setReportParams] = useState("");

  const reduxPageProps = useSelector(state => state.resPlanner);

  // const handleLegacyViewClick = e => {
  //     e.stopPropagation();
  //     setReportParams({
  //         url: `${apiUrl}documents/?dataset=calendar_excel`,
  //         fileName: "Resource_Planner_Current_Year.xls",
  //         fetchMsg: "Fetching... This might take up to 5 minutes on the current server."
  //     });
  //
  // }

  // useFetchDocument(reportParams);

  return (
    <GridContainer>
      <GridItem xs={3} lg={3}>
        <div className={formClasses.inlineChecks}>
          <FormControlLabel
            control={
              <Checkbox
                id="incAllHrsCheckbox"
                tabIndex={-1}
                onClick={e =>
                  dispatch({
                    type: SET_RES_PLANNER_ALL_HRS_VIEW,
                    payload: e.target.checked
                  })
                }
                checked={reduxPageProps.allHrsView}
                checkedIcon={<Check className={formClasses.checkedIcon} />}
                icon={<Check className={formClasses.uncheckedIcon} />}
                classes={{
                  checked: formClasses.checked,
                  root: formClasses.checkRoot
                }}
              />
            }
            classes={{
              label: formClasses.label,
              root: formClasses.labelRoot
            }}
            label="Show 24h"
          />
          <FormControlLabel
            control={
              <Checkbox
                id="incTSCheckbox"
                tabIndex={-1}
                onClick={e =>
                  dispatch({
                    type: SET_RES_PLANNER_INC_TS_VIEW,
                    payload: e.target.checked
                  })
                }
                checked={reduxPageProps.incTsView}
                checkedIcon={<Check className={formClasses.checkedIcon} />}
                icon={<Check className={formClasses.uncheckedIcon} />}
                classes={{
                  checked: formClasses.checked,
                  root: formClasses.checkRoot
                }}
              />
            }
            classes={{
              label: formClasses.label,
              root: formClasses.labelRoot
            }}
            label="Include Timesheets"
          />
        </div>
      </GridItem>
      <GridItem xs={8} lg={8}>
        <Chip
          size="small"
          label="No Quotes"
          color="primary"
          style={{ backgroundColor: "#7f8c8d", marginTop: "20px" }}
        />
        <Chip
          size="small"
          label="Holiday/Sick Day"
          color="primary"
          style={{ backgroundColor: "#000000", marginTop: "20px" }}
        />
        <Chip
          size="small"
          label="Internal"
          color="primary"
          style={{ backgroundColor: "#f1c40f", marginTop: "20px" }}
        />
        <Chip
          size="small"
          label="Quoting"
          color="primary"
          style={{ backgroundColor: "#e74c3c", marginTop: "20px" }}
        />
        <Chip
          size="small"
          label="Quote Sent"
          color="primary"
          style={{ backgroundColor: "#c0392b", marginTop: "20px" }}
        />
        <Chip
          size="small"
          label="PO Received"
          color="primary"
          style={{ backgroundColor: "#27ae60", marginTop: "20px" }}
        />
        <Chip
          size="small"
          label="Declined"
          color="primary"
          style={{ backgroundColor: "#8e44ad", marginTop: "20px" }}
        />
        <Chip
          size="small"
          label="Archived"
          color="primary"
          style={{ backgroundColor: "#964B00", marginTop: "20px" }}
        />
        <Chip
          size="small"
          label="Error"
          color="primary"
          style={{ backgroundColor: "#e67e22", marginTop: "20px" }}
        />
      </GridItem>
      {/*<GridItem xs={1} lg={1}>*/}
      {/*    <Button style={{float: "right"}} color="primary" onClick={handleLegacyViewClick} className={buttonClasses.marginRight}>*/}
      {/*        <Assignment className={buttonClasses.icons} /> Legacy View*/}
      {/*    </Button>*/}
      {/*</GridItem>*/}
    </GridContainer>
  );
}
