import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
// redux
import { useDispatch } from "react-redux";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import showNotification from "global_functions/redux_action_creators/showNotification";
// custom components & functions
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import { getDialogProps } from "global_functions/_common/getDialogProps";
import useFetchDocument from "global_functions/_common/useFetchDocument";
//material-ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import regularFormStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

const useStyles = makeStyles(styles);
const useRegularFormStyles = makeStyles(regularFormStyle);
// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const apiUrl = process.env.REACT_APP_API_V1_URL;

export default function PrintQrCodesDialog({
  printAllQrsModal,
  setPrintAllQrsModal,
  projId,
  itemFilter
}) {
  const classes = useStyles();
  const formClasses = useRegularFormStyles();
  const dispatch = useDispatch();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [printNumber, setPrintNumber] = useState(0);
  const [includeLogoCheck, setIncludeLogoCheck] = useState(false);

  const [formLoading, setFormLoading] = useState(false);

  const [reportParams, setReportParams] = useState("");

  const handlePrintPress = () => {
    setReportParams({
      url: `${apiUrl}documents/?dataset=qr_codes&project_id=${projId}&status=${itemFilter}&include_logo=${includeLogoCheck}`, // page filter in redux shows the index of actual filter in filterTabValues array in tableParams. In this case, + 1 gives actual filter.
      fileName: "qr_codes.pdf",
      fetchMsg: "Fetching QR codes..."
    });
  };

  useEffect(() => {
    if (printAllQrsModal) {
      // every time modal opens
      setFormLoading(true);
      axios
        .get(
          apiUrl + `projects/${projId}/items/?filter=${itemFilter}`,
          getTokenConfig(store.getState)
        )
        .then(res => {
          setPrintNumber(res.data.length);
          setFormLoading(false);
        })
        .catch(() => {
          dispatch(
            showNotification(
              "Error fetching data. Please refresh and try again.",
              "error"
            )
          );
        });
    }
  }, [printAllQrsModal, dispatch, projId, itemFilter]);

  // generate PDF, if 'true' is returned, close modal
  useFetchDocument(reportParams).then(res => {
    if (res) {
      setPrintAllQrsModal(false);
      setReportParams("");
    }
  });

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={printAllQrsModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setPrintAllQrsModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setPrintAllQrsModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Print Multiple Qr Codes</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {formLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <form id="printAllQrsForm" onSubmit={handlePrintPress}>
              <GridContainer>
                <GridItem xs={12}>
                  {printNumber ? (
                    <p>{`Are you sure you would like to print QR codes for ${printNumber} items?`}</p>
                  ) : (
                    <p>No QR items to print</p>
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <div className={formClasses.inlineChecks}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="singleDayCheck"
                          tabIndex={-1}
                          onClick={e => setIncludeLogoCheck(e.target.checked)}
                          checked={includeLogoCheck}
                          checkedIcon={
                            <Check className={formClasses.checkedIcon} />
                          }
                          icon={<Check className={formClasses.uncheckedIcon} />}
                          classes={{
                            checked: formClasses.checked,
                            root: formClasses.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot
                      }}
                      label="Include SRW Logo"
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </form>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="primary"
            simple
            type="submit"
            form="printAllQrsForm"
            disabled={formLoading || !printNumber}
          >
            Print
          </Button>
          <Button
            color="transparent"
            onClick={() => setPrintAllQrsModal(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
