import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import QrReader from "react-qr-reader";
// redux
import store from "redux/store";
import { useDispatch } from "react-redux";
import showNotification from "global_functions/redux_action_creators/showNotification";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// sound
import useSound from "use-sound";
import cameraSound from "assets/sounds/camera.mp3";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
// custom components
import CustomInput from "components/CustomInput/CustomInput";
import ItemInfoDialog from "views/Dialogs/Warehouse/ItemInfoDialog";
import ScanTypeSelector from "./components/ScanTypeSelector";
import NewStatusOptions from "./components/NewStatusOptions";
import NewLocationOptions from "./components/NewLocationOptions";
import NewContainerOptions from "./components/NewContainerOptions";
import SpecifyNumberDialog from "../../../Dialogs/Warehouse/SpecifyNumberDialog";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
// styles
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const actionsObj = [
  "scan_to_new_status",
  "scan_to_new_location",
  "scan_to_from_container"
];

const previewStyle = {
  height: "300px",
  width: "300px"
};

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formsStyles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

export default function WarehouseItemScanPage() {
  const dispatch = useDispatch();

  const classes = useStyles();
  const formClasses = useFormStyles();

  const [result, setResult] = useState("");
  const [comments, setComments] = useState("");
  const [qrType, setQrType] = useState("");
  // selecting scan type
  const [scanAction, setScanAction] = useState(0); // get info/change status/change location
  // status change
  const [statusScanType, setStatusScanType] = useState(3); // default to 'checked out' for status scans
  // location change
  const [locationScanType, setLocationScanType] = useState(0); // default to 'Bays' for location scan
  const [newItemBay, setNewItemBay] = useState(0);
  const [newItemShelf, setNewItemShelf] = useState(0);
  const [newEngineerDesk, setNewEngineerDesk] = useState(null);
  // container move
  const [containerActionType, setContainerActionType] = useState(0); // 0 for 'to', 1 for 'from'
  const [projId, setProjId] = useState(null);
  const [destinationContainerId, setDestinationContainerId] = useState(null);
  // specifying number when scanning box
  const [specifyNumberModal, setSpecifyNumberModal] = useState(false);
  const [totalInBox, setTotalInBox] = useState(1);
  const [specifyReqData, setSpecifyReqData] = useState({});

  const [infoModal, setInfoModal] = useState(false);
  const [scannedItemId, setScannedItemId] = useState(null);

  const [play] = useSound(cameraSound);

  const handleScan = data => setResult(data);

  const handleScanError = err => {
    // else show errors from error object or generic error
    if (err.response.data.errors) {
      dispatch(showNotification(err.response.data.errors, "error"));
    } else {
      dispatch(
        showNotification(
          "Error scanning item. Please refresh and try again.",
          "error"
        )
      );
    }
  };

  const handleError = err =>
    dispatch(
      showNotification("Error has occurred. " + err.toString(), "error")
    );

  const scanItem = useCallback(
    (reqUrl, reqData) => {
      axios
        .put(reqUrl, reqData, getTokenConfig(store.getState))
        .then(res => {
          dispatch(showNotification(res.data.statusText, "success"));
          play();
        })
        .catch(err => {
          try {
            // check if the error is shown when box is scanned and number isn't provided (meets specific format, show specify modal)
            if (
              err.response.data.errors.split("'")[1].split("_")[0] === "provide"
            ) {
              setSpecifyNumberModal(true);
              setTotalInBox(
                err.response.data.errors.split("'")[1].split("_")[2]
              );
              setSpecifyReqData(reqData);
            } else handleScanError(err); // else show generic error
          } catch {
            handleScanError(err);
          }
        });
    },
    // eslint-disable-next-line
    [dispatch, play]
  );

  useEffect(() => {
    if (result && !infoModal) {
      let resultParts = result.split("_");
      if (
        resultParts.length === 4 &&
        resultParts[0] === "srw" &&
        resultParts[1] === "warehouse" &&
        (resultParts[2] === "item" || resultParts[2] === "container") &&
        !resultParts[3].isNaN
      ) {
        // set Id
        const itemId = parseInt(resultParts[3]);
        setScannedItemId(itemId);

        // set if container or single item for item info modal + set URL for put request
        let reqUrl = "";
        if (resultParts[2] === "item") {
          setQrType("Item");
          reqUrl = `${apiUrl}projects/0/items/${itemId}`;
        } else if (resultParts[2] === "container") {
          reqUrl = `${apiUrl}projects/0/containers/${itemId}`;
          setQrType("Container");
        }

        let reqData; // defining request data variable outside of switch/case statement for best practice

        switch (scanAction) {
          case 0: // getting info
            setInfoModal(true);
            break;
          case 1: // changing status
          case 2: // changing location
            reqData = {
              edit_type: actionsObj[scanAction - 1],
              comments: comments
            };
            if (scanAction === 1) reqData.status = statusScanType;
            else {
              reqData.location_type = locationScanType;
              reqData.bay = newItemBay;
              reqData.shelf = newItemShelf;
              reqData.desk = newEngineerDesk;
            }
            scanItem(reqUrl, reqData);
            break;
          case 3:
            reqData = {
              edit_type: actionsObj[scanAction - 1],
              dest_container: destinationContainerId,
              from_container: containerActionType,
              comments: comments
            };
            scanItem(reqUrl, reqData);
            break;
          default:
            return;
        }
      } else {
        dispatch(showNotification("Not an SRW QR code.", "error"));
      }
    }
  }, [
    result,
    dispatch,
    infoModal,
    scanAction,
    statusScanType,
    play,
    comments,
    locationScanType,
    newEngineerDesk,
    newItemBay,
    newItemShelf,
    containerActionType,
    destinationContainerId,
    scanItem
  ]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={8} lg={6} xl={4}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>All Warehouse Items</h4>
                <h4 className={classes.cardCategoryWhite}>
                  All Warehouse Items
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <p>
                    Allow camera access on your device then use preview below to
                    scan SRW-issued barcodes.
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <ScanTypeSelector
                  scanAction={scanAction}
                  setScanAction={setScanAction}
                />
              </GridContainer>
              {scanAction === 1 ? (
                <NewStatusOptions
                  statusScanType={statusScanType}
                  setStatusScanType={setStatusScanType}
                />
              ) : scanAction === 2 ? (
                <NewLocationOptions
                  locationScanType={locationScanType}
                  setLocationScanType={setLocationScanType}
                  newEngineerDesk={newEngineerDesk}
                  setNewEngineerDesk={setNewEngineerDesk}
                  newItemBay={newItemBay}
                  setNewItemBay={setNewItemBay}
                  newItemShelf={newItemShelf}
                  setNewItemShelf={setNewItemShelf}
                />
              ) : scanAction === 3 ? (
                <NewContainerOptions
                  setDestinationContainerId={setDestinationContainerId}
                  destinationContainerId={destinationContainerId}
                  projId={projId}
                  setProjId={setProjId}
                  containerActionType={containerActionType}
                  setContainerActionType={setContainerActionType}
                />
              ) : (
                <></>
              )}
              {scanAction ? ( // not showing comments for scanAction = 0 ('get info')
                <GridContainer>
                  <GridItem xs={3}>
                    <FormLabel className={formClasses.labelHorizontal}>
                      Scan Comments (Optional)
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: comments,
                        onChange: e => {
                          setComments(e.target.value);
                        },
                        maxLength: 200
                      }}
                      helpText=""
                    />
                  </GridItem>
                </GridContainer>
              ) : (
                <></>
              )}
              <GridContainer>
                <GridItem xs={12}>
                  <QrReader
                    delay={1000}
                    style={previewStyle}
                    onError={handleError}
                    onScan={handleScan}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <ItemInfoDialog
          infoModal={infoModal}
          setInfoModal={setInfoModal}
          scannedItemId={scannedItemId}
          qrType={qrType}
        />
        <SpecifyNumberDialog
          boxId={scannedItemId}
          modal={specifyNumberModal}
          setModal={setSpecifyNumberModal}
          action={actionsObj[scanAction - 1]}
          totalInBox={totalInBox}
          reqDataObj={specifyReqData}
        />
      </GridContainer>
    </div>
  );
}
