import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
// redux
import { useDispatch } from "react-redux";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import { SET_WAREHOUSE_DELIVERY_DT_TRIG_REFRESH } from "redux/dispatch_types";
import showNotification from "global_functions/redux_action_creators/showNotification";
// custom components & functions
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import { getDialogProps } from "global_functions/_common/getDialogProps";
//material-ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Slide from "@material-ui/core/Slide";
// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import regularFormStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStyles = makeStyles(styles);
const useRegularFormStyles = makeStyles(regularFormStyle);
// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const apiUrl = process.env.REACT_APP_API_V1_URL;

export default function MoveDeliveryDialog(props) {
  const classes = useStyles();
  const regularFormClasses = useRegularFormStyles();

  const dispatch = useDispatch();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [dataLoading, setDataLoading] = useState(false);

  const [itemLocationInt, setItemLocationInt] = useState(null);
  const [checkBoxValue, setCheckBoxValue] = useState(0);

  useEffect(() => {
    if (props.moveDeliveryModal) {
      setCheckBoxValue("");

      setDataLoading(true);
      axios
        .get(
          `${apiUrl}warehouse-delivery-schedule/${props.deliveryId}`,
          getTokenConfig(store.getState)
        )
        .then(res => {
          setItemLocationInt(res.data.location);
          setDataLoading(false);
        })
        .catch(() => {
          dispatch(
            showNotification(
              `Error fetching delivery data. Please refresh and try again.`,
              "error"
            )
          );
          setDataLoading(false);
        });
    }
  }, [props.moveDeliveryModal, props.deliveryId, dispatch]);

  const handleStatusChange = e => {
    e.preventDefault();
    if (!checkBoxValue) {
      // moving back to awaiting
      props.setMoveDeliveryModal(false);
      props.setConfirmationDialog(true);
    } else {
      axios
        .put(
          `${apiUrl}warehouse-delivery-schedule/${props.deliveryId}`,
          { location: checkBoxValue, form: "move_delivery_form" },
          getTokenConfig(store.getState)
        )
        .then(res => {
          dispatch(showNotification("Delivery moved", "success"));
          props.setMoveDeliveryModal(false);
          dispatch({
            type: SET_WAREHOUSE_DELIVERY_DT_TRIG_REFRESH,
            payload: res.data
          });
        })
        .catch(() =>
          dispatch(
            showNotification(
              "Error moving. Please refresh and try again",
              "error"
            )
          )
        );
    }
  };

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={props.moveDeliveryModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.setMoveDeliveryModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => props.setMoveDeliveryModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Move Delivery</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {dataLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "150px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <>
              <form id="moveDeliveryForm" onSubmit={handleStatusChange}>
                <GridContainer>
                  <GridItem xs={2} />
                  {/*both, moving from warehouse and moving from delivered has the 'move to awaiting option'                                    */}
                  <GridItem xs={4}>
                    <div
                      className={
                        regularFormClasses.checkboxAndRadio +
                        " " +
                        regularFormClasses.checkboxAndRadioHorizontal
                      }
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            checked={checkBoxValue === 0}
                            onChange={event =>
                              setCheckBoxValue(parseInt(event.target.value))
                            }
                            value={0}
                            name="radio button enabled"
                            aria-label="B"
                            icon={
                              <FiberManualRecord
                                className={regularFormClasses.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={regularFormClasses.radioChecked}
                              />
                            }
                            classes={{
                              checked: regularFormClasses.radio,
                              root: regularFormClasses.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: regularFormClasses.label,
                          root: regularFormClasses.labelRoot
                        }}
                        label="Move back to awaiting"
                      />
                    </div>
                  </GridItem>
                  {/*only moving from delivery shelves (location > 2) have the 'move to warehouse' option*/}
                  {itemLocationInt > 1 && (
                    <>
                      <GridItem xs={4}>
                        <div
                          className={
                            regularFormClasses.checkboxAndRadio +
                            " " +
                            regularFormClasses.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={checkBoxValue === 1}
                                onChange={event =>
                                  setCheckBoxValue(parseInt(event.target.value))
                                }
                                value={1}
                                name="radio button enabled"
                                aria-label="A"
                                icon={
                                  <FiberManualRecord
                                    className={
                                      regularFormClasses.radioUnchecked
                                    }
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={regularFormClasses.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: regularFormClasses.radio,
                                  root: regularFormClasses.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: regularFormClasses.label,
                              root: regularFormClasses.labelRoot
                            }}
                            label="Move to Warehouse"
                          />
                        </div>
                      </GridItem>
                    </>
                  )}
                </GridContainer>
                {/*from warehouse item can be moved to any of the delivery shelves*/}
                {itemLocationInt === 1 && (
                  <>
                    <GridContainer>
                      <GridItem xs={2} />
                      <GridItem xs={4}>
                        <div
                          className={
                            regularFormClasses.checkboxAndRadio +
                            " " +
                            regularFormClasses.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={checkBoxValue === 2}
                                onChange={event =>
                                  setCheckBoxValue(parseInt(event.target.value))
                                }
                                value={2}
                                name="radio button enabled"
                                aria-label="A"
                                icon={
                                  <FiberManualRecord
                                    className={
                                      regularFormClasses.radioUnchecked
                                    }
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={regularFormClasses.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: regularFormClasses.radio,
                                  root: regularFormClasses.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: regularFormClasses.label,
                              root: regularFormClasses.labelRoot
                            }}
                            label="Move to D1"
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={4}>
                        <div
                          className={
                            regularFormClasses.checkboxAndRadio +
                            " " +
                            regularFormClasses.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={checkBoxValue === 3}
                                onChange={event =>
                                  setCheckBoxValue(parseInt(event.target.value))
                                }
                                value={3}
                                name="radio button enabled"
                                aria-label="A"
                                icon={
                                  <FiberManualRecord
                                    className={
                                      regularFormClasses.radioUnchecked
                                    }
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={regularFormClasses.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: regularFormClasses.radio,
                                  root: regularFormClasses.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: regularFormClasses.label,
                              root: regularFormClasses.labelRoot
                            }}
                            label="Move to D2"
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={2} />
                      <GridItem xs={4}>
                        <div
                          className={
                            regularFormClasses.checkboxAndRadio +
                            " " +
                            regularFormClasses.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={checkBoxValue === 4}
                                onChange={event =>
                                  setCheckBoxValue(parseInt(event.target.value))
                                }
                                value={4}
                                name="radio button enabled"
                                aria-label="A"
                                icon={
                                  <FiberManualRecord
                                    className={
                                      regularFormClasses.radioUnchecked
                                    }
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={regularFormClasses.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: regularFormClasses.radio,
                                  root: regularFormClasses.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: regularFormClasses.label,
                              root: regularFormClasses.labelRoot
                            }}
                            label="Move to D3"
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={4}>
                        <div
                          className={
                            regularFormClasses.checkboxAndRadio +
                            " " +
                            regularFormClasses.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={checkBoxValue === 5}
                                onChange={event =>
                                  setCheckBoxValue(parseInt(event.target.value))
                                }
                                value={5}
                                name="radio button enabled"
                                aria-label="A"
                                icon={
                                  <FiberManualRecord
                                    className={
                                      regularFormClasses.radioUnchecked
                                    }
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={regularFormClasses.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: regularFormClasses.radio,
                                  root: regularFormClasses.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: regularFormClasses.label,
                              root: regularFormClasses.labelRoot
                            }}
                            label="Move to D4"
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </>
                )}
              </form>
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="primary"
            simple
            type="submit"
            form="moveDeliveryForm"
            disabled={dataLoading}
          >
            Save
          </Button>
          <Button
            color="transparent"
            onClick={() => props.setMoveDeliveryModal(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
