import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
// redux
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import { useSelector, useDispatch } from "react-redux";
import { AUTH_HIDE_PASS_CHANGE_DIALOG } from "../../../redux/dispatch_types";
import showNotification from "global_functions/redux_action_creators/showNotification";
import { logoutUser } from "global_functions/redux_action_creators/auth/logoutUser";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// template
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
// material-ui
import FormLabel from "@material-ui/core/FormLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formsStyles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

// API URL

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ChangePasswordDialog = () => {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const showModal = useSelector(state => state.auth.showPassChangeDialog);

  const [modalLoading, setModalLoading] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const dispatch = useDispatch();

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  const handleFormSubmit = e => {
    e.preventDefault();
    // front-end validation (repeated in backend)
    if (newPassword1 !== newPassword2) {
      dispatch(showNotification("Entered passwords do not match.", "error"));
      return;
    } else if (newPassword1.length < 8) {
      dispatch(showNotification("New password is too short.", "error"));
      return;
    } else if (currentPassword === newPassword1) {
      dispatch(
        showNotification(
          "New password cannot be the same as the old password.",
          "error"
        )
      );
      return;
    }
    // submit form
    setModalLoading(true);
    axios
      .put(
        `${apiUrl}accounts/update-password/`,
        {
          old_password: currentPassword,
          new_password1: newPassword1,
          new_password2: newPassword2
        },
        getTokenConfig(store.getState)
      )
      .then(() => {
        setModalLoading(false);
        dispatch(
          showNotification(
            "Password changed. Please log in with your new credentials.",
            "success"
          )
        );
        dispatch({ type: AUTH_HIDE_PASS_CHANGE_DIALOG })
        dispatch(logoutUser());
      })
      .catch(err => {
        let errRespObj = err.response.data;
        Object.keys(errRespObj).forEach(key => {
          errRespObj[key].forEach(errorMsg => {
            dispatch(showNotification(errorMsg, "error"));
          });
        });
        setModalLoading(false);
      });
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener("keydown", captureKeyDown, false);
    } else {
      document.removeEventListener("keydown", captureKeyDown, false);
    }
  }, [showModal, captureKeyDown]);

  // clear fields on load
  useEffect(() => {
    if (showModal) {
      setCurrentPassword("");
      setNewPassword1("");
      setNewPassword2("");
    }
  }, [showModal]);

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        fullWidth={true}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => dispatch({ type: AUTH_HIDE_PASS_CHANGE_DIALOG })}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => dispatch({ type: AUTH_HIDE_PASS_CHANGE_DIALOG })}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Change Password</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {modalLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
                width: "500px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <form id="changePasswordForm" onSubmit={handleFormSubmit}>
              <GridContainer>
                <GridItem xs={4}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Current Password
                  </FormLabel>
                </GridItem>
                <GridItem xs={7}>
                  <CustomInput
                    id="bundleName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helpText="Bundle name"
                    inputProps={{
                      type: "password",
                      value: currentPassword,
                      onChange: e => {
                        setCurrentPassword(e.target.value);
                      },
                      required: true,
                      maxLength: 30
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    New Password
                  </FormLabel>
                </GridItem>
                <GridItem xs={7}>
                  <CustomInput
                    id="bundleName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helpText="Bundle name"
                    inputProps={{
                      type: "password",
                      value: newPassword1,
                      onChange: e => {
                        setNewPassword1(e.target.value);
                      },
                      required: true,
                      maxLength: 30
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Repeat New Password
                  </FormLabel>
                </GridItem>
                <GridItem xs={7}>
                  <CustomInput
                    id="bundleName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helpText="Bundle name"
                    inputProps={{
                      type: "password",
                      value: newPassword2,
                      onChange: e => {
                        setNewPassword2(e.target.value);
                      },
                      required: true,
                      maxLength: 30
                    }}
                  />
                </GridItem>
              </GridContainer>
            </form>
          )}
        </DialogContent>
        <DialogActions className={formClasses.modalFooter}>
          <Button
            color="primary"
            simple
            type="submit"
            form="changePasswordForm"
            disabled={modalLoading}
          >
            Save
          </Button>
          <Button
            color="transparent"
            onClick={() => dispatch({ type: AUTH_HIDE_PASS_CHANGE_DIALOG })}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
};

export default ChangePasswordDialog;
