// Edited NavPills component

import React from "react";
import { useDispatch } from "react-redux";

import classNames from "classnames";
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";

import styles from "assets/jss/material-dashboard-pro-react/components/navPillsStyle.js";

const useStyles = makeStyles(styles);

export default function TableFilter(props) {
  const dispatch = useDispatch();

  const handleChange = (event, selectedFilter) => {
    dispatch({ type: props.dispatchType, payload: selectedFilter });
  };

  const classes = useStyles();
  const { tabs, color, horizontal, alignCenter } = props;

  const flexContainerClasses = classNames({
    [classes.flexContainer]: true,
    [classes.horizontalDisplay]: horizontal !== undefined
  });

  const tabButtons = (
    <Tabs
      classes={{
        root: classes.root,
        fixed: classes.fixed,
        flexContainer: flexContainerClasses,
        indicator: classes.displayNone
      }}
      value={props.activeFilter | 0}
      onChange={handleChange}
      centered={alignCenter}
    >
      {tabs.map((prop, key) => {
        var icon = {};
        if (prop.tabIcon !== undefined) {
          icon["icon"] = <prop.tabIcon className={classes.tabIcon} />;
        }
        const pillsClasses = classNames({
          [classes.pills]: true,
          [classes.horizontalPills]: horizontal !== undefined,
          [classes.pillsWithIcons]: prop.tabIcon !== undefined
        });
        return (
          <Tab
            label={prop}
            key={key}
            {...icon}
            classes={{
              root: pillsClasses,
              selected: classes[color]
            }}
          />
        );
      })}
    </Tabs>
  );

  return horizontal !== undefined ? (
    <GridContainer>
      <GridItem {...horizontal.tabsGrid}>{tabButtons}</GridItem>
    </GridContainer>
  ) : (
    <div>{tabButtons}</div>
  );
}

TableFilter.propTypes = {
  // index of the default active pill
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ]),
  direction: PropTypes.string,
  horizontal: PropTypes.shape({
    tabsGrid: PropTypes.object,
    contentGrid: PropTypes.object
  }),
  alignCenter: PropTypes.bool,
  activeFilter: PropTypes.number.isRequired,
  dispatchType: PropTypes.string.isRequired,
  dataTable: PropTypes.any.isRequired
};
