import React, { useState } from "react";

import { createPortal } from "react-dom";

const CustomIframe: React.FC<CustomIframeProps> = ({ children,
                                                     title,
                                                     ...props }) => {

  const [contentRef, setContentRef] = useState(null);

  // @ts-ignore
  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    // @ts-ignore
    <iframe title={title} {...props} ref={setContentRef}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};

export default CustomIframe;

interface CustomIframeProps {
  children?: React.ReactNode;
  title: string;
}
