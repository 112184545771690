import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
// redux
import { useDispatch } from "react-redux";
import { SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH } from "redux/dispatch_types";
// custom functions
import showNotification from "global_functions/redux_action_creators/showNotification";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import { getDialogProps } from "global_functions/_common/getDialogProps";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
//core components
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import store from "redux/store";
const useStyles = makeStyles(styles);
// API URL
const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ChangeStatusMultipleDialog({
  modal,
  setModal,
  projId,
  currentStatus
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [statusChangeInProgress, setStatusChangeInProgress] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const btnTitle =
    currentStatus === 1 ? "Check In" : currentStatus === 5 ? "Check Out" : "";

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    // removing ability to go back when modal is open as goes back in the previous page (not closes modal)
    if (modal) {
      document.addEventListener("keydown", captureKeyDown, false);
    } else {
      document.removeEventListener("keydown", captureKeyDown, false);
    }
  }, [modal, captureKeyDown]);

  const handleCheckInConfirmClick = () => {
    setStatusChangeInProgress(true);
    axios
      .put(
        apiUrl + `projects/${projId}/items/0`,
        { current_status: currentStatus },
        getTokenConfig(store.getState)
      )
      .then(res => {
        dispatch(showNotification(res.data.statusText, "success"));
        setModal(false);
        setStatusChangeInProgress(false);
      })
      .then(() =>
        dispatch({
          type: SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH,
          payload: { id: null }
        })
      ) // table will be empty after refresh
      .catch(err => {
        let errorMsg;
        if (err.response.data.error_msg) {
          errorMsg = err.response.data.error_msg;
        } else if (Array.isArray(err.response.data.errors)) {
          errorMsg = err.response.data.errors[0];
        } else if (err.response.data.errors) {
          errorMsg = err.response.data.errors;
        } else {
          errorMsg = "Error changing status in.";
        }
        dispatch(
          showNotification("[" + err.response.status + "] " + errorMsg, "error")
        );
        setStatusChangeInProgress(false);
      });
  };

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>

          {currentStatus === 1 ? (
            <h4 className={classes.modalTitle}>
              Check In All Items with Pre-Check-In Status
            </h4>
          ) : (
            <h4 className={classes.modalTitle}>
              Check Out All Items with Pre-Check-Out Status
            </h4>
          )}
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {statusChangeInProgress ? (
            <CustomLoadingSpinner />
          ) : currentStatus === 1 ? (
            <p>
              <span style={{ color: "red" }}>Warning: </span>This will check in
              all items with pre-check-in status for this project. Are you sure
              you wish to proceed?
            </p>
          ) : currentStatus === 5 ? (
            <p>
              <span style={{ color: "red" }}>Warning: </span>This will check out
              all items with pre-check-out status for this project. Are you sure
              you wish to proceed?
            </p>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            disabled={statusChangeInProgress}
            color="danger"
            onClick={() => handleCheckInConfirmClick()}
            simple
          >
            {btnTitle}
          </Button>
          <Button
            disabled={statusChangeInProgress}
            color="transparent"
            onClick={() => setModal(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
