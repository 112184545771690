import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// color
import { successColor } from "assets/jss/material-dashboard-pro-react";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";

// icon
import CropFree from "@material-ui/icons/CropFree";

const useStyles = makeStyles(theme => ({
  arrowForwardButton: {
    backgroundColor: "#f9f9f9",
    "&:hover, &.Mui-focusVisible": { backgroundColor: primaryColor[3] },
    marginTop: 10,
    marginRight: 10
  }
}));

export default function QrButton({ handleGenQrClick, tooltip }) {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip}>
      <IconButton
        className={classes.arrowForwardButton}
        onClick={handleGenQrClick}
      >
        <CropFree style={{ color: successColor[0] }} />
      </IconButton>
    </Tooltip>
  );
}
