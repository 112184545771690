import React, { useEffect, useState } from "react";
// redux
import { useDispatch } from "react-redux";
import { registerUserFromAdmin } from "../../../global_functions/redux_action_creators/auth/registerUserFromAdmin";
import { SET_USER_DB_CLOSE_ADD_DIALOG } from "../../../redux/dispatch_types";
// material ui
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import FormLabel from "@material-ui/core/FormLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
// template
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "../../../components/CustomButtons/Button";
// styles
import { makeStyles } from "@material-ui/core/styles";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import axios from "axios";
import { getTokenConfig } from "../../../global_functions/redux_action_creators/auth/getTokenConfig";
import store from "../../../redux/store";
const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const apiUrl = process.env.REACT_APP_API_V1_URL;

export default function AddUserDialog({ showModal }) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();
  const classes = useStyles();

  // form fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [clientCompany, setClientCompany] = useState(null);

  const [positionChoiceFields, setPositionChoiceFields] = useState(null);
  const [companyChoiceFields, setCompanyChoiceFields] = useState(null);
  const [clientPositionIds, setClientPositionIds] = useState(null);

  const [choiceFieldsLoading, setChoiceFieldsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (showModal) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setPosition("");
      setPassword("");
      setPassword2("");

      setChoiceFieldsLoading(false);
      axios
        .get(
          `${apiUrl}form-fields/new-user-form`,
          getTokenConfig(store.getState)
        )
        .then(res => {
          setPositionChoiceFields(res.data.POSITION_CHOICES);
          setCompanyChoiceFields(res.data.CLIENT_CHOICES);
          setClientPositionIds(res.data.CLIENT_POSITIONS);
          setChoiceFieldsLoading(false);
        });
    }
  }, [showModal]);

  const handleFormSubmit = e => {
    e.preventDefault();
    // company id is only set for clients
    let client_id;
    client_id = clientPositionIds.includes(position) ? clientCompany : null;

    dispatch(
      registerUserFromAdmin(
        firstName,
        lastName,
        email,
        position,
        password,
        password2,
        client_id
      )
    );
  };

  return (
    <>
      <GridItem xs={12} sm={12} md={12} className={classes.center}>
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal
          }}
          fullWidth={true}
          open={showModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => dispatch({ type: SET_USER_DB_CLOSE_ADD_DIALOG })}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => dispatch({ type: SET_USER_DB_CLOSE_ADD_DIALOG })}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Register New User</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={notifyClasses.modalBody}
          >
            <form id="addEditUserForm" onSubmit={handleFormSubmit}>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Name
                  </FormLabel>
                </GridItem>
                <GridItem xs={9}>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: firstName,
                      onChange: e => {
                        setFirstName(e.target.value);
                      },
                      required: true,
                      maxLength: 30
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel className={formClasses.labelHorizontal}>
                    Surname
                  </FormLabel>
                </GridItem>
                <GridItem xs={9}>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: lastName,
                      onChange: e => {
                        setLastName(e.target.value);
                      },
                      maxLength: 50,
                      required: true
                    }}
                    helpText=""
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel className={formClasses.labelHorizontal}>
                    E-Mail (Username)
                  </FormLabel>
                </GridItem>
                <GridItem xs={9}>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: email,
                      onChange: e => {
                        setEmail(e.target.value);
                      },
                      maxLength: 50,
                      required: true
                    }}
                    helpText=""
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Position
                  </FormLabel>
                </GridItem>
                <GridItem xs={9}>
                  <CustomSelect
                    options={positionChoiceFields}
                    isDisabled={choiceFieldsLoading}
                    value={position}
                    setValue={setPosition}
                    required={true}
                  />
                </GridItem>
              </GridContainer>
              {clientPositionIds && clientPositionIds.includes(position) ? (
                <GridContainer>
                  <GridItem xs={3}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      Company
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={9}>
                    <CustomSelect
                      options={companyChoiceFields}
                      isDisabled={choiceFieldsLoading}
                      value={clientCompany}
                      setValue={setClientCompany}
                      required={true}
                    />
                  </GridItem>
                </GridContainer>
              ) : (
                <></>
              )}
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel className={formClasses.labelHorizontal}>
                    Password
                  </FormLabel>
                </GridItem>
                <GridItem xs={9}>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "password",
                      value: password,
                      onChange: e => {
                        setPassword(e.target.value);
                      },
                      maxLength: 50,
                      required: true
                    }}
                    helpText=""
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel className={formClasses.labelHorizontal}>
                    Repeat Password
                  </FormLabel>
                </GridItem>
                <GridItem xs={9}>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "password",
                      value: password2,
                      onChange: e => {
                        setPassword2(e.target.value);
                      },
                      maxLength: 50,
                      required: true
                    }}
                    helpText=""
                  />
                </GridItem>
              </GridContainer>
            </form>
          </DialogContent>
          <DialogActions className={formClasses.modalFooter}>
            <Button
              color="primary"
              simple
              type="submit"
              form="addEditUserForm"
              disabled={choiceFieldsLoading}
            >
              Save
            </Button>
            <Button
              color="transparent"
              onClick={() => dispatch({ type: SET_USER_DB_CLOSE_ADD_DIALOG })}
              simple
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </>
  );
}
