import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
// custom functions
import showNotification from "global_functions/redux_action_creators/showNotification";
import { getDialogProps } from "global_functions/_common/getDialogProps";
// redux
import { useDispatch } from "react-redux";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
// components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
// @material-ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Table } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
const useStyles = makeStyles(styles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ItemInfoDialog({
  infoModal,
  scannedItemId,
  setInfoModal,
  qrType
}) {
  const classes = useStyles();

  const [resData, setResData] = useState("");
  const [infoLoading, setInfoLoading] = useState(false);

  const dispatch = useDispatch();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (infoModal) document.addEventListener("keydown", captureKeyDown, false);
    else document.removeEventListener("keydown", captureKeyDown, false);
  }, [infoModal, captureKeyDown]);

  // fetching item data on every modal load
  useEffect(() => {
    if (infoModal) {
      setInfoLoading(true);

      // set url based on container/item
      let qrInfoUrl = "";
      if (qrType === "Item")
        qrInfoUrl = `${apiUrl}projects/0/items/${scannedItemId}`;
      else if (qrType === "Container")
        qrInfoUrl = `${apiUrl}projects/0/containers/${scannedItemId}`;

      axios
        .get(qrInfoUrl, getTokenConfig(store.getState))
        .then(res => {
          setResData(res.data);
          setInfoLoading(false);
        })
        .catch(err => {
          setResData("");
          let errorMsg = "";
          if (err.response.data.errors) {
            errorMsg = `Error: ${err.response.data.errors}`;
          }
          dispatch(
            showNotification(
              `Error scanning ${qrType} ID ${scannedItemId}. Please make sure it is in the database. ${errorMsg}`,
              "error"
            )
          );
          setInfoLoading(false);
        });
    } else {
      setResData("");
    }
  }, [infoModal, scannedItemId, dispatch, qrType]);

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={infoModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setInfoModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setInfoModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{qrType} Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {infoLoading ? (
            <GridContainer>
              <GridItem xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              </GridItem>
            </GridContainer>
          ) : (
            <>
              <GridContainer>
                <GridItem xs={1} />
                <GridItem xs={10}>
                  <TableContainer component={Paper}>
                    <Table style={{ alignItems: "left" }}>
                      <TableBody>
                        <TableRow>
                          <TableCell>Status:</TableCell>
                          <TableCell>{resData.status_str}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{qrType} ID:</TableCell>
                          <TableCell>{resData.id}</TableCell>
                        </TableRow>
                        {qrType === "Item" ? (
                          <>
                            <TableRow>
                              <TableCell>Manufacturer:</TableCell>
                              <TableCell>{resData.manufacturer}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Model:</TableCell>
                              <TableCell>{resData.model}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Serial No:</TableCell>
                              <TableCell>{resData.serial_no}</TableCell>
                            </TableRow>
                          </>
                        ) : (
                          <TableRow>
                            <TableCell>Container Name</TableCell>
                            <TableCell>{resData.name}</TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell>Project Name:</TableCell>
                          <TableCell>{resData.project_name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Project Number:</TableCell>
                          <TableCell>{resData.project_number}</TableCell>
                        </TableRow>
                        {resData.comments && (
                          <TableRow>
                            <TableCell>Comments:</TableCell>
                            <TableCell>{resData.comments}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </GridItem>
                <GridItem xs={1} />
              </GridContainer>
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="transparent"
            onClick={() => setInfoModal(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
