import React, { useEffect, useState } from "react";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// template
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
// material ui
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
// styles
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);

export default function AddEditCompanyServiceForm(props) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  // form fields
  const [serviceName, setServiceName] = useState("");

  useEffect(() => {
    if (props.editData && props.open) {
      setServiceName(props.editData.name);
    } else if (!props.editData && props.open) {
      setServiceName("");
    }
  }, [props.editData, props.open]);

  useEffect(() => {
    props.setformData({
      name: serviceName
    });
    // eslint-disable-next-line
    }, [serviceName]);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "140px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Name
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <CustomInput
                  id="serviceName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Service name"
                  inputProps={{
                    type: "text",
                    value: serviceName,
                    onChange: e => {
                      setServiceName(e.target.value);
                    },
                    required: true,
                    maxLength: 100
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
}
