import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

export const projectsWithWarehouseItemsMain = {
  section: "projectsWithWarehouseItems",
  reduxTableName: "projectsWithWarehouseItemsMain",
  filterTabValues: [0, 1],
  filterTabNames: ["Active", "Spent"],
  refreshURL: "projects/?with_warehouse_items=1&filter=",
  tableParams: {
    autoWidth: true,
    responsive: true,
    buttonsColumn: {
      id: 8,
      genIcons: () => {
        return [{ icon: "Visibility", tooltip: "" }];
      }
    },
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    columns: [
      {
        data: "id",
        title: "id",
        className: "dt-body-center",
        visible: false
      }, // 0
      {
        data: "project_number",
        title: "Project Number",
        className: "dt-body-center"
      }, // 1
      {
        data: "project_name",
        title: "Project Name",
        className: "dt-body-center"
      }, // 2
      {
        data: "pre_check_in_item_no",
        title: "Pre-Check-in",
        className: "dt-body-center"
      }, // 3
      {
        data: "checked_in_item_no",
        title: "Checked-In",
        className: "dt-body-center"
      }, // 4
      {
        data: "pre_check_out_item_no",
        title: "Pre-Check-Out",
        className: "dt-body-center"
      }, // 5
      {
        data: "checked_out_item_no",
        title: "Checked-Out",
        className: "dt-body-center"
      }, // 6
      {
        data: "container_no",
        title: "Container No",
        className: "dt-body-center"
      }, // 7
      {
        data: null,
        title: "View",
        className: "dt-body-center",
        responsivePriority: 1
      } // 8
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[0, "asc"]]
  }
};
