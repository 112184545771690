import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
// custom functions
import { getDialogProps } from "global_functions/_common/getDialogProps";
// redux
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
// material-ui
import Info from "@material-ui/icons/Info";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
const customFormHeader = {
  textAlign: "left",
  marginBottom: "0px",
  marginTop: "10px"
};
const useStyles = makeStyles(styles);
// API URL
const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DeliveryInfoDialog(props) {
  const classes = useStyles();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [infoLoading, setInfoLoading] = useState(false);
  const [comments, setComments] = useState("");
  const [condition, setCondition] = useState("");
  const [dateDelivered, setDateDelivered] = useState(null);
  const [timeDelivered, setTimeDelivered] = useState(null);
  const [deliveryCompany, setDeliveryCompany] = useState("");
  const [expectedContents, setExpectedContents] = useState("");
  const [boxNumber, setBoxNumber] = useState(0);
  const [location, setLocation] = useState("");
  const [acceptedBy, setAcceptedBy] = useState("");

  useEffect(() => {
    if (props.deliveryInfoDialog) {
      // load info on every open
      setInfoLoading(true);
      axios
        .get(
          `${apiUrl}warehouse-delivery-schedule/${props.deliveryId}`,
          getTokenConfig(store.getState)
        )
        .then(res => {
          setComments(res.data.comments);
          setCondition(res.data.condition_str);
          setLocation(res.data.location_disp);
          setAcceptedBy(res.data.accepted_by);
          setDeliveryCompany(res.data.delivery_company);
          setExpectedContents(res.data.expected_contents ? "Yes" : "No");
          setBoxNumber(res.data.no_boxes || 0);
          setDateDelivered(res.data.date_delivered_str);
          setTimeDelivered(res.data.time_delivered_str);
        })
        .then(() => setInfoLoading(false));
    }
  }, [props.deliveryInfoDialog, props.deliveryId]);

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={props.deliveryInfoDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.setDeliveryInfoDialog(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => props.setDeliveryInfoDialog(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Delivery Information</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {infoLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
                width: "500px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <>
              <GridContainer>
                <GridItem sm={2}>
                  <Info
                    color="primary"
                    fontSize="large"
                    style={{ marginTop: "10px" }}
                  />
                </GridItem>
                <GridItem sm={5}>
                  <p
                    className={classes.modalSectionTitle}
                    style={customFormHeader}
                  >
                    {" "}
                    Delivery Date:{" "}
                  </p>
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    {dateDelivered}
                  </p>
                </GridItem>
                <GridItem sm={5}>
                  <p
                    className={classes.modalSectionTitle}
                    style={customFormHeader}
                  >
                    {" "}
                    Delivery Time:{" "}
                  </p>
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    {timeDelivered}
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={2} />
                <GridItem sm={5}>
                  <p
                    className={classes.modalSectionTitle}
                    style={customFormHeader}
                  >
                    {" "}
                    Delivery Company:{" "}
                  </p>
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {deliveryCompany}{" "}
                  </p>
                </GridItem>
                <GridItem sm={5}>
                  <p
                    className={classes.modalSectionTitle}
                    style={customFormHeader}
                  >
                    {" "}
                    Number of Boxes:{" "}
                  </p>
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {boxNumber}{" "}
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={2} />
                <GridItem sm={5}>
                  <p
                    className={classes.modalSectionTitle}
                    style={customFormHeader}
                  >
                    {" "}
                    Expected Contents:{" "}
                  </p>
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {expectedContents}{" "}
                  </p>
                </GridItem>
                <GridItem sm={5}>
                  <p
                    className={classes.modalSectionTitle}
                    style={customFormHeader}
                  >
                    {" "}
                    Condition:{" "}
                  </p>
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {condition}{" "}
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={2} />
                <GridItem sm={5}>
                  <p
                    className={classes.modalSectionTitle}
                    style={customFormHeader}
                  >
                    {" "}
                    Accepted By:{" "}
                  </p>
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {acceptedBy}{" "}
                  </p>
                </GridItem>
                <GridItem sm={5}>
                  <p
                    className={classes.modalSectionTitle}
                    style={customFormHeader}
                  >
                    {" "}
                    Location:{" "}
                  </p>
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {location}{" "}
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={2} />
                <GridItem sm={10}>
                  <p
                    className={classes.modalSectionTitle}
                    style={customFormHeader}
                  >
                    {" "}
                    Comments:{" "}
                  </p>
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {comments}{" "}
                  </p>
                </GridItem>
              </GridContainer>
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="primary"
            onClick={() => {
              props.setDeliveryInfoDialog(false);
              props.setEditDialog(true);
            }}
            simple
          >
            Edit
          </Button>
          <Button
            color="transparent"
            onClick={() => props.setDeliveryInfoDialog(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
