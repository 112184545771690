import { SET_DASHBOARD_WIDGET_TYPE } from "redux/dispatch_types";

import { ReduxActionType } from "../../../../global_functions/types/shared";

const initialState: UserAdminDashboardType = {
  viewedWidgetType: null
};

export default function(
  state = initialState,
  action: ReduxActionType
): UserAdminDashboardType {
  switch (action.type) {
    case SET_DASHBOARD_WIDGET_TYPE:
      return {
        ...state,
        viewedWidgetType: action.payload
      };
    default:
      return state;
  }
}

// typescript declarations
type UserAdminDashboardType = {
  viewedWidgetType: "admin" | "user" | null;
};
