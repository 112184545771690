// auth (common)
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const AUTH_SHOW_PASS_CHANGE_DIALOG = "AUTH_SHOW_PASS_CHANGE_DIALOG";
export const AUTH_HIDE_PASS_CHANGE_DIALOG = "AUTH_HIDE_PASS_CHANGE_DIALOG";
// export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
// export const REGISTER_FAIL = 'REGISTER_FAIL';
export const AUTH_ERROR_NOTIFICATION = "AUTH_ERROR_NOTIFICATION"; // handled by the ReduxNotifications component

// messages (common)
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";

// spinner cursor
export const TOGGLE_SPINNER_CURSOR = "TOGGLE_SPINNER_CURSOR";

// user dashboard
export const SET_DASHBOARD_WIDGET_TYPE = "SET_DASHBOARD_WIDGET_TYPE";

// client dashboard
export const SET_CLIENT_DASHBOARD_VIEW_ITEMS =
  "SET_CLIENT_DASHBOARD_VIEW_ITEMS";
export const SET_CLIENT_DASHBOARD_VIEW_PROJECTS =
  "SET_CLIENT_DASHBOARD_VIEW_PROJECTS";
export const SET_CLIENT_DASHBOARD_PROJ_DT_DT_PAGE =
  "SET_CLIENT_DASHBOARD_PROJ_DT_DT_PAGE";
export const SET_CLIENT_DASHBOARD_PROJ_DT_DT_TRIG_REFRESH =
  "SET_CLIENT_DASHBOARD_PROJ_DT_DT_TRIG_REFRESH";
export const SET_CLIENT_DASHBOARD_PROJ_DT_DT_ROW =
  "SET_CLIENT_DASHBOARD_PROJ_DT_DT_ROW";
export const SET_CLIENT_DASHBOARD_PROJ_DT_FILTER =
  "SET_CLIENT_DASHBOARD_PROJ_DT_FILTER";
export const SET_CLIENT_DASHBOARD_PROJ_DT_REFRESH_URL =
  "SET_CLIENT_DASHBOARD_PROJ_DT_REFRESH_URL";
export const SET_CLIENT_DASHBOARD_ITEM_DT_PAGE =
  "SET_CLIENT_DASHBOARD_ITEM_DT_PAGE";
export const SET_CLIENT_DASHBOARD_ITEM_DT_ROW =
  "SET_CLIENT_DASHBOARD_ITEM_DT_ROW";
export const SET_CLIENT_DASHBOARD_ITEM_DT_FILTER =
  "SET_CLIENT_DASHBOARD_ITEM_DT_FILTER";
export const SET_CLIENT_DASHBOARD_ITEM_DT_REFRESH_URL =
  "SET_CLIENT_DASHBOARD_ITEM_DT_REFRESH_URL";
export const SET_CLIENT_DASHBOARD_ITEM_DT_TRIG_REFRESH =
  "SET_CLIENT_DASHBOARD_ITEM_DT_TRIG_REFRESH";
// project db
export const SET_PROJECT_DB_FILTER = "SET_PROJECT_DB_FILTER";
export const SET_PROJECT_DB_DT_PAGE = "SET_PROJECT_DB_DT_PAGE";
export const SET_PROJECT_DB_DT_ROW = "SET_PROJECT_DB_DT_ROW";
export const SET_PROJECT_DB_DT_TRIG_REFRESH = "SET_PROJECT_DB_DT_TRIG_REFRESH";

// user db
export const SET_USER_DB_DT_PAGE = "SET_USER_DB_DT_PAGE";
export const SET_USER_DB_FILTER = "SET_USER_DB_FILTER";
export const SET_USER_DB_DT_ROW = "SET_USER_DB_DT_ROW";
export const SET_USER_DB_DT_TRIG_REFRESH = "SET_USER_DB_DT_TRIG_REFRESH";
export const SET_USER_DB_SHOW_ADD_DIALOG = "SET_USER_DB_SHOW_ADD_DIALOG";
export const SET_USER_DB_CLOSE_ADD_DIALOG = "SET_USER_DB_CLOSE_ADD_DIALOG";

// client db
export const SET_CLIENT_DB_FILTER = "SET_CLIENT_DB_FILTER";
export const SET_CLIENT_DB_DT_PAGE = "SET_CLIENT_DB_DT_PAGE";
export const SET_CLIENT_DB_DT_ROW = "SET_CLIENT_DB_DT_ROW";
export const SET_CLIENT_DB_DT_TRIG_REFRESH = "SET_CLIENT_DB_DT_TRIG_REFRESH";

// certificate types
export const SET_CERTIFICATE_TYPE_DT_PAGE = "SET_CERTIFICATE_TYPE_DT_PAGE";
export const SET_CERTIFICATE_TYPE_DT_ROW = "SET_CERTIFICATE_TYPE_DT_ROW";
export const SET_CERTIFICATE_TYPE_DT_TRIG_REFRESH =
  "SET_CERTIFICATE_TYPE_DT_TRIG_REFRESH";

// certificate requirements
export const SET_CERTIFICATE_REQ_DT_PAGE = "SET_CERTIFICATE_REQ_DT_PAGE";
export const SET_CERTIFICATE_REQ_DT_TRIG_REFRESH =
  "SET_CERTIFICATE_REQ_DT_TRIG_REFRESH";
export const SET_CERTIFICATE_REQ_DT_ROW = "SET_CERTIFICATE_REQ_DT_ROW";

// certificate db (all users page)
export const SET_CERTIFICATE_ALL_USERS_DT_PAGE =
  "SET_CERTIFICATE_ALL_USERS_DT_PAGE";
export const SET_CERTIFICATE_ALL_USERS_DT_ROW =
  "SET_CERTIFICATE_ALL_USERS_DT_ROW";
export const SET_CERTIFICATE_ALL_USERS_DT_TRIG_REFRESH =
  "SET_CERTIFICATE_ALL_USERS_DT_TRIG_REFRESH";
export const SET_CERTIFICATE_ALL_USERS_DT_FILTER =
  "SET_CERTIFICATE_ALL_USERS_DT_FILTER";

// certificate db (one user page)
export const SET_CERTIFICATE_ONE_USER_DT_PAGE =
  "SET_CERTIFICATE_ONE_USER_DT_PAGE";
export const SET_CERTIFICATE_ONE_USER_DT_TRIG_REFRESH =
  "SET_CERTIFICATE_ONE_USER_DT_TRIG_REFRESH";
export const SET_CERTIFICATE_ONE_USER_DT_ROW =
  "SET_CERTIFICATE_ONE_USER_DT_ROW";
export const SET_CERTIFICATE_ONE_USER_REFRESH_URL =
  "SET_CERTIFICATE_ONE_USER_REFRESH_URL";

// user timesheet bundles
export const SET_TS_BUNDLE_USER_FILTER = "SET_TS_BUNDLE_USER_FILTER";
export const SET_TS_BUNDLE_USER_DT_ROW = "SET_TS_BUNDLE_USER_DT_ROW";
export const SET_TS_BUNDLE_USER_DT_PAGE = "SET_TS_BUNDLE_USER_DT_PAGE";
export const SET_TS_BUNDLE_USER_DT_TRIG_REFRESH =
  "SET_TS_BUNDLE_USER_DT_TRIG_REFRESH";

// user timesheet entries
export const SET_TS_ENTRIES_USER_DT_ROW = "SET_TS_ENTRIES_USER_DT_ROW";
export const SET_TS_ENTRIES_USER_DT_PAGE = "SET_TS_ENTRIES_USER_DT_PAGE";
export const SET_TS_ENTRIES_USER_DT_TRIG_REFRESH =
  "SET_TS_ENTRIES_USER_DT_TRIG_REFRESH";
export const SET_TS_ENTRIES_USER_REFRESH_URL =
  "SET_TS_ENTRIES_USER_REFRESH_URL";

// admin timesheet bundles
export const SET_TS_BUNDLE_ADMIN_FILTER = "SET_TS_BUNDLE_ADMIN_FILTER";
export const SET_TS_BUNDLE_ADMIN_DT_ROW = "SET_TS_BUNDLE_ADMIN_DT_ROW";
export const SET_TS_BUNDLE_ADMIN_DT_PAGE = "SET_TS_BUNDLE_ADMIN_DT_PAGE";
export const SET_TS_BUNDLE_ADMIN_DT_TRIG_REFRESH =
  "SET_TS_BUNDLE_ADMIN_DT_TRIG_REFRESH";

// admin timesheet entries
export const SET_TS_ENTRIES_ADMIN_DT_ROW = "SET_TS_ENTRIES_ADMIN_DT_ROW";
export const SET_TS_ENTRIES_ADMIN_DT_PAGE = "SET_TS_ENTRIES_ADMIN_DT_PAGE";
export const SET_TS_ENTRIES_ADMIN_REFRESH_URL =
  "SET_TS_ENTRIES_ADMIN_REFRESH_URL";
export const SET_TS_ENTRIES_ADMIN_DT_TRIG_REFRESH =
  "SET_TS_ENTRIES_ADMIN_DT_TRIG_REFRESH";

// user expense entries
export const SET_EXP_ENTRIES_USER_DT_ROW = "SET_EXP_ENTRIES_USER_DT_ROW";
export const SET_EXP_ENTRIES_USER_DT_PAGE = "SET_EXP_ENTRIES_USER_DT_PAGE";
export const SET_EXP_ENTRIES_USER_DT_TRIG_REFRESH =
  "SET_EXP_ENTRIES_USER_DT_TRIG_REFRESH";
export const SET_EXP_ENTRIES_USER_REFRESH_URL =
  "SET_EXP_ENTRIES_USER_REFRESH_URL";

// user expense bundles
export const SET_EXP_BUNDLE_USER_FILTER = "SET_EXP_BUNDLE_USER_FILTER";
export const SET_EXP_BUNDLE_USER_DT_ROW = "SET_EXP_BUNDLE_USER_DT_ROW";
export const SET_EXP_BUNDLE_USER_DT_PAGE = "SET_EXP_BUNDLE_USER_DT_PAGE";
export const SET_EXP_BUNDLE_USER_DT_TRIG_REFRESH =
  "SET_EXP_BUNDLE_USER_DT_TRIG_REFRESH";

// admin expense bundles
export const SET_EXP_BUNDLE_ADMIN_FILTER = "SET_EXP_BUNDLE_ADMIN_FILTER";
export const SET_EXP_BUNDLE_ADMIN_DT_ROW = "SET_EXP_BUNDLE_ADMIN_DT_ROW";
export const SET_EXP_BUNDLE_ADMIN_DT_PAGE = "SET_EXP_BUNDLE_ADMIN_DT_PAGE";
export const SET_EXP_BUNDLE_ADMIN_DT_TRIG_REFRESH =
  "SET_EXP_BUNDLE_ADMIN_DT_TRIG_REFRESH";

// admin expense entries
export const SET_EXP_ENTRIES_ADMIN_REFRESH_URL =
  "SET_EXP_ENTRIES_ADMIN_REFRESH_URL";
export const SET_EXP_ENTRIES_ADMIN_DT_ROW = "SET_EXP_ENTRIES_ADMIN_DT_ROW";
export const SET_EXP_ENTRIES_ADMIN_DT_PAGE = "SET_EXP_ENTRIES_ADMIN_DT_PAGE";
export const SET_EXP_ENTRIES_ADMIN_DT_TRIG_REFRESH =
  "SET_EXP_ENTRIES_ADMIN_DT_TRIG_REFRESH";

// holidays - user
export const SET_USER_HOLS_DT_PAGE = "SET_USER_HOLS_DT_PAGE";
export const SET_USER_HOLS_DT_ROW = "SET_USER_HOLS_DT_ROW";
export const SET_USER_HOLS_DT_TRIG_REFRESH = "SET_USER_HOLS_DT_TRIG_REFRESH";
export const SET_HOLS_USER_DT_FILTER = "SET_HOLS_USER_DT_FILTER";

// holidays - admin
export const SET_HOLS_ADMIN_MAIN_DT_FILTER = "SET_HOLS_ADMIN_FILTER";
export const SET_HOLS_ADMIN_MAIN_DT_PAGE = "SET_HOLS_ADMIN_DT_PAGE";
export const SET_HOLS_ADMIN_MAIN_DT_TRIG_REFRESH =
  "SET_HOLS_ADMIN_DT_TRIG_REFRESH";
export const SET_HOLS_ADMIN_MAIN_DT_ROW = "SET_HOLS_ADMIN_MAIN_DT_ROW";
export const SET_HOLS_ADMIN_STATS_DT_TRIG_REFRESH =
  "SET_HOLS_ADMIN_STATS_DT_TRIG_REFRESH";
export const SET_HOLS_ADMIN_STATS_DT_ROW = "SET_HOLS_ADMIN_STATS_DT_ROW";
export const SET_HOLS_ADMIN_STATS_DT_PAGE = "SET_HOLS_ADMIN_STATS_DT_PAGE";
export const SET_HOLS_ADMIN_STATS_REFRESH_URL =
  "SET_HOLS_ADMIN_STATS_REFRESH_URL";
export const SET_HOLS_ADMIN_SICKIE_DT_ROW = "SET_HOLS_ADMIN_SICKIE_DT_ROW";
export const SET_HOLS_ADMIN_SICKIE_DT_PAGE = "SET_HOLS_ADMIN_SICKIE_DT_PAGE";
export const SET_HOLS_ADMIN_SICKIE_REFRESH_URL =
  "SET_HOLS_ADMIN_SICKIE_REFRESH_URL";

// holidays - allowances
export const SET_HOLIDAY_ALLOWANCES_DT_PAGE = "SET_HOLIDAY_ALLOWANCES_DT_PAGE";
export const SET_HOLIDAY_ALLOWANCES_DT_TRIG_REFRESH =
  "SET_HOLIDAY_ALLOWANCES_DT_TRIG_REFRESH";
export const SET_HOLIDAY_ALLOWANCES_DT_ROW = "SET_HOLIDAY_ALLOWANCES_DT_ROW";
export const SET_HOLIDAY_ALLOWANCES_FILTER = "SET_HOLIDAY_ALLOWANCES_FILTER";
export const SET_HOLIDAY_ALLOWANCES_VIEWED_YEAR =
  "SET_HOLIDAY_ALLOWANCES_VIEWED_YEAR";
// resource planner
export const SET_RES_PLANNER_ALL_HRS_VIEW = "SET_RES_PLANNER_ALL_HRS_VIEW";
export const SET_RES_PLANNER_INC_TS_VIEW = "SET_RES_PLANNER_INC_TS_VIEW";

// sales pipeline
export const SET_SALES_PIPELINE_FILTER = "SET_SALES_PIPELINE_FILTER";
export const SET_SALES_PIPELINE_DT_ROW = "SET_SALES_PIPELINE_DT_ROW";
export const SET_SALES_PIPELINE_DT_PAGE = "SET_SALES_PIPELINE_DT_PAGE";
export const SET_SALES_PIPELINE_DT_TRIG_REFRESH =
  "SET_SALES_PIPELINE_DT_TRIG_REFRESH";
export const SET_SALES_PIPELINE_STATS_YEAR_INC =
  "SET_SALES_PIPELINE_STATS_YEAR_INC";
export const SET_SALES_PIPELINE_STATS_YEAR_DEC =
  "SET_SALES_PIPELINE_STATS_YEAR_DEC";
export const SET_SALES_PIPELINE_STATS_SHOW = "SET_SALES_PIPELINE_STATS_SHOW";
export const SET_SALES_PIPELINE_STATS_HIDE = "SET_SALES_PIPELINE_STATS_HIDE";
// quote breakdown
export const SET_QUOTE_BREAKDOWN_URL = "SET_QUOTE_BREAKDOWN_URL";
export const SET_QUOTE_BREAKDOWN_DT_TRIG_REFRESH =
  "SET_QUOTE_BREAKDOWN_DT_TRIG_REFRESH";
export const SET_QUOTE_BREAKDOWN_DT_PAGE = "SET_QUOTE_BREAKDOWN_DT_PAGE";
export const SET_QUOTE_BREAKDOWN_DT_ROW = "SET_QUOTE_BREAKDOWN_DT_ROW";
// my bookings
export const SET_USER_BOOKINGS_DB_DT_PAGE = "SET_USER_BOOKINGS_DB_DT_PAGE";
export const SET_USER_BOOKINGS_DB_DT_ROW = "SET_USER_BOOKINGS_DB_DT_ROW";
export const SET_USER_BOOKINGS_REFRESH_URL = "SET_USER_BOOKINGS_REFRESH_URL";
// public holidays
export const SET_PUBLIC_HOLS_DB_DT_PAGE = "SET_PUBLIC_HOLS_DB_DT_PAGE";
export const SET_PUBLIC_HOLS_DB_DT_ROW = "SET_PUBLIC_HOLS_DB_DT_ROW";
export const SET_PUBLIC_HOLS_DB_DT_TRIG_REFRESH =
  "SET_PUBLIC_HOLS_DB_DT_TRIG_REFRESH";

// warehouse items by project
export const SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_FILTER =
  "SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_FILTER";
export const SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_PAGE =
  "SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_PAGE";
export const SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_ROW =
  "SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_ROW";
export const SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_TRIG_REFRESH =
  "SET_PROJECTS_WITH_WAREHOUSE_ITEMS_DT_TRIG_REFRESH";

// warehouse individual items
export const SET_WAREHOUSE_ITEMS_DT_PAGE = "SET_WAREHOUSE_ITEMS_DT_PAGE";
export const SET_WAREHOUSE_ITEMS_DT_ROW = "SET_WAREHOUSE_ITEMS_DT_ROW";
export const SET_WAREHOUSE_ITEMS_REFRESH_URL =
  "SET_WAREHOUSE_ITEMS_REFRESH_URL";
export const SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH =
  "SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH";
export const SET_WAREHOUSE_ITEMS_DT_FILTER = "SET_WAREHOUSE_ITEMS_DT_FILTER";

// warehouse delivery schedule
export const SET_WAREHOUSE_DELIVERY_DT_PAGE = "SET_WAREHOUSE_DELIVERY_DT_PAGE";
export const SET_WAREHOUSE_DELIVERY_DT_TRIG_REFRESH =
  "SET_WAREHOUSE_DELIVERY_DT_TRIG_REFRESH";
export const SET_WAREHOUSE_DELIVERY_DT_ROW = "SET_WAREHOUSE_DELIVERY_DT_ROW";
export const SET_WAREHOUSE_DELIVERY_REFRESH_URL =
  "SET_WAREHOUSE_DELIVERY_REFRESH_URL";
export const SET_WAREHOUSE_DELIVERY_FILTER = "SET_WAREHOUSE_DELIVERY_FILTER";

// warehouse item containers
export const SET_WAREHOUSE_ITEM_CONTAINERS_DT_FILTER =
  "SET_WAREHOUSE_ITEM_CONTAINERS_DT_FILTER";
export const SET_WAREHOUSE_ITEM_CONTAINERS_DT_PAGE =
  "SET_WAREHOUSE_ITEM_CONTAINERS_DT_PAGE";
export const SET_WAREHOUSE_ITEM_CONTAINERS_DT_ROW =
  "SET_WAREHOUSE_ITEM_CONTAINERS_DT_ROW";
export const SET_WAREHOUSE_ITEM_CONTAINERS_DT_TRIG_REFRESH =
  "SET_WAREHOUSE_ITEM_CONTAINERS_DT_TRIG_REFRESH";
export const SET_WAREHOUSE_ITEM_CONTAINERS_REFRESH_URL =
  "SET_WAREHOUSE_ITEM_CONTAINERS_REFRESH_URL";

// warehouse items in container
export const SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_PAGE =
  "SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_PAGE";
export const SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_TRIG_REFRESH =
  "SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_TRIG_REFRESH";
export const SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_ROW =
  "SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_ROW";
export const SET_WAREHOUSE_ITEMS_IN_CONTAINER_REFRESH_URL =
  "SET_WAREHOUSE_ITEMS_IN_CONTAINER_REFRESH_URL";
export const SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_FILTER =
  "SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_FILTER";

// log entries
export const SET_LOG_ENTRY_DT_PAGE = "SET_LOG_ENTRY_DT_PAGE";
export const SET_LOG_ENTRY_DT_ROW = "SET_LOG_ENTRY_DT_ROW";
export const SET_LOG_ENTRY_FILTER = "SET_LOG_ENTRY_FILTER";

// projects with sheq documents
export const SET_PROJ_WITH_SHEQ_DT_PAGE = "SET_PROJ_WITH_SHEQ_DT_PAGE";
export const SET_PROJ_WITH_SHEQ_DT_TRIG_REFRESH =
  "SET_PROJ_WITH_SHEQ_DT_TRIG_REFRESH";
export const SET_PROJ_WITH_SHEQ_DT_ROW = "SET_PROJ_WITH_SHEQ_DT_ROW";
export const SET_PROJ_WITH_SHEQ_FILTER = "SET_PROJ_WITH_SHEQ_FILTER";

// sheq documents
export const SET_SHEQ_DOCS_DT_PAGE = "SET_SHEQ_DOCS_DT_PAGE";
export const SET_SHEQ_DOCS_DT_TRIG_REFRESH = "SET_SHEQ_DOCS_DT_TRIG_REFRESH";
export const SET_SHEQ_DOCS_DT_ROW = "SET_SHEQ_DOCS_DT_ROW";
export const SET_SHEQ_DOCS_FILTER = "SET_SHEQ_DOCS_FILTER";
export const SET_SHEQ_DOCS_REFRESH_URL = "SET_SHEQ_DOCS_REFRESH_URL";

// company services
export const SET_COMPANY_SERVICES_DB_DT_PAGE =
  "SET_COMPANY_SERVICES_DB_DT_PAGE";
export const SET_COMPANY_SERVICES_DB_DT_TRIG_REFRESH =
  "SET_COMPANY_SERVICES_DB_DT_TRIG_REFRESH";
export const SET_COMPANY_SERVICES_DB_DT_ROW = "SET_COMPANY_SERVICES_DB_DT_ROW";
export const SET_COMPANY_SERVICES_DB_FILTER = "SET_COMPANY_SERVICES_DB_FILTER";

// vehicle tracking
export const SET_VEHICLE_TRACKING_DT_PAGE = "SET_VEHICLE_TRACKING_DT_PAGE";
export const SET_VEHICLE_TRACKING_DT_TRIG_REFRESH =
  "SET_VEHICLE_TRACKING_DT_TRIG_REFRESH";
export const SET_VEHICLE_TRACKING_DT_ROW = "SET_VEHICLE_TRACKING_DT_ROW";
export const SET_VEHICLE_TRACKING_DT_FILTER = "SET_VEHICLE_TRACKING_DT_FILTER";

// procurement requests
export const SET_PROCUREMENT_REQUESTS_DT_PAGE =
  "SET_PROCUREMENT_REQUESTS_DT_PAGE";
export const SET_PROCUREMENT_REQUESTS_DT_TRIG_REFRESH =
  "SET_PROCUREMENT_REQUESTS_DT_TRIG_REFRESH";
export const SET_PROCUREMENT_REQUESTS_DT_ROW =
  "SET_PROCUREMENT_REQUESTS_DT_ROW";
export const SET_PROCUREMENT_REQUESTS_FILTER =
  "SET_PROCUREMENT_REQUESTS_FILTER";

// departments
export const SET_DEPARTMENTS_DT_PAGE = "SET_DEPARTMENTS_DT_PAGE";
export const SET_DEPARTMENTS_DT_TRIG_REFRESH =
  "SET_DEPARTMENTS_DT_TRIG_REFRESH";
export const SET_DEPARTMENTS_DT_ROW = "SET_DEPARTMENTS_DT_ROW";

// positions
export const SET_POSITIONS_DT_PAGE = "SET_POSITIONS_DT_PAGE";
export const SET_POSITIONS_DT_TRIG_REFRESH = "SET_POSITIONS_DT_TRIG_REFRESH";
export const SET_POSITIONS_DT_ROW = "SET_POSITIONS_DT_ROW";
export const SET_POSITIONS_REFRESH_URL = "SET_POSITIONS_REFRESH_URL";
