import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

import { DataTableParamsType } from "../../../../global_functions/types/dataTables";

const viewBundleIcon = { icon: "Visibility", tooltip: "" };
const infoIcon = {
  icon: "InfoBlue",
  tooltip: "View user training information"
};

export const certificateAllUsersMain: DataTableParamsType = {
  section: "certificateAllUsers",
  refreshURL: "users/0/certificates/",
  reduxTableName: "certificateAllUsersMain",
  filterTabValues: [
    "?filter=all_users",
    "?filter=with_missing",
    "?filter=with_expired",
    "?filter=with_no_scans"
  ],
  filterTabNames: ["All Users", "With Missing", "With Expired", "No Scans"],
  tableParams: {
    autoWidth: true,
    buttonsColumn: {
      id: 9,
      genIcons: () => {
        return [infoIcon, viewBundleIcon];
      }
    },
    responsive: true,
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    columns: [
      { data: "id", title: "User ID", className: "dt-body-center", width: 50 }, // 0
      { data: "name", title: "User", className: "dt-body-center", width: 150 }, // 1
      {
        data: "position_name",
        title: "Position",
        className: "dt-body-center",
        width: 150
      }, // 2
      {
        data: "total_required_no",
        title: "Required Certificates",
        className: "dt-body-center",
        width: 50
      }, // 3
      {
        data: "req_and_present_no",
        title: "Present",
        className: "dt-body-center",
        width: 50
      }, // 4
      {
        data: "cert_missing_no",
        title: "Missing",
        className: "dt-body-center",
        width: 50
      }, // 5
      {
        data: "expired_no",
        title: "Expired",
        className: "dt-body-center",
        width: 50
      }, // 6
      {
        data: "certificates_without_photos",
        title: "No Scans",
        className: "dt-body-center",
        width: 50
      }, // 7
      {
        data: "additional_no",
        title: "Additional Certificates",
        className: "dt-body-center",
        width: 50
      }, // 8
      {
        data: null,
        title: "View",
        className: "dt-body-center",
        width: 100,
        responsivePriority: 1
      } // 9
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "asc"]]
  }
};
