import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import { makeStyles } from "@material-ui/core/styles";

import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
// custom dialog & form
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import AddHolsUserForm from "views/Forms/AddHolsUserForm";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import Button from "components/CustomButtons/Button";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
// @material-ui/icons
import Add from "@material-ui/icons/Add";
import useEventListener from "global_functions/_common/useEventListener";
import identifyClick from "global_functions/_common/identifyClick";

import {
  SET_HOLS_USER_DT_FILTER,
  SET_USER_HOLS_DT_PAGE,
  SET_USER_HOLS_DT_ROW,
  SET_USER_HOLS_DT_TRIG_REFRESH
} from "redux/dispatch_types";

import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";

import TableFilter from "components/_Custom/_Common/TableFilter";
import UserHolsStats from "components/_Custom/PageSpecific/Hols/UserHolsStats";

import { holsUserMain } from "../../DataTables/Holidays/holsUserMain";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

export default function HolsUserPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [addEditModal, setAddEditModal] = useState(false);
  const [dataTable, setDataTable] = useState("");
  const [editDelID, setEditDelID] = useState(null);

  const reduxState = useSelector(state => state);

  const reduxPageProps = reduxState.holsUser;
  const userProfile = reduxState.auth.user;

  useDataTableRefresh(dataTable, holsUserMain);

  // handlers
  const handleAddClick = () => {
    setEditDelID(0);
    setAddEditModal(true);
  };

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_USER_HOLS_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_USER_HOLS_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        default:
          return false;
      }
    }
  };

  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} lg={3} xl={4}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>
                  {userProfile.first_name}'s Holidays
                </h4>
                <h4 className={classes.cardCategoryWhite}>
                  Your Holiday Summary
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <UserHolsStats />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} lg={9} xl={8}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Holiday Requests</h4>
                <h4 className={classes.cardCategoryWhite}>
                  Add a holiday request
                </h4>
              </CardText>
              {dataTable ? (
                <TableFilter
                  color="primary"
                  activeFilter={reduxPageProps.tables.holsUserMain.pageFilter}
                  dispatchType={SET_HOLS_USER_DT_FILTER}
                  dataTable={dataTable}
                  tabs={holsUserMain.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                tableParams={holsUserMain}
                setDataTable={setDataTable}
              />
            </CardBody>
            <CardFooter>
              <div className={classes.cardContentRight}>
                <Button
                  color="success"
                  className={classes.marginRight}
                  onClick={() => handleAddClick()}
                >
                  <Add className={classes.icons} /> Add
                </Button>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelID}
          tableRefreshType={SET_USER_HOLS_DT_TRIG_REFRESH}
          addTitle="Add Holiday Request"
          urlSuffix="holiday-requests/"
        >
          <AddHolsUserForm />
        </AddEditDtEntryDialog>
      </GridContainer>
    </div>
  );
}
