import React, { useEffect, useRef, useState } from "react";
// components
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomAccordion from "components/CustomAccordion/CustomAccordion.js";
// core
import { makeStyles } from "@material-ui/core/styles";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import axios from "axios";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";

const useStyles = makeStyles(styles);

export default function StaffLocationsAdmin() {
  const isInitialMount = useRef(true);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [accordionData, setAccordionData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_V1_URL;

  // initial load (get accordion data and update it)
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        apiUrl + "datasets/?type=future_bookings_all_ranged",
        getTokenConfig(store.getState)
      )
      .then(res => {
        setAccordionData(processProjObj(res.data));
      });
  }, [apiUrl]);

  // Once data updates, set loading to false
  useEffect(() => {
    if (isInitialMount.current) {
      // ignore initial load
      isInitialMount.current = false;
    } else {
      setIsLoading(false);
    }
  }, [accordionData]);

  return (
    <GridItem xs={12} sm={12} md={8} lg={6} xl={4}>
      <Card style={{ minHeight: "300px" }}>
        {" "}
        {/*to make it similar to ChartAdmin component when there are no/few bookings*/}
        <CardHeader>
          <h4 className={classes.cardTitle}>Staff Locations Today</h4>
        </CardHeader>
        <CardBody>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <>
              {accordionData.length ? (
                <CustomAccordion
                  collapses={accordionData.map((value, index) => {
                    return {
                      title:
                        accordionData[index].title +
                        " (" +
                        accordionData[index].content.length +
                        ")",
                      content: (
                        <ul>
                          {accordionData[index].content.map((value, i) => {
                            return (
                              <li key={i}>
                                {accordionData[index].content[i].name}&nbsp;
                                {accordionData[index].content[i].time ? (
                                  <>
                                    ({accordionData[index].content[i].time}
                                    )&nbsp;
                                  </>
                                ) : (
                                  ""
                                )}
                                {accordionData[index].content[i].also_str ? (
                                  <>
                                    <b>| </b>
                                    {accordionData[index].content[i].also_str}
                                  </>
                                ) : (
                                  ""
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      )
                    };
                  })}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "300px"
                  }}
                >
                  <div>There are currently no staff bookings for today.</div>
                </div>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </GridItem>
  );
}

// helper function to process bookings received from API into format that can then be easily loaded into the 'accordion'
// TODO: check if this can be moved to backend
function processProjObj(projObj) {
  const monthShortNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const weekdayShortNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  // check what happens when empty

  let finalArray = [];

  let projNames = Object.keys(projObj);

  for (const projName of projNames) {
    // loop through projs
    let bookingsData = projObj[projName];
    let projBookings = [];

    for (const index in bookingsData) {
      // loop through individual bookings for the proj
      let alsoHereStr = "";
      let bookingTime;
      // if start and finish times are standard, it is a full day booking
      if (
        bookingsData[index].start_time === "08:00" &&
        bookingsData[index].end_time === "17:00"
      ) {
        bookingTime = ""; // no need to specify time when full day
      } else {
        bookingTime =
          bookingsData[index].start_time + "-" + bookingsData[index].end_time;
      }

      // set the 'also here on' message
      let todayDate = new Date();
      const todayStr =
        todayDate.getDate().toString() +
        " " +
        monthShortNames[todayDate.getMonth()] +
        " " +
        todayDate.getFullYear().toString() +
        " (" +
        weekdayShortNames[todayDate.getDay()] +
        ")";

      const bookingSeries = bookingsData[index].series_bookings;
      let alsoHereArray = []; // will store dates adjusted for display

      // process series into alsoHereArray
      // if series only have one entry of [today; today] then it is a single day booking and 'also here' is not needed
      if (
        !(
          todayStr === bookingSeries[0][0] &&
          bookingSeries[0][0] === bookingSeries[0][1] &&
          bookingSeries.length === 1
        )
      ) {
        alsoHereStr += "Also here ";
        for (const bookingRange of bookingSeries) {
          if (bookingRange[0] === todayStr && bookingRange[1] === todayStr) {
            // 'today only' booking is skipped (not added to 'also here'
          } else if (bookingRange[0] === todayStr) {
            // if range starts from today, only add 'until range end'
            alsoHereArray.push("until " + bookingRange[1]);
          } else if (bookingRange[0] === bookingRange[1]) {
            // single day future booking
            alsoHereArray.push(bookingRange[0]);
          } else {
            // else add full range
            alsoHereArray.push(bookingRange[0] + " - " + bookingRange[1]);
          }
        }
      }

      // process the 'also here' array (if exists)  and display it
      if (alsoHereArray.length !== 0) {
        let alsoHereLength = alsoHereArray.length;
        for (let i = 0; i < alsoHereLength; i++) {
          if (i < alsoHereLength - 2) {
            // add comma after entry that goes before penultimate
            alsoHereStr += alsoHereArray[i] + ", ";
          } else if (i < alsoHereLength - 1) {
            // add penultimate with nothing
            alsoHereStr += alsoHereArray[i] + " ";
          } else if (i === alsoHereLength - 1 && i !== 0) {
            // add 'and' before and full stop after last entry that isn't first
            alsoHereStr += " and " + alsoHereArray[i] + ".";
          } else if (i === alsoHereLength - 1 && i === 0) {
            // add full stop after last entry that is also first
            alsoHereStr += alsoHereArray[i] + ".";
          }
        }
      }
      projBookings.push({
        name: bookingsData[index].name,
        time: bookingTime,
        also_str: alsoHereStr
      });
    }

    finalArray.push({ title: projName, content: projBookings });
  }

  return finalArray;
}
