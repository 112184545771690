import React, { useCallback, useEffect, useRef, useState } from "react";

import axios from "axios";

// redux
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_SPINNER_CURSOR } from "redux/dispatch_types";
import showNotification from "global_functions/redux_action_creators/showNotification";
// calendar
import FullCalendar from "@fullcalendar/react";
import { calendarParams } from "./calendarParams";
// custom components
import AddEditBookingDialog from "views/Dialogs/ResourcePlanner/AddEditBookingDialog";
import BookingInfoDialog from "views/Dialogs/ResourcePlanner/BookingInfoDialog";
import DeleteCalendarBookingDialog from "views/Dialogs/ResourcePlanner/DeleteCalendarBookingDialog";
import MoveResizeBookingDialog from "views/Dialogs/ResourcePlanner/MoveResizeBookingDialog";
import WastedBookingDialog from "./WastedBookingDialog";
// constants
import { ANNUAL_LEAVE_PROJ_ID } from "../../../../global_functions/systemConstants";
const apiURL = process.env.REACT_APP_API_V1_URL;

const ResourceCalendar = () => {
  const dispatch = useDispatch();

  const [infoModal, setInfoModal] = useState(false);
  const [bookingType, setBookingType] = useState("");
  const [bookingID, setBookingID] = useState("");

  const [selectObj, setSelectObj] = useState("");
  const [mrInfo, setMrInfo] = useState({});

  const [addEditDialog, setAddEditDialog] = useState(false);
  const [deleteBookingDialog, setDeleteBookingDialog] = useState(false);
  const [wastedDialog, setWastedDialog] = useState(false);

  const [calTimeRange, setCalTimeRange] = useState(["07:00:00", "19:00:00"]);

  const reduxPageProps = useSelector(state => state.resPlanner);

  const fcRef = useRef();

  // setting calendar time range
  useEffect(() => {
    const calStartEnd = reduxPageProps.allHrsView
      ? ["00:00:00", "24:00:00"]
      : ["07:00:00", "19:00:00"];
    setCalTimeRange(calStartEnd);
  }, [reduxPageProps.allHrsView]);

  // MEMOIZED CALENDAR FUNCTIONS

  // get calendar resource
  const getResources = useCallback((fetchInfo, successCallback) => {
    axios
      .get(
        `${apiURL}users/?in_rp=1&for_calendar=true`,
        getTokenConfig(store.getState)
      )
      .then(resp => {
        successCallback(resp.data);
      });
  }, []);

  // capturing event click & launching modal
  const handleEventClick = useCallback(
    info => {
      // only standard and timesheet entries can be displayed on info modal, bank holidays can't
      const eventType = checkCalendarEventType(info.event);
      if (eventType === "standard" || eventType === "timesheet") {
        setBookingType(eventType);
        setBookingID(info.event.id);
        setInfoModal(true);
      } else if (eventType === "bank_holiday") {
        dispatch(
          showNotification(info.event.title + " (calendar placeholder)", "info")
        );
      }
    },
    [dispatch]
  );

  // setting whether the calendar should include timesheets or not
  const getEventSources = useCallback(
    (pageInfo, successCallback) => {
      const incTs = reduxPageProps.incTsView ? "1" : "0";
      axios
        .get(
          `${apiURL}calendar-bookings/?include_timesheets=${incTs}&start=${pageInfo.startStr}&end=${pageInfo.endStr}`,
          getTokenConfig(store.getState)
        )
        .then(resp => successCallback(resp.data));
    },
    [reduxPageProps.incTsView]
  );

  // selecting a range on calendar
  const calendarSelect = useCallback(selectObj => {
    fcRef.current.calendar.unselect();
    setBookingID(0);
    setAddEditDialog(true);
    setSelectObj(selectObj);
  }, []);

  const handleMoveResize = useCallback(
    mrInfo => {
      // if not holiday (annual leave project), show modal
      if (mrInfo.event.extendedProps.proj_id === ANNUAL_LEAVE_PROJ_ID) {
        fcRef.current.calendar.refetchEvents();
        dispatch(
          showNotification(
            "Please use the holiday section to manage holiday bookings.",
            "error"
          )
        );
      } else setMrInfo(mrInfo);
    },
    [dispatch]
  );

  const handleLoading = useCallback(
    isLoading => {
      if (isLoading) {
        dispatch({ type: TOGGLE_SPINNER_CURSOR, payload: 1 });
      } else {
        dispatch({ type: TOGGLE_SPINNER_CURSOR, payload: 0 });
      }
    },
    [dispatch]
  );

  return (
    <>
      <FullCalendar
        ref={fcRef}
        {...calendarParams}
        resources={getResources}
        eventClick={handleEventClick}
        eventDrop={handleMoveResize}
        eventResize={handleMoveResize}
        events={getEventSources}
        minTime={calTimeRange[0]}
        maxTime={calTimeRange[1]}
        select={calendarSelect}
        loading={handleLoading}
      />
      <BookingInfoDialog
        infoModal={infoModal}
        setInfoModal={setInfoModal}
        bookingType={bookingType}
        bookingID={bookingID}
        setAddEditDialog={setAddEditDialog}
        setDeleteBookingDialog={setDeleteBookingDialog}
        setWastedDialog={setWastedDialog}
      />
      <AddEditBookingDialog
        addEditDialog={addEditDialog}
        setAddEditDialog={setAddEditDialog}
        bookingType={bookingType}
        bookingId={bookingID}
        calendarRef={fcRef}
        selectObj={selectObj}
      />
      <DeleteCalendarBookingDialog
        deleteBookingDialog={deleteBookingDialog}
        setDeleteBookingDialog={setDeleteBookingDialog}
        bookingId={bookingID}
        calendarRef={fcRef}
      />
      <WastedBookingDialog
        wastedDialog={wastedDialog}
        setWastedDialog={setWastedDialog}
        bookingId={bookingID}
        calendarRef={fcRef}
      />
      <MoveResizeBookingDialog
        mrInfo={mrInfo}
        checkCalendarEventType={checkCalendarEventType}
        calendarRef={fcRef}
      />
    </>
  );
};

export default ResourceCalendar;

// helpers
const checkCalendarEventType = event => {
  if (event.id.length < 10) {
    return "timesheet";
  } else if (event.id.substring(0, 7) === "bankhol") {
    // if the first 7 letters of the ID are 'bankhol'
    return "bank_holiday";
  } else {
    return "standard";
  }
};
