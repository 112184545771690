// CHECK TOKEN & LOAD USER
import { AUTH_FAILURE, USER_LOADED, USER_LOADING } from "redux/dispatch_types";
import axios from "axios";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import showAuthError from "../showAuthError";
const apiUrl = process.env.REACT_APP_API_V1_URL;

export const loadUser = () => (dispatch, getState) => {
  // User Loading
  dispatch({ type: USER_LOADING });

  axios
    .get(apiUrl + "accounts/user/", getTokenConfig(getState))
    .then(res => {
      dispatch({
        type: USER_LOADED,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch(showAuthError(err)); // not currently handled
      dispatch({
        type: AUTH_FAILURE
      });
    });
};
