import React, { useEffect, useState } from "react";
import axios from "axios";
// redux
import { useDispatch, useSelector } from "react-redux";
import store from "../../../../../redux/store";
import {
  SET_CLIENT_DASHBOARD_VIEW_PROJECTS,
  SET_CLIENT_DASHBOARD_ITEM_DT_PAGE,
  SET_CLIENT_DASHBOARD_ITEM_DT_ROW,
  SET_CLIENT_DASHBOARD_ITEM_DT_FILTER,
  SET_CLIENT_DASHBOARD_ITEM_DT_REFRESH_URL
} from "../../../../../redux/dispatch_types";
import { getTokenConfig } from "../../../../../global_functions/redux_action_creators/auth/getTokenConfig";
// template
import CardBody from "../../../../Card/CardBody";
import CardFooter from "../../../../Card/CardFooter";
import GridItem from "../../../../Grid/GridItem";
import Card from "../../../../Card/Card";
import CardHeader from "../../../../Card/CardHeader";
import CardText from "../../../../Card/CardText";
import GridContainer from "../../../../Grid/GridContainer";
// custom components
import ItemHistoryDialog from "../../../../../views/Dialogs/Warehouse/ItemHistoryDialog";
import BackButtonWithDispatch from "../../../../CustomButtons/BackButtonWithDispatch";
import TableFilter from "../../../_Common/TableFilter";
import Datatable from "../../../_Common/DataTables/DataTable";
import { clientDashboardItems } from "../../../../../views/DataTables/Dashboard/clientDashboardItems";
import CustomLoadingSpinner from "../../../_Common/CustomLoadingSpinner";
import ReportButton from "../../../../CustomButtons/ReportButton";
// functions
import formatDate from "../../../../../global_functions/_common/formatDate";
import useFetchDocument from "../../../../../global_functions/_common/useFetchDocument";
import identifyClick from "../../../../../global_functions/_common/identifyClick";
import useDataTableRefresh from "../../../../../global_functions/dataTables/useDatatableRefresh";
import useEventListener from "../../../../../global_functions/_common/useEventListener";
// material ui
import { makeStyles } from "@material-ui/core/styles";
// styles
import styles from "../../../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { cardTitle } from "../../../../../assets/jss/material-dashboard-pro-react";
const useStyles = makeStyles({
  ...styles,
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
});

const apiUrl = process.env.REACT_APP_API_V1_URL;

const ClientDashboardItemsTable = () => {
  const [dataTable, setDataTable] = useState("");
  const [reportParams, setReportParams] = useState("");
  const [topInfoLoading, setTopInfoLoading] = useState(false);

  const [projectDescription, setProjectDescription] = useState("");
  const [clientName, setClientName] = useState("");
  const [itemsInWarehouse, setItemsInWarehouse] = useState(0);

  const { itemProjId } = useSelector(state => state.clientDashboard);

  const classes = useStyles();

  const client_company_id = useSelector(
    state => state.auth.user.client_company_id
  );

  const pageFilter = useSelector(
    state => state.clientDashboard.tables.clientDashboardItems.pageFilter
  );

  const [itemHistoryDialog, setItemHistoryDialog] = useState(false);
  const [historyItemId, setHistoryItemId] = useState(null);

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({
            type: SET_CLIENT_DASHBOARD_ITEM_DT_ROW,
            payload: selectedRow
          });
          break;
        case "pagination":
          dispatch({
            type: SET_CLIENT_DASHBOARD_ITEM_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
          dataTable.rows().deselect();
          if (clickRelevantType.params[0] === "History") {
            dataTable.rows().deselect();
            setHistoryItemId(dtData.id);
            setItemHistoryDialog(true);
          }
          break;
        default:
          return false;
      }
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_CLIENT_DASHBOARD_ITEM_DT_REFRESH_URL,
      payload: `projects/${itemProjId}/items/`
    });
  }, [client_company_id, dispatch, itemProjId]);

  // show project info at the top
  useEffect(() => {
    setTopInfoLoading(true);
    axios
      .get(`${apiUrl}projects/${itemProjId}`, getTokenConfig(store.getState))
      .then(res => {
        setProjectDescription(res.data.description);
        setClientName(res.data.client_name);
        setTopInfoLoading(false);
        setItemsInWarehouse(res.data.checked_in_or_pre_checkout_item_no);
      });
  }, [itemProjId]);

  useDataTableRefresh(dataTable, clientDashboardItems);

  useEventListener("click", handleClickEvent, window);

  const handleReportClick = e => {
    e.stopPropagation();
    setReportParams({
      url: `${apiUrl}documents/?dataset=project_stock_report&proj_id=${itemProjId}`,
      fileName: `Stock Report for ${projectDescription} (${formatDate(
        new Date(),
        "dd MM yyyy"
      )})`,
      fetchMsg: "Fetching stock report..."
    });
  };

  useFetchDocument(reportParams);

  return (
    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" text>
          <CardText color="info">
            {!topInfoLoading ? (
              <>
                <h4 className={classes.cardTitleWhite}>
                  Items for {projectDescription}
                </h4>
                <h4 className={classes.cardCategoryWhite}>{clientName}</h4>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50px"
                }}
              >
                <CustomLoadingSpinner />
              </div>
            )}
          </CardText>
          <GridContainer>
            <GridItem xs={12}>
              <BackButtonWithDispatch
                dispatchType={SET_CLIENT_DASHBOARD_VIEW_PROJECTS}
                dispatchPayload={null}
              />
              {itemsInWarehouse ? (
                <ReportButton
                  tooltip="Generate stock report"
                  handleReportClick={handleReportClick}
                />
              ) : (
                <></>
              )}
            </GridItem>
          </GridContainer>
          {dataTable ? (
            <TableFilter
              color="primary"
              activeFilter={pageFilter}
              dispatchType={SET_CLIENT_DASHBOARD_ITEM_DT_FILTER}
              dataTable={dataTable}
              tabs={clientDashboardItems.filterTabNames}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "400px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          )}
        </CardHeader>
        <CardBody>
          <Datatable
            setDataTable={setDataTable}
            tableParams={clientDashboardItems}
          />
        </CardBody>
        <CardFooter />
      </Card>
      <ItemHistoryDialog
        itemHistoryDialog={itemHistoryDialog}
        setItemHistoryDialog={setItemHistoryDialog}
        itemId={historyItemId}
      />
    </GridItem>
  );
};

export default ClientDashboardItemsTable;
