import React, { useEffect, useState } from "react";

// material-ui
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
// redux
import {useSelector} from "react-redux";
import {RootStateType} from "../../redux/reducers";
// template
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
// custom components & functions
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
// date
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// typescript types
import { AddEditFormTypes } from "../../types/views/FormTypes";
// styles
import { customTheme } from "global_functions/_common/customTheme";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
// @ts-ignore
const useFormStyles = makeStyles(formsStyles);
// @ts-ignore
const useNotifyStyles = makeStyles(notificationStyles);

const AddEditHolidayAllowanceForm: React.FC<
  AddEditHolidayAllowanceFormType
> = ({
  editData,
  setformData,
  formLoading,
  handleFormSubmit,
  open,
  formChoiceFields
}) => {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  const [user, setUser] = useState<number | null>(null);
  const [allowance, setAllowance] = useState<number | null>(null);
  const [extra, setExtra] = useState<number | null>(null);
  const [comment, setComment] = useState<string>("");

  // set date for year picker based on redux selection
  const reduxYear = useSelector(
    (state: RootStateType) => state.holidayAllowances.viewedYear
  );
  const date = new Date(reduxYear, 0, 1);

  // form opening/closing
  useEffect(() => {
    if (editData && open) {
      setUser(editData.user);
      setAllowance(editData.allowance);
      setExtra(editData.extra);
      setComment(editData.comment);
    } else if (!editData && open) {
      setUser(0);
      setAllowance(21);
      setExtra(0);
      setComment("");
    }
  }, [editData, open]);

  useEffect(() => {
    setformData({
      user: user,
      allowance: allowance,
      extra: extra,
      comment: comment,
      year: date.getFullYear()
    });
    // eslint-disable-next-line
    }, [user, allowance, extra, comment]);

  return (
    <>
      {formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "275px",
            width: "300px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={handleFormSubmit}>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel className={formClasses.labelHorizontal}>
                  User
                </FormLabel>
              </GridItem>
              <GridItem xs={6}>
                <CustomSelect
                  options={formChoiceFields.user_list}
                  isDisabled={formLoading || editData} // disabled when in edit mode
                  value={user}
                  setValue={setUser}
                  required={true}
                />
              </GridItem>
              <GridItem xs={4}>
                <ThemeProvider theme={customTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disabled={true}
                      style={{ float: "left" }}
                      margin="normal"
                      label="Year"
                      minDate="2019"
                      format="yyyy"
                      views={["year"]}
                      value={date}
                      onChange={() => null}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Allowance
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  id="amount"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    value: allowance ? allowance : 0,
                    // @ts-ignore
                    onChange: e => {
                      setAllowance(e.target.value);
                    },
                    required: true,
                    maxLength: 10,
                    min: 0,
                    max: 365,
                    step: 0.5
                  }}
                />
              </GridItem>
              <GridItem xs={2}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Extra
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  id="amount"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    value: extra ? extra : 0,
                    // @ts-ignore
                    onChange: e => {
                      setExtra(e.target.value);
                    },
                    required: true,
                    maxLength: 10,
                    min: 0,
                    max: 365,
                    step: 0.5
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Comment
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <CustomInput
                  id="userName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: comment,
                    onChange: (e: React.FormEvent<HTMLInputElement>) => {
                      setComment(e.currentTarget.value);
                    },
                    required: false,
                    maxLength: 1000
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
};

export default AddEditHolidayAllowanceForm;

type EditDataType = {
  user: number;
  year: number;
  allowance: number;
  extra: number;
  comment: string;
};

interface AddEditHolidayAllowanceFormType extends AddEditFormTypes {
  editData: EditDataType | null;
  formChoiceFields: any;
}
