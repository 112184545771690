import React, { useCallback, useEffect, useRef, useState } from "react";

import axios from "axios";

import { useMediaQuery } from "react-responsive";
// redux
import store from "redux/store";
import { useDispatch } from "react-redux";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// plugins
import { Carousel } from "react-responsive-carousel";
// custom components
import DeleteUploadDialog from "views/Dialogs/Uploads/DeleteUploadDialog";
import ImageUploadArea from "views/Dialogs/Uploads/ImagePdfUploadArea";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// custom functions
import showNotification from "global_functions/redux_action_creators/showNotification";
import getDataIDFromEvent from "global_functions/_common/getDataIDFromEvent";
import { getDialogProps } from "global_functions/_common/getDialogProps";
// material ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Pageview } from "@material-ui/icons";
import Delete from "@material-ui/icons/Delete";
// components
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";

import pdfIcon from "assets/img/icons/pdf.png";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import FullScreenIframeDialog from "views/Dialogs/_Common/FullScreenIframeDialog";
import AsyncImage from "components/_Custom/_Common/AsyncImage";

const useStyles = makeStyles(styles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ManageImgPdfUploadsDialog(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [filesOnServer, setFilesOnServer] = useState([]);
  const [refreshInProgress, setRefreshInProgress] = useState(false);

  const [selectedCarouselPage, setSelectedCarouselPage] = useState(0);

  const [fullScreenIframeDialog, setFullScreenIframeDialog] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");

  const [deleteUploadDialog, setDeleteUploadDialog] = useState(false);
  const [delId, setDelId] = useState(0);

  const [processingMsg, setProcessingMsg] = useState(false);

  const carouselRef = useRef();

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (props.parentId) {
      refreshFileList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parentId]);

  useEffect(() => {
    if (props.manageUploadsDialog) {
      document.addEventListener("keydown", captureKeyDown, false);
    } else {
      document.removeEventListener("keydown", captureKeyDown, false);
    }
  }, [props.manageUploadsDialog, captureKeyDown]);

  const refreshFileList = () => {
    if (props.parentId && props.refreshUrl) {
      let refreshUrl = `${apiUrl}${props.refreshUrl}${props.parentId}/uploaded_files/`;
      setRefreshInProgress(true);
      axios
        .get(refreshUrl, getTokenConfig(store.getState))
        .then(res => {
          setFilesOnServer(res.data);
          setRefreshInProgress(false);
          dispatch({ type: props.tableRefreshType, payload: { id: null } }); // refresh main sales pipeline table
          // if the last item was deleted, set page to one before (bug in plugin leaves it on non-existent page)
          if (res.data.length > 0) {
            let carouselLength = res.data.length;
            let selectItem = carouselRef.current.selectItem;

            selectItem(state => {
              let selectedItem = state.selectedItem;
              if (selectedItem === carouselLength) {
                setSelectedCarouselPage(selectedCarouselPage - 1);
              }
            });
          }
        })
        .catch(() => {
          dispatch(
            showNotification(
              "Error getting uploads. Please refresh and try again.",
              "error"
            )
          );
          setRefreshInProgress(false);
        });
    }
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={props.manageUploadsDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.setManageUploadsDialog(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => props.setManageUploadsDialog(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{props.title}</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <Carousel
            infiniteLoop={true}
            useKeyboardArrows={true}
            ref={carouselRef}
            showThumbs={false}
            selectedItem={selectedCarouselPage}
          >
            {refreshInProgress ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CustomLoadingSpinner />
              </div>
            ) : (
              filesOnServer &&
              filesOnServer.map((key, index) => {
                return (
                  <React.Fragment key={`upload_img${index}`}>
                    <div>
                      <Button
                        color="info"
                        data-id={key.id}
                        onClick={e => {
                          e.stopPropagation();
                          let uploadId = parseInt(getDataIDFromEvent(e));
                          let clickedUpload = filesOnServer.find(upload => {
                            return upload.id === uploadId;
                          });
                          setPreviewUrl(`/media/${clickedUpload.upload}`);
                          setFullScreenIframeDialog(true);
                        }}
                      >
                        <Pageview /> View
                      </Button>
                      {props.showControls && (
                        <Button
                          color="danger"
                          data-id={key.id}
                          onClick={e => {
                            e.stopPropagation();
                            setDelId(getDataIDFromEvent(e));
                            setDeleteUploadDialog(true);
                          }}
                        >
                          <Delete /> Delete
                        </Button>
                      )}
                    </div>
                    <div>
                      {key.upload.split(".").pop() === "png" ? (
                        <AsyncImage url={`/media/${key.upload}`} />
                      ) : (
                        <img alt="" src={pdfIcon} />
                      )}

                      <p className="legend">{key.upload.split("/").pop()}</p>
                    </div>
                  </React.Fragment>
                );
              })
            )}
          </Carousel>
          {props.showControls && (
            <>
              <ImageUploadArea
                addButtonProps={{
                  color: "primary",
                  round: true
                }}
                setProcessingMsg={setProcessingMsg}
                refreshFileList={refreshFileList}
                uploadUrl={`${apiUrl}${props.refreshUrl}${props.parentId}/uploaded_files/`}
              />
              {processingMsg && <p>Processing...</p>}
            </>
          )}
          <DeleteUploadDialog
            deleteDialog={deleteUploadDialog}
            setDeleteDialog={setDeleteUploadDialog}
            delUrl={`${apiUrl}${props.refreshUrl}${props.parentId}/uploaded_files/${delId}`}
            refreshFileList={refreshFileList}
          />
          <FullScreenIframeDialog
            fullScreenIframeDialog={fullScreenIframeDialog}
            setFullScreenIframeDialog={setFullScreenIframeDialog}
            previewUrl={previewUrl}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="transparent"
            onClick={() => props.setManageUploadsDialog(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
