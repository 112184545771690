import React, { useEffect, useState } from "react";

import axios from "axios";
// redux
import { useDispatch } from "react-redux";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
// material-ui
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// template
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomSelect/CustomSelect";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import PermissionsBox from "../../components/_Custom/PageSpecific/UserDB/PermissionsBox";
// custom functions
import showNotification from "global_functions/redux_action_creators/showNotification";

import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const apiUrl = process.env.REACT_APP_API_V1_URL;
const useFormStyles = makeStyles(formsStyles);

const UserProfileForm = props => {
  const dispatch = useDispatch();
  const formClasses = useFormStyles();

  const [valuesLoading, setValuesLoading] = useState(false);
  const [choiceFieldsLoading, setChoiceFieldsLoading] = useState(false);

  const [positionChoiceFields, setPositionChoiceFields] = useState("");
  const [clientCompanyChoiceFields, setClientCompanyChoiceFields] = useState(
    ""
  );

  const [userID, setUserID] = useState("");

  // text & choice fields
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [hols, setHols] = useState("");
  const [position, setPosition] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [signatureBase64, setSignatureBase64] = useState("");
  const [clientCompany, setClientCompany] = useState(null);

  // client fields
  const [isClient, setIsClient] = useState(null);

  // checkboxes
  const [isActive, setIsActive] = useState(false);
  const [receivesHolsNot, setReceivesHolsNot] = useState(false);
  const [receivesExpNot, setReceivesExpNot] = useState(false);
  const [receivesProcNot, setReceivesProcNot] = useState(false);

  // permissions
  const [isBookingsAdmin, setIsBookingsAdmin] = useState(false);
  const [isBookingsUser, setIsBookingsUser] = useState(false);
  const [isClientDbAdmin, setIsClientDbAdmin] = useState(false);
  const [isStatsAdmin, setIsStatsAdmin] = useState(false);
  const [isExpensesAdmin, setIsExpensesAdmin] = useState(false);
  const [isExpensesUser, setIsExpensesUser] = useState(false);
  const [isHolidaysAdmin, setIsHolidaysAdmin] = useState(false);
  const [isHolidaysUser, setIsHolidaysUser] = useState(false);
  const [isHrhsAdmin, setIsHrhsAdmin] = useState(false);
  const [isHrhsUser, setIsHrhsUser] = useState(false);
  const [isProcurementAdmin, setIsProcurementAdmin] = useState(false);
  const [isProcurementUser, setIsProcurementUser] = useState(false);
  const [isProjectDbAdmin, setIsProjectDbAdmin] = useState(false);
  const [isReportsUser, setIsReportsUser] = useState(false);
  const [isSalesPipelineAdmin, setIsSalesPipelineAdmin] = useState(false);
  const [isSettingsAdmin, setIsSettingsAdmin] = useState(false);
  const [isTimesheetsAdmin, setIsTimesheetsAdmin] = useState(false);
  const [isTimesheetsUser, setIsTimesheetsUser] = useState(false);
  const [isUserDbAdmin, setIsUserDbAdmin] = useState(false);
  const [isVehicleTrackingAdmin, setIsVehicleTrackingAdmin] = useState(false);
  const [isWarehouseAdmin, setIsWarehouseAdmin] = useState(false);
  const [isWarehouseUser, setIsWarehouseUser] = useState(false);

  const formValues = {
    user_id: userID,
    first_name: firstName,
    last_name: lastName,
    username: username,
    email: email,
    is_active: isActive,
    userprofile: {
      mobile: mobile,
      hols: hols,
      receives_exp_not: receivesExpNot,
      receives_hols_not: receivesHolsNot,
      receives_procurement_not: receivesProcNot,
      position: position,
      job_title: jobTitle,
      signature_base64: signatureBase64,
      client: clientCompany,
      // permissions
      is_bookings_admin: isBookingsAdmin,
      is_bookings_user: isBookingsUser,
      is_stats_admin: isStatsAdmin,
      is_client_db_admin: isClientDbAdmin,
      is_expenses_admin: isExpensesAdmin,
      is_expenses_user: isExpensesUser,
      is_holidays_admin: isHolidaysAdmin,
      is_holidays_user: isHolidaysUser,
      is_hrhs_admin: isHrhsAdmin,
      is_hrhs_user: isHrhsUser,
      is_procurement_admin: isProcurementAdmin,
      is_procurement_user: isProcurementUser,
      is_project_db_admin: isProjectDbAdmin,
      is_reports_user: isReportsUser,
      is_sales_pipeline_admin: isSalesPipelineAdmin,
      is_settings_admin: isSettingsAdmin,
      is_timesheets_admin: isTimesheetsAdmin,
      is_timesheets_user: isTimesheetsUser,
      is_user_db_admin: isUserDbAdmin,
      is_vehicle_tracking_admin: isVehicleTrackingAdmin,
      is_warehouse_admin: isWarehouseAdmin,
      is_warehouse_user: isWarehouseUser
    }
  };

  const reloadFormValues = () => {
    setValuesLoading(true);
    axios
      .get(`${apiUrl}users/${props.userID}`, getTokenConfig(store.getState))
      .then(res => {
        setUserID(res.data.id);
        setUsername(res.data.username);
        setFirstName(res.data.first_name);
        setLastName(res.data.last_name);
        setEmail(res.data.email);
        setMobile(res.data.userprofile.mobile);
        setHols(res.data.userprofile.hols);
        setIsActive(res.data.is_active);
        setReceivesHolsNot(res.data.userprofile.receives_hols_not);
        setReceivesProcNot(res.data.userprofile.receives_procurement_not);
        setReceivesExpNot(res.data.userprofile.receives_exp_not);
        setPosition(res.data.userprofile.position);
        setJobTitle(res.data.userprofile.job_title);
        setSignatureBase64(res.data.userprofile.signature_base64);
        setClientCompany(res.data.userprofile.client);
        setIsClient(res.data.is_client);
        // permissions
        setIsBookingsAdmin(res.data.userprofile.is_bookings_admin);
        setIsBookingsUser(res.data.userprofile.is_bookings_user);
        setIsClientDbAdmin(res.data.userprofile.is_client_db_admin);
        setIsStatsAdmin(res.data.userprofile.is_stats_admin);
        setIsExpensesAdmin(res.data.userprofile.is_expenses_admin);
        setIsExpensesUser(res.data.userprofile.is_expenses_user);
        setIsHolidaysAdmin(res.data.userprofile.is_holidays_admin);
        setIsHolidaysUser(res.data.userprofile.is_holidays_user);
        setIsHrhsAdmin(res.data.userprofile.is_hrhs_admin);
        setIsHrhsUser(res.data.userprofile.is_hrhs_user);
        setIsProcurementAdmin(res.data.userprofile.is_procurement_admin);
        setIsProcurementUser(res.data.userprofile.is_procurement_user);
        setIsProjectDbAdmin(res.data.userprofile.is_project_db_admin);
        setIsReportsUser(res.data.userprofile.is_reports_user);
        setIsSalesPipelineAdmin(res.data.userprofile.is_sales_pipeline_admin);
        setIsSettingsAdmin(res.data.userprofile.is_settings_admin);
        setIsTimesheetsAdmin(res.data.userprofile.is_timesheets_admin);
        setIsTimesheetsUser(res.data.userprofile.is_timesheets_user);
        setIsUserDbAdmin(res.data.userprofile.is_user_db_admin);
        setIsVehicleTrackingAdmin(
          res.data.userprofile.is_vehicle_tracking_admin
        );
        setIsWarehouseAdmin(res.data.userprofile.is_warehouse_admin);
        setIsWarehouseUser(res.data.userprofile.is_warehouse_user);
      })
      .then(() => setValuesLoading(false));

    setChoiceFieldsLoading(true);
    axios
      .get(apiUrl + "form-fields/user-form", getTokenConfig(store.getState))
      .then(res => {
        setPositionChoiceFields(res.data.POSITION_CHOICES);
        setClientCompanyChoiceFields(res.data.CLIENT_CHOICES);
      })
      .then(setChoiceFieldsLoading(false));
  };

  // below hook runs on every form is open or closes
  useEffect(() => {
    reloadFormValues();
    // eslint-disable-next-line
    }, []);

  const FUNCTION_OBJ = {
    activeCheckbox: setIsActive,
    expensesCheckbox: setReceivesExpNot,
    holidayCheckbox: setReceivesHolsNot,
    procurementCheckbox: setReceivesProcNot,
    // permissions
    bookingsAdminCheckbox: setIsBookingsAdmin,
    bookingsUserCheckbox: setIsBookingsUser,
    clientDbUserCheckbox: setIsClientDbAdmin,
    statsAdminCheckbox: setIsStatsAdmin,
    expensesAdminCheckbox: setIsExpensesAdmin,
    expensesUserCheckbox: setIsExpensesUser,
    holsAdminCheckbox: setIsHolidaysAdmin,
    holsUserCheckbox: setIsHolidaysUser,
    hrhsAdminCheckbox: setIsHrhsAdmin,
    hrhsUserCheckbox: setIsHrhsUser,
    procAdminCheckbox: setIsProcurementAdmin,
    procUserCheckbox: setIsProcurementUser,
    projDbUserCheckbox: setIsProjectDbAdmin,
    reportsUserCheckbox: setIsReportsUser,
    salesPipelineAdminCheckbox: setIsSalesPipelineAdmin,
    settingsAdminCheckbox: setIsSettingsAdmin,
    tsAdminCheckbox: setIsTimesheetsAdmin,
    tsUserCheckbox: setIsTimesheetsUser,
    userDbUserCheckbox: setIsUserDbAdmin,
    vehicleTrackingUserCheckbox: setIsVehicleTrackingAdmin,
    warehouseAdminCheckbox: setIsWarehouseAdmin,
    warehouseUserCheckbox: setIsWarehouseUser
  };

  const handleToggle = e => {
    if (e.target.checked) FUNCTION_OBJ[e.target.id](true);
    else FUNCTION_OBJ[e.target.id](false);
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    setValuesLoading(true);
    axios
      .put(
        apiUrl + "users/" + userID,
        formValues,
        getTokenConfig(store.getState)
      )
      .then(() => {
        dispatch(showNotification("Profile updated.", "success"));
      })
      .then(() => reloadFormValues())
      .catch(err => {
        let errorMsg;
        if (err.response.data.errors) {
          errorMsg = err.response.data.errors; // prepared error in backend
        } else {
          errorMsg = "Error has occurred when updating profile";
        }
        dispatch(showNotification(errorMsg, "error"));
        setValuesLoading(false);
      });
  };

  return (
    <>
      {choiceFieldsLoading || valuesLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "600px",
            width: "100%"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <form id="addEditForm" onSubmit={handleFormSubmit}>
          <GridContainer>
            <GridItem xs={4} lg={2}>
              <FormLabel className={formClasses.labelHorizontal} fontSize="1px">
                Username
              </FormLabel>
            </GridItem>
            <GridItem xs={8} lg={4}>
              <CustomInput
                id="username"
                formControlProps={{
                  fullWidth: true
                }}
                helpText="Project name"
                inputProps={{
                  type: "text",
                  value: username,
                  onChange: e => {
                    setUsername(e.target.value);
                  },
                  required: true,
                  maxLength: 50
                }}
              />
            </GridItem>
            <GridItem xs={4} lg={2}>
              <FormLabel className={formClasses.labelHorizontal} fontSize="1px">
                First Name(s)
              </FormLabel>
            </GridItem>
            <GridItem xs={8} lg={4}>
              <CustomInput
                id="projNickname"
                formControlProps={{
                  fullWidth: true
                }}
                helpText="Nickname"
                inputProps={{
                  type: "text",
                  value: firstName,
                  onChange: e => {
                    setFirstName(e.target.value);
                  },
                  maxLength: 30
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} lg={2}>
              <FormLabel className={formClasses.labelHorizontal} fontSize="1px">
                Last Name (s)
              </FormLabel>
            </GridItem>
            <GridItem xs={8} lg={4}>
              <CustomInput
                id="lastName"
                formControlProps={{
                  fullWidth: true
                }}
                helpText="Last Name"
                inputProps={{
                  type: "text",
                  value: lastName,
                  onChange: e => {
                    setLastName(e.target.value);
                  },
                  maxLength: 30
                }}
              />
            </GridItem>
            <GridItem xs={4} lg={2}>
              <FormLabel className={formClasses.labelHorizontal} fontSize="1px">
                Email
              </FormLabel>
            </GridItem>
            <GridItem xs={8} lg={4}>
              <CustomInput
                id="email"
                formControlProps={{
                  fullWidth: true
                }}
                helpText="Email"
                inputProps={{
                  type: "text",
                  value: email,
                  onChange: e => {
                    setEmail(e.target.value);
                  },
                  maxLength: 30
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} lg={2}>
              <FormLabel className={formClasses.labelHorizontal} fontSize="1px">
                Mobile
              </FormLabel>
            </GridItem>
            <GridItem xs={8} lg={4}>
              <CustomInput
                id="mobile"
                formControlProps={{
                  fullWidth: true
                }}
                helpText="Mobile"
                inputProps={{
                  type: "text",
                  value: mobile,
                  onChange: e => {
                    setMobile(e.target.value);
                  },
                  maxLength: 30
                }}
              />
            </GridItem>
            <GridItem xs={4} lg={2}>
              <FormLabel className={formClasses.labelHorizontal} fontSize="1px">
                Job Title (Optional)
              </FormLabel>
            </GridItem>
            <GridItem xs={8} lg={4}>
              <CustomInput
                id="jobTitle"
                formControlProps={{
                  fullWidth: true
                }}
                helpText="Job Title"
                inputProps={{
                  type: "text",
                  value: jobTitle,
                  onChange: e => {
                    setJobTitle(e.target.value);
                  },
                  required: false,
                  maxLength: 30
                }}
              />
            </GridItem>
          </GridContainer>
          {/* client field */}
          {isClient ? (
            <GridContainer>
              <GridItem xs={4} lg={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Client Company
                </FormLabel>
              </GridItem>
              <GridItem xs={8} lg={4}>
                <CustomSelect
                  options={clientCompanyChoiceFields}
                  isDisabled={choiceFieldsLoading}
                  value={clientCompany}
                  setValue={setClientCompany}
                  required={true}
                />
              </GridItem>
            </GridContainer>
          ) : (
            <></>
          )}
          {/* non-client fields */}
          {!isClient ? (
            <>
              <GridContainer>
                <GridItem xs={4} lg={2}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Position
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} lg={4}>
                  <CustomSelect
                    options={positionChoiceFields}
                    isDisabled={choiceFieldsLoading || valuesLoading}
                    value={position}
                    setValue={setPosition}
                    required={true}
                  />
                </GridItem>
                <GridItem xs={4} lg={2}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Holiday allowance
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} lg={4}>
                  <CustomInput
                    id="hols"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helpText="Holiday Allowance"
                    inputProps={{
                      type: "text",
                      value: hols,
                      onChange: e => {
                        setHols(e.target.value);
                      },
                      maxLength: 30
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={4} lg={2}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Signature Image (Base64 - 300 x 140)
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} lg={4}>
                  <CustomInput
                    id="signatureBase64"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helpText="Signature"
                    inputProps={{
                      type: "text",
                      value: signatureBase64,
                      onChange: e => {
                        setSignatureBase64(e.target.value);
                      }
                      // maxLength: 30
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {/*Notifications Settings Checkboxes*/}
                <GridItem xs={4} lg={2}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                  >
                    E-mail Notifications
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} lg={4}>
                  <div className={formClasses.inlineChecks}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="expensesCheckbox"
                          tabIndex={-1}
                          onClick={handleToggle}
                          checked={receivesExpNot}
                          checkedIcon={
                            <Check className={formClasses.checkedIcon} />
                          }
                          icon={<Check className={formClasses.uncheckedIcon} />}
                          classes={{
                            checked: formClasses.checked,
                            root: formClasses.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot
                      }}
                      label="Expenses"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="holidayCheckbox"
                          tabIndex={-1}
                          onClick={handleToggle}
                          checked={receivesHolsNot}
                          checkedIcon={
                            <Check className={formClasses.checkedIcon} />
                          }
                          icon={<Check className={formClasses.uncheckedIcon} />}
                          classes={{
                            checked: formClasses.checked,
                            root: formClasses.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot
                      }}
                      label="Holiday"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="procurementCheckbox"
                          tabIndex={-1}
                          onClick={handleToggle}
                          checked={receivesProcNot}
                          checkedIcon={
                            <Check className={formClasses.checkedIcon} />
                          }
                          icon={<Check className={formClasses.uncheckedIcon} />}
                          classes={{
                            checked: formClasses.checked,
                            root: formClasses.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot
                      }}
                      label="Procurement"
                    />
                  </div>
                </GridItem>
                {/*Other Settings*/}

                <GridItem xs={4} lg={2}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                  >
                    Activation
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} lg={4}>
                  <div className={formClasses.inlineChecks}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="activeCheckbox"
                          tabIndex={-1}
                          onClick={handleToggle}
                          checked={isActive}
                          checkedIcon={
                            <Check className={formClasses.checkedIcon} />
                          }
                          icon={<Check className={formClasses.uncheckedIcon} />}
                          classes={{
                            checked: formClasses.checked,
                            root: formClasses.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot
                      }}
                      label="Active"
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </>
          ) : (
            <></>
          )}
          {!isClient ? (
            // permissions checkboxes - non-clients only
            <PermissionsBox
              handleToggle={handleToggle}
              isBookingsAdmin={isBookingsAdmin}
              isBookingsUser={isBookingsUser}
              isClientDbAdmin={isClientDbAdmin}
              isStatsAdmin={isStatsAdmin}
              isExpensesAdmin={isExpensesAdmin}
              isExpensesUser={isExpensesUser}
              isHolidaysAdmin={isHolidaysAdmin}
              isHolidaysUser={isHolidaysUser}
              isHrhsAdmin={isHrhsAdmin}
              isHrhsUser={isHrhsUser}
              isProcurementAdmin={isProcurementAdmin}
              isProcurementUser={isProcurementUser}
              isProjectDbAdmin={isProjectDbAdmin}
              isReportsUser={isReportsUser}
              isSalesPipelineAdmin={isSalesPipelineAdmin}
              isSettingsAdmin={isSettingsAdmin}
              isTimesheetsAdmin={isTimesheetsAdmin}
              isTimesheetsUser={isTimesheetsUser}
              isUserDbAdmin={isUserDbAdmin}
              isVehicleTrackingAdmin={isVehicleTrackingAdmin}
              isWarehouseAdmin={isWarehouseAdmin}
              isWarehouseUser={isWarehouseUser}
            />
          ) : (
            <></>
          )}
          <GridContainer>
            <GridItem xs={12}>
              <br />
              <br />
              <Button
                style={{ float: "right" }}
                color="rose"
                type="submit"
                form="addEditForm"
              >
                Update
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      )}
    </>
  );
};

export default UserProfileForm;
