const padNumber = (zz, totalDigits) => {
  let numberStr = zz.toString();
  for (let i = 0; i < totalDigits; i++) {
    if (numberStr.length < totalDigits) numberStr = `0${numberStr}`;
    else break;
  }
  return numberStr;
};

export default padNumber;
