import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
// redux
import { useDispatch } from "react-redux";
import { SET_WAREHOUSE_DELIVERY_DT_TRIG_REFRESH } from "redux/dispatch_types";
// custom functions
import formatDate from "global_functions/_common/formatDate";
import showNotification from "global_functions/redux_action_creators/showNotification";
import { getDialogProps } from "global_functions/_common/getDialogProps";
// custom components
import CustomInput from "components/CustomInput/CustomInput";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// core components
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
// date
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
// material ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import GridContainer from "components/Grid/GridContainer";
import FormLabel from "@material-ui/core/FormLabel";
import CustomSelect from "components/CustomSelect/CustomSelect";
// styles
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { customTheme } from "global_functions/_common/customTheme";
const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formsStyles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AcceptDeliveryDialog(props) {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  const [editDataLoading, setEditDataLoading] = useState(false);
  const [formFieldsLoading, setFormFieldsLoading] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [comments, setComments] = useState("");
  const [condition, setCondition] = useState("");
  const [dateDelivered, setDateDelivered] = useState(null);
  const [timeDelivered, setTimeDelivered] = useState(null);
  const [deliveryCompany, setDeliveryCompany] = useState("");
  const [expectedContents, setExpectedContents] = useState("");
  const [boxNumber, setBoxNumber] = useState(0);
  const [location, setLocation] = useState("");
  const [acceptedBy, setAcceptedBy] = useState("");

  const [conditionFormFields, setConditionFormFields] = useState([]);
  const [locationFormFields, setLocationFormFields] = useState([]);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    if (props.acceptDeliveryModal) {
      // load form data
      setEditDataLoading(true);
      axios
        .get(
          `${apiUrl}warehouse-delivery-schedule/${props.deliveryId}`,
          getTokenConfig(store.getState)
        )
        .then(res => {
          setComments(res.data.comments);
          setCondition(res.data.condition);
          setLocation(res.data.location);
          setAcceptedBy(res.data.accepted_by);
          setDeliveryCompany(res.data.delivery_company);
          setBoxNumber(res.data.no_boxes || 0);
          // set expected contents
          if (res.data.expected_contents === null) setExpectedContents("");
          else setExpectedContents(res.data.expected_contents ? 1 : 0);

          // set date
          if (res.data.date_delivered) {
            let dateIncurredParts = res.data.date_delivered.split("-");
            setDateDelivered(
              new Date(
                dateIncurredParts[0],
                dateIncurredParts[1] - 1,
                dateIncurredParts[2]
              )
            );
          } else {
            setDateDelivered(null);
          }

          // set time (date format)
          let timeStr = res.data.time_delivered;
          if (timeStr) {
            let timeArr = timeStr.split(":");
            let dateObj = new Date();
            dateObj.setHours(timeArr[0]);
            dateObj.setMinutes(timeArr[1]);
            setTimeDelivered(dateObj);
          } else setTimeDelivered(null);
        })
        .then(() => setEditDataLoading(false));

      // load form fields
      setFormFieldsLoading(true);
      axios
        .get(
          `${apiUrl}form-fields/delivery_acceptance_form`,
          getTokenConfig(store.getState)
        )
        .then(res => {
          setConditionFormFields(res.data.DELIVERY_CONDITION_CHOICES);
          setLocationFormFields(res.data.DELIVERY_ACCEPT_TO_CHOICES);
        })
        .then(() => setFormFieldsLoading(false));
    }
  }, [props.acceptDeliveryModal, props.deliveryId, dispatch]);

  const loadingInProgress =
    editDataLoading || formFieldsLoading || formSubmitting;

  const handleFormSubmit = () => {
    // basic validation
    let todayDate = new Date();
    let submittedDate = dateDelivered;
    todayDate.setHours(0, 0, 0, 0);
    submittedDate.setHours(0, 0, 0, 0);

    if (todayDate.getTime() < submittedDate.getTime()) {
      dispatch(
        showNotification(
          "The date is in the future. Please only log deliveries after they occur.",
          "error"
        )
      );
      return;
    }

    // submitting form
    setFormSubmitting(true);
    axios
      .put(
        `${apiUrl}warehouse-delivery-schedule/${props.deliveryId}`,
        formData,
        getTokenConfig(store.getState)
      )
      .then(() => {
        dispatch(
          showNotification("Delivery Acceptance Form Submitted", "success")
        );
        dispatch({
          type: SET_WAREHOUSE_DELIVERY_DT_TRIG_REFRESH,
          payload: { id: props.deliveryId }
        });
        props.setAcceptDeliveryModal(false);
        setFormSubmitting(false);
      })
      .catch(err => {
        let errorMsg;
        err.response.data.errors
          ? (errorMsg = err.response.data.errors)
          : (errorMsg = "Error has occurred.");
        dispatch(showNotification(errorMsg, "error"));
        setFormSubmitting(false);
      });
  };

  // updates formData object every time one form variable changes
  useEffect(() => {
    let timeDeliveredStr;
    timeDeliveredStr = timeDelivered
      ? `${timeDelivered.getHours()}:${timeDelivered.getMinutes()}`
      : "";

    setFormData({
      comments: comments,
      condition: condition,
      date_delivered: formatDate(dateDelivered, "yyyy-mm-dd"),
      time_delivered: timeDeliveredStr,
      delivery_company: deliveryCompany,
      expected_contents: expectedContents,
      no_boxes: boxNumber,
      location: location,
      accepted_by: acceptedBy,
      form: "delivery_acceptance"
    });
  }, [
    comments,
    condition,
    location,
    dateDelivered,
    timeDelivered,
    deliveryCompany,
    expectedContents,
    boxNumber,
    acceptedBy
  ]);

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={props.acceptDeliveryModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.setAcceptDeliveryModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => props.setAcceptDeliveryModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            Delivery Acceptance Form (Delivery {props.deliveryId} )
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {loadingInProgress ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <form id="acceptDeliveryForm" onSubmit={handleFormSubmit}>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Delivery Date & Time
                  </FormLabel>
                </GridItem>
                <GridItem xs={5}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={customTheme}>
                      <KeyboardDatePicker
                        style={{ float: "left" }}
                        required={true}
                        margin="normal"
                        label="Date"
                        format="dd MMM yyyy"
                        value={dateDelivered}
                        onChange={date => setDateDelivered(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={4}>
                  <ThemeProvider theme={customTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        style={{ float: "left" }}
                        required={true}
                        margin="normal"
                        id="deliveryTime"
                        label="Time"
                        onChange={e => setTimeDelivered(e)}
                        value={timeDelivered}
                        KeyboardButtonProps={{
                          "aria-label": "change time"
                        }}
                        minutesStep={15}
                        ampm={false}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel className={formClasses.labelHorizontal}>
                    Delivery Company
                  </FormLabel>
                </GridItem>
                <GridItem xs={4}>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      type: "text",
                      value: deliveryCompany,
                      onChange: e => {
                        setDeliveryCompany(e.target.value);
                      },
                      maxLength: 50
                    }}
                    helpText=""
                  />
                </GridItem>
                <GridItem xs={3}>
                  <FormLabel className={formClasses.labelHorizontal}>
                    Number of Boxes
                  </FormLabel>
                </GridItem>
                <GridItem xs={2}>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      value: boxNumber,
                      onChange: e => {
                        setBoxNumber(parseInt(e.target.value));
                      },
                      required: true,
                      min: 1,
                      max: 999,
                      step: 1
                    }}
                    helpText=""
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Expected Contents
                  </FormLabel>
                </GridItem>
                <GridItem xs={3}>
                  <CustomSelect
                    options={[
                      { value: 1, label: "Yes" },
                      { value: 0, label: "No" }
                    ]}
                    isDisabled={props.formLoading}
                    value={expectedContents}
                    setValue={setExpectedContents}
                    required={true}
                  />
                </GridItem>
                <GridItem xs={2}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Condition
                  </FormLabel>
                </GridItem>
                <GridItem xs={4}>
                  <CustomSelect
                    options={conditionFormFields}
                    isDisabled={props.formLoading}
                    value={condition}
                    setValue={setCondition}
                    required={true}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel className={formClasses.labelHorizontal}>
                    Comments
                  </FormLabel>
                </GridItem>
                <GridItem xs={9}>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: comments,
                      onChange: e => {
                        setComments(e.target.value);
                      },
                      maxLength: 200
                    }}
                    helpText=""
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel className={formClasses.labelHorizontal}>
                    Delivered to
                  </FormLabel>
                </GridItem>
                <GridItem xs={4}>
                  <CustomSelect
                    options={locationFormFields}
                    isDisabled={loadingInProgress}
                    value={location}
                    setValue={setLocation}
                    required={true}
                  />
                </GridItem>
                <GridItem xs={2}>
                  <FormLabel className={formClasses.labelHorizontal}>
                    Accepted By
                  </FormLabel>
                </GridItem>
                <GridItem xs={3}>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      type: "text",
                      value: acceptedBy,
                      onChange: e => {
                        setAcceptedBy(e.target.value);
                      },
                      maxLength: 50
                    }}
                    helpText=""
                  />
                </GridItem>
              </GridContainer>
            </form>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="primary"
            simple
            type="submit"
            form="acceptDeliveryForm"
            disabled={loadingInProgress}
          >
            Save
          </Button>
          <Button
            color="transparent"
            onClick={() => props.setAcceptDeliveryModal(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
