import { ReduxActionType } from "../../../global_functions/types/shared";

import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR_NOTIFICATION,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  AUTH_FAILURE,
  AUTH_SHOW_PASS_CHANGE_DIALOG,
  AUTH_HIDE_PASS_CHANGE_DIALOG
} from "redux/dispatch_types";

const initialState: AuthStateType = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  isLoading: false,
  user: null,
  authError: { msg: {}, status: "" },
  showPassChangeDialog: false
};

export default function(
  state = initialState,
  action: ReduxActionType
): AuthStateType {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false
      };
    case AUTH_ERROR_NOTIFICATION:
      return {
        ...state,
        authError: {
          msg: action.payload.message,
          status: action.payload.status
        }
      };
    case AUTH_FAILURE:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false
      };
    case AUTH_SHOW_PASS_CHANGE_DIALOG:
      return {
        ...state,
        showPassChangeDialog: true
      };
    case AUTH_HIDE_PASS_CHANGE_DIALOG:
      return {
        ...state,
        showPassChangeDialog: false
      };
    default:
      return state;
  }
}

// typescript declarations
type AuthStateType = {
  token: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  user: UserType | null;
  authError: AuthErrorType;
  showPassChangeDialog: boolean;
};

type AuthErrorType = {
  msg: any;
  status: string;
};

type UserType = {
  id: number;
  client_company_id: number | null;
  email: string;
  first_name: string;
  full_name: string;
  is_client: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  username: string;
  userprofile: UserProfileType;
};

type UserProfileType = {
  client: number;
  company: number;
  department: number;
  hols: number;
  id: number;
  user_permissions: string[];
  admin_permissions: string[];
  is_bookings_admin: boolean;
  is_bookings_user: boolean;
  is_client_db_admin: boolean;
  is_stats_admin: boolean;
  is_expenses_admin: boolean;
  is_expenses_user: boolean;
  is_holidays_admin: boolean;
  is_holidays_user: boolean;
  is_hrhs_admin: boolean;
  is_hrhs_user: boolean;
  is_procurement_admin: boolean;
  is_procurement_user: boolean;
  is_project_db_admin: boolean;
  is_reports_user: boolean;
  is_sales_pipeline_admin: boolean;
  is_settings_admin: boolean;
  is_timesheets_admin: boolean;
  is_timesheets_user: boolean;
  is_user_db_admin: boolean;
  is_vehicle_tracking_admin: boolean;
  is_warehouse_admin: boolean;
  is_warehouse_user: boolean;
  job_title: string;
  mobile: string;
  new_user: boolean;
  receives_exp_not: boolean;
  receives_hols_not: boolean;
  signature_base64: string;
  user: number;
};
