import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

export const holsAdminSickLeave = {
  section: "holsAdmin",
  reduxTableName: "holsAdminSickLeave",
  filterTabValues: [1, 2, 3],
  filterTabNames: ["Pending", "Approved", "Declined"],
  tableParams: {
    autoWidth: true,
    responsive: true,
    dom: "Bfrtip",
    select: { style: "single" },
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    // searching: false,
    // paging: false,
    info: false,
    columns: [
      {
        data: "id",
        title: "ID",
        responsivePriority: 1,
        visible: false,
        searchable: false
      }, // 0
      { data: "user", title: "User" }, // 1
      { data: "sick_days", title: "Taken", className: "dt-body-center" } // 2
    ],
    aaSorting: [[1, "asc"]]
  }
};
