import React, { useEffect, useState } from "react";

// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import CustomInput from "components/CustomInput/CustomInput";
// material-ui
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
// template
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// types
import { AddEditFormTypes } from "../../types/views/FormTypes";
// styles
import { makeStyles } from "@material-ui/core/styles";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
// @ts-ignore
const useFormStyles = makeStyles(formsStyles);
// @ts-ignore
const useNotifyStyles = makeStyles(notificationStyles);

const AddEditDepartmentForm: React.FC<AddEditDepartmentFormType> = ({
  editData,
  setformData,
  formLoading,
  handleFormSubmit,
  open
}) => {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  // form fields
  const [name, setName] = useState("");
  const [resourcePlannerOrder, setResourcePlannerOrder] = useState(0);

  useEffect(() => {
    if (editData && open) {
      setName(editData.name);
      setResourcePlannerOrder(editData.resource_planner_order);
    } else if (!editData && open) {
      setName("");
      setResourcePlannerOrder(0);
    }
  }, [editData, open]);

  useEffect(() => {
    setformData({
      resource_planner_order: resourcePlannerOrder,
      name: name
    });
    // eslint-disable-next-line
    }, [name, resourcePlannerOrder]);

  return (
    <>
      {formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "170px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={handleFormSubmit}>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Name
                </FormLabel>
              </GridItem>
              <GridItem xs={6}>
                <CustomInput
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: name,
                    onChange: (e: React.FormEvent<HTMLInputElement>) => {
                      setName(e.currentTarget.value);
                    },
                    required: true,
                    maxLength: 50
                  }}
                />
              </GridItem>
              <GridItem xs={2}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Resource Planner Order
                </FormLabel>
              </GridItem>
              <GridItem xs={2}>
                <CustomInput
                  id="resourcePlannerOrder"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    value: resourcePlannerOrder,
                    onChange: (e: React.FormEvent<HTMLInputElement>) => {
                      // @ts-ignore
                      setResourcePlannerOrder(e.currentTarget.value);
                    },
                    required: true,
                    maxLength: 30
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
};

export default AddEditDepartmentForm;

type EditDataType = {
  name: string;
  resource_planner_order: number;
};

interface AddEditDepartmentFormType extends AddEditFormTypes {
  editData: EditDataType | null;
}
