import React, { useState } from "react";
// redux
import { useDispatch } from "react-redux";
import {
  SET_COMPANY_SERVICES_DB_DT_TRIG_REFRESH,
  SET_COMPANY_SERVICES_DB_DT_PAGE,
  SET_COMPANY_SERVICES_DB_DT_ROW
} from "../../../redux/dispatch_types";
// template
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardFooter from "../../../components/Card/CardFooter";
import CardText from "../../../components/Card/CardText";
import CardBody from "../../../components/Card/CardBody";
// custom components
import AddButton from "../../../components/CustomButtons/AddButton";
import AddEditDtEntryDialog from "../../Dialogs/_Common/AddEditDtEntryDialog";
import AddEditCompanyServiceForm from "../../Forms/AddEditCompanyServiceForm";
import BackButton from "../../../components/CustomButtons/BackButton";
import DeleteDialog from "../../Dialogs/_Common/DeleteDialog";
// custom functions
import identifyClick from "../../../global_functions/_common/identifyClick";
import useEventListener from "../../../global_functions/_common/useEventListener";
// datatables
import Datatable from "../../../components/_Custom/_Common/DataTables/DataTable";
import { companyServicesMain } from "../../DataTables/companyServicesMain";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
// styles
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

const CompanyServicesPage = () => {
  const classes = useStyles();

  const [dataTable, setDataTable] = useState(null);

  const [editDelId, setEditDelId] = useState(0);
  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");

  const handleAddClick = e => {
    e.stopPropagation();
    setEditDelId(0);
    setAddEditModal(true);
  };

  const dispatch = useDispatch();
  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "dtButton":
          if (clickRelevantType.params[0] === "Edit") {
            // dataTable.rows().deselect();
            let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
            setEditDelId(dtData.id);
            setAddEditModal(true);
          } else if (clickRelevantType.params[0] === "Delete") {
            let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
            setEditDelId(dtData.id);
            let delName = `${dtData.name} (service id ${dtData.id})`;
            setDeleteName(delName);
            setDeleteModal(true);
          }
          break;
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({
            type: SET_COMPANY_SERVICES_DB_DT_ROW,
            payload: selectedRow
          });
          break;
        case "pagination":
          dispatch({
            type: SET_COMPANY_SERVICES_DB_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        default:
          return false;
      }
    }
  };

  useDataTableRefresh(dataTable, companyServicesMain);

  useEventListener("click", handleClickEvent, window);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={10} lg={8}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Company Services</h4>
                <h4 className={classes.cardCategoryWhite}>
                  Manage Services Provided By The Company
                </h4>
              </CardText>
              <GridContainer>
                <GridItem xs={12}>
                  <BackButton />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setDataTable}
                tableParams={companyServicesMain}
              />
            </CardBody>
            <CardFooter>
              <div className={classes.cardContentRight}>
                <AddButton
                  handleAddClick={handleAddClick}
                  tooltip="Add Service"
                />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <AddEditDtEntryDialog
        addEditModal={addEditModal}
        setAddEditModal={setAddEditModal}
        editDelID={editDelId}
        tableRefreshType={SET_COMPANY_SERVICES_DB_DT_TRIG_REFRESH}
        addTitle="Add Service"
        editTitle="Edit Service"
        urlSuffix="company-services/"
      >
        <AddEditCompanyServiceForm />
      </AddEditDtEntryDialog>
      <DeleteDialog
        dataTable={dataTable}
        deleteFrom="the company service database"
        deleteModal={deleteModal}
        deleteName={deleteName}
        selectedRow={editDelId}
        setDeleteModal={setDeleteModal}
        tableRefreshType={SET_COMPANY_SERVICES_DB_DT_TRIG_REFRESH}
        urlSuffix="company-services/"
      />
    </>
  );
};

export default CompanyServicesPage;
