import React, { useState, useEffect } from "react";
import axios from "axios";

import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";

import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";

const useStyles = makeStyles(styles);
const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ReadConfirmationsDialog = ({
  readConfirmationsDialog,
  setReadConfirmationsDialog,
  docId
}) => {
  const classes = useStyles();

  const [readReceiptArray, setReadReceiptArray] = useState([]);

  const [loadingInProgress, setLoadingInProgress] = useState(false);

  useEffect(() => {
    if (readConfirmationsDialog) {
      setLoadingInProgress(true);
      axios
        .get(
          `${apiUrl}projects/0/sheq_docs/${docId}`,
          getTokenConfig(store.getState)
        )
        .then(res => {
          setReadReceiptArray(res.data.read_user_list);
        })
        .then(() => setLoadingInProgress(false));
    }
  }, [readConfirmationsDialog, docId]);

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        fullWidth={true}
        open={readConfirmationsDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setReadConfirmationsDialog(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setReadConfirmationsDialog(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Read Receipts</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {loadingInProgress ? (
            <CustomLoadingSpinner />
          ) : (
            <ul>
              {readReceiptArray.map((name, index) => {
                return <li key={`list-item-${index}`}>{name}</li>;
              })}
            </ul>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="transparent"
            onClick={() => setReadConfirmationsDialog(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
};

export default ReadConfirmationsDialog;
