import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";

import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";

import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import showNotification from "global_functions/redux_action_creators/showNotification";

const useStyles = makeStyles(styles);

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DeleteUploadDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const handleDeleteConfirmClick = () => {
    setDeleteInProgress(true);
    axios
      .delete(props.delUrl, getTokenConfig(store.getState))
      .then(() => {
        props.refreshFileList();
        dispatch(showNotification("Deleted.", "success"));
        props.setDeleteDialog(false);
        setDeleteInProgress(false);
      })
      .catch(() => {
        dispatch(
          showNotification("Error deleting. Please try again.", "error")
        );
        setDeleteInProgress(false);
      });
  };

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (props.deleteDialog) {
      document.addEventListener("keydown", captureKeyDown, false);
    } else {
      document.removeEventListener("keydown", captureKeyDown, false);
    }
  }, [props.deleteDialog, captureKeyDown]);

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        fullWidth={true}
        open={props.deleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.setDeleteDialog(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => props.setDeleteDialog(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Delete Entry</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {deleteInProgress ? (
            <CustomLoadingSpinner />
          ) : (
            <p>Are you sure you would like to delete this upload?</p>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            disabled={deleteInProgress}
            color="danger"
            onClick={() => handleDeleteConfirmClick()}
            simple
          >
            Delete
          </Button>
          <Button
            color="transparent"
            onClick={() => props.setDeleteDialog(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
