import {
  DataTableParamsType,
  DatatableIconType
} from "../../../global_functions/types/dataTables";

import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const deleteButton = { icon: "Delete", tooltip: "" };
const editButton = { icon: "Edit", tooltip: "" };

export const vehicleTrackingMain: DataTableParamsType = {
  section: "vehicleTracking",
  refreshURL: "vehicles/",
  reduxTableName: "vehicleTrackingMain",
  filterTabValues: ["?show=all", "?show=expired"],
  filterTabNames: ["All", "Expired"],
  tableParams: {
    autoWidth: true,
    responsive: true,
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    buttonsColumn: {
      id: 11,
      genIcons: (): DatatableIconType[] => {
        return [editButton, deleteButton];
      }
    },
    columns: [
      {
        data: "id",
        title: "ID",
        className: "dt-body-center",
        responsivePriority: 1,
        width: 50
      }, // 0
      {
        data: "reg_no",
        title: "Reg. Number",
        className: "dt-body-center",
        responsivePriority: 1,
        width: 150
      }, // 1
      {
        data: "make_model",
        title: "Make & Model",
        // className: "dt-body-center",
        responsivePriority: 1,
        width: 250
      }, // 2
      {
        data: "assigned_to",
        title: "Assigned To",
        // className: "dt-body-center",
        responsivePriority: 1,
        width: 250
      }, // 3
      {
        data: { _: "tax_expiry.display", sort: "tax_expiry.@data-order" },
        className: "dt-body-center",
        title: "Tax Expiry",
        width: 200
      }, // 4
      {
        data: { _: "mot_expiry.display", sort: "mot_expiry.@data-order" },
        title: "MOT Expiry",
        className: "dt-body-center",
        width: 200
      }, // 5
      {
        data: { _: "next_service.display", sort: "next_service.@data-order" },
        title: "Service Due",
        className: "dt-body-center",
        width: 200
      }, // 6
      {
        data: "fuel_pin",
        title: "Fuel Card Pin",
        className: "dt-body-center",
        width: 200
      }, // 7
      {
        data: "dart_registered",
        title: "Dart",
        className: "dt-body-center",
        width: 200
      }, // 8
      {
        data: "cc_registered",
        title: "CC",
        className: "dt-body-center",
        width: 200
      }, // 9
      {
        data: "tracker_installed",
        title: "Tracker",
        className: "dt-body-center",
        width: 200
      }, // 10
      {
        data: null,
        title: "Actions",
        className: "dt-body-center",
        width: 50
      } // 11
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[0, "asc"]]
  }
};
