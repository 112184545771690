import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const deleteButton = { icon: "Delete", tooltip: "" };
const editButton = { icon: "Edit", tooltip: "" };
const reportButton = { icon: "Assignment", tooltip: "Generate job report" };
const contactsButton = {
  icon: "ContactMailTwoToneIcon",
  tooltip: "Manage contacts"
};

export const projectDBMain = {
  section: "projectDB",
  refreshURL: "projects/?status_filters=",
  reduxTableName: "projectDBMain",
  filterTabValues: [0, 1, 2, 3, 5, 4],
  filterTabNames: [
    "All",
    "Active",
    "Quoted",
    "Complete",
    "On-going",
    "Declined"
  ],
  tableParams: {
    autoWidth: true,
    dom: "Bfrtip",
    responsive: true,
    select: {
      style: "single"
    },
    buttonsColumn: {
      id: 8,
      genIcons: () => {
        return [editButton, deleteButton, contactsButton, reportButton];
      }
    },
    columns: [
      { data: "id", title: "Project ID", visible: false, searchable: false }, // 0
      { data: "number", title: "Number", responsivePriority: 1, width: 100 }, // 1
      { data: "client", title: "Client", responsivePriority: 2, width: 200 }, // 2
      { data: "description", title: "Name", responsivePriority: 1, width: 150 }, // 3
      { data: "nickname", title: "Nickname" }, // 4
      { data: "type", title: "Type" }, // 5
      { data: "status", title: "Status" }, // 6
      { data: "charge", title: "Charge" }, // 7
      {
        data: null,
        responsivePriority: 1,
        title: "Report",
        className: "dt-body-center",
        width: 175
      } // 8
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "asc"]]
  }
};
