import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  SET_USER_BOOKINGS_REFRESH_URL,
  SET_USER_BOOKINGS_DB_DT_PAGE,
  SET_USER_BOOKINGS_DB_DT_ROW,
} from "redux/dispatch_types";

import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import { makeStyles } from "@material-ui/core/styles";

import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
// @material-ui/icons
import useEventListener from "global_functions/_common/useEventListener";
import identifyClick from "global_functions/_common/identifyClick";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
// table
import { bookingsUserMain } from "views/DataTables/Bookings/bookingsUserMain";
// styles
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formsStyles);

export default function BookingsUserPage() {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const [showPastBookings, setShowPastBookings] = useState(false);

  const dispatch = useDispatch();

  const [dataTable, setDataTable] = useState("");

  useDataTableRefresh(dataTable, bookingsUserMain);

  const currentUserId = useSelector(state => state.auth.user.id);

  useEffect(() => {
    // set url
    dispatch({
      type: SET_USER_BOOKINGS_REFRESH_URL,
      payload: `datasets/?type=${
        showPastBookings ? "past_bookings_user" : "future_bookings_user"
      }&user_id=${currentUserId}`
    });
  }, [currentUserId, dispatch, showPastBookings]);

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_USER_BOOKINGS_DB_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_USER_BOOKINGS_DB_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        default:
          return false;
      }
    }
  };

  useEventListener("click", handleClickEvent, window);

  return (
    <GridContainer>
      {/*<GridItem xs={12} lg={10} xl={8}>*/}
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitleWhite}>My Bookings</h4>
              <h4 className={classes.cardCategoryWhite}>
                Future & Past Bookings
              </h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <div className={formClasses.inlineChecks}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="incAllHrsCheckbox"
                        tabIndex={-1}
                        onClick={e => setShowPastBookings(e.target.checked)}
                        checked={showPastBookings}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot
                    }}
                    label="Show Past Bookings"
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <Datatable
                  tableParams={bookingsUserMain}
                  setDataTable={setDataTable}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
