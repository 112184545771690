import React, { useEffect, useState, useMemo } from "react";
// custom components
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import CustomInput from "components/CustomInput/CustomInput";
import ProjectSelect from "components/_Custom/_Common/ProjectSelect";
// components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
// material ui
import FormLabel from "@material-ui/core/FormLabel";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// styles
import { makeStyles } from "@material-ui/core/styles";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);

export default function AddEditWarehouseItemForm(props) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  // form fields
  const [manufacturer, setManufacturer] = useState("");
  const [model, setModel] = useState("");
  const [serial, setSerial] = useState("");
  const [comments, setComments] = useState("");
  const [itemStatus, setItemStatus] = useState(1);
  const [clientId, setClientId] = useState("");
  const [projId, setProjId] = useState("");
  const [itemLocation, setItemLocation] = useState(null);
  const [itemBay, setItemBay] = useState(0);
  const [itemShelf, setItemShelf] = useState(0);
  const [itemType, setItemType] = useState(0);
  const [itemQty, setItemQty] = useState(1);
  const [engineerDesk, setEngineerDesk] = useState(null);

  const [itemContainer, setItemContainer] = useState(null);
  const [itemStatusStr, setItemStatusStr] = useState("");

  // generate select values for the 8 engineer desks
  const engineerDeskChoices = useMemo(() => {
    let options = [];
    for (let i = 0; i <= 7; i++) {
      options.push({ label: (i + 1).toString(), value: i + 1 });
    }
    return options;
  }, []);

  useEffect(() => {
    if (props.editData && props.open) {
      setItemStatus(props.editData.status);
      setManufacturer(props.editData.manufacturer);
      setModel(props.editData.model);
      setComments(props.editData.comments);
      setSerial(props.editData.serial_no);
      setProjId(props.editData.project);
      setClientId(props.editData.client_id);
      setItemBay(props.editData.location_bay);
      setItemShelf(props.editData.location_shelf);
      setItemType(props.editData.type);
      setItemQty(props.editData.qty);
      setItemContainer(props.editData.container);
      setItemStatusStr(props.editData.status_str);
      setItemLocation(props.editData.location);
      setEngineerDesk(props.editData.location_desk_no);
    } else if (!props.editData && props.open) {
      setItemStatus(1); // default status is 'pre-check-in'
      setManufacturer("");
      setModel("");
      setComments("");
      setSerial("");
      setClientId("");
      setItemBay(0);
      setItemShelf(0);
      setItemType(0);
      setItemQty(1);
      setItemContainer(null);
      setItemLocation(null);
      setEngineerDesk(null);
      // when opening with projectId provided, it is being opened from single project view
      // project select box will be not be shown in that case and project id will stay constant
      if (props.projectId) {
        setProjId(props.projectId);
      } else {
        setProjId("");
      }
    }
  }, [props.editData, props.open, props.projectId]);

  useEffect(() => {
    props.setformData({
      status: itemStatus,
      manufacturer: manufacturer,
      model: model,
      serial_no: serial,
      comments: comments,
      project: projId,
      location_bay: itemBay,
      location_shelf: itemShelf,
      type: itemType,
      qty: itemQty,
      location: itemLocation,
      location_desk_no: engineerDesk
    });
    // eslint-disable-next-line
    }, [manufacturer, model, serial, comments, itemStatus, clientId, projId, itemBay, itemShelf, itemQty, itemType, itemLocation, engineerDesk]);

  // whenever item type changes to single item (1) the qty box gets disabled an (2) item qty gets set to 1

  useEffect(() => {
    if (itemType === 0) {
      setItemQty(1);
    }
  }, [itemType]);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "350px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            {props.editData && (
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Status
                  </FormLabel>
                </GridItem>
                <GridItem xs={9}>
                  {!itemContainer ? (
                    <CustomSelect
                      options={props.formChoiceFields.STATUS_CHOICES}
                      isDisabled={props.formLoading}
                      value={itemStatus}
                      setValue={setItemStatus}
                      required={true}
                    />
                  ) : (
                    <FormLabel
                      className={formClasses.label}
                      style={{ marginTop: "38px", float: "left" }}
                      fontSize="1px"
                    >
                      {itemStatusStr} (follows status of container ID{" "}
                      {itemContainer})
                    </FormLabel>
                  )}
                </GridItem>
              </GridContainer>
            )}
            {/*if not project id and client number is provided, the list for all items is being viewed and*/}
            {/*project select box needs to be provided*/}
            {!props.projectId && (
              <ProjectSelect
                clientList={props.clientList}
                formLoading={props.formLoading}
                clientID={clientId}
                setClientID={setClientId}
                statusFilters={[0]}
                projID={projId}
                setProjID={setProjId}
                lhsFieldWidth={3}
                rhsFieldWidth={9}
              />
            )}
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Manufacturer
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    type: "text",
                    value: manufacturer,
                    onChange: e => {
                      setManufacturer(e.target.value);
                    },
                    maxLength: 50
                  }}
                  helpText=""
                />
              </GridItem>
              <GridItem xs={1}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Model
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: model,
                    onChange: e => {
                      setModel(e.target.value);
                    },
                    maxLength: 50
                  }}
                  helpText=""
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Serial
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: serial,
                    onChange: e => {
                      setSerial(e.target.value);
                    },
                    maxLength: 50
                  }}
                  helpText=""
                />
              </GridItem>
            </GridContainer>

            {!itemContainer ? (
              <>
                <GridContainer>
                  <GridItem xs={3}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      Location
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={9}>
                    <div
                      className={
                        formClasses.checkboxAndRadio +
                        " " +
                        formClasses.checkboxAndRadioHorizontal
                      }
                      style={{ float: "left", marginTop: "24px" }}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            checked={itemLocation === 0}
                            onChange={() => setItemLocation(0)}
                            value={0}
                            name="radio button enabled"
                            aria-label="A"
                            icon={
                              <FiberManualRecord
                                className={formClasses.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={formClasses.radioChecked}
                              />
                            }
                            classes={{
                              checked: formClasses.radio,
                              root: formClasses.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: formClasses.label,
                          root: formClasses.labelRoot
                        }}
                        label="Bays/External Storage"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            checked={itemLocation === 1}
                            onChange={() => setItemLocation(1)}
                            value={1}
                            name="radio button enabled"
                            aria-label="B"
                            icon={
                              <FiberManualRecord
                                className={formClasses.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={formClasses.radioChecked}
                              />
                            }
                            classes={{
                              checked: formClasses.radio,
                              root: formClasses.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: formClasses.label,
                          root: formClasses.labelRoot
                        }}
                        label="Engineer Desk"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                {itemLocation === 0 ? (
                  <GridContainer>
                    <GridItem xs={3}>
                      <FormLabel
                        className={formClasses.labelHorizontal}
                        fontSize="1px"
                      >
                        Location Detail
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={4}>
                      <CustomSelect
                        options={props.formChoiceFields.BAY_CHOICES}
                        isDisabled={props.formLoading}
                        value={itemBay}
                        setValue={setItemBay}
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <FormLabel
                        className={formClasses.labelHorizontal}
                        fontSize="1px"
                      >
                        Shelf
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={3}>
                      <CustomSelect
                        options={props.formChoiceFields.SHELF_CHOICES}
                        isDisabled={props.formLoading}
                        value={itemShelf}
                        setValue={setItemShelf}
                      />
                    </GridItem>
                  </GridContainer>
                ) : itemLocation ? (
                  <GridContainer>
                    <GridItem xs={3}>
                      <FormLabel
                        className={formClasses.labelHorizontal}
                        fontSize="1px"
                      >
                        Desk No.
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={3}>
                      <CustomSelect
                        options={engineerDeskChoices}
                        isDisabled={props.formLoading}
                        value={engineerDesk}
                        setValue={setEngineerDesk}
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Location
                  </FormLabel>
                </GridItem>
                <GridItem xs={9}>
                  <FormLabel
                    className={formClasses.label}
                    style={{ marginTop: "38px", float: "left" }}
                    fontSize="1px"
                  >
                    Bay {itemBay} Shelf {itemShelf} (follows location of
                    container ID {itemContainer})
                  </FormLabel>
                </GridItem>
              </GridContainer>
            )}

            <GridContainer>
              <GridItem xs={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Type
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomSelect
                  options={props.formChoiceFields.TYPE_CHOICES}
                  isDisabled={props.formLoading}
                  value={itemType}
                  setValue={setItemType}
                />
              </GridItem>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Qty.
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  id="amount"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Qty."
                  inputProps={{
                    type: "number",
                    value: itemQty,
                    onChange: e => {
                      setItemQty(e.target.value);
                    },
                    disabled: itemType === 0,
                    required: true,
                    min: 1,
                    max: 9999,
                    step: 1
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Comments
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: comments,
                    onChange: e => {
                      setComments(e.target.value);
                    },
                    maxLength: 50
                  }}
                  helpText=""
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
}
