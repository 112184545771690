import axios from "axios";
import { LOGOUT_SUCCESS, AUTH_FAILURE } from "redux/dispatch_types";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import showNotification from "global_functions/redux_action_creators/showNotification";
import showAuthError from "../showAuthError";
const apiUrl = process.env.REACT_APP_API_V1_URL;

export const logoutUser = () => (dispatch, getState) => {
  axios
    .post(apiUrl + "accounts/logout/", null, getTokenConfig(getState))
    .then(res => {
      dispatch(showNotification("Logged out", "success"));
      dispatch({
        type: LOGOUT_SUCCESS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch(showAuthError(err));
      dispatch({
        type: AUTH_FAILURE
      });
    });
};
