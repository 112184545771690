import React, { useState, useEffect, useCallback } from "react";

import axios from "axios";

// responsive
import { useMediaQuery } from "react-responsive";
import { getDialogProps } from "global_functions/_common/getDialogProps";
// redux
import { useDispatch } from "react-redux";
import { getTokenConfig } from "../../../global_functions/redux_action_creators/auth/getTokenConfig";
import store from "../../../redux/store";
import showNotification from "../../../global_functions/redux_action_creators/showNotification";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// template components
import CustomInput from "../../../components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import GridContainer from "../../../components/Grid/GridContainer";
// material ui
import FormLabel from "@material-ui/core/FormLabel";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import formsStyles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// styles
const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formsStyles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ProjectContactInfoModal({ modal, setModal, projId }) {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const dispatch = useDispatch();

  const [loadingInProgress, setLoadingInProgress] = useState(false);

  const [formTitle, setFormTitle] = useState("");
  const [projManager, setProjManager] = useState("");
  const [projManagerContactDetails, setProjManagerContactDetails] = useState(
    ""
  );
  const [siteContactName, setSiteContactName] = useState("");
  const [siteContactContactDetails, setSiteContactContactDetails] = useState(
    ""
  );
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [address4, setAddress4] = useState("");

  const [editEnabled, setEditEnabled] = useState(false);

  const preFillForm = useCallback(() => {
    setLoadingInProgress(true);
    axios
      .get(`${apiUrl}projects/${projId}`, getTokenConfig(store.getState))
      .then(res => {
        setFormTitle(
          `Contacts for ${res.data.number} ${res.data.client_name} ${res.data.description}`
        );
        setProjManager(res.data.project_manager);
        setProjManagerContactDetails(res.data.proj_manager_contacts);
        setSiteContactContactDetails(res.data.site_contact_contacts);
        setSiteContactName(res.data.site_contact);
        setAddress1(res.data.address.address_1);
        setAddress2(res.data.address.address_2);
        setAddress3(res.data.address.address_3);
        setAddress4(res.data.address.address_4);
      })
      .then(() => setLoadingInProgress(false))
      .catch(() => {
        dispatch(showNotification("Error loading contact details", "error"));
        setModal(false);
      });
  }, [projId, dispatch, setModal]);

  useEffect(() => {
    // when modal opens and every time edit is disabled (to load original values from form)
    if (modal && !editEnabled) {
      preFillForm();
    } else if (!modal) {
      // when modal closes, reset edit switch
      setEditEnabled(false);
    }
  }, [modal, projId, editEnabled, preFillForm]);

  const handleFormSubmit = e => {
    e.preventDefault();
    setLoadingInProgress(true);
    axios
      .put(
        `${apiUrl}projects/${projId}`,
        {
          id: projId,
          project_manager: projManager,
          proj_manager_contacts: projManagerContactDetails,
          site_contact: siteContactName,
          site_contact_contacts: siteContactContactDetails,
          address_1: address1 || "",
          address_2: address2 || "",
          address_3: address3 || "",
          address_4: address4 || ""
        },
        getTokenConfig(store.getState)
      )
      .then(res => {
        setLoadingInProgress(false);
        setEditEnabled(false);
        preFillForm();
        dispatch(showNotification(res.data.statusText, "success"));
        // dispatch({  // reselecting slows it down, test more
        //   type: SET_PROJECT_DB_DT_TRIG_REFRESH,
        //   payload: res.data.id
        // });
      })
      .catch(() => {
        dispatch(
          showNotification(
            "Error changing contact details. Check your internet connection and try again.",
            "error"
          )
        );
        setLoadingInProgress(false);
      });
  };

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Project Contact Info</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {loadingInProgress ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "500px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <>
              <form id="projContactDetailsForm" onSubmit={handleFormSubmit}>
                <GridContainer>
                  <GridItem md={9} />
                  <GridItem md={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={editEnabled}
                          onChange={event =>
                            setEditEnabled(event.target.checked)
                          }
                          value="editToggle"
                          color="primary"
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label="Edit"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={1} />
                  <GridItem xs={11}>
                    <FormLabel
                      className={formClasses.label}
                      style={{ marginTop: "38px", float: "left" }}
                      fontSize="1px"
                    >
                      {formTitle}
                    </FormLabel>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      Project Manager
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={7}>
                    {editEnabled ? (
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          type: "text",
                          value: projManager,
                          onChange: e => {
                            setProjManager(e.target.value);
                          },
                          maxLength: 50
                        }}
                        helpText=""
                      />
                    ) : (
                      <FormLabel
                        className={formClasses.label}
                        style={{ marginTop: "38px", float: "left" }}
                        fontSize="1px"
                      >
                        {projManager}
                      </FormLabel>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      PM Contacts
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={7}>
                    {editEnabled ? (
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          type: "text",
                          value: projManagerContactDetails,
                          onChange: e => {
                            setProjManagerContactDetails(e.target.value);
                          },
                          maxLength: 50
                        }}
                        helpText=""
                      />
                    ) : (
                      <FormLabel
                        className={formClasses.label}
                        style={{ marginTop: "38px", float: "left" }}
                        fontSize="1px"
                      >
                        {projManagerContactDetails}
                      </FormLabel>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      Site Contact
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={7}>
                    {editEnabled ? (
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          type: "text",
                          value: siteContactName,
                          onChange: e => {
                            setSiteContactName(e.target.value);
                          },
                          maxLength: 50
                        }}
                        helpText=""
                      />
                    ) : (
                      <FormLabel
                        className={formClasses.label}
                        style={{ marginTop: "38px", float: "left" }}
                        fontSize="1px"
                      >
                        {siteContactName}
                      </FormLabel>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      SC Contacts
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={7}>
                    {editEnabled ? (
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          type: "text",
                          value: siteContactContactDetails,
                          onChange: e => {
                            setSiteContactContactDetails(e.target.value);
                          },
                          maxLength: 50
                        }}
                        helpText=""
                      />
                    ) : (
                      <FormLabel
                        className={formClasses.label}
                        style={{ marginTop: "38px", float: "left" }}
                        fontSize="1px"
                      >
                        {siteContactContactDetails}
                      </FormLabel>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      Address Line 1
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={7}>
                    {editEnabled ? (
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          type: "text",
                          value: address1,
                          onChange: e => {
                            setAddress1(e.target.value);
                          },
                          maxLength: 50
                        }}
                        helpText=""
                      />
                    ) : (
                      <FormLabel
                        className={formClasses.label}
                        style={{ marginTop: "38px", float: "left" }}
                        fontSize="1px"
                      >
                        {address1}
                      </FormLabel>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      Address Line 2
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={7}>
                    {editEnabled ? (
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          type: "text",
                          value: address2,
                          onChange: e => {
                            setAddress2(e.target.value);
                          },
                          maxLength: 50
                        }}
                        helpText=""
                      />
                    ) : (
                      <FormLabel
                        className={formClasses.label}
                        style={{ marginTop: "38px", float: "left" }}
                        fontSize="1px"
                      >
                        {address2}
                      </FormLabel>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      Address Line 3
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={7}>
                    {editEnabled ? (
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          type: "text",
                          value: address3,
                          onChange: e => {
                            setAddress3(e.target.value);
                          },
                          maxLength: 50
                        }}
                        helpText=""
                      />
                    ) : (
                      <FormLabel
                        className={formClasses.label}
                        style={{ marginTop: "38px", float: "left" }}
                        fontSize="1px"
                      >
                        {address3}
                      </FormLabel>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={5}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      Address Line 4
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={7}>
                    {editEnabled ? (
                      <CustomInput
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          type: "text",
                          value: address4,
                          onChange: e => {
                            setAddress4(e.target.value);
                          },
                          maxLength: 50
                        }}
                        helpText=""
                      />
                    ) : (
                      <FormLabel
                        className={formClasses.label}
                        style={{ marginTop: "38px", float: "left" }}
                        fontSize="1px"
                      >
                        {address4}
                      </FormLabel>
                    )}
                  </GridItem>
                </GridContainer>
              </form>
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {editEnabled && (
            <Button
              color="primary"
              simple={true}
              type="submit"
              form="projContactDetailsForm"
              disabled={loadingInProgress || !editEnabled}
            >
              Save
            </Button>
          )}
          <Button color="transparent" onClick={() => setModal(false)} simple>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
