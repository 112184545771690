import React, { useEffect, useState } from "react";

import axios from "axios";
// redux
import { useDispatch } from "react-redux";
import store from "redux/store";

import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import { makeStyles } from "@material-ui/core/styles";

// tables
import { warehouseItemsInContainerMain } from "views/DataTables/Warehouse/Containers/warehouseItemsInContainerMain";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
// functions
import useEventListener from "global_functions/_common/useEventListener";
import identifyClick from "global_functions/_common/identifyClick";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";

import {
  SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_PAGE,
  SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_ROW,
  SET_WAREHOUSE_ITEMS_IN_CONTAINER_REFRESH_URL,
  SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_TRIG_REFRESH
} from "redux/dispatch_types";

// buttons
import BackButton from "components/CustomButtons/BackButton";
// import AddButton from "components/CustomButtons/AddButton";

// styles
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import MoveItemToFromContainerDialog from "../../../Dialogs/Warehouse/MoveItemToFromContainerDialog";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

export default function WarehouseItemsInContainerPage(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [dataTable, setDataTable] = useState("");
  const [moveId, setMoveId] = useState(null);
  const [moveItemModal, setMoveItemModal] = useState(false);

  const containerId = props.match.params.container_id;
  const parentProjectId = props.match.params.proj_id;

  // for showing container info at the top of the table
  const [projectName, setProjectName] = useState("Loading...");
  const [containerType, setContainerType] = useState("Loading...");
  const [containerName, setContainerName] = useState("Loading...");

  useDataTableRefresh(dataTable, warehouseItemsInContainerMain);

  // sets refresh url for page on load
  useEffect(() => {
    dispatch({
      type: SET_WAREHOUSE_ITEMS_IN_CONTAINER_REFRESH_URL,
      payload: `projects/${parentProjectId}/containers/${containerId}/contents`
    });

    // get container info to show at the top of the table
    axios
      .get(
        `${apiUrl}projects/${parentProjectId}/containers/${containerId}`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setProjectName(res.data.project_name);
        setContainerType(res.data.container_type_disp);
        setContainerName(res.data.name);
      });
  }, [parentProjectId, containerId, dispatch]);

  // handlers

  const handleMoveItemClick = itemId => {
    setMoveId(itemId);
    setMoveItemModal(true);
  };

  const handleClickEvent = e => {
    const clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({
            type: SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_ROW,
            payload: selectedRow
          });
          break;
        case "pagination":
          dispatch({
            type: SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
          dataTable.rows().deselect();
          if (clickRelevantType.params[0] === "Storage") {
            handleMoveItemClick(dtData.id);
          }
          break;
        default:
          return false;
      }
    }
  };

  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                {/*below name written in black was requested by a staff member who has difficulties reading grey text*/}
                <p
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    marginBottom: 0
                  }}
                >
                  {containerName}
                </p>
                <h4 className={classes.cardTitleWhite}>
                  {containerType} ID {containerId}
                </h4>
                <h4 className={classes.cardCategoryWhite}>{projectName}</h4>
              </CardText>
              <GridContainer>
                <GridItem xs={12}>
                  <BackButton />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <Datatable
                tableParams={warehouseItemsInContainerMain}
                setDataTable={setDataTable}
              />
            </CardBody>
            <CardFooter>
              {/*<div className={classes.cardContentRight}>*/}
              {/*    <AddButton handleAddClick={handleAddClick} tooltip="Add Warehouse Container" />*/}
              {/*</div>*/}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <MoveItemToFromContainerDialog
        moveItemFromContainerDialog={moveItemModal}
        setMoveItemFromContainerDialog={setMoveItemModal}
        moveItemId={moveId}
        projectId={parentProjectId}
        refreshDispatchType={SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_TRIG_REFRESH}
        containerId={parseInt(containerId)}
      />
    </div>
  );
}
