import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";

import useEventListener from "global_functions/_common/useEventListener";

import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "components/CustomButtons/BackButton";
// tables
import { expEntriesUserMain } from "views/DataTables/Expenses/expEntriesUserMain";
// custom components
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import DeleteDialog from "views/Dialogs/_Common/DeleteDialog";
import ManageImgPdfUploadsDialog from "views/Dialogs/Uploads/ManageImgPdfUploadsDialog";
import ReportButton from "components/CustomButtons/ReportButton";
import AddButton from "components/CustomButtons/AddButton";
import SubmitButton from "components/CustomButtons/SubmitButton";
import RejectButton from "components/CustomButtons/RejectButton";
import ConfirmationDialog from "views/Dialogs/_Common/ConfirmationDialog";
import AddEditExpEntryForm from "views/Forms/AddEditExpEntryForm";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import {
  SET_EXP_ENTRIES_USER_DT_PAGE,
  SET_EXP_ENTRIES_USER_DT_TRIG_REFRESH,
  SET_EXP_ENTRIES_USER_DT_ROW,
  SET_EXP_ENTRIES_USER_REFRESH_URL
} from "redux/dispatch_types";

// custom functions
import identifyClick from "global_functions/_common/identifyClick";
import useFetchDocument from "global_functions/_common/useFetchDocument";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import showNotification from "../../../global_functions/redux_action_creators/showNotification";

const apiUrl = process.env.REACT_APP_API_V1_URL;

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

export default function TsEntryUserPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const reduxPageProps = useSelector(state => state.expEntriesUser);

  const [bundleHasEntries, setBundleHasEntries] = useState(false);
  const [bundleStatus, setBundleStatus] = useState("");
  const [bundleName, setBundleName] = useState("");
  const [bundleUser, setBundleUser] = useState("");

  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [manageReceiptsDialog, setManageReceiptsDialog] = useState(false);

  const [deleteName, setDeleteName] = useState(false);
  const [dataTable, setDataTable] = useState("");
  const [editDelId, setEditDelId] = useState(null);

  const [receiptExpID, setReceiptExpID] = useState(0);

  const [reportParams, setReportParams] = useState("");

  const expBundleID = props.match.params.slug;

  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [confirmationParams, setConfirmationParams] = useState(false);

  useEffect(() => {
    // sets refresh url for page on load
    dispatch({
      type: SET_EXP_ENTRIES_USER_REFRESH_URL,
      payload: "expense-bundles/" + expBundleID + "/expenses/"
    });
  }, [expBundleID, dispatch]);

  useEffect(() => {
    // get bundle details
    axios
      .get(
        apiUrl + "expense-bundles/" + expBundleID,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setBundleStatus(res.data.status);
        setBundleName(res.data.name);
        setBundleUser(res.data.user);
        setBundleHasEntries(res.data.has_entries);
      });
  }, [reduxPageProps.tables.expEntriesUserMain.triggerDtRefresh, expBundleID]);

  useDataTableRefresh(dataTable, expEntriesUserMain);

  // handlers
  const handleAddClick = e => {
    e.stopPropagation();
    setEditDelId(0);
    setAddEditModal(true);
  };

  const handleSubmitClick = () => {
    setConfirmationParams([
      "Are you sure you would like to submit this bundle? (Note: to avoid delay in payment, please make sure you enter correct VAT status for each expense item.)",
      "Submit Bundle",
      "Bundle Submitted",
      "Error submitting bundle.",
      2
    ]);
    setConfirmationDialog(true);
  };

  const handleUnsubmitClick = () => {
    setConfirmationParams([
      "Are you sure you would like to unsubmit this bundle?",
      "Unsubmit Bundle",
      "Bundle Unsubmitted",
      "Error unsubmitting bundle.",
      1
    ]);
    setConfirmationDialog(true);
  };

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_EXP_ENTRIES_USER_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_EXP_ENTRIES_USER_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
          if (clickRelevantType.params[0].substring(0, 6) === "Filter") {
            setReceiptExpID(
              dataTable.rows(clickRelevantType.params[1]).data()[0].id
            );
            setManageReceiptsDialog(true);
          } else if (clickRelevantType.params[0] === "Edit") {
            dataTable.rows().deselect();
            setEditDelId(dtData.id);
            setAddEditModal(true);
          } else if (clickRelevantType.params[0] === "Delete") {
            dataTable.rows().deselect();
            setEditDelId(dtData.id);
            setDeleteName(
              `ID ${dtData.id} (vendor: ${dtData.vendor}, amount: ${dtData.amount})`
            );
            setDeleteModal(true);
          } else if (clickRelevantType.params[0] === "FileCopy") {
            dataTable.rows().deselect();
            axios
              .post(
                `${apiUrl}${reduxPageProps.tables.expEntriesUserMain.refreshURL}`,
                { id: dtData.id, duplicate: true },
                getTokenConfig(store.getState)
              )
              .then(res => {
                dispatch(showNotification(res.data.statusText, "success"));
                dispatch({
                  type: SET_EXP_ENTRIES_USER_DT_TRIG_REFRESH,
                  payload: res.data.id
                });
                setEditDelId(res.data.id);
                setAddEditModal(true);
              })
              .catch(err => {
                let errMsg;
                if ("errors" in err.response.data)
                  errMsg = err.response.data.errors[0];
                else errMsg = "Error duplicating";

                dispatch(showNotification(errMsg, "error"));
              });
          }
          break;
        default:
          return false;
      }
    }
  };

  const handleReportClick = e => {
    e.stopPropagation();
    setReportParams({
      url: `${apiUrl}documents/?dataset=expenses_report&exp_bundle_id=${expBundleID}`,
      fileName: `Expenses report for ${bundleUser} (bundle '${bundleName}').pdf`,
      fetchMsg: "Fetching expenses' report..."
    });
  };

  // add event listener for datatable clicks
  useEventListener("click", handleClickEvent, window);

  useFetchDocument(reportParams);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>{bundleName}</h4>
                <h4 className={classes.cardCategoryWhite}>
                  Status: {bundleStatus}
                </h4>
              </CardText>
              <GridContainer>
                <GridItem xs={12}>
                  <BackButton />
                  {(bundleStatus === "Open" || bundleStatus === "Rejected") && (
                    <SubmitButton
                      handleSubmit={handleSubmitClick}
                      greyedOut={!bundleHasEntries}
                    />
                  )}

                  {bundleStatus === "Submitted" && (
                    <RejectButton
                      onClick={handleUnsubmitClick}
                      tooltip="Unsubmit expenses bundle"
                    />
                  )}
                  <ReportButton
                    tooltip="Generate expenses report"
                    handleReportClick={handleReportClick}
                    greyedOut={!bundleHasEntries}
                  />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setDataTable}
                tableParams={expEntriesUserMain}
              />
            </CardBody>
            {(bundleStatus === "Open" || bundleStatus === "Rejected") && (
              <CardFooter>
                <div className={classes.cardContentRight}>
                  <AddButton
                    handleAddClick={handleAddClick}
                    tooltip="Add Expense Entry"
                  />
                </div>
              </CardFooter>
            )}
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelId}
          dataTable={dataTable}
          detailURLSuffix={reduxPageProps.tables.expEntriesUserMain.refreshURL}
          tableRefreshType={SET_EXP_ENTRIES_USER_DT_TRIG_REFRESH}
          filterInForm="filter_active_only"
          editTitle="Edit Expense Entry"
          addTitle="Add Expense Entry"
          choiceFieldForm="exp_entry_form"
        >
          <AddEditExpEntryForm />
        </AddEditDtEntryDialog>
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="your expense bundle"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={editDelId}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_EXP_ENTRIES_USER_DT_TRIG_REFRESH}
          urlSuffix={reduxPageProps.tables.expEntriesUserMain.refreshURL}
        />
        <ManageImgPdfUploadsDialog
          manageUploadsDialog={manageReceiptsDialog}
          setManageUploadsDialog={setManageReceiptsDialog}
          refreshUrl={reduxPageProps.tables.expEntriesUserMain.refreshURL}
          tableRefreshType={SET_EXP_ENTRIES_USER_DT_TRIG_REFRESH}
          parentId={receiptExpID}
          showControls={bundleStatus === "Open" || bundleStatus === "Rejected"}
          title="Manage Receipts"
        />
        <ConfirmationDialog
          confirmationDialog={confirmationDialog}
          setConfirmationDialog={setConfirmationDialog}
          confirmationMessage={confirmationParams[0]}
          confirmationTitle={confirmationParams[1]}
          confirmURL={`expense-bundles/${expBundleID}`}
          confirmPayload={{ new_status: confirmationParams[4] }}
          successMsg={confirmationParams[2]}
          errorMsg={confirmationParams[3]}
          tableRefreshType={SET_EXP_ENTRIES_USER_DT_TRIG_REFRESH}
          addMessage={true}
        />
      </GridContainer>
    </div>
  );
}
