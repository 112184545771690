import {
  SET_USER_BOOKINGS_DB_DT_PAGE,
  SET_USER_BOOKINGS_DB_DT_ROW,
  SET_USER_BOOKINGS_REFRESH_URL
} from "../../dispatch_types";

const initialState = {
  tables: {
    bookingsUserMain: {
      selectedRow: null,
      viewedPage: 0,
      refreshURL: "",
      triggerDtRefresh: {}
    }
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_USER_BOOKINGS_DB_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          bookingsUserMain: {
            ...state.tables.bookingsUserMain,
            selectedRow: action.payload
          }
        }
      };
    case SET_USER_BOOKINGS_DB_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          bookingsUserMain: {
            ...state.tables.bookingsUserMain,
            viewedPage: action.payload
          }
        }
      };
    case SET_USER_BOOKINGS_REFRESH_URL:
      return {
        ...state,
        tables: {
          ...state.tables,
          bookingsUserMain: {
            ...state.tables.bookingsUserMain,
            refreshURL: action.payload
          }
        }
      };
    default:
      return state;
  }
}
