import React, { useEffect, useState } from "react";
import axios from "axios";
// redux
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import { useSelector } from "react-redux";
// custom components
import { WidgetTemplate } from "../WidgetTemplate";
// icons
import AccountBalance from "@material-ui/icons/AccountBalance";
// types
import { RootStateType } from "redux/reducers";

const ExpensesWidgetUser: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState({
    unapproved_expenses: 0
  });

  const currentUserId = useSelector((state: RootStateType) =>
    state.auth.user ? state.auth.user.id : null
  );

  const apiUrl = process.env.REACT_APP_API_V1_URL;

  // initial load
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${apiUrl}datasets/?type=expenses_widget_user&user_id=${currentUserId}`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setCardData(res.data);
        setIsLoading(false);
      });
  }, [apiUrl, currentUserId]);

  return (
    <WidgetTemplate
      bottomText="Expenses Section"
      bottomUrl="/#/user/my-expenses/"
      icon={<AccountBalance />}
      iconColor="success"
      isLoading={isLoading}
      statsText=" pending"
      title="My Expenses"
      value={cardData.unapproved_expenses}
    />
  );
};

export default ExpensesWidgetUser;
