import React, { ReactElement } from "react";
// redux
import { useSelector } from "react-redux";
import { RootStateType } from "redux/reducers";
// template
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
// custom
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import { salesPipelineMain } from "views/DataTables/SalesPipeline/salesPipelineMain";
// material-ui
import { List, ListItem } from "@material-ui/core";
// styles
import { boxHeight } from "../TopStats";
import { useStatsStyles } from "../TopStats";

const MostPopularServices: React.FC<MostPopularServices> = ({
  statsData,
  statsLoading
}) => {
  const classes = useStatsStyles();

  const TAB_VALUES = salesPipelineMain.filterTabValues;

  const reduxPageFilter = useSelector(
    (state: RootStateType) =>
      state.salesPipeline.tables.salesPipelineMain.pageFilter
  );

  const pageFilter =
    TAB_VALUES && reduxPageFilter ? TAB_VALUES[reduxPageFilter] : 0;
  const getStatItems: GetStatItemsFunction = (statKey, title) => {
    return (
      <>
        <h6 style={{ textAlign: "center" }}>Most Popular Services</h6>
        <h6 style={{ fontWeight: "normal", textAlign: "center" }}>{title}</h6>
        <List className={classes.list}>
          {Object.keys(statsData.most_popular_services[statKey]).map(
            (serviceKey, index) => {
              return (
                <ListItem
                  className={classes.listItem}
                  key={`${statKey}-${index}`}
                >
                  {`${index +
                    1}. ${serviceKey} - £${statsData.most_popular_services[
                    statKey
                  ][serviceKey]
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                </ListItem>
              );
            }
          )}
        </List>
      </>
    );
  };

  return (
    <GridItem xs={8} sm={8} md={4}>
      <GridContainer
        className={classes.stats}
        // @ts-ignore
        style={{ fontSize: "15px", minHeight: boxHeight }}
      >
        <GridItem xs={12}>
          {!statsLoading &&
          statsData &&
          "most_popular_services" in statsData ? (
            pageFilter === 0 ? (
              getStatItems("all_unarchived", "All Active Quotes")
            ) : pageFilter === 2 ? (
              getStatItems("quote_sent", "Quote Sent Status")
            ) : pageFilter === 3 ? (
              getStatItems("po_received", "PO Received Status")
            ) : pageFilter === 6 ? (
              getStatItems("declined", "Declined Quotes")
            ) : pageFilter === 7 ? (
              getStatItems("archived", "Archived Quotes")
            ) : (
              <p>Invalid Filter</p>
            )
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: boxHeight
              }}
            >
              <CustomLoadingSpinner />
            </div>
          )}
        </GridItem>
      </GridContainer>
    </GridItem>
  );
};

export default MostPopularServices;

export type StatsType = {
  pageFilter: number;
  classes: any;
  statsData: any;
  loading: boolean;
};

type GetStatItemsFunction = (statKey: string, title: string) => ReactElement;

interface MostPopularServices {
  statsData: any;
  statsLoading: boolean;
}
