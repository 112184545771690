import { createTheme } from "@material-ui/core/styles";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor[2]
    }
  }
});
