import React from "react";

import { useDispatch } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Assessment from "@material-ui/icons/Assessment";

import showNotification from "global_functions/redux_action_creators/showNotification";

const useStyles = makeStyles(theme => ({
  completionButton: {
    backgroundColor: "#26c6da",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#90e1ec" },
    marginTop: 10,
    marginRight: 10
  },
  completionButtonGreyed: {
    backgroundColor: "#bdbdbd",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#dedede" },
    marginTop: 10,
    marginRight: 10
  }
}));

export default function CompletionButton({ handleCompletionClick, greyedOut }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  return (
    <>
      {!greyedOut ? (
        <Tooltip title="View completion">
          <IconButton
            className={classes.completionButton}
            onClick={handleCompletionClick}
          >
            <Assessment style={{ color: "#f9f9f9" }} />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <Tooltip title="View completion">
            <IconButton
              className={classes.completionButtonGreyed}
              onClick={() => dispatch(showNotification("Empty bundle", "info"))}
            >
              <Assessment style={{ color: "#f9f9f9" }} />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
}
