import React, { useEffect, useState } from "react";
import axios from "axios";
// redux
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
// template
import GridItem from "components/Grid/GridItem";
import CustomSelect from "components/CustomSelect/CustomSelect";
import GridContainer from "components/Grid/GridContainer";
// material ui
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// custom components
import ProjectSelect from "components/_Custom/_Common/ProjectSelect";
// styles
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// styles
const useFormStyles = makeStyles(formsStyles);
const apiUrl = process.env.REACT_APP_API_V1_URL;

const NewContainerOptions = ({
  projId,
  setProjId,
  destinationContainerId,
  setDestinationContainerId,
  containerActionType,
  setContainerActionType,
}) => {
  const [clientId, setClientId] = useState(null);
  const [clientList, setClientList] = useState([]);

  const [clientListLoading, setClientListLoading] = useState(false);
  const [loadingProjectContainers, setLoadingProjectContainers] = useState(
    false
  );

  const [containerData, setContainerData] = useState([]);

  const lhsFieldWidth = 2;
  const rhsFieldWidth = 7;

  const formClasses = useFormStyles();

  // runs once to load project filter in modal
  useEffect(() => {
    setClientListLoading(true);

    axios
      .get(
        apiUrl + "clients/?data_format=filter_active_only",
        getTokenConfig(store.getState)
      )
      .then(res => setClientList(res.data))
      .then(() => {
        setClientListLoading(false);
      });

    // on unmount, set project id to zero (it is stored in parent so does not get reset when navigating with checkboxes
    return () => {
      setProjId(null);
    };
  }, [setProjId]);

  // when project changes, loads available containers (when null reset containers to nothing)
  useEffect(() => {
    if (projId) {
      // load available containers
      setLoadingProjectContainers(true);
      axios
        .get(
          `${apiUrl}projects/${projId}/containers/?filter=7`,
          getTokenConfig(store.getState)
        )
        .then(res => {
          let optionsArray = res.data;
          setContainerData(optionsArray);
        })
        .then(() => setLoadingProjectContainers(false));
    } else {
      setContainerData([]);
    }
  }, [projId]);

  // resetting project id whenever client changes (limitation with the ProjectSelect component)
  useEffect(() => {
    setProjId(null);
  }, [clientId, setProjId]);

  return (
    <>
      <ContainerActionSwitcher
        containerActionType={containerActionType}
        setContainerActionType={setContainerActionType}
      />
      {containerActionType === 0 ? (
        <>
          <ProjectSelect
            clientList={clientList}
            formLoading={clientListLoading}
            clientID={clientId}
            setClientID={setClientId}
            statusFilters={[1, 5]}
            projID={projId}
            setProjID={setProjId}
            lhsFieldWidth={lhsFieldWidth}
            rhsFieldWidth={rhsFieldWidth}
          />
          <GridContainer>
            <GridItem xs={lhsFieldWidth}>
              <FormLabel className={formClasses.labelHorizontal} fontSize="1px">
                Containers
              </FormLabel>
            </GridItem>
            <GridItem xs={rhsFieldWidth}>
              {containerData.length ? (
                <CustomSelect
                  options={containerData}
                  isDisabled={loadingProjectContainers}
                  value={destinationContainerId}
                  setValue={setDestinationContainerId}
                  required={true}
                />
              ) : (
                <p style={{ marginTop: "40px" }}>
                  {clientId && projId
                    ? "No available containers for this project"
                    : "Select project to view available containers"}
                </p>
              )}
            </GridItem>
          </GridContainer>
        </>
      ) : (
        <p>Scan item to remove it from container</p>
      )}
    </>
  );
};

export default NewContainerOptions;

const ContainerActionSwitcher = ({
  containerActionType,
  setContainerActionType
}) => {
  const formClasses = useFormStyles();

  return (
    <>
      <hr />
      <GridContainer>
        <GridItem>
          <div
            className={
              formClasses.checkboxAndRadio +
              " " +
              formClasses.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              style={{ float: "left" }}
              control={
                <Radio
                  checked={containerActionType === 0}
                  onChange={e =>
                    setContainerActionType(parseInt(e.target.value))
                  }
                  value={0}
                  name="radio button enabled"
                  aria-label="A"
                  icon={
                    <FiberManualRecord className={formClasses.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={formClasses.radioChecked} />
                  }
                  classes={{
                    checked: formClasses.radio,
                    root: formClasses.radioRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Scan To Container"
            />
            <FormControlLabel
              style={{ float: "left" }}
              control={
                <Radio
                  checked={containerActionType === 1}
                  onChange={e =>
                    setContainerActionType(parseInt(e.target.value))
                  }
                  value={1}
                  name="radio button enabled"
                  aria-label="B"
                  icon={
                    <FiberManualRecord className={formClasses.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={formClasses.radioChecked} />
                  }
                  classes={{
                    checked: formClasses.radio,
                    root: formClasses.radioRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Remove From Container"
            />
          </div>
        </GridItem>
      </GridContainer>
      <hr />
    </>
  );
};
