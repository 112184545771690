import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, auth, location, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isLoading || auth.isAuthenticated == null) {
          return <CustomLoadingSpinner positionFixed={true} />;
        } else if (!auth.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: "/auth/login-page",
                state: { originalUrl: location.pathname }
              }}
            />
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
