import React, { useEffect, useState } from "react";

import axios from "axios";
// redux
import store from "redux/store";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "redux/reducers";
import showNotification from "global_functions/redux_action_creators/showNotification";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// custom
import SalesPipelineChart from "./components/SalesPipelineChart";
import MostPopularServices from "./components/MostPopularServices";
import QuoteStats from "./components/QuoteStats";
// template
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
// material-ui
import TrendingUp from "@material-ui/icons/TrendingUp";
import { makeStyles } from "@material-ui/core/styles";

// styles
import {
  cardTitle,
  defaultFont,
  grayColor,
  whiteColor
} from "assets/jss/material-dashboard-pro-react";
import GridContainer from "../../../../Grid/GridContainer";
export const boxHeight = 270;
export const topStatsStyles = {
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    // marginRight: "-15px",
    marginTop: "7px",
    marginLeft: "7px",
    paddingLeft: "0",
    listStyle: "none",
    color: whiteColor,
    // @ts-ignore
    height: boxHeight,
    paddingTop: "0",
    paddingBottom: "0",
    // @ts-ignore
    maxHeight: boxHeight,
    overflow: "auto"
  },
  listItem: {
    // textAlign: "center",
    float: "left",
    position: "relative",
    display: "block",
    // width: "auto",
    margin: "0",
    padding: "0",
    color: "#999"
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex"
    // "& svg": {
    //   position: "relative",
    //   top: "4px",
    //   width: "16px",
    //   height: "16px",
    //   marginRight: "3px"
    // },
    // "& .fab,& .fas,& .far,& .fal,& .material-icons": {
    //   position: "relative",
    //   top: "4px",
    //   fontSize: "16px",
    //   marginRight: "3px"
    // }
  }
};

const apiUrl = process.env.REACT_APP_API_V1_URL;

// @ts-ignore
export const useStatsStyles = makeStyles(topStatsStyles);

const TopStats = () => {
  const classes = useStatsStyles();

  const [chartLoading, setChartLoading] = useState(false);
  const [statsLoading, setStatsLoading] = useState(false);

  const [rawChartData, setRawChartData] = useState({});
  const [statsData, setStatsData] = useState({});

  const dispatch = useDispatch();

  const statsYear = useSelector(
    (state: RootStateType) => state.salesPipeline.stats.year
  );

  const triggerDtRefresh = useSelector(
    (state: RootStateType) =>
      state.salesPipeline.tables.salesPipelineMain.triggerDtRefresh
  );

  // refresh stats every load and whenever refresh is triggered for the main table
  useEffect(() => {
    setStatsLoading(true);
    axios
      .get(
        `${apiUrl}datasets/?type=quotes_page_dataset&year=${statsYear}`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setStatsData(res.data);
        setStatsLoading(false);
      })
      .catch(err => {
        let errorMsg;
        err.response.data.errors
          ? (errorMsg = err.response.data.errors)
          : (errorMsg = "Error loading stats");
        dispatch(showNotification(errorMsg, "error"));
        setStatsLoading(false);
      });
  }, [triggerDtRefresh, statsYear, dispatch]);

  // refresh chart every load and every time datatable is refreshed
  useEffect(() => {
    setChartLoading(true);
    const fetchUrl = `${apiUrl}datasets/?type=sales_pipeline_chart&year=${statsYear}`;
    axios
      .get(fetchUrl, getTokenConfig(store.getState))
      .then(res => {
        setRawChartData(res.data.chart_data);
        setChartLoading(false);
      })
      .catch(err => {
        let errorMsg;
        err.response.data.errors
          ? (errorMsg = err.response.data.errors)
          : (errorMsg = "Error loading chart");
        dispatch(showNotification(errorMsg, "error"));
        setChartLoading(false);
      });
  }, [triggerDtRefresh, statsYear, dispatch]);

  return (
    <>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" stats icon>
            <CardIcon color="info">
              <TrendingUp />
            </CardIcon>
            {/*<h3 className={classes.cardTitle}>Hello</h3>*/}
            <p className={classes.cardCategory}>Sales Stats</p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <SalesPipelineChart
                rawChartData={rawChartData}
                chartLoading={chartLoading}
              />
              <MostPopularServices
                statsData={statsData}
                statsLoading={statsLoading}
              />
              <QuoteStats statsData={statsData} statsLoading={statsLoading} />
            </GridContainer>
          </CardBody>
          {"received_pos_without_files" in statsData &&
          // @ts-ignore
          statsData.received_pos_without_files > 0 ? (
            <CardFooter stats>
              <div className={classes.stats}>
                <p style={{ color: "red" }}>
                  {/*@ts-ignore*/}
                  Warning: {statsData.received_pos_without_files}{" "}
                  {/*@ts-ignore*/}
                  {statsData.received_pos_without_files > 1
                    ? "quotes currently have "
                    : "quote currently has "}
                  the status of &apos;PO Received&apos; but no associated
                  uploads.
                </p>
              </div>
            </CardFooter>
          ) : (
            <></>
          )}
        </Card>
      </GridItem>
    </>
  );
};

export default TopStats;
