import React, { useState, useEffect, Dispatch } from "react";

import axios from "axios";
// redux
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// template
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
// material ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";

// @ts-ignore
const useStyles = makeStyles(styles);
const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="down" ref={ref} {...props} />;
});

const TrainingInfoModal: React.FC<{
  open: boolean;
  setOpen: Dispatch<boolean>;
  userId: number | null;
}> = ({ open, setOpen, userId }) => {
  const classes = useStyles();

  const [loadingInProgress, setLoadingInProgress] = useState(false);

  const [name, setName] = useState("");
  const [posName, setPosName] = useState("");
  const [reqCerts, setReqCerts] = useState([]);
  const [userCertInfo, setUserCertInfo] = useState({
    missing_cert_type_list: [],
    req_and_present_cert_type_list: [],
    additional_cert_type_list: []
  });
  const [expiredCerts, setExpiredCerts] = useState([]);
  const [certsNoPhotos, setCertsNoPhotos] = useState(0);

  useEffect(() => {
    if (open) {
      setLoadingInProgress(true);
      axios
        .get(
          `${apiUrl}users/${userId}/certificates/?show_summary=1`,
          getTokenConfig(store.getState)
        )
        .then(res => {
          setName(res.data.name);
          setPosName(res.data.position_name);
          setReqCerts(res.data.req_certs);
          setUserCertInfo(res.data.user_cert_info);
          setCertsNoPhotos(res.data.certificates_without_photos);
          setExpiredCerts(res.data.expired_certs);
        })
        .then(() => setLoadingInProgress(false));
    }
  }, [open, userId]);

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        fullWidth={true}
        open={open}
        // @ts-ignore
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          {/*@ts-ignore*/}
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setOpen(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{name}: Training Summary</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {loadingInProgress ? (
            <CustomLoadingSpinner />
          ) : (
            <>
              {reqCerts.length ? (
                <p>
                  <h6>Job title: {posName}</h6>
                  {/*Required certificate list*/}
                  <p style={{ textAlign: "left", fontWeight: "bold" }}>
                    Required:
                  </p>
                  <ul style={{ textAlign: "left" }}>
                    {reqCerts.map((cert, index) => {
                      return (
                        // @ts-ignore
                        <li key={`req-list-item-${index}`}>{cert.name}</li>
                      );
                    })}
                  </ul>
                  {/*Missing certificate list*/}
                  <p style={{ textAlign: "left", fontWeight: "bold" }}>
                    Missing:
                  </p>
                  {userCertInfo.missing_cert_type_list.length ? (
                    <ul style={{ textAlign: "left" }}>
                      {userCertInfo.missing_cert_type_list.map(
                        (cert, index) => {
                          return (
                            // @ts-ignore
                            <li key={`req-list-item-${index}`}>{cert.name}</li>
                          );
                        }
                      )}
                    </ul>
                  ) : (
                    <p style={{ textAlign: "left" }}>
                      None
                    </p>
                  )}
                  {/*Expired certificate list*/}
                  <p style={{ textAlign: "left", fontWeight: "bold" }}>
                    Expired:
                  </p>
                  {expiredCerts.length ? (
                    <ul style={{ textAlign: "left" }}>
                      {expiredCerts.map((cert, index) => {
                        return (
                          <li key={`req-list-item-${index}`}>
                            {/*@ts-ignore*/}
                            {`${cert.name} (expired on ${cert.expiry_date})`}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <p style={{ textAlign: "left" }}>
                      None
                    </p>
                  )}
                  {/*Additional certificate list*/}
                  <p style={{ textAlign: "left", fontWeight: "bold" }}>
                    Additional:
                  </p>
                  {userCertInfo.additional_cert_type_list.length ? (
                    <>
                      <ul style={{ textAlign: "left" }}>
                        {userCertInfo.additional_cert_type_list.map(
                          (cert, index) => {
                            return (
                              <li key={`req-list-item-${index}`}>
                                {/*@ts-ignore*/}
                                {cert.name}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </>
                  ) : (
                    <p style={{ textAlign: "left" }}>
                      None
                    </p>
                  )}
                  {certsNoPhotos ? (
                    <>
                      <p style={{ textAlign: "left", fontWeight: "bold" }}>
                        Certificates without scans:
                      </p>
                      <p style={{ textAlign: "left" }}>
                        User has {certsNoPhotos} certificates without attached
                        scans
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </p>
              ) : (
                <p>
                  There are no required certificates set for position {posName}{" "}
                  currently.
                </p>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {/*@ts-ignore*/}
          <Button color="transparent" onClick={() => setOpen(false)} simple>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
};

export default TrainingInfoModal;
