import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// datatables
import TableFilter from "components/_Custom/_Common/TableFilter";
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import { userDBMain } from "../../DataTables/Users/userDBMain";
// core components
import CardFooter from "../../../components/Card/CardFooter";
import AddButton from "../../../components/CustomButtons/AddButton";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

// redux
import {
  SET_USER_DB_DT_PAGE,
  SET_USER_DB_DT_ROW,
  SET_USER_DB_FILTER,
  SET_USER_DB_SHOW_ADD_DIALOG
} from "redux/dispatch_types";

// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import ReportButton from "../../../components/CustomButtons/ReportButton";
import AddUserDialog from "../../Dialogs/Auth/AddUserDialog";
// custom functions
import formatDate from "global_functions/_common/formatDate";
import useEventListener from "global_functions/_common/useEventListener";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import identifyClick from "global_functions/_common/identifyClick";
import useFetchDocument from "../../../global_functions/_common/useFetchDocument";
// types
import { RootStateType } from "../../../redux/reducers";
import { ReportParams } from "../../../global_functions/types/shared";
// styles
import { makeStyles } from "@material-ui/core/styles";
const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

// @ts-ignore
const useStyles = makeStyles(styles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

const UserDbPage: React.FC = () => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const reduxPageProps = useSelector((state: RootStateType) => state.userDB);

  const history = useHistory();

  const [dataTable, setDataTable] = useState<DataTables.Api | undefined>(
    undefined
  );

  const [pdfParams, setPdfParams] = useState<ReportParams>({
    url: "",
    fileName: "",
    fetchMsg: ""
  });

  useDataTableRefresh(dataTable, userDBMain);

  useFetchDocument(pdfParams);

  const handleClickEvent = (e: React.MouseEvent): void => {
    const clickRelevantType = identifyClick(e);

    if (dataTable && clickRelevantType && "type" in clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_USER_DB_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_USER_DB_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          if (
            "params" in clickRelevantType &&
            Array.isArray(clickRelevantType["params"])
          ) {
            dataTable.rows().deselect();
            const dtData = dataTable
              .rows(clickRelevantType.params[1])
              .data()[0];
            history.push(`/databases/user-database/${dtData.id}`);
          }
          break;
        default:
          return;
      }
    }
  };

  const handleReportClick = () => {
    setPdfParams({
      url: `${apiUrl}documents/?dataset=user_permissions_report`,
      fileName: `User Permissions Report, ${formatDate(
        new Date(),
        "dd MM yyyy"
      )}`,
      fetchMsg: "Fetching user report..."
    });
  };

  // add event listener for datatable button clicks
  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>User Database</h4>
                <h4 className={classes.cardCategoryWhite}>Manage Site Users</h4>
              </CardText>
              <GridContainer>
                <GridItem xs={12}>
                  <ReportButton
                    tooltip="Generate user report"
                    handleReportClick={handleReportClick}
                    greyedOut={false}
                  />
                </GridItem>
              </GridContainer>
              {dataTable ? (
                <TableFilter
                  color="primary"
                  activeFilter={reduxPageProps.tables.userDBMain.pageFilter}
                  dispatchType={SET_USER_DB_FILTER}
                  dataTable={dataTable}
                  tabs={userDBMain.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable tableParams={userDBMain} setDataTable={setDataTable} />
            </CardBody>
            <CardFooter>
              <div>
                <AddButton
                  handleAddClick={() =>
                    dispatch({ type: SET_USER_DB_SHOW_ADD_DIALOG })
                  }
                  tooltip="Add User"
                />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <AddUserDialog showModal={reduxPageProps.showAddDialog} />
      </GridContainer>
    </div>
  );
};

export default UserDbPage;
