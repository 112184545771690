import React, { useCallback, useState } from "react";
import axios from "axios";
// redux
import store from "redux/store";
import { useDispatch } from "react-redux";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import showNotification from "global_functions/redux_action_creators/showNotification";
// plugins
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "@material-ui/core/Box";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";

export default function ImagePdfUploadArea(props) {
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);

  const dispatch = useDispatch();

  // file resizer
  const resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        "PNG",
        100,
        0,
        uri => {
          resolve([uri, file.name]);
        },
        "blob"
      );
    });

  // file uploader
  const uploadFile = formData => {
    // set headers
    let config = getTokenConfig(store.getState);
    config.headers["Content-Type"] = "multipart/form-data"; // replace default header

    // show progress bar and 'processing' label
    config.onUploadProgress = progressEvent => {
      setProgressPercent(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );
      if (progressEvent.loaded / progressEvent.total === 1) {
        props.setProcessingMsg(true);
      }
    };

    setUploadInProgress(true);
    axios
      .post(props.uploadUrl, formData, config)
      .then(() => {
        dispatch(showNotification("File uploaded", "success"));
        props.refreshFileList();
        setUploadInProgress(false);
        props.setProcessingMsg(false);
        setProgressPercent(0);
      })
      .catch(err => {
        const errRespObj = err.response.data;
        Object.keys(errRespObj).forEach(key => {
          errRespObj[key].forEach(errorMsg => {
            dispatch(showNotification(errorMsg, "error"));
          });
        });
        setUploadInProgress(false);
        props.setProcessingMsg(false);
        setProgressPercent(0);
      });
  };

  // dropzone
  const onDrop = useCallback(
    acceptedFiles => {
      const formData = new FormData();

      if (acceptedFiles[0].size < 25000000) {
        // if pdf, go straight tu plo
        if (acceptedFiles[0].type === "application/pdf") {
          formData.append("new_upload", acceptedFiles[0]);

          uploadFile(formData);
        } else {
          // if image, resize
          props.setProcessingMsg(true);
          resizeFile(acceptedFiles[0]).then(([blob, filename]) => {
            props.setProcessingMsg(false);
            formData.append("new_upload", new File([blob], filename));
            uploadFile(formData);
          });
        }
      } else {
        dispatch(
          showNotification(
            "Unable to upload file as file size is too large and exceeds the maximum of" +
              "25MB",
            "error"
          )
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.uploadUrl]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*, application/pdf"
  });

  return (
    <GridContainer>
      <GridItem xs={12}>
        <br />
        <Box border={1}>
          <br />
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>Drag 'n' drop files here, or click to select</p>
            )}
          </div>
          {uploadInProgress ? (
            <CustomLinearProgress
              variant="determinate"
              color="primary"
              value={progressPercent}
              style={{ marginLeft: "20px", marginRight: "20px" }}
            />
          ) : (
            <br />
          )}
        </Box>
      </GridItem>
    </GridContainer>
  );
}
