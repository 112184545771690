import React, { useEffect, useState } from "react";
import axios from "axios";
// redux & auth
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
// components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
// material ui

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const apiURL = process.env.REACT_APP_API_V1_URL;

const useStyles = makeStyles(styles);

export default function ConflictStats(props) {
  const classes = useStyles();

  const [conflictStats, setConflictStats] = useState("");
  const [statsLoading, setStatsLoading] = useState(false);

  useEffect(() => {
    if (props.showModal) {
      setStatsLoading(true);
      axios
        .get(
          apiURL + "datasets/?type=holiday_conflicts&hols_id=" + props.reqID,
          getTokenConfig(store.getState)
        )
        .then(res => {
          setStatsLoading(false);
          setConflictStats(res.data);
        });
    }
  }, [props.showModal, props.reqID]);

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        fullWidth={true}
        open={props.showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.setShowModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => props.setShowModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            Staff on Holiday During Request Period
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {/* if stats are loading show spinner, if object is empty show message, if object has entries loop through them*/}
          {statsLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "400px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : Object.keys(conflictStats).length ? (
            <GridContainer>
              <GridItem xs={12} sm={4} lg={12}>
                {Object.keys(conflictStats).map(key => {
                  return (
                    <React.Fragment key={`conflict${key}`}>
                      <h6>{key}</h6>
                      <ul>
                        {conflictStats[key].map((item, index) => {
                          let returnStr = `${item[0]} (${item[2]}-${item[3]}) ${
                            item[1]
                          }`;
                          return <li key={`entry${index}`}>{returnStr}</li>;
                        })}
                      </ul>
                    </React.Fragment>
                  );
                })}
              </GridItem>
            </GridContainer>
          ) : (
            <GridContainer>
              <GridItem xs={12} sm={4} lg={12}>
                <h6>No other holidays</h6>
              </GridItem>
            </GridContainer>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="transparent"
            onClick={() => props.setShowModal(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
