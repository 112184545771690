import React from "react";

import { useDispatch } from "react-redux";

import showNotification from "global_functions/redux_action_creators/showNotification";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// color
import { dangerColor } from "assets/jss/material-dashboard-pro-react";

// icon
import Delete from "@material-ui/icons/Delete";

const useStyles = makeStyles(theme => ({
  deleteButton: {
    backgroundColor: dangerColor[0],
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#f9a19a" },
    marginTop: 10,
    marginRight: 10
  },
  deleteButtonGreyed: {
    backgroundColor: "#bdbdbd",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#bdbdbd" },
    marginTop: 10,
    marginRight: 10
  }
}));

export default function DeleteButton({
  handleDeleteClick,
  greyedOut,
  tooltip,
  notSelectedWarning
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  let warningMessage = "";
  notSelectedWarning && (warningMessage = notSelectedWarning);

  return (
    <>
      {!greyedOut ? (
        <Tooltip title={tooltip}>
          <IconButton
            className={classes.deleteButton}
            onClick={handleDeleteClick}
          >
            <Delete style={{ color: "#f9f9f9" }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={tooltip}>
          <IconButton
            className={classes.deleteButtonGreyed}
            onClick={() =>
              dispatch(showNotification(warningMessage, "warning"))
            }
          >
            <Delete style={{ color: "#f9f9f9" }} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
