import React, { useState } from "react";

// redux
import { useDispatch } from "react-redux";
import {
  SET_CERTIFICATE_REQ_DT_PAGE,
  SET_CERTIFICATE_REQ_DT_TRIG_REFRESH,
  SET_CERTIFICATE_REQ_DT_ROW,
} from "redux/dispatch_types";
// custom functions
import useEventListener from "global_functions/_common/useEventListener";
import identifyClick from "global_functions/_common/identifyClick";
// template components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
// custom components
import BackButton from "../../../../components/CustomButtons/BackButton";
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import AddEditCertificateRequirementForm from "../../../Forms/AddEditCertificateRequirementForm";
// datatable
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import { certificateReqsMain } from "views/DataTables/HRHS/Certificates/certificateReqsMain";
// styles
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from "@material-ui/core/styles";
const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardContentRight: {
    padding: "15px 20px 15px 0px",
    position: "relative"
  }
};

// @ts-ignore
const useStyles = makeStyles(styles);

const CertificateTypesPage: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [addEditModal, setAddEditModal] = useState(false);
  const [editDelId, setEditDelId] = useState(null);

  const [dataTable, setDataTable] = useState<DataTables.Api | undefined>(
    undefined
  );

  const handleClickEvent = (e: React.MouseEvent): void => {
    const clickRelevantType = identifyClick(e);

    if (dataTable && clickRelevantType && "type" in clickRelevantType) {
      let selectedRow;

      switch (clickRelevantType.type) {
        case "dtButton":
          if (
            "params" in clickRelevantType &&
            Array.isArray(clickRelevantType["params"])
          ) {
            const dtData = dataTable
              .rows(clickRelevantType.params[1])
              .data()[0];
            const iconName = clickRelevantType.params[0];

            if (iconName === "Edit") {
              dataTable.rows().deselect();
              setEditDelId(dtData.id);
              setAddEditModal(true);
            }
          }
          break;
        case "cellClick":
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_CERTIFICATE_REQ_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_CERTIFICATE_REQ_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        default:
          return;
      }
    }
  };

  useEventListener("click", handleClickEvent, window);

  useDataTableRefresh(dataTable, certificateReqsMain);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>
                  Certificate Requirements
                </h4>
                <h4 className={classes.cardCategoryWhite}>
                  Manage training requirements for each position
                </h4>
              </CardText>
              <GridContainer>
                <GridItem xs={12}>
                  <BackButton />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <Datatable
                tableParams={certificateReqsMain}
                setDataTable={setDataTable}
              />
            </CardBody>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelId}
          choiceFieldForm="add_edit_cert_req_type_form"
          tableRefreshType={SET_CERTIFICATE_REQ_DT_TRIG_REFRESH}
          urlSuffix="departments/0/positions/"
          editTitle="Edit Certificate Requirements"
          addTitle=""
        >
          {/*@ts-ignore*/}
          <AddEditCertificateRequirementForm />
        </AddEditDtEntryDialog>
      </GridContainer>
    </div>
  );
};

export default CertificateTypesPage;
