import { SHOW_NOTIFICATION } from "redux/dispatch_types";

const showNotification = (message, type) => dispatch => {
  dispatch({
    type: SHOW_NOTIFICATION,
    payload: {
      message: message,
      notifyType: type
    }
  });
};

export default showNotification;
