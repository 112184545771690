import {
  SET_RES_PLANNER_ALL_HRS_VIEW,
  SET_RES_PLANNER_INC_TS_VIEW
} from "../../dispatch_types";

const initialState = {
  allHrsView: false,
  incTsView: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_RES_PLANNER_ALL_HRS_VIEW:
      return {
        ...state,
        allHrsView: action.payload
      };
    case SET_RES_PLANNER_INC_TS_VIEW:
      return {
        ...state,
        incTsView: action.payload
      };

    default:
      return state;
  }
}
