import {
  SET_CERTIFICATE_TYPE_DT_PAGE,
  SET_CERTIFICATE_TYPE_DT_TRIG_REFRESH,
  SET_CERTIFICATE_TYPE_DT_ROW
} from "redux/dispatch_types";

const initialState = {
  tables: {
    certificateTypesMain: {
      selectedRow: null,
      triggerDtRefresh: {},
      viewedPage: 0
    }
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CERTIFICATE_TYPE_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          certificateTypesMain: {
            ...state.tables.certificateTypesMain,
            selectedRow: action.payload
          }
        }
      };
    case SET_CERTIFICATE_TYPE_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          certificateTypesMain: {
            ...state.tables.certificateTypesMain,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_CERTIFICATE_TYPE_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          certificateTypesMain: {
            ...state.tables.certificateTypesMain,
            viewedPage: action.payload
          }
        }
      };
    default:
      return state;
  }
}
