import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

export const logEntryMain = {
  section: "logEntries",
  refreshURL: "log-entries/",
  reduxTableName: "logEntryMain",
  tableParams: {
    autoWidth: true,
    responsive: true,
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    columns: [
      {
        data: { _: "entry_date.display", sort: "entry_date.@data-order" },
        title: "Action Date",
        className: "dt-body-center",
        width: 200
      },
      { data: "user", title: "User", className: "dt-body-center", width: 200 },
      { data: "info", title: "info", className: "dt-body-center", width: 600 },
      {
        data: "database",
        title: "Database",
        className: "dt-body-center",
        width: 200
      }
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[0, "desc"]]
  }
};
