import React, { useState } from "react";
// redux
import {
  SET_VEHICLE_TRACKING_DT_TRIG_REFRESH,
  SET_VEHICLE_TRACKING_DT_ROW,
  SET_VEHICLE_TRACKING_DT_PAGE,
  SET_VEHICLE_TRACKING_DT_FILTER
} from "redux/dispatch_types";
import { useDispatch, useSelector } from "react-redux";
// custom functions & hooks
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import useEventListener from "global_functions/_common/useEventListener";
import identifyClick from "global_functions/_common/identifyClick";
// custom components
import AddButton from "../../../components/CustomButtons/AddButton";
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import DeleteDialog from "views/Dialogs/_Common/DeleteDialog";
import AddEditVehicleForm from "../../Forms/AddEditVehicleForm";
import TableFilter from "../../../components/_Custom/_Common/TableFilter";
import CustomLoadingSpinner from "../../../components/_Custom/_Common/CustomLoadingSpinner";
// table
import { vehicleTrackingMain } from "views/DataTables/Inventory/vehicleTrackingMain";
// template
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
// types
import { RootStateType } from "../../../redux/reducers";
// styles
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardContentRight: {
    padding: "15px 20px 15px 0px",
    position: "relative"
  }
};
// @ts-ignore
const useStyles = makeStyles(styles);

const VehicleTrackingPage: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [dataTable, setDataTable] = useState<DataTables.Api | undefined>(
    undefined
  );
  const [editDelId, setEditDelId] = useState(0);

  useDataTableRefresh(dataTable, vehicleTrackingMain);

  const viewedFilter = useSelector(
    (state: RootStateType) =>
      state.vehicleTracking.tables.vehicleTrackingMain.pageFilter
  );

  // handlers
  const handleAddClick = () => {
    setEditDelId(0);
    setAddEditModal(true);
  };

  const handleClickEvent = (e: React.MouseEvent): void => {
    const clickRelevantType = identifyClick(e);

    if (dataTable && clickRelevantType && "type" in clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_VEHICLE_TRACKING_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_VEHICLE_TRACKING_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          if (
            "params" in clickRelevantType &&
            Array.isArray(clickRelevantType["params"])
          ) {
            const dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
            if (clickRelevantType.params[0] === "Edit") {
              setEditDelId(dtData.id);
              setAddEditModal(true);
              dataTable.rows().deselect();
            } else if (clickRelevantType.params[0] === "Delete") {
              setEditDelId(dtData.id);
              setDeleteName(
                `${dtData.make_model} (reg. no ${dtData.reg_no}, id ${dtData.id})`
              );
              setDeleteModal(true);
              dataTable.rows().deselect();
            }
          }
          break;
        default:
          return;
      }
    }
  };

  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Vehicle Tracking</h4>
                <h4 className={classes.cardCategoryWhite}>
                  Track Company Vehicles
                </h4>
              </CardText>
              {dataTable && vehicleTrackingMain.filterTabNames ? (
                <TableFilter
                  color="primary"
                  activeFilter={viewedFilter ? viewedFilter : 0}
                  dispatchType={SET_VEHICLE_TRACKING_DT_FILTER}
                  dataTable={dataTable}
                  tabs={vehicleTrackingMain.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                tableParams={vehicleTrackingMain}
                setDataTable={setDataTable}
              />
            </CardBody>
            <CardFooter>
              <div className={classes.cardContentRight}>
                <AddButton
                  handleAddClick={handleAddClick}
                  tooltip="Add Client Entry"
                />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelId}
          tableRefreshType={SET_VEHICLE_TRACKING_DT_TRIG_REFRESH}
          urlSuffix="vehicles/"
          filterInForm={false}
          choiceFieldForm="vehicle_tracking_form"
          editTitle="Edit Vehicle"
          addTitle="Add a Vehicle"
        >
          <AddEditVehicleForm />
        </AddEditDtEntryDialog>
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="the vehicle database"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={editDelId}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_VEHICLE_TRACKING_DT_TRIG_REFRESH}
          urlSuffix={"vehicles/"}
        />
      </GridContainer>
    </div>
  );
};

export default VehicleTrackingPage;
