import DashboardPage from "views/Pages/DashboardPage";
import ClientDBPage from "views/Pages/Databases/ClientDBPage";
import CertificatesAllUsersPage from "views/Pages/HRHS/Certificates/CertificatesAllUsersPage";
import CertificateOneUserPage from "views/Pages/HRHS/Certificates/CertificatesOneUserPage";
import CertificateTypesPage from "views/Pages/HRHS/Certificates/CertificateTypesPage";
import ProjectDBPage from "views/Pages/Databases/ProjectDBPage";
import UserDbPage from "views/Pages/Databases/UserDbPage";
import UserDBDetailPage from "views/Pages/Databases/UserDBDetailPage";
import TsBundleUserPage from "views/Pages/Timesheets/TsBundleUserPage";
import TsEntryUserPage from "views/Pages/Timesheets/TsEntryUserPage";
import TsBundleAdminPage from "views/Pages/Timesheets/TsBundleAdminPage";
import TsEntryAdminPage from "views/Pages/Timesheets/TsEntryAdminPage";
import ExpBundleUserPage from "views/Pages/Expenses/ExpBundlesUserPage";
import ExpEntriesUserPage from "views/Pages/Expenses/ExpEntriesUserPage";
import ExpBundlesAdminPage from "views/Pages/Expenses/ExpBundlesAdminPage";
import ExpEntriesAdminPage from "views/Pages/Expenses/ExpEntriesAdminPage";
import HolidayAllowancesPage from "./views/Pages/Holidays/HolidayAllowancesPage";
import HolsUserPage from "views/Pages/Holidays/HolsUserPage";
import HolsAdminPage from "views/Pages/Holidays/HolsAdminPage";
import ResourcePlannerPage from "views/Pages/Bookings/ResourcePlannerPage";
import SalesPipelinePage from "views/Pages/SalesPipeline/SalesPipelinePage";
import ReportsPage from "views/Pages/ReportsPage";
import BookingsUserPage from "views/Pages/Bookings/BookingsUserPage";
import LoginPage from "views/Pages/Auth/LoginPage";
import RegisterPage from "views/Pages/Auth/RegisterPage";
import PublicHolidaysPage from "views/Pages/Settings/PublicHolidaysPage";
import ProjectsWithSHEQDocsPage from "./views/Pages/HRHS/ProjectsWithSHEQDocsPage";
import SHEQDocsPage from "./views/Pages/HRHS/SHEQDocsPage";
import WarehouseItemContainersPage from "./views/Pages/Warehouse/Containers/WarehouseItemContainersPage";
import WarehouseItemsInContainerPage from "./views/Pages/Warehouse/Containers/WarehouseItemsInContainerPage";
import ProjectsWithWarehouseItemsPage from "views/Pages/Warehouse/Items/ProjectsWithWarehouseItemsPage";
import WarehouseDeliverySchedulePage from "views/Pages/Warehouse/WarehouseDeliverySchedulePage";
import WarehouseItemsPage from "views/Pages/Warehouse/Items/WarehouseItemsPage";
import WarehouseItemScanPage from "./views/Pages/Warehouse/Scan/WarehouseItemScanPage";
import LogsPage from "views/Pages/Settings/LogsPage";
import CompanyServicesPage from "./views/Pages/SalesPipeline/CompanyServicesPage";
import QuoteBreakdownPage from "./views/Pages/SalesPipeline/QuoteBreakdownPage";
import VehicleTrackingPage from "./views/Pages/Inventory/VehicleTrackingPage";
import ProcurementRequestPage from "./views/Pages/ProcurementRequestPage";
import DepartmentsPage from "./views/Pages/Settings/DepartmentsPage";
import PositionsPage from "./views/Pages/Settings/PositionsPage";
import CertificateRequirementsPage from "./views/Pages/HRHS/Certificates/CertificateRequirementsPage";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Settings from "@material-ui/icons/Settings";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import TableChart from "@material-ui/icons/TableChart";
import Image from "@material-ui/icons/Image";
import Widgets from "@material-ui/icons/Widgets";
import Business from "@material-ui/icons/Business";

const dashRoutes = [
  // Hidden custom links for detail sub-pages (not on sidebar): sidebar structure not followed
  {
    path: "/hrhs-certificate-requirements",
    name: "Certificate Requirements",
    mini: "CR",
    component: CertificateRequirementsPage,
    layout: "/admin",
    invisible: true,
    visibleToPermissions: []
  },
  {
    path: "/hrhs-certificate-types",
    name: "Certificate Types",
    mini: "CT",
    component: CertificateTypesPage,
    layout: "/admin",
    invisible: true,
    visibleToPermissions: []
  },
  {
    path: "/user-database/:slug",
    name: "User DB View Single",
    mini: "U",
    component: UserDBDetailPage,
    layout: "/databases",
    invisible: true,
    visibleToPermissions: ["is_user_db_admin"]
  },
  {
    path: "/my-timesheets/:slug",
    name: "User Timesheet Entries",
    mini: "U",
    component: TsEntryUserPage,
    layout: "/user",
    invisible: true,
    visibleToPermissions: ["is_timesheets_user"]
  },
  {
    path: "/expenses/:slug",
    name: "Manage Expenses",
    mini: "U",
    component: ExpEntriesAdminPage,
    layout: "/admin",
    invisible: true,
    visibleToPermissions: ["is_expenses_admin"]
  },
  {
    path: "/my-expenses/:slug",
    name: "User Expenses Entries",
    mini: "U",
    component: ExpEntriesUserPage,
    layout: "/user",
    invisible: true,
    visibleToPermissions: ["is_expenses_user"]
  },
  {
    path: "/timesheets/:slug",
    name: "User Timesheet Entries",
    mini: "U",
    component: TsEntryAdminPage,
    layout: "/admin",
    invisible: true,
    visibleToPermissions: ["is_timesheets_user"]
  },
  {
    path: "/departments/:slug",
    name: "Positions",
    mini: "P",
    component: PositionsPage,
    layout: "/admin",
    invisible: true,
    visibleToPermissions: []
  },
  {
    path:
      "/projects-with-warehouse-items/:proj_id/containers/:container_id/items",
    name: "Item Containers",
    mini: "IC",
    component: WarehouseItemsInContainerPage,
    layout: "/admin",
    invisible: true,
    visibleToPermissions: ["is_warehouse_admin"]
  },
  {
    path: "/projects-with-warehouse-items/:slug/containers",
    name: "Item Containers",
    mini: "IC",
    component: WarehouseItemContainersPage,
    layout: "/admin",
    invisible: true,
    visibleToPermissions: ["is_warehouse_admin"]
  },
  {
    path: "/projects-with-warehouse-items/:slug",
    name: "Warehouse Items",
    mini: "WI",
    component: WarehouseItemsPage,
    layout: "/admin",
    invisible: true,
    visibleToPermissions: ["is_warehouse_admin"]
  },
  {
    path: "/holiday-allowances",
    name: "Holiday Allowances",
    mini: "HA",
    component: HolidayAllowancesPage,
    layout: "/admin",
    invisible: true,
    visibleToPermissions: ["is_holidays_admin"]
  },
  {
    path: "/hrhs-projects-with-sheq-items/:slug",
    name: "SHEQ Documents",
    mini: "SD",
    component: SHEQDocsPage,
    layout: "/admin",
    invisible: true,
    visibleToPermissions: ["is_hrhs_admin", "is_hrhs_user"]
  },
  {
    path: "/hrhs-certificates/:slug",
    name: "User Certificates",
    mini: "UC",
    component: CertificateOneUserPage,
    layout: "/admin",
    invisible: true,
    visibleToPermissions: ["is_hrhs_admin"]
  },
  {
    path: "/sales-pipeline/manage-services",
    name: "Manage Services",
    mini: "MC",
    component: CompanyServicesPage,
    layout: "/admin",
    invisible: true,
    visibleToPermissions: ["is_sales_pipeline_admin"]
  },
  {
    path: "/sales-pipeline/:slug",
    name: "Sales Pipeline Breakdown",
    mini: "SPB",
    component: QuoteBreakdownPage,
    layout: "/admin",
    invisible: true,
    visibleToPermissions: ["is_sales_pipeline_admin"]
  },
  // Standard (sidebar) links
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: DashboardPage,
    layout: "/admin",
    visibleToPermissions: null
  },
  {
    collapse: true,
    name: "Admin Tools",
    icon: SupervisorAccount,
    state: "adminToolsCollapse",
    visibleToPermissions: [
      "is_expenses_admin",
      "is_holidays_admin",
      "is_sales_pipeline_admin",
      "is_reports_user",
      "is_bookings_admin",
      "is_timesheets_admin",
      "is_vehicle_tracking_admin",
      "is_procurement_admin",
      "is_procurement_user"
    ],
    views: [
      {
        path: "/expenses",
        name: "Expenses",
        mini: "E",
        component: ExpBundlesAdminPage,
        layout: "/admin",
        visibleToPermissions: ["is_expenses_admin"]
      },
      {
        path: "/holidays-and-leave",
        name: "Holidays and Leave",
        mini: "H&L",
        component: HolsAdminPage,
        layout: "/admin",
        visibleToPermissions: ["is_holidays_admin"]
      },
      {
        path: "/procurement-requests",
        name: "Procurement Requests",
        mini: "PR",
        component: ProcurementRequestPage,
        layout: "/admin",
        visibleToPermissions: ["is_procurement_admin", "is_procurement_user"]
      },
      {
        path: "/sales-pipeline",
        name: "Sales Pipeline",
        mini: "SP",
        component: SalesPipelinePage,
        layout: "/admin",
        visibleToPermissions: ["is_sales_pipeline_admin"]
      },
      {
        path: "/reports",
        name: "Reports",
        mini: "R",
        component: ReportsPage,
        layout: "/admin",
        visibleToPermissions: ["is_reports_user"]
      },
      {
        path: "/resource-planner",
        name: "Resource Planner",
        mini: "RP",
        component: ResourcePlannerPage,
        layout: "/admin",
        visibleToPermissions: ["is_bookings_admin"]
      },
      {
        path: "/timesheets",
        name: "Timesheets",
        mini: "T",
        component: TsBundleAdminPage,
        layout: "/admin",
        visibleToPermissions: ["is_timesheets_admin"]
      },
      {
        path: "/vehicle-tracking",
        name: "Vehicle Tracking",
        mini: "VT",
        component: VehicleTrackingPage,
        layout: "/admin",
        visibleToPermissions: ["is_vehicle_tracking_admin"]
      }
    ]
  },
  {
    collapse: true,
    name: "User Tools",
    icon: Person,
    state: "userToolsCollapse",
    visibleToPermissions: [
      "is_bookings_user",
      "is_expenses_user",
      "is_holidays_user",
      "is_warehouse_user",
      "is_timesheets_user"
    ],
    views: [
      {
        path: "/my-bookings",
        name: "My Bookings",
        mini: "B",
        component: BookingsUserPage,
        layout: "/user",
        visibleToPermissions: ["is_bookings_user"]
      },
      {
        path: "/my-expenses",
        name: "My Expenses",
        mini: "E",
        component: ExpBundleUserPage,
        layout: "/user",
        visibleToPermissions: ["is_expenses_user"]
      },
      {
        path: "/my-holidays-and-leave",
        name: "My Holidays and Leave",
        mini: "H&L",
        component: HolsUserPage,
        layout: "/user",
        visibleToPermissions: ["is_holidays_user"]
      },
      {
        path: "/scan-warehouse-item",
        name: "Scan Warehouse Item",
        mini: "SWI",
        component: WarehouseItemScanPage,
        layout: "/user",
        visibleToPermissions: ["is_warehouse_user"]
      },
      {
        path: "/my-timesheets",
        name: "My Timesheets",
        mini: "T",
        component: TsBundleUserPage,
        layout: "/user",
        visibleToPermissions: ["is_timesheets_user"]
      }
    ]
  },
  {
    collapse: true,
    name: "Warehouse",
    icon: Widgets,
    state: "warehouseCollapse",
    visibleToPermissions: ["is_warehouse_admin"],
    views: [
      {
        path: "/warehouse-delivery-schedule",
        name: "Delivery Schedule",
        mini: "DS",
        component: WarehouseDeliverySchedulePage,
        layout: "/admin",
        visibleToPermissions: ["is_warehouse_admin"]
      },
      {
        path: "/projects-with-warehouse-items",
        name: "Warehouse Items",
        mini: "IBP",
        component: ProjectsWithWarehouseItemsPage,
        layout: "/admin",
        visibleToPermissions: ["is_warehouse_admin"]
      }
    ]
  },
  {
    collapse: true,
    name: "HR and H&S",
    icon: Business,
    state: "hrHsCollapse",
    visibleToPermissions: ["is_hrhs_admin", "is_hrhs_user"],
    views: [
      {
        path: "/hrhs-projects-with-sheq-items",
        name: "SHEQ Documents",
        mini: "SD",
        component: ProjectsWithSHEQDocsPage,
        layout: "/admin",
        visibleToPermissions: ["is_hrhs_admin", "is_hrhs_user"]
      },
      {
        path: "/hrhs-certificates",
        name: "Training",
        mini: "T",
        component: CertificatesAllUsersPage,
        layout: "/admin",
        visibleToPermissions: ["is_hrhs_admin"]
      },
    ]
  },
  {
    collapse: true,
    name: "Databases",
    icon: TableChart,
    state: "dbCollapse",
    visibleToPermissions: [
      "is_client_db_admin",
      "is_project_db_admin",
      "is_user_db_admin"
    ],
    views: [
      {
        path: "/client-database",
        name: "Client DB",
        mini: "C",
        component: ClientDBPage,
        layout: "/databases",
        visibleToPermissions: ["is_client_db_admin"]
      },
      {
        path: "/project-database",
        name: "Project DB",
        mini: "P",
        component: ProjectDBPage,
        layout: "/databases",
        visibleToPermissions: ["is_project_db_admin"]
      },
      {
        path: "/user-database",
        name: "User DB",
        mini: "U",
        component: UserDbPage,
        layout: "/databases",
        visibleToPermissions: ["is_user_db_admin"]
      }
    ]
  },
  {
    collapse: true,
    name: "Settings",
    icon: Settings,
    state: "settingsCollapse",
    visibleToPermissions: ["is_settings_admin"],
    views: [
      {
        path: "/departments",
        name: "Departments",
        mini: "D",
        component: DepartmentsPage,
        layout: "/settings",
        visibleToPermissions: ["is_settings_admin"]
      },
      {
        path: "/logs",
        name: "Logs",
        mini: "L",
        component: LogsPage,
        layout: "/settings",
        visibleToPermissions: ["is_settings_admin"]
      },
      {
        path: "/public-holidays",
        name: "Public Holidays",
        mini: "PH",
        component: PublicHolidaysPage,
        layout: "/settings",
        visibleToPermissions: ["is_settings_admin"]
      }
    ]
  },
  {
    collapse: true,
    name: "Pages",
    icon: Image,
    state: "pageCollapse",
    invisible: true,
    visibleToPermissions: null,
    views: [
      {
        path: "/login-page",
        name: "Login to SRW Admin",
        mini: "L",
        component: LoginPage,
        layout: "/auth",
        invisible: true,
        visibleToPermissions: null
      },
      {
        path: "/register-page",
        name: "Register",
        mini: "R",
        component: RegisterPage,
        layout: "/auth",
        invisible: true,
        visibleToPermissions: null
      }
    ]
  }
];

export default dashRoutes;
