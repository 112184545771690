import {
  SET_VEHICLE_TRACKING_DT_PAGE,
  SET_VEHICLE_TRACKING_DT_TRIG_REFRESH,
  SET_VEHICLE_TRACKING_DT_ROW,
  SET_VEHICLE_TRACKING_DT_FILTER
} from "../../../dispatch_types";

import {
  ReduxTableType,
  ReduxActionType
} from "../../../../global_functions/types/shared";

const initialState: VehicleTrackingStateType = {
  tables: {
    vehicleTrackingMain: {
      selectedRow: null,
      triggerDtRefresh: {},
      viewedPage: 0,
      pageFilter: 0
    }
  }
};

export default function(state = initialState, action: ReduxActionType) {
  switch (action.type) {
    case SET_VEHICLE_TRACKING_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          vehicleTrackingMain: {
            ...state.tables.vehicleTrackingMain,
            selectedRow: action.payload
          }
        }
      };
    case SET_VEHICLE_TRACKING_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          vehicleTrackingMain: {
            ...state.tables.vehicleTrackingMain,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_VEHICLE_TRACKING_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          vehicleTrackingMain: {
            ...state.tables.vehicleTrackingMain,
            viewedPage: action.payload
          }
        }
      };
    case SET_VEHICLE_TRACKING_DT_FILTER:
      return {
        ...state,
        tables: {
          ...state.tables,
          vehicleTrackingMain: {
            ...state.tables.vehicleTrackingMain,
            pageFilter: action.payload
          }
        }
      };
    default:
      return state;
  }
}

// typescript declarations
type VehicleTrackingStateType = {
  tables: { vehicleTrackingMain: ReduxTableType };
};
