import Check from "@material-ui/icons/Check";
import React from "react";

const StatsText = props => {
  return (
    <>
      {(props.value && props.value !== 0) ||
      (props.value2 && props.value2 !== 0) ? (
        <p style={{ color: "#777", fontSize: "17px" }}>
          {props.value ? `${props.value} ${props.text}` : ""}
          {props.value && props.value2 ? ", " : ""}
          {props.value2 ? props.text2 : <></>}
        </p>
      ) : (
        <p style={{ color: "green", fontSize: "32px" }}>
          <Check
            style={{
              width: "30px",
              height: "30px",
              margin: "0 0 0 0"
            }}
          />
        </p>
      )}
    </>
  );
};

export default StatsText;
