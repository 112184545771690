import React, { useCallback, useEffect, useState } from "react";

import axios from "axios";

import { useDispatch } from "react-redux";
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// components
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// @material-ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Delete from "@material-ui/icons/Delete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import showNotification from "global_functions/redux_action_creators/showNotification";

const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formsStyles);
const customFormHeaderStyle = {
  textAlign: "left",
  marginBottom: "0px",
  marginTop: "10px"
};

// API URL
const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DeleteDialog(props) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const formClasses = useFormStyles();

  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const [loadingInfo, setLoadingInfo] = useState(false);

  const modalLoading = deleteInProgress || loadingInfo;

  const [bookingUser, setBookingUser] = useState("");
  const [projName, setProjName] = useState("");
  const [bookingTime, setBookingTime] = useState("");
  const [bookingSeries, setBookingSeries] = useState("");
  const [delSeries, setDelSeries] = useState(false);

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (props.deleteBookingDialog) {
      document.addEventListener("keydown", captureKeyDown, false);
      setLoadingInfo(true);
      axios
        .get(
          apiUrl +
            "calendar-bookings/" +
            props.bookingId +
            "?format_type=info_modal",
          getTokenConfig(store.getState)
        )
        .then(res => {
          setBookingUser(res.data.booking_user);
          setProjName(res.data.proj_name);
          setBookingTime(res.data.date_and_time);
          setBookingSeries(res.data.series);
          setDelSeries(false);
        })
        .then(() => setLoadingInfo(false));
    } else {
      document.removeEventListener("keydown", captureKeyDown, false);
    }
  }, [props.deleteBookingDialog, props.bookingId, captureKeyDown]);

  const handleFormSubmit = e => {
    e.preventDefault();
    let body = getTokenConfig(store.getState);
    body.data = { del_series: delSeries };

    setDeleteInProgress(true);
    axios
      .delete(apiUrl + "calendar-bookings/" + props.bookingId, body)
      .then(() => {
        let deleteMsg = delSeries ? "Series deleted" : "Booking deleted";
        dispatch(showNotification(deleteMsg, "success"));
        props.setDeleteBookingDialog(false);
        props.calendarRef.current.calendar.refetchEvents();
        setDeleteInProgress(false);
      })
      .catch(err => {
        let errorMsg;
        if (err.response.data.error_msg) {
          errorMsg = err.response.data.error_msg;
        } else if (err.response.data.errors[0]) {
          errorMsg = err.response.data.errors[0];
        } else {
          errorMsg = "Error deleting.";
        }
        dispatch(
          showNotification("[" + err.response.status + "] " + errorMsg, "error")
        );
        setDeleteInProgress(false);
      });
  };

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        fullWidth={true}
        open={props.deleteBookingDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.setDeleteBookingDialog(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => props.setDeleteBookingDialog(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Delete Entry</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {modalLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
                width: "500px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <form id="delBookingForm" onSubmit={handleFormSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <Delete
                    color="secondary"
                    fontSize="large"
                    style={{ marginTop: "10px" }}
                  />
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <p
                    className={classes.modalSectionTitle}
                    style={customFormHeaderStyle}
                  >
                    {" "}
                    Staff Member:{" "}
                  </p>
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {bookingUser}{" "}
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={2} />
                <GridItem xs={12} sm={10}>
                  <p
                    className={classes.modalSectionTitle}
                    style={customFormHeaderStyle}
                  >
                    {" "}
                    Project:{" "}
                  </p>
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {projName}{" "}
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={2} />
                <GridItem xs={12} sm={10}>
                  <p
                    className={classes.modalSectionTitle}
                    style={customFormHeaderStyle}
                  >
                    {" "}
                    Time:{" "}
                  </p>
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {bookingTime}{" "}
                  </p>
                </GridItem>
              </GridContainer>
              {/*if booking has more than one series entry or one entry but with different start and finish*/}
              {/*date then it is part of a series (no series is one entry with [start_date, start_date]*/}
              {bookingSeries &&
                (bookingSeries.length > 1 ||
                  bookingSeries[0][0] !== bookingSeries[0][1]) && (
                  <>
                    <GridContainer>
                      <GridItem xs={12} sm={2} />
                      <GridItem xs={12} sm={10}>
                        <p
                          className={classes.modalSectionTitle}
                          style={customFormHeaderStyle}
                        >
                          {" "}
                          Series:{" "}
                        </p>
                        <ul
                          className={classes.cardSubtitle}
                          style={{ textAlign: "left" }}
                        >
                          {bookingSeries.map((entry, index) => {
                            if (entry[0] === entry[1]) {
                              return (
                                <li key={`delSeriesLi${index}`}>{`${
                                  entry[0]
                                }`}</li>
                              );
                            } else {
                              return (
                                <li key={`delSeriesLi${index}`}>{`${
                                  entry[0]
                                } - ${entry[1]}`}</li>
                              );
                            }
                          })}
                        </ul>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={2} />
                      <GridItem xs={12} lg={4}>
                        <div className={formClasses.inlineChecks}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="delSeriesCheckbox"
                                tabIndex={-1}
                                onClick={e => setDelSeries(e.target.checked)}
                                checked={delSeries}
                                checkedIcon={
                                  <Check className={formClasses.checkedIcon} />
                                }
                                icon={
                                  <Check
                                    className={formClasses.uncheckedIcon}
                                  />
                                }
                                classes={{
                                  checked: formClasses.checked,
                                  root: formClasses.checkRoot
                                }}
                              />
                            }
                            classes={{
                              label: formClasses.label,
                              root: formClasses.labelRoot
                            }}
                            label="Delete series"
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </>
                )}
            </form>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            disabled={modalLoading}
            color="danger"
            type="submit"
            form="delBookingForm"
            simple
          >
            Confirm Delete
          </Button>
          <Button
            disabled={modalLoading}
            color="transparent"
            onClick={() => props.setDeleteBookingDialog(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
