import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

import { DataTableParamsType } from "../../../global_functions/types/dataTables";

const editButton = { icon: "Edit", tooltip: "Edit allowance" };
const deleteButton = { icon: "Delete", tooltip: "" };

export const holidayAllowancesMain: DataTableParamsType = {
  section: "holidayAllowances",
  reduxTableName: "holidayAllowancesMain",
  tableParams: {
    autoWidth: true,
    buttonsColumn: {
      id: 7,
      genIcons: () => {
        return [editButton, deleteButton];
      }
    },
    dom: "Bfrtip",
    select: { style: "single" },
    processing: true,
    responsive: true,
    language: {
      processing: DtLoadingSpinner
    },
    columns: [
      {
        data: "id",
        title: "ID",
        responsivePriority: 1,
        className: "dt-body-center",
        width: 100,
        visible: false
      },
      {
        data: "user_name_str",
        title: "User",
        width: 300
      },
      {
        data: "department",
        title: "Department",
        width: 300
      },
      {
        data: "year",
        title: "Year",
        width: 100,
        className: "dt-body-center"
      },
      {
        data: "allowance",
        title: "Allowance",
        width: 100,
        className: "dt-body-center"
      },
      {
        data: "extra",
        title: "Extra",
        width: 100,
        className: "dt-body-center"
      },
      {
        data: "comment",
        title: "Comment",
        width: 300
      },
      {
        data: null,
        title: "Actions",
        className: "dt-body-center",
        responsivePriority: 1
      }
    ],
    aaSorting: [[1, "asc"]]
  }
};
