import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

let editIcon = { icon: "Edit", tooltip: "Edit entry" };
let deleteIcon = { icon: "Delete", tooltip: "Delete entry" };
let noReceiptsIcon = { icon: "FilterNone", tooltip: "Manage receipts" };
let manyReceiptsIcon = { icon: "Filter9Plus", tooltip: "Manage receipts" };

export const certificateOneUserMain = {
  section: "certificateOneUser",
  reduxTableName: "certificateOneUserMain",
  tableParams: {
    autoWidth: true,
    buttonsColumn: {
      id: 5,
      genIcons: rowData => {
        let return_array = [];
        // get receipt number icons
        if (!rowData.scan_count) {
          return_array.push(noReceiptsIcon);
        } else if (rowData.scan_count <= 9) {
          return_array.push({
            icon: "Filter" + rowData.scan_count.toString(),
            tooltip: "Manage receipts"
          });
        } else {
          return_array.push(manyReceiptsIcon);
        }

        return_array.push(editIcon, deleteIcon);

        return return_array;
      }
    },
    responsive: true,
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    columns: [
      {
        data: "id",
        title: "ID",
        className: "dt-body-center",
        visible: false,
        searchable: false
      }, // 0
      {
        data: "user",
        title: "User",
        className: "dt-body-center",
        visible: false,
        searchable: false
      }, // 1
      {
        data: "name",
        title: "Certificate Name",
        responsivePriority: 2,
        className: "dt-body-center"
      }, // 2
      {
        data: { _: "added_date.display", sort: "added_date.@data-order" },
        title: "Added Date",
        className: "dt-body-center"
      }, // 3
      {
        data: { _: "expiry_date.display", sort: "expiry_date.@data-order" },
        title: "Expiry Date",
        className: "dt-body-center"
      }, // 4
      {
        data: null,
        title: "Actions",
        width: 200,
        responsivePriority: 1,
        className: "dt-body-center"
      } // 5
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "asc"]]
  }
};
