import { DataTableParamsType } from "../../global_functions/types/dataTables";

import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const deleteButton = { icon: "Delete", tooltip: "Delete Position" };
const editButton = { icon: "Edit", tooltip: "Edit Position" };

export const positionsMain: DataTableParamsType = {
  section: "positions",
  reduxTableName: "positionsMain",
  tableParams: {
    autoWidth: true,
    responsive: true,
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    buttonsColumn: {
      id: 2,
      genIcons: () => {
        return [editButton, deleteButton];
      }
    },
    columns: [
      {
        data: "id",
        title: "ID",
        className: "dt-body-center",
        width: 100
      }, // 0
      {
        data: "name",
        title: "Position Name",
        className: "dt-body-center",
        responsivePriority: 1,
        width: 300
      }, // 1
      {
        data: null,
        title: "Actions",
        className: "dt-body-center",
        responsivePriority: 1,
        width: 100
      } // 2
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "asc"]]
  }
};
