/*!
* Template Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

*/
import React from "react";
import ReactDOM from "react-dom";
import { createHashHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

import store from "./redux/store";
import { Provider } from "react-redux";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
// import RtlLayout from "layouts/RTL.js";

import "react-notifications/lib/notifications.css";
import ReduxAlerts from "components/_Custom/_Common/ReduxAlerts";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import { loadUser } from "global_functions/redux_action_creators/auth/loadUser";
import ErrorPage from "views/Pages/ErrorPage";

// below dialog can be called from most parts of the site so stored at top level
import ChangePasswordDialog from "./views/Dialogs/Auth/ChangePasswordDialog";

const hist = createHashHistory();

const App = () => {
  // every time app reloads, reload user info
  React.useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router history={hist}>
        <Switch>
          <Redirect exact from="/" to="/admin/dashboard" />
          <PrivateRoute path="/admin" component={AdminLayout} />
          <PrivateRoute path="/user" component={AdminLayout} />
          <PrivateRoute path="/databases" component={AdminLayout} />
          <PrivateRoute path="/settings" component={AdminLayout} />
          <Route path="/auth" component={AuthLayout} />
          <Route path="*" component={ErrorPage} />
        </Switch>
      </Router>
      <ReduxAlerts />
      <ChangePasswordDialog />
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
