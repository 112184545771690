import {
  SET_SHEQ_DOCS_DT_PAGE,
  SET_SHEQ_DOCS_DT_TRIG_REFRESH,
  SET_SHEQ_DOCS_DT_ROW,
  SET_SHEQ_DOCS_FILTER,
  SET_SHEQ_DOCS_REFRESH_URL
} from "redux/dispatch_types";

const initialState = {
  tables: {
    sheqDocsMain: {
      selectedRow: null,
      triggerDtRefresh: {},
      viewedPage: 0,
      pageFilter: 0,
      refreshURL: ""
    }
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SHEQ_DOCS_FILTER:
      return {
        ...state,
        tables: {
          ...state.tables,
          sheqDocsMain: {
            ...state.tables.sheqDocsMain,
            pageFilter: action.payload,
            viewedPage: 0
          }
        }
      };
    case SET_SHEQ_DOCS_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          sheqDocsMain: {
            ...state.tables.sheqDocsMain,
            selectedRow: action.payload
          }
        }
      };
    case SET_SHEQ_DOCS_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          sheqDocsMain: {
            ...state.tables.sheqDocsMain,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_SHEQ_DOCS_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          sheqDocsMain: {
            ...state.tables.sheqDocsMain,
            viewedPage: action.payload
          }
        }
      };
    case SET_SHEQ_DOCS_REFRESH_URL:
      return {
        ...state,
        tables: {
          ...state.tables,
          sheqDocsMain: {
            ...state.tables.sheqDocsMain,
            refreshURL: action.payload
          }
        }
      };
    default:
      return state;
  }
}
