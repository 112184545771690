import React, { useEffect, useRef } from "react";
import useTableParams from "global_functions/dataTables/useTableParams";

// datatables dependencies
const $ = require("jquery");
$.DataTable = require("datatables.net");

// datatables plugins
addSmallerPlugins();
require("datatables.net-select");
require("datatables.net-responsive");

function Datatable(props) {
  const dtHtmlEl = useRef(); // datatable html ref
  let tableParams = useTableParams(props.tableParams);

  // initialises required buttons
  useEffect(() => {
    let $table = $(dtHtmlEl.current);
    tableParams.responsive = {
      details: {
        renderer: $.fn.dataTable.Responsive.renderer.listHiddenNodes()
      }
    };

    props.setDataTable($table.DataTable({ ...tableParams }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableParams]); // only runs once as tableParams is a constant

  return (
    <>
      <table
        id={props.tableID}
        className="display"
        width="100%"
        ref={dtHtmlEl}
      />
    </>
  );
}

export default Datatable;

// add smaller plugins to datatable
function addSmallerPlugins() {
  // row().show() plugin from datatables cdn (allows navigating to a specific row)
  $.fn.dataTable.Api.register("row().show()", function() {
    let page_info = this.table().page.info();
    // Get row index
    let new_row_index = this.index();
    // Row position
    let row_position = this.table()
      .rows()[0]
      .indexOf(new_row_index);
    // Already on right page ?
    if (row_position >= page_info.start && row_position < page_info.end) {
      // Return row object
      return this;
    }
    // Find page number
    let page_to_display = Math.floor(row_position / this.table().page.len());
    // Go to that page
    this.table().page(page_to_display);
    // Return row object
    return this;
  });

  // processing() plugin from datatables cdn (adds processing function for  manual setting of spinner)
  $.fn.dataTable.Api.register("processing()", function(show) {
    return this.iterator("table", function(ctx) {
      ctx.oApi._fnProcessingDisplay(ctx, show);
    });
  });
}
