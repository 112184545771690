import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  SET_EXP_BUNDLE_ADMIN_DT_PAGE,
  SET_EXP_BUNDLE_ADMIN_DT_TRIG_REFRESH,
  SET_EXP_BUNDLE_ADMIN_DT_ROW,
  SET_EXP_BUNDLE_ADMIN_FILTER
} from "redux/dispatch_types";
// functions
import useEventListener from "global_functions/_common/useEventListener";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import identifyClick from "global_functions/_common/identifyClick";
import useFetchDocument from "global_functions/_common/useFetchDocument";
import showNotification from "global_functions/redux_action_creators/showNotification";
// custom components
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import TableFilter from "components/_Custom/_Common/TableFilter";
// material ui
import { makeStyles } from "@material-ui/core/styles";
// styles
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import ConfirmationDialog from "views/Dialogs/_Common/ConfirmationDialog";
// tables
import { expBundlesAdminMain } from "../../DataTables/Expenses/expBundlesAdminMain";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

export default function ExpBundlesAdminPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const reduxPageProps = useSelector(state => state.expBundlesAdmin);

  const [dataTable, setDataTable] = useState("");

  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [confirmationParams, setConfirmationParams] = useState(false);

  const [confirmURL, setConfirmURL] = useState("");

  const [reportParams, setReportParams] = useState("");

  useDataTableRefresh(dataTable, expBundlesAdminMain);

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_EXP_BUNDLE_ADMIN_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_EXP_BUNDLE_ADMIN_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
          dataTable.rows().deselect();
          switch (clickRelevantType.params[0]) {
            case "CheckCircle":
              setConfirmURL(`expense-bundles/${dtData.id}`);
              setConfirmationParams([
                "Are you sure you would like to approve this bundle?",
                "Approve Bundle",
                "Bundle Approved",
                "Error approving bundle.",
                3
              ]);
              setConfirmationDialog(true);
              break;
            case "Visibility":
              history.push(`/admin/expenses/${dtData.id}`);
              break;
            case "Cancel":
              setConfirmURL(`expense-bundles/${dtData.id}`);
              setConfirmationParams([
                "Are you sure you would like to reject this bundle?",
                "Reject Bundle",
                "Bundle Rejected",
                "Error rejecting bundle.",
                4
              ]);
              setConfirmationDialog(true);
              break;
            case "Assignment":
              dataTable.rows().deselect();
              setReportParams({
                url:
                  apiUrl + "documents/?dataset=expenses_report&exp_bundle_id=" + dtData.id,
                fileName: `Expenses report for ${dtData.user} (bundle '${dtData.name}').pdf`,
                fetchMsg: "Fetching expenses' report"
              });

              break;
            case "AssignmentGreyedOut":
              dispatch(showNotification("Empty bundle", "info"));
              break;
            default:
              return false;
          }
          break;
        default:
          return false;
      }
    }
  };

  // add event listener for datatable clicks
  useEventListener("click", handleClickEvent, window);

  useFetchDocument(reportParams);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Expense Bundles</h4>
                <h4 className={classes.cardCategoryWhite}>Manage expenses</h4>
              </CardText>
              {dataTable ? (
                <TableFilter
                  color="primary"
                  activeFilter={
                    reduxPageProps.tables.expBundlesAdminMain.pageFilter
                  }
                  dispatchType={SET_EXP_BUNDLE_ADMIN_FILTER}
                  dataTable={dataTable}
                  tabs={expBundlesAdminMain.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setDataTable}
                tableParams={expBundlesAdminMain}
              />
            </CardBody>
          </Card>
        </GridItem>
        <ConfirmationDialog
          confirmationDialog={confirmationDialog}
          setConfirmationDialog={setConfirmationDialog}
          confirmationMessage={confirmationParams[0]}
          confirmationTitle={confirmationParams[1]}
          confirmURL={confirmURL}
          confirmPayload={{ new_status: confirmationParams[4] }}
          successMsg={confirmationParams[2]}
          errorMsg={confirmationParams[3]}
          tableRefreshType={SET_EXP_BUNDLE_ADMIN_DT_TRIG_REFRESH}
          addMessage={true}
        />
      </GridContainer>
    </div>
  );
}
