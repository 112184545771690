import {
  SET_QUOTE_BREAKDOWN_URL,
  SET_QUOTE_BREAKDOWN_DT_TRIG_REFRESH,
  SET_QUOTE_BREAKDOWN_DT_PAGE,
  SET_QUOTE_BREAKDOWN_DT_ROW
} from "redux/dispatch_types";

const initialState = {
  tables: {
    quoteBreakdownMain: {
      selectedRow: null,
      refreshURL: "",
      viewedPage: 0,
      triggerDtRefresh: {}
    }
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_QUOTE_BREAKDOWN_URL:
      let refreshUrl;
      if (action.payload) refreshUrl = `quotes/${action.payload}/breakdowns/`;
      else refreshUrl = "";

      return {
        ...state,
        tables: {
          ...state.tables,
          quoteBreakdownMain: {
            ...state.tables.quoteBreakdownMain,
            refreshURL: refreshUrl
          }
        }
      };
    case SET_QUOTE_BREAKDOWN_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          quoteBreakdownMain: {
            ...state.tables.quoteBreakdownMain,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_QUOTE_BREAKDOWN_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          quoteBreakdownMain: {
            ...state.tables.quoteBreakdownMain,
            viewedPage: action.payload
          }
        }
      };
    case SET_QUOTE_BREAKDOWN_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          quoteBreakdownMain: {
            ...state.tables.quoteBreakdownMain,
            selectedRow: action.payload
          }
        }
      };
    default:
      return state;
  }
}
