import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
// redux
import store from "redux/store";
import { useDispatch, useSelector } from "react-redux";
import { getTokenConfig } from "../../../global_functions/redux_action_creators/auth/getTokenConfig";
import {
  SET_SALES_PIPELINE_DT_PAGE,
  SET_SALES_PIPELINE_DT_TRIG_REFRESH,
  SET_SALES_PIPELINE_DT_ROW,
  SET_SALES_PIPELINE_FILTER,
  SET_SALES_PIPELINE_STATS_SHOW,
  SET_SALES_PIPELINE_STATS_HIDE
} from "redux/dispatch_types";
import { RootStateType } from "../../../redux/reducers";
import { ReportParams } from "../../../global_functions/types/shared";
// custom functions/hooks
import useEventListener from "global_functions/_common/useEventListener";
import identifyClick from "global_functions/_common/identifyClick";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import useFetchDocument from "../../../global_functions/_common/useFetchDocument";
// custom components
import AddButton from "components/CustomButtons/AddButton";
import SettingsButton from "../../../components/CustomButtons/SettingsButton";
import AddEditQuoteForm from "views/Forms/AddEditQuoteForm";
import DeleteDialog from "views/Dialogs/_Common/DeleteDialog";
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import TopStats from "components/_Custom/PageSpecific/SalesPipeline/TopStats/TopStats";
import ManageImgPdfUploadsDialog from "views/Dialogs/Uploads/ManageImgPdfUploadsDialog";
import StatsButton from "../../../components/CustomButtons/StatsButton";
import RejectButton from "../../../components/CustomButtons/RejectButton";
// template components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import TableFilter from "components/_Custom/_Common/TableFilter";
// @material-ui
import { makeStyles } from "@material-ui/core/styles";
// styles
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { grayColor } from "assets/jss/material-dashboard-pro-react";
// table
import { salesPipelineMain } from "../../DataTables/SalesPipeline/salesPipelineMain";
import showNotification from "../../../global_functions/redux_action_creators/showNotification";

const styles = {
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardContentRight: {
    padding: "15px 20px 15px 0px",
    position: "relative"
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  }
};

// @ts-ignore
const useStyles = makeStyles(styles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

const SalesPipelinePage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const history = useHistory();

  const reduxPageProps = useSelector(
    (state: RootStateType) => state.salesPipeline
  );

  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [viewedPoId, setViewedPoId] = useState(null);

  const [deleteName, setDeleteName] = useState("");
  const [dataTable, setDataTable] = useState<DataTables.Api | undefined>(
    undefined
  );
  const [editDelId, setEditDelId] = useState(0);

  const [managePosDialog, setManagePosDialog] = useState(false);

  const [pdfParams, setPdfParams] = useState<ReportParams>({
    url: "",
    fileName: "",
    fetchMsg: ""
  });

  useDataTableRefresh(dataTable, salesPipelineMain);

  // handlers
  const handleAddClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    setEditDelId(0);
    if (reduxPageProps.tables.salesPipelineMain.pageFilter) {
      // if a filter is being viewed, change it to 0 so added entries can be seen
      dispatch({ type: SET_SALES_PIPELINE_FILTER, payload: 0 });
    }
    setAddEditModal(true);
  };

  const changeQuoteStatus: ChangeQuoteStatus = (itemId, newStatus) => {
    axios
      .put(
        `${apiUrl}quotes/${itemId}`,
        // eslint-disable-next-line @typescript-eslint/camelcase
        { edit_type: "change_quote_status", new_status: newStatus },
        getTokenConfig(store.getState)
      )
      .then(res => {
        dispatch(showNotification(res.data.statusText, "success"));
        dispatch({
          type: SET_SALES_PIPELINE_DT_TRIG_REFRESH,
          payload: { id: res.data.id }
        });
      })
      .catch(err => {
        let errorMsg = "";
        if (err.response.data.errors) {
          errorMsg = `Error: ${err.response.data.errors}`;
        } else {
          errorMsg = `Error changing status`;
        }
        dispatch(showNotification(errorMsg, "error"));
      });
  };

  const handleClickEvent = (e: React.MouseEvent): void => {
    const clickRelevantType = identifyClick(e);

    if (dataTable && clickRelevantType && "type" in clickRelevantType) {
      let selectedRow;

      switch (clickRelevantType.type) {
        case "dtButton":
          if (
            "params" in clickRelevantType &&
            Array.isArray(clickRelevantType["params"])
          ) {
            const dtData = dataTable
              .rows(clickRelevantType.params[1])
              .data()[0];

            const iconName = clickRelevantType.params[0];

            if (iconName === "Assignment") {
              dataTable.rows().deselect();
              setPdfParams({
                url: `${apiUrl}documents/?dataset=sales_quote&quote_id=${dtData.id}`,
                fileName: `Quote ${dtData.id} - ${dtData.number} - ${dtData.client} - ${dtData.proj_name}.pdf`,
                fetchMsg: "Fetching quote PDF..."
              });
            } else if (iconName === "Edit") {
              dataTable.rows().deselect();
              setEditDelId(dtData.id);
              setAddEditModal(true);
            } else if (iconName === "Delete") {
              setEditDelId(dtData.id);
              const delName = `quote ${dtData.id} (${dtData.number} - ${dtData.client})`;
              setDeleteName(delName);
              setDeleteModal(true);
            } else if (iconName === "FormatListNumberedIcon") {
              history.push(`/admin/sales-pipeline/${dtData.id}`);
            } else if (iconName === "CheckCircle") {
              dataTable.rows().deselect();
              changeQuoteStatus(dtData.id, 3);
            } else if (iconName === "Cancel") {
              dataTable.rows().deselect();
              changeQuoteStatus(dtData.id, 6);
            } else if (
              iconName === "CloudUploadGreen" ||
              iconName === "CloudUpload" ||
              iconName === "CloudUploadRed"
            ) {
              setViewedPoId(dtData.id);
              setManagePosDialog(true);
            }
          }
          break;
        case "cellClick":
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_SALES_PIPELINE_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_SALES_PIPELINE_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        default:
          return;
      }
    }
  };

  // add event listener for datatable clicks
  useEventListener("click", handleClickEvent, window);

  useFetchDocument(pdfParams);

  return (
    <>
      <GridContainer>
        {reduxPageProps.stats.open ? (
          <>
            <GridItem xs={12}>
              <RejectButton
                onClick={() =>
                  dispatch({
                    type: SET_SALES_PIPELINE_STATS_HIDE,
                    payload: null
                  })
                }
                tooltip="Hide stats"
              />
            </GridItem>
            <TopStats />
          </>
        ) : (
          <GridItem xs={12}>
            <StatsButton
              onClick={() =>
                dispatch({ type: SET_SALES_PIPELINE_STATS_SHOW, payload: null })
              }
              tooltip="Show stats"
              fontSize="small"
              disabled={false}
            />
          </GridItem>
        )}
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Quote Database</h4>
                <h4 className={classes.cardCategoryWhite}>Manage SRW Quotes</h4>
              </CardText>
              {dataTable && salesPipelineMain.filterTabNames ? (
                <TableFilter
                  color="primary"
                  activeFilter={
                    reduxPageProps.tables.salesPipelineMain.pageFilter
                      ? reduxPageProps.tables.salesPipelineMain.pageFilter
                      : 0
                  }
                  dispatchType={SET_SALES_PIPELINE_FILTER}
                  dataTable={dataTable}
                  tabs={salesPipelineMain.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setDataTable}
                tableParams={salesPipelineMain}
              />
            </CardBody>
            <CardFooter>
              <div className={classes.cardContentRight}>
                <AddButton
                  handleAddClick={handleAddClick}
                  tooltip="Add Quote"
                />
                <SettingsButton
                  handleSettingsClick={() =>
                    history.push("/admin/sales-pipeline/manage-services")
                  }
                  tooltip="Manage Services"
                />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelId}
          tableRefreshType={SET_SALES_PIPELINE_DT_TRIG_REFRESH}
          filterInForm="clients_with_projects_status_1_2"
          addTitle="Add Quote"
          editTitle="Edit Quote"
          urlSuffix="quotes/"
          choiceFieldForm="quotes_form"
        >
          <AddEditQuoteForm />
        </AddEditDtEntryDialog>
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="the quote database"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={editDelId}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_SALES_PIPELINE_DT_TRIG_REFRESH}
          urlSuffix="quotes/"
        />
        <ManageImgPdfUploadsDialog
          manageUploadsDialog={managePosDialog}
          setManageUploadsDialog={setManagePosDialog}
          refreshUrl={`quotes/`}
          tableRefreshType={SET_SALES_PIPELINE_DT_TRIG_REFRESH}
          parentId={viewedPoId}
          showControls={true}
          title="Manage PO Uploads"
        />
      </GridContainer>
    </>
  );
};

export default SalesPipelinePage;

type ChangeQuoteStatus = (itemId: number, newStatus: number) => void;
