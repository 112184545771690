import React from "react";
// template
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// material-ui
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { makeStyles } from "@material-ui/core/styles";
// styles
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useFormStyles = makeStyles(formsStyles);

const NewStatusOptions = ({ statusScanType, setStatusScanType }) => {
  const formClasses = useFormStyles();

  return (
    <>
      <GridContainer>
        <GridItem>
          <FormLabel className={formClasses.labelHorizontal}>
            New status:
          </FormLabel>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem>
          <div
            className={
              formClasses.checkboxAndRadio +
              " " +
              formClasses.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              style={{ float: "left" }}
              control={
                <Radio
                  checked={statusScanType === 3}
                  onChange={e => setStatusScanType(parseInt(e.target.value))}
                  value={3}
                  name="radio button enabled"
                  aria-label="A"
                  icon={
                    <FiberManualRecord className={formClasses.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={formClasses.radioChecked} />
                  }
                  classes={{
                    checked: formClasses.radio,
                    root: formClasses.radioRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Checked Out"
            />
            <FormControlLabel
              style={{ float: "left" }}
              control={
                <Radio
                  checked={statusScanType === 2}
                  onChange={e => setStatusScanType(parseInt(e.target.value))}
                  value={2}
                  name="radio button enabled"
                  aria-label="B"
                  icon={
                    <FiberManualRecord className={formClasses.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={formClasses.radioChecked} />
                  }
                  classes={{
                    checked: formClasses.radio,
                    root: formClasses.radioRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Checked In"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem>
          <div
            className={
              formClasses.checkboxAndRadio +
              " " +
              formClasses.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              style={{ float: "left" }}
              control={
                <Radio
                  checked={statusScanType === 5}
                  onChange={e => setStatusScanType(parseInt(e.target.value))}
                  value={5}
                  name="radio button enabled"
                  aria-label="A"
                  icon={
                    <FiberManualRecord className={formClasses.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={formClasses.radioChecked} />
                  }
                  classes={{
                    checked: formClasses.radio,
                    root: formClasses.radioRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Pre-Check-Out"
            />
          </div>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default NewStatusOptions;
