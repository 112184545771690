import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
// custom functions
import { getDialogProps } from "global_functions/_common/getDialogProps";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// core components
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
// material ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
// styles
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import Slide from "@material-ui/core/Slide";
import GridContainer from "../../../components/Grid/GridContainer";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { Table } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import FormLabel from "@material-ui/core/FormLabel";

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import showNotification from "../../../global_functions/redux_action_creators/showNotification";
import { useDispatch } from "react-redux";
const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formsStyles);

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const apiUrl = process.env.REACT_APP_API_V1_URL;

export default function MoveItemToFromContainerDialog({
  moveItemId,
  projectId,
  setMoveItemFromContainerDialog,
  moveItemFromContainerDialog,
  refreshDispatchType,
  containerId
}) {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const dispatch = useDispatch();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [itemData, setItemData] = useState({});
  const [containerData, setContainerData] = useState([]);
  const [boxItemNo, setBoxItemNo] = useState(0);
  const [moveItemNo, setMoveItemNo] = useState(1);

  const [destinationContainer, setDestinationContainer] = useState(0);

  // loading states
  const [loadingItemInfo, setLoadingItemInfo] = useState(false);
  const [loadingProjectContainers, setLoadingProjectContainers] = useState(
    false
  );
  const [moveInProgress, setMoveInProgress] = useState(false);

  const loadingInProgress =
    loadingItemInfo || loadingProjectContainers || moveInProgress;

  const handleMoveClick = () => {
    setMoveInProgress(true);
    axios
      .put(
        `${apiUrl}projects/0/items/${moveItemId}`,
        {
          destination_container: destinationContainer,
          edit_type: "assign_container",
          number: moveItemNo
        },
        getTokenConfig(store.getState)
      )
      .then(res => {
        dispatch(showNotification(res.data.statusText, "success"));
        setMoveInProgress(false);
        setMoveItemFromContainerDialog(false);
        dispatch({ type: refreshDispatchType, payload: res.data });
      })
      .catch(err => {
        let errorMsg;
        err.response.data.errors
          ? (errorMsg = err.response.data.errors)
          : (errorMsg = "Error moving.");
        dispatch(showNotification(errorMsg, "error"));
        setMoveInProgress(false);
      });
  };

  useEffect(() => {
    if (moveItemFromContainerDialog) {
      setDestinationContainer(0); // reset destination container

      // load item info
      setLoadingItemInfo(true);
      axios
        .get(
          `${apiUrl}projects/0/items/${moveItemId}`,
          getTokenConfig(store.getState)
        )
        .then(res => {
          setItemData(res.data);
          setBoxItemNo(res.data.qty);
        })
        .then(() => setLoadingItemInfo(false));

      // load available containers
      setLoadingProjectContainers(true);
      axios
        .get(
          `${apiUrl}projects/${projectId}/containers/?filter=7`,
          getTokenConfig(store.getState)
        )
        .then(res => {
          let optionsArray = res.data;
          optionsArray.unshift({ value: 0, label: "Remove from container" }); // add 'remove from container' option
          let containerSelectBoxData = optionsArray.filter(
            container => container.value !== containerId
          );
          setContainerData(containerSelectBoxData);
        })
        .then(() => setLoadingProjectContainers(false));
    } else {
      setMoveItemNo(1); // reset item no to default when closing modal
    }
  }, [moveItemFromContainerDialog, moveItemId, projectId, containerId]);

  const dropboxOptions = useMemo(() => {
    let return_array = [];

    for (let i = 1; i <= boxItemNo; i++) {
      return_array.push({
        label: i,
        value: i
      });
    }
    return return_array;
  }, [boxItemNo]);

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={moveItemFromContainerDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setMoveItemFromContainerDialog(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setMoveItemFromContainerDialog(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Move Item To/From Container</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {loadingInProgress ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <>
              <GridContainer>
                <GridContainer>
                  <GridItem xs={1} />
                  <GridItem xs={10}>
                    <TableContainer component={Paper}>
                      <Table style={{ alignItems: "left" }}>
                        <TableBody>
                          <TableRow>
                            <TableCell>Item ID:</TableCell>
                            <TableCell>{itemData.id}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Manufacturer:</TableCell>
                            <TableCell>{itemData.manufacturer}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Model:</TableCell>
                            <TableCell>{itemData.model}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Serial No:</TableCell>
                            <TableCell>{itemData.serial_no}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Comments:</TableCell>
                            <TableCell>{itemData.comments}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Status:</TableCell>
                            <TableCell>{itemData.status_str}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </GridItem>
                  <GridItem xs={1} />
                </GridContainer>
                {(itemData.type === 1) & (itemData.qty > 1) ? (
                  <GridContainer>
                    <GridItem xs={1} />
                    <GridItem xs={10}>
                      <GridContainer>
                        <GridItem xs={3}>
                          <FormLabel
                            className={formClasses.labelHorizontal}
                            fontSize="1px"
                          >
                            Item Number
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={9}>
                          <CustomSelect
                            options={dropboxOptions}
                            isDisabled={loadingInProgress}
                            value={moveItemNo}
                            setValue={setMoveItemNo}
                            required={true}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={1} />
                  </GridContainer>
                ) : (
                  <></>
                )}
                <GridContainer>
                  <GridItem xs={1} />
                  <GridItem xs={10}>
                    <GridContainer>
                      <GridItem xs={3}>
                        <FormLabel
                          className={formClasses.labelHorizontal}
                          fontSize="1px"
                        >
                          Destination
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={9}>
                        {containerData.length ? (
                          <CustomSelect
                            options={containerData}
                            isDisabled={loadingInProgress}
                            value={destinationContainer}
                            setValue={setDestinationContainer}
                            required={true}
                          />
                        ) : (
                          <p style={{ marginTop: "32px" }}>
                            No available destinations
                          </p>
                        )}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={1} />
                </GridContainer>
              </GridContainer>
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="primary"
            simple
            // type="submit"
            // form="moveDeliveryForm"
            disabled={loadingInProgress}
            onClick={handleMoveClick}
          >
            Move
          </Button>
          <Button
            color="transparent"
            onClick={() => setMoveItemFromContainerDialog(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
