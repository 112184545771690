import { AUTH_FAILURE } from "redux/dispatch_types";
import axios from "axios";
import showNotification from "global_functions/redux_action_creators/showNotification";
import showAuthError from "../showAuthError";

const apiUrl = process.env.REACT_APP_API_V1_URL;

export const registerUser = (
  firstName,
  lastName,
  email,
  department,
  password,
  password2,
  history
) => (dispatch, getState) => {
  // check if passwords match
  if (password !== password2) {
    let errMsg = {
      response: {
        data: { password: ["provided passwords do not match"] },
        status: ""
      }
    };
    dispatch(showAuthError(errMsg));
  } else {
    // Request Body
    const body = JSON.stringify({
      username: email,
      first_name: firstName,
      last_name: lastName,
      email: email,
      userprofile: { department: department },
      password: password
    });

    axios
      .post(apiUrl + "accounts/register/", body)
      .then(res => {
        dispatch(
          showNotification(
            "Thank you for registering. Your account is pending approval.",
            "success"
          )
        );
        // dispatch({
        //     type: LOGIN_SUCCESS,
        //     payload: res.data
        // })
        history.push("/auth/login-page");
      })
      .catch(err => {
        dispatch(showAuthError(err));
        dispatch({
          type: AUTH_FAILURE
        });
      });
  }
};
