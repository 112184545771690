import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  SET_EXP_BUNDLE_USER_DT_PAGE,
  SET_EXP_BUNDLE_USER_DT_TRIG_REFRESH,
  SET_EXP_BUNDLE_USER_DT_ROW,
  SET_EXP_BUNDLE_USER_FILTER
} from "redux/dispatch_types";
// functions
import useEventListener from "global_functions/_common/useEventListener";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import identifyClick from "global_functions/_common/identifyClick";
import useFetchDocument from "global_functions/_common/useFetchDocument";
import showNotification from "global_functions/redux_action_creators/showNotification";
// custom components
import AddEditExpBundleForm from "views/Forms/AddEditExpUserBundleForm";
import ConfirmationDialog from "views/Dialogs/_Common/ConfirmationDialog";
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import DeleteDialog from "views/Dialogs/_Common/DeleteDialog";
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// components
import CardFooter from "components/Card/CardFooter";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import TableFilter from "components/_Custom/_Common/TableFilter";
import AddButton from "components/CustomButtons/AddButton";
import EditButton from "components/CustomButtons/EditButton";
// material ui
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
// table
import { expBundlesUserMain } from "../../DataTables/Expenses/expBundlesUserMain";
// styles

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

export default function ExpBundleUserPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const reduxPageProps = useSelector(state => state.expBundlesUser);

  const [editDelID, setEditDelID] = useState(0);
  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");

  const [dataTable, setDataTable] = useState("");

  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [confirmationParams, setConfirmationParams] = useState(false);

  const [confirmURL, setConfirmURL] = useState("");

  const [reportParams, setReportParams] = useState("");

  useDataTableRefresh(dataTable, expBundlesUserMain);

  // handlers
  const handleAddClick = () => {
    setEditDelID(0);
    setAddEditModal(true);
  };

  const handleEditClick = () => {
    setEditDelID(dataTable.rows({ selected: true }).data()[0].id);
    setAddEditModal(true);
  };

  const handleDeleteClick = (delId, delName) => {
    setEditDelID(delId);
    setDeleteName(delName);
    setDeleteModal(true);
  };

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_EXP_BUNDLE_USER_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_EXP_BUNDLE_USER_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
          dataTable.rows().deselect();
          switch (clickRelevantType.params[0]) {
            case "Publish":
              setConfirmURL(`expense-bundles/${dtData.id}`);
              setConfirmationParams([
                "Are you sure you would like to submit this bundle? (Note: to avoid delay in payment, please make sure you enter correct VAT status for each expense item.)",
                "Submit Bundle",
                "Bundle Submitted",
                "Error submitting bundle.",
                2
              ]);
              setConfirmationDialog(true);
              break;
            case "Visibility":
            case "Edit":
              history.push(`/user/my-expenses/${dtData.id}`);
              break;
            case "Cancel":
              setConfirmURL(`expense-bundles/${dtData.id}`);
              setConfirmationParams([
                "Are you sure you would like to unsubmit this bundle?",
                "Unsubmit Bundle",
                "Bundle Unsubmitted",
                "Error unsubmitting bundle.",
                1
              ]);
              setConfirmationDialog(true);
              break;
            case "Assignment":
              dataTable.rows().deselect();
              setReportParams({
                url:
                  apiUrl + "documents/?dataset=expenses_report&exp_bundle_id=" + dtData.id,
                fileName: `Expenses report for ${dtData.user} (bundle '${dtData.name}').pdf`,
                fetchMsg: "Fetching expenses' report..."
              });
              break;
            case "AssignmentGreyedOut":
            case "PublishGreyedOut":
              dispatch(showNotification("Empty bundle", "info"));
              break;
            case "Delete":
              handleDeleteClick(dtData.id, dtData.name);
              break;
            default:
              return false;
          }
          break;
        default:
          return false;
      }
    }
  };

  // add event listener for datatable clicks
  useEventListener("click", handleClickEvent, window);

  useFetchDocument(reportParams);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Expense Bundles</h4>
                <h4 className={classes.cardCategoryWhite}>
                  Manage your expenses
                </h4>
              </CardText>
              {dataTable ? (
                <TableFilter
                  color="primary"
                  activeFilter={
                    reduxPageProps.tables.expBundlesUserMain.pageFilter
                  }
                  dispatchType={SET_EXP_BUNDLE_USER_FILTER}
                  dataTable={dataTable}
                  tabs={expBundlesUserMain.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setDataTable}
                tableParams={expBundlesUserMain}
              />
            </CardBody>
            {!reduxPageProps.tables.expBundlesUserMain.pageFilter && ( // only show add/edit/delete when page filter is 0
              <CardFooter>
                <div className={classes.cardContentRight}>
                  <AddButton
                    handleAddClick={handleAddClick}
                    tooltip="Add Expense Bundle"
                  />
                  <EditButton
                    handleEditClick={handleEditClick}
                    greyedOut={
                      !reduxPageProps.tables.expBundlesUserMain.selectedRow
                    }
                    tooltip="Edit Bundle Name"
                    notSelectedWarning="Please select an expense bundle first"
                  />
                </div>
              </CardFooter>
            )}
          </Card>
        </GridItem>
        <ConfirmationDialog
          confirmationDialog={confirmationDialog}
          setConfirmationDialog={setConfirmationDialog}
          confirmationMessage={confirmationParams[0]}
          confirmationTitle={confirmationParams[1]}
          confirmURL={confirmURL}
          confirmPayload={{ new_status: confirmationParams[4] }}
          successMsg={confirmationParams[2]}
          errorMsg={confirmationParams[3]}
          tableRefreshType={SET_EXP_BUNDLE_USER_DT_TRIG_REFRESH}
          addMessage={true}
        />
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelID}
          tableRefreshType={SET_EXP_BUNDLE_USER_DT_TRIG_REFRESH}
          urlSuffix="expense-bundles/"
          editTitle="Edit Expense Bundle Name"
          addTitle="Add Expense Bundle"
        >
          <AddEditExpBundleForm />
        </AddEditDtEntryDialog>
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="your expenses"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={editDelID}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_EXP_BUNDLE_USER_DT_TRIG_REFRESH}
          urlSuffix={"expense-bundles/"}
        />
      </GridContainer>
    </div>
  );
}
