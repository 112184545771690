import React from "react";

import { useDispatch } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

// core components
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
  greyIconButton: {
    backgroundColor: "#ebebeb",
    marginTop: 10,
    marginRight: 10
  }
}));

// component provides back button that goes back or to override URL if provided
export default function BackButtonWithDispatch({
  dispatchType,
  dispatchPayload
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  return (
    <Tooltip title="Go back">
      <IconButton
        className={classes.greyIconButton}
        onClick={() =>
          dispatch({ type: dispatchType, payload: dispatchPayload })
        }
      >
        <KeyboardArrowLeft />
      </IconButton>
    </Tooltip>
  );
}
