import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
// redux
import { useDispatch } from "react-redux";
import { SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH } from "redux/dispatch_types";
// custom functions
import { getDialogProps } from "global_functions/_common/getDialogProps";
import showNotification from "global_functions/redux_action_creators/showNotification";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
//core components
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import store from "redux/store";
const useStyles = makeStyles(styles);
// API URL
const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DeleteAllPreCheckInDialog({
  deleteModal,
  setDeleteModal,
  projId
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    // removing ability to go back when modal is open as goes back in the previous page (not closes modal)
    if (deleteModal) {
      document.addEventListener("keydown", captureKeyDown, false);
    } else {
      document.removeEventListener("keydown", captureKeyDown, false);
    }
  }, [deleteModal, captureKeyDown]);

  const handleDeleteConfirmClick = () => {
    setDeleteInProgress(true);
    axios
      .delete(
        apiUrl + `projects/${projId}/items/0`,
        getTokenConfig(store.getState)
      )
      .then(() =>
        dispatch({
          type: SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH,
          payload: { id: null }
        })
      ) // set selected row to null as the entry will no longer be there
      .then(() => {
        dispatch(showNotification("Deleted", "success"));
        setDeleteModal(false);
        setDeleteInProgress(false);
      })
      .catch(err => {
        let errorMsg;
        if (err.response.data.error_msg) {
          errorMsg = err.response.data.error_msg;
        } else if (Array.isArray(err.response.data.errors)) {
          errorMsg = err.response.data.errors[0];
        } else if (err.response.data.errors) {
          errorMsg = err.response.data.errors;
        } else {
          errorMsg = "Error deleting.";
        }
        dispatch(
          showNotification("[" + err.response.status + "] " + errorMsg, "error")
        );
        setDeleteInProgress(false);
      });
  };

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={deleteModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setDeleteModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            Delete All Pre-Check-In Entries
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {deleteInProgress ? (
            <CustomLoadingSpinner />
          ) : (
            <p>
              <span style={{ color: "red" }}>Warning: </span>This will delete
              all items with pre-check-in status for this project. Are you sure
              you wish to proceed?
            </p>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            disabled={deleteInProgress}
            color="danger"
            onClick={() => handleDeleteConfirmClick()}
            simple
          >
            Delete
          </Button>
          <Button
            disabled={deleteInProgress}
            color="transparent"
            onClick={() => setDeleteModal(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
