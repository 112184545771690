import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// icon
import Done from "@material-ui/icons/Done";

const useStyles = makeStyles(theme => ({
  submitButton: {
    backgroundColor: "#4caf50",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#a4d7a6" },
    marginTop: 10,
    marginRight: 10
  }
}));

export default function ApproveButton({ handleApprove }) {
  const classes = useStyles();

  return (
    <Tooltip title="Approve bundle">
      <IconButton className={classes.submitButton} onClick={handleApprove}>
        <Done style={{ color: "#f9f9f9" }} />
      </IconButton>
    </Tooltip>
  );
}
