import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

export const publicHolsMain = {
  section: "publicHols",
  refreshURL: "public_holidays/",
  reduxTableName: "publicHolsMain",
  tableParams: {
    aaSorting: [[1, "asc"]],
    dom: "Bfrtip",
    autoWidth: true,
    responsive: true,
    searching: false,
    paging: false,
    info: false,
    select: {
      style: "single",
      processing: true
    },
    language: {
      processing: DtLoadingSpinner
    },
    columns: [
      {
        data: "id",
        visible: false,
        searchable: false,
        orderable: false,
        className: "dt-body-center"
      }, // 0
      {
        data: "sortable_date",
        visible: false,
        searchable: false,
        orderable: false,
        className: "dt-body-center"
      }, // 1
      {
        data: "date",
        title: "Date",
        orderable: false,
        className: "dt-body-center"
      }, // 2
      {
        data: "weekday",
        title: "Weekday",
        orderable: false,
        className: "dt-body-center"
      }, // 3
      {
        data: "description",
        title: "Description",
        orderable: false,
        className: "dt-body-center"
      } // 4
    ]
  }
};
