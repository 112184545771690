import { ReduxActionType } from "../../../global_functions/types/shared";

import { SHOW_NOTIFICATION } from "redux/dispatch_types";

const initialState: NotificationStateType = {
  message: "",
  notifyType: ""
};

export default function(state = initialState, action: ReduxActionType) {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return (state = action.payload);
    default:
      return state;
  }
}

// typescript declarations
type NotificationStateType = {
  message: string;
  notifyType: string;
};
