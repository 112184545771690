import React from "react";

import { ThemeProvider } from "@material-ui/core/styles";
import { customTheme } from "global_functions/_common/customTheme";

import CircularProgress from "@material-ui/core/CircularProgress";

const CustomLoadingSpinner: React.FC<CustomLoadingSpinnerProps> = ({
  positionFixed
}) => {
  let progressStyles = {
    left: "50%",
    top: "50%",
    height: "50px",
    width: "50px"
  };

  return (
    <ThemeProvider theme={customTheme}>
      <CircularProgress
        style={
          positionFixed
            ? { ...progressStyles, position: "fixed" }
            : progressStyles
        }
      />
    </ThemeProvider>
  );
};

export default CustomLoadingSpinner;

interface CustomLoadingSpinnerProps {
  positionFixed?: boolean;
}
