import formatDate from "global_functions/_common/formatDate";

// types
import { FormChoiceField } from "../../../../global_functions/types/shared";
import {
  ReportName,
  VisibilityObject
} from "../../../../views/Pages/ReportsPage";

const getReportParams: GetReportParams = (
  formChoiceFields,
  whClientId,
  whBayId,
  reportUserId,
  date
) => {
  return {
    all_monthly_bookings_report: {
      name: `All Bookings Report for ${formatDate(date, "MM yyyy")}`,
      fieldVisibility: {
        monthFieldEnabled: true,
        userFieldEnabled: false,
        whClientsFieldEnabled: false,
        whBayChoiceFieldEnabled: false,
        projFieldWithWhChoicesEnabled: false,
        projFieldWithAllChoicesEnabled: false,
        excelOption: true
      }
    },
    all_user_timesheet_summary_report: {
      name: `All User Timesheet Report for ${formatDate(date, "MM yyyy")}`,
      fieldVisibility: {
        monthFieldEnabled: true,
        userFieldEnabled: false,
        whClientsFieldEnabled: false,
        whBayChoiceFieldEnabled: false,
        projFieldWithWhChoicesEnabled: false,
        projFieldWithAllChoicesEnabled: false,
        excelOption: false
      }
    },
    all_user_timesheet_dump: {
      name: `All User Timesheet Dump for ${formatDate(date, "MM yyyy")}`,
      fieldVisibility: {
        monthFieldEnabled: true,
        userFieldEnabled: false,
        whClientsFieldEnabled: false,
        whBayChoiceFieldEnabled: false,
        projFieldWithWhChoicesEnabled: false,
        projFieldWithAllChoicesEnabled: false,
        excelOption: true
      }
    },
    client_report: {
      name: `Work By Client Report for ${formatDate(date, "MM yyyy")}`,
      fieldVisibility: {
        monthFieldEnabled: true,
        userFieldEnabled: false,
        whClientsFieldEnabled: false,
        whBayChoiceFieldEnabled: false,
        projFieldWithWhChoicesEnabled: false,
        projFieldWithAllChoicesEnabled: false,
        excelOption: false
      }
    },
    client_stock_report: {
      name: (): string => {
        const selectedClient = formChoiceFields.wh_active_clients.find(
          (o: FormChoiceField) => o.value === whClientId
        );
        return `${selectedClient.label} Stock Report ${formatDate(
          new Date(),
          "dd MM yyyy"
        )}`;
      },
      fieldVisibility: {
        monthFieldEnabled: false,
        userFieldEnabled: false,
        whClientsFieldEnabled: true,
        whBayChoiceFieldEnabled: false,
        projFieldWithWhChoicesEnabled: false,
        projFieldWithAllChoicesEnabled: false,
        excelOption: true
      }
    },
    project_all_bookings_report: {
      name: (): string => {
        const selectedClient = formChoiceFields.all_clients.find(
          (o: FormChoiceField) => o.value === whClientId
        );
        return `${
          selectedClient.label
        } - Project All Bookings Report ${formatDate(
          new Date(),
          "dd MM yyyy"
        )}`;
      },
      fieldVisibility: {
        monthFieldEnabled: false,
        userFieldEnabled: false,
        whClientsFieldEnabled: false,
        whBayChoiceFieldEnabled: false,
        projFieldWithWhChoicesEnabled: false,
        projFieldWithAllChoicesEnabled: true,
        excelOption: false
      }
    },
    project_checked_out_stock_report: {
      name: (): string => {
        const selectedClient = formChoiceFields.wh_all_clients.find(
          (o: FormChoiceField) => o.value === whClientId
        );
        return `${
          selectedClient.label
        } - Single Project Checked Out Stock Report ${formatDate(
          new Date(),
          "dd MM yyyy"
        )}`;
      },
      fieldVisibility: {
        monthFieldEnabled: false,
        userFieldEnabled: false,
        whClientsFieldEnabled: false,
        whBayChoiceFieldEnabled: false,
        projFieldWithWhChoicesEnabled: true,
        projFieldWithAllChoicesEnabled: false,
        excelOption: true
      }
    },
    project_info: {
      name: "All Project Info",
      fieldVisibility: {
        monthFieldEnabled: false,
        userFieldEnabled: false,
        whClientsFieldEnabled: false,
        whBayChoiceFieldEnabled: false,
        projFieldWithWhChoicesEnabled: false,
        projFieldWithAllChoicesEnabled: false,
        excelOption: true
      }
    },
    project_stock_report: {
      name: (): string => {
        const selectedClient = formChoiceFields.wh_all_clients.find(
          (o: FormChoiceField) => o.value === whClientId
        );
        return `${
          selectedClient.label
        } - Single Project Stock Report ${formatDate(
          new Date(),
          "dd MM yyyy"
        )}`;
      },
      fieldVisibility: {
        monthFieldEnabled: false,
        userFieldEnabled: false,
        whClientsFieldEnabled: false,
        whBayChoiceFieldEnabled: false,
        projFieldWithWhChoicesEnabled: true,
        projFieldWithAllChoicesEnabled: false,
        excelOption: true
      }
    },
    staff_performance_report: {
      name: `Staff Performance Report ${formatDate(date, "MM yyyy")}`,
      fieldVisibility: {
        monthFieldEnabled: true,
        userFieldEnabled: false,
        whClientsFieldEnabled: false,
        whBayChoiceFieldEnabled: false,
        projFieldWithWhChoicesEnabled: false,
        projFieldWithAllChoicesEnabled: false,
        excelOption: false
      }
    },
    subcontractor_bookings: {
      name: `Subcontractor Work Report (Bookings-based) ${formatDate(
        date,
        "MM yyyy"
      )}`,
      fieldVisibility: {
        monthFieldEnabled: true,
        userFieldEnabled: false,
        whClientsFieldEnabled: false,
        whBayChoiceFieldEnabled: false,
        projFieldWithWhChoicesEnabled: false,
        projFieldWithAllChoicesEnabled: false,
        excelOption: false
      }
    },
    user_bookings: {
      name: (): string => {
        const reportUser = formChoiceFields.user_list.find(
          (obj: FormChoiceField) => obj.value === reportUserId
        );
        return `${reportUser.label}'s Bookings Report for ${formatDate(
          date,
          "MM yyyy"
        )}`;
      },
      fieldVisibility: {
        monthFieldEnabled: true,
        userFieldEnabled: true,
        whClientsFieldEnabled: false,
        whBayChoiceFieldEnabled: false,
        projFieldWithWhChoicesEnabled: false,
        projFieldWithAllChoicesEnabled: false,
        excelOption: false
      }
    },
    user_info: {
      name: "usr info",
      fieldVisibility: {
        monthFieldEnabled: false,
        userFieldEnabled: false,
        whClientsFieldEnabled: false,
        whBayChoiceFieldEnabled: false,
        projFieldWithWhChoicesEnabled: false,
        projFieldWithAllChoicesEnabled: false,
        excelOption: true
      }
    },
    warehouse_bay_report: {
      name: `Bay ${whBayId} Stock Report ${formatDate(
        new Date(),
        "dd MM yyyy"
      )}`,
      fieldVisibility: {
        monthFieldEnabled: false,
        userFieldEnabled: false,
        whClientsFieldEnabled: false,
        whBayChoiceFieldEnabled: true,
        projFieldWithWhChoicesEnabled: false,
        projFieldWithAllChoicesEnabled: false,
        excelOption: false
      }
    },
    work_by_type_report: {
      name: `Work By Type Report (Timesheet-based) ${formatDate(
        date,
        "MM yyyy"
      )}`,
      fieldVisibility: {
        monthFieldEnabled: true,
        userFieldEnabled: false,
        whClientsFieldEnabled: false,
        whBayChoiceFieldEnabled: false,
        projFieldWithWhChoicesEnabled: false,
        projFieldWithAllChoicesEnabled: false,
        excelOption: false
      }
    }
  };
};

export default getReportParams;

type GetReportParams = (
  formChoiceFields: any,
  whClientId: number,
  whBayId: number,
  reportUserId: number,
  date: Date
) => AllReportParams;

type AllReportParams = {
  [key in ReportName]: SingleReportParams;
};

type SingleReportParams = {
  name: string | NameGeneratorFunction;
  fieldVisibility: VisibilityObject;
};

type NameGeneratorFunction = () => string;
