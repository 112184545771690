import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

let editIcon = { icon: "Edit", tooltip: "Edit entry" };
let deleteIcon = { icon: "Delete", tooltip: "Delete entry" };

export const certificateTypesMain = {
  section: "certificateTypes",
  refreshURL: "certificate-types/",
  reduxTableName: "certificateTypesMain",
  tableParams: {
    autoWidth: true,
    buttonsColumn: {
      id: 6,
      genIcons: () => {
        return [editIcon, deleteIcon];
      }
    },
    responsive: true,
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    columns: [
      { data: "id", title: "ID", className: "dt-body-center", width: 50 }, // 0
      {
        data: "issuer",
        title: "Issuer",
        responsivePriority: 2,
        className: "dt-body-center",
        width: 150
      }, // 1
      { data: "name", title: "Name", className: "dt-body-center", width: 200 }, // 2
      {
        data: "duration",
        title: "Duration (Months)",
        responsivePriority: 2,
        className: "dt-body-center",
        width: 100
      }, // 3
      {
        data: "current",
        title: "Current",
        responsivePriority: 2,
        className: "dt-body-center",
        width: 80
      }, // 4
      {
        data: "req_pos_verbose",
        title: "Required For",
        responsivePriority: 2,
        width: 400,
        className: "dt-body-center"
      }, // 5
      {
        data: null,
        title: "Actions",
        width: 100,
        responsivePriority: 1,
        className: "dt-body-center"
      } // 6
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "asc"]]
  }
};
