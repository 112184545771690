import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

// icon constants
const viewBundleIcon = { icon: "Visibility", tooltip: "" };
const editButtonIcon = { icon: "Edit", tooltip: "" };
const unsubmitBundleIcon = { icon: "Cancel", tooltip: "Unsubmit bundle" };
const reportButtonIcon = {
  icon: "Assignment",
  tooltip: "Generate timesheet report"
};
const reportGreyedIcon = {
  icon: "AssignmentGreyedOut",
  tooltip: "Generate timesheet report"
};
const submitButtonIcon = { icon: "Publish", tooltip: "Submit bundle" };
const completionButton = { icon: "Assessment", tooltip: "View completion" };
const completionButtonGreyed = {
  icon: "AssessmentGreyedOut",
  tooltip: "View completion"
};

export const tsBundleUserDBMain = {
  section: "tsBundleUser",
  reduxTableName: "tsBundleUserDBMain",
  refreshURL: "timesheet-bundles/?page=user_ts_bundles&filter=",
  filterTabValues: [1, 2, 3],
  filterTabNames: ["Open", "Submitted", "Approved"],
  tableParams: {
    autoWidth: true,
    responsive: true,
    buttonsColumn: {
      id: 6,
      genIcons: rowData => {
        let returnArray;
        switch (rowData.status) {
          case "Open":
            returnArray = [submitButtonIcon, editButtonIcon];
            break;
          case "Submitted":
            returnArray = [viewBundleIcon, unsubmitBundleIcon];
            break;
          case "Approved":
            returnArray = [viewBundleIcon];
            break;
          case "Rejected":
            returnArray = [submitButtonIcon, editButtonIcon];
            break;
          default:
            return false;
        }
        // add blue report button to bundles that have entries and grey for ones that don't
        if (rowData.ts_entries)
          returnArray.push(reportButtonIcon, completionButton);
        else returnArray.push(reportGreyedIcon, completionButtonGreyed);

        return returnArray;
      }
    },
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    columns: [
      { data: "id", title: "ID", visible: false, searchable: false }, // 0
      {
        data: { _: "sub_month.display", sort: "sub_month.@data-order" },
        title: "Bundle Month",
        width: 400,
        className: "dt-body-center"
      }, // 1
      {
        data: { _: "open_date.display", sort: "open_date.@data-order" },
        title: "Date Open",
        width: 400,
        visible: false,
        searchable: false
      }, // 2
      {
        data: { _: "sub_date.display", sort: "sub_date.@data-order" },
        title: "Submission Date",
        width: 200,
        visible: false,
        searchable: false
      }, // 3
      {
        data: "status",
        title: "Status",
        width: 200,
        className: "dt-body-center"
      }, // 4
      {
        data: { _: "approval_info.display", sort: "approval_info.@data-order" },
        title: "Date Approved",
        width: 300
      }, // 5
      {
        data: null,
        title: "Actions",
        width: 170,
        responsivePriority: 8,
        className: "dt-body-center"
      } // 6
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[2, "desc"]]
  }
};
