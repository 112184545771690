import {
  SET_TS_BUNDLE_USER_DT_PAGE,
  SET_TS_BUNDLE_USER_DT_TRIG_REFRESH,
  SET_TS_BUNDLE_USER_DT_ROW,
  SET_TS_BUNDLE_USER_FILTER
} from "redux/dispatch_types";

const initialState = {
  tables: {
    tsBundleUserDBMain: {
      pageFilter: 0,
      selectedRow: null,
      triggerDtRefresh: {},
      viewedPage: 0
    }
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TS_BUNDLE_USER_FILTER:
      return {
        ...state,
        tables: {
          ...state.tables,
          tsBundleUserDBMain: {
            ...state.tables.tsBundleUserDBMain,
            pageFilter: action.payload,
            viewedPage: 0
          }
        }
      };
    case SET_TS_BUNDLE_USER_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          tsBundleUserDBMain: {
            ...state.tables.tsBundleUserDBMain,
            selectedRow: action.payload
          }
        }
      };
    case SET_TS_BUNDLE_USER_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          tsBundleUserDBMain: {
            ...state.tables.tsBundleUserDBMain,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_TS_BUNDLE_USER_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          tsBundleUserDBMain: {
            ...state.tables.tsBundleUserDBMain,
            viewedPage: action.payload
          }
        }
      };
    default:
      return state;
  }
}
