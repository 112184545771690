import {
  SET_HOLIDAY_ALLOWANCES_DT_PAGE,
  SET_HOLIDAY_ALLOWANCES_DT_TRIG_REFRESH,
  SET_HOLIDAY_ALLOWANCES_DT_ROW,
  SET_HOLIDAY_ALLOWANCES_FILTER,
  SET_HOLIDAY_ALLOWANCES_VIEWED_YEAR
} from "../../../dispatch_types";

// types
import {
  ReduxActionType,
  ReduxTableType
} from "../../../../global_functions/types/shared";

const initialState: HolidayAllowancesStateType = {
  viewedYear: new Date().getFullYear(),
  tables: {
    holidayAllowancesMain: {
      selectedRow: null,
      triggerDtRefresh: {},
      viewedPage: 0,
      refreshURL: `holiday-allowances/?year=${new Date().getFullYear()}`
    }
  }
};

export default function(state = initialState, action: ReduxActionType) {
  switch (action.type) {
    case SET_HOLIDAY_ALLOWANCES_FILTER:
      return {
        ...state,
        tables: {
          ...state.tables,
          holidayAllowancesMain: {
            ...state.tables.holidayAllowancesMain,
            pageFilter: action.payload,
            viewedPage: 0
          }
        }
      };
    case SET_HOLIDAY_ALLOWANCES_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          holidayAllowancesMain: {
            ...state.tables.holidayAllowancesMain,
            selectedRow: action.payload
          }
        }
      };
    case SET_HOLIDAY_ALLOWANCES_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          holidayAllowancesMain: {
            ...state.tables.holidayAllowancesMain,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_HOLIDAY_ALLOWANCES_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          holidayAllowancesMain: {
            ...state.tables.holidayAllowancesMain,
            viewedPage: action.payload
          }
        }
      };
    case SET_HOLIDAY_ALLOWANCES_VIEWED_YEAR:
      return {
        ...state,
        viewedYear: action.payload,
        tables: {
          ...state.tables,
          holidayAllowancesMain: {
            ...state.tables.holidayAllowancesMain,
            refreshURL: `holiday-allowances/?year=${action.payload}`,
            triggerDtRefresh: {}
          }
        }
      };
    default:
      return state;
  }
}

// typescript declarations
type HolidayAllowancesStateType = {
  tables: { holidayAllowancesMain: ReduxTableType };
  viewedYear: number;
};
