import React, { useCallback, useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
// custom functions
import showNotification from "global_functions/redux_action_creators/showNotification";
import { getDialogProps } from "global_functions/_common/getDialogProps";
// redux
import { useDispatch } from "react-redux";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import { SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH } from "../../../redux/dispatch_types";
// components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// template
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
// @material-ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
// styles
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formsStyles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const titleObj = {
  quick_move_to_pre_check_out: "Move Items From Box To Pre Checkout",
  remove_from_box: "Remove Items From Box",
  scan_to_new_status: "Change Box Item Status",
  scan_to_new_location: "Change Box Item Location",
  scan_to_from_container: "Change Box Item Container"
};

export default function SpecifyNumberDialog({
  modal,
  boxId,
  setModal,
  totalInBox,
  reqDataObj
}) {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const [boxNo, setBoxNo] = useState("");
  const [infoLoading, setInfoLoading] = useState(false);
  const [affectNo, setAffectNo] = useState(0);

  const dispatch = useDispatch();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const dropboxOptions = useMemo(() => {
    let return_array = [];

    let maxItems;
    if (reqDataObj.edit_type === "remove_from_box") {
      maxItems = boxNo - 1; // can't remove all items from box
    } else {
      maxItems = boxNo;
    }

    for (let i = 1; i <= maxItems; i++) {
      return_array.push({
        label: i,
        value: i
      });
    }
    return return_array;
  }, [boxNo, reqDataObj]);

  const handleFormSubmit = e => {
    e.preventDefault();
    axios
      .put(
        `${apiUrl}projects/0/items/${boxId}`,
        { ...reqDataObj, number: affectNo },
        getTokenConfig(store.getState)
      )
      .then(res => {
        dispatch(showNotification(res.data.statusText, "success"));
        setModal(false);
        dispatch({
          type: SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH,
          payload: res.data
        });
      })
      .catch(err => {
        let errorMsg;
        if (err.response.data.errors) errorMsg = err.response.data.errors;
        else errorMsg = "Error moving. Please refresh and try again";
        dispatch(showNotification(errorMsg, "error"));
      });
  };

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (modal) document.addEventListener("keydown", captureKeyDown, false);
    else document.removeEventListener("keydown", captureKeyDown, false);
  }, [modal, captureKeyDown]);

  // fetching item count on every modal load (if not provided)
  useEffect(() => {
    if (modal && !totalInBox) {
      // if item number not provided, get the number of item options from api
      setInfoLoading(true);
      axios
        .get(
          `${apiUrl}projects/0/items/${boxId}`,
          getTokenConfig(store.getState)
        )
        .then(res => {
          setBoxNo(parseInt(res.data.qty));
          setInfoLoading(false);
        })
        .catch(err => {
          let errorMsg;
          if (err.response.data.errors) {
            errorMsg = `Error: ${err.response.data.errors}`;
          } else errorMsg = `Error obtaining box info`;
          dispatch(showNotification(`${errorMsg}`, "error"));
          setInfoLoading(false);
        });
    } else if (modal && totalInBox) {
      setBoxNo(totalInBox);
      setAffectNo(0);
    } else {
      setBoxNo(0);
      setAffectNo(0);
    }
  }, [modal, dispatch, boxId, totalInBox]);

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{titleObj[reqDataObj.edit_type]}</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {infoLoading ? (
            <GridContainer>
              <GridItem xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              </GridItem>
            </GridContainer>
          ) : (
            <form id="splitBoxForm" onSubmit={handleFormSubmit}>
              <GridContainer>
                <GridItem xs={1} />
                <GridItem xs={4}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Number of Items
                  </FormLabel>
                </GridItem>
                <GridItem xs={6}>
                  <CustomSelect
                    options={dropboxOptions}
                    isDisabled={infoLoading}
                    value={affectNo}
                    setValue={setAffectNo}
                    required={true}
                  />
                </GridItem>
                <GridItem xs={1} />
              </GridContainer>
            </form>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="primary"
            simple
            type="submit"
            form="splitBoxForm"
            disabled={infoLoading}
          >
            Save
          </Button>
          <Button color="transparent" onClick={() => setModal(false)} simple>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
