import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

export const clientDashboardProjects = {
  section: "clientDashboard",
  reduxTableName: "clientDashboardProjects",
  filterTabValues: [0, 1],
  filterTabNames: ["Active", "Spent"],
  refreshURL: "",
  tableParams: {
    autoWidth: true,
    responsive: true,
    buttonsColumn: {
      id: 9,
      genIcons: () => {
        return [{ icon: "Visibility", tooltip: "" }];
      }
    },
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    columns: [
      {
        data: "id",
        title: "id",
        className: "dt-body-center",
        visible: false
      }, // 0
      {
        data: "project_number",
        title: "Project Number",
        className: "dt-body-center",
        visible: false
      }, // 1
      {
        data: "description",
        title: "Project",
        width: 400
        // className: "dt-body-center"
      }, // 2
      {
        data: "client_job_no",
        title: "Company Job No",
        width: 150
        // className: "dt-body-center"
      }, // 3
      {
        data: "pre_check_in_item_no",
        title: "Pre-Check-in",
        className: "dt-body-center",
        width: 100,
        visible: false
      }, // 4
      {
        data: "checked_in_or_pre_checkout_item_no",
        title: "In Warehouse",
        width: 100,
        className: "dt-body-center"
      }, // 5
      {
        data: "pre_check_out_item_no",
        title: "Pre-Check-Out",
        className: "dt-body-center",
        visible: false
      }, // 6
      {
        data: "checked_out_item_no",
        title: "Checked-Out",
        className: "dt-body-center",
        width: 100
      }, // 7
      {
        data: "container_no",
        title: "Container No",
        className: "dt-body-center",
        visible: false
      }, // 8
      {
        data: null,
        title: "View",
        className: "dt-body-center",
        responsivePriority: 1
      } // 9
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[2, "asc"]]
  }
};
