import {
  DataTableParamsType,
  DatatableIconType
} from "../../global_functions/types/dataTables";

import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const deleteButton = { icon: "Delete", tooltip: "Delete Department" };
const editButton = { icon: "Visibility", tooltip: "" };

export const departmentsMain: DataTableParamsType = {
  section: "departments",
  refreshURL: "departments/",
  reduxTableName: "departmentsMain",
  tableParams: {
    autoWidth: true,
    responsive: true,
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    buttonsColumn: {
      id: 4,
      genIcons: (rowData: DepartmentsRowType): DatatableIconType[] => {
        const return_array = [editButton];
        if (rowData.deletable) return_array.push(deleteButton);
        return return_array;
      }
    },
    columns: [
      {
        data: "id",
        title: "ID",
        className: "dt-body-center",
        width: 100
      }, // 0
      {
        data: "name",
        title: "Department Name",
        className: "dt-body-center",
        responsivePriority: 1,
        width: 200
      }, // 1
      {
        data: "resource_planner_order",
        title: "Resource Planner Order",
        className: "dt-body-center",
        width: 150
      }, // 2
      {
        data: "position_list",
        title: "Positions",
        width: 500
      }, // 3
      {
        data: null,
        title: "Actions",
        className: "dt-body-center",
        responsivePriority: 1,
        width: 100
      } // 4
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[0, "asc"]]
  }
};

type DepartmentsRowType = {
  deletable: boolean;
};
