import React from "react";

import {useSelector} from "react-redux";

// template
import GridContainer from "../../../../Grid/GridContainer";
// custom components
import WidgetSwitcher from "./WidgetSwitcher";
import StaffLocationsAdmin from "./AdminWidgets/StaffLocationsAdmin";
import ChartAdmin from "./AdminWidgets/ChartAdmin";
import FutureBookingsUser from "./UserWidgets/FutureBookingsUser";
import ProcurementWidgetAdmin from "./AdminWidgets/ProcurementWidgetAdmin";
import ProcurementWidgetUser from "./UserWidgets/ProcurementWidgetUser";
import VehicleWidgetAdmin from "./AdminWidgets/VehicleWidgetAdmin";
// admin widgets
import HolidaysWidgetAdmin from "./AdminWidgets/HolidaysWidgetAdmin";
import ExpensesWidgetAdmin from "./AdminWidgets/ExpensesWidgetAdmin";
import UsersWidgetAdmin from "./AdminWidgets/UsersWidgetAdmin";
import TimesheetsWidgetAdmin from "./AdminWidgets/TimesheetsWidgetAdmin";
import TrainingWidget from "./AdminWidgets/TrainingWidget";
// user widgets
import TimesheetsWidgetUser from "./UserWidgets/TimesheetsWidgetUser";
import HolidaysWidgetUser from "./UserWidgets/HolidaysWidgetUser";
import ExpensesWidgetUser from "./UserWidgets/ExpensesWidgetUser";
// types
import {RootStateType} from "../../../../../redux/reducers";


const UserAdminDashboard: React.FC = () => {

  const { viewedWidgetType } = useSelector(
    (state: RootStateType) => state.userAdminDashboard
  );
  const authUser = useSelector((state: RootStateType) => state.auth).user;

  return (
      <div>
        <WidgetSwitcher />
        {viewedWidgetType === "admin" ?
          <>
            <GridContainer>
              { authUser?.userprofile.is_timesheets_admin ? <TimesheetsWidgetAdmin /> : <></> }
              { authUser?.userprofile.is_expenses_admin ? <ExpensesWidgetAdmin /> : <></> }
              { authUser?.userprofile.is_holidays_admin ? <HolidaysWidgetAdmin /> : <></> }
              { authUser?.userprofile.is_user_db_admin ? <UsersWidgetAdmin /> : <></> }
              { authUser?.userprofile.is_procurement_admin ? <ProcurementWidgetAdmin /> : <></> }
              { authUser?.userprofile.is_vehicle_tracking_admin ? <VehicleWidgetAdmin /> : <></> }
              { authUser?.userprofile.is_hrhs_admin ? <TrainingWidget /> : <></> }
            </GridContainer>
            <GridContainer>
              { authUser?.userprofile.is_bookings_admin ? <StaffLocationsAdmin/> : <></> }
              { authUser?.userprofile.is_stats_admin ? <ChartAdmin/> : <></> }
            </GridContainer>
          </>
        : viewedWidgetType === "user" ?
          <>
            <GridContainer>
              { authUser?.userprofile.is_timesheets_user ? <TimesheetsWidgetUser /> : <></> }
              { authUser?.userprofile.is_expenses_user ? <ExpensesWidgetUser /> : <></> }
              { authUser?.userprofile.is_holidays_user ? <HolidaysWidgetUser /> : <></> }
              { authUser?.userprofile.is_procurement_user ? <ProcurementWidgetUser /> : <></> }
            </GridContainer>
            {authUser?.userprofile.is_bookings_user ?
              <GridContainer>
                <FutureBookingsUser/>
              </GridContainer>
              :
              <></>
            }
          </>
        :
          <></>
        }
      </div>
  )
}

export default UserAdminDashboard;
