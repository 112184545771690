import React, { useEffect, useState } from "react";

// custom components
import NightIcon from "components/_Custom/_Common/NightIcon";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import ProjectSelect from "components/_Custom/_Common/ProjectSelect";
// @material-ui
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/core/styles";
// template
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomSelect/CustomSelect";
import Button from "components/CustomButtons/Button";
// custom functions
import getDataIDFromEvent from "global_functions/_common/getDataIDFromEvent";
import formatDate from "global_functions/_common/formatDate";
// styles
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import { makeStyles } from "@material-ui/core/styles";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import buttonStyles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
// date
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
// styles & themes
import { customTheme } from "global_functions/_common/customTheme";
const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);
const useButtonStyles = makeStyles(buttonStyles);

export default function TsEntryUserForm(props) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();
  const buttonClasses = useButtonStyles();

  const [clientID, setClientID] = useState(0);
  const [projID, setProjID] = useState(0);
  const [date, setDate] = React.useState(new Date());
  const [location, setLocation] = useState(0);
  const [start, setStart] = useState(new Date());
  const [finish, setFinish] = useState(new Date());
  const [breakTime, setBreakTime] = useState(1);
  const [totalHrs, setTotalHrs] = useState(8);
  const [projFunction, setProjFunction] = useState(1);
  const [comments, setComments] = useState("");
  const [overnightStay, setOvernightStay] = useState(false);

  // loads form with data// if form has 'edit data', fills in form, otherwise cleans it
  useEffect(() => {
    if (props.editData && props.open) {
      setClientID(props.editData.client_id);
      setProjID(props.editData.proj_no);
      setLocation(props.editData.location);
      setBreakTime(props.editData.breaks);
      setTotalHrs(props.editData.hours);
      setProjFunction(props.editData.function);
      setComments(props.editData.comments);
      setOvernightStay(props.editData.overnight_stay);
      // convert date from YYYY-MM-DD to JS date object
      let projDateParts = props.editData.job_date.split("-");
      setDate(
        new Date(projDateParts[0], projDateParts[1] - 1, projDateParts[2])
      );
      // set start and finish times (timepicker only accepts full date)
      let startTime = new Date();
      let finishTime = new Date();
      let startTimeParts = props.editData.start.split(":");
      let finishTimeParts = props.editData.finish.split(":");
      startTime.setHours(
        parseInt(startTimeParts[0]),
        parseInt(startTimeParts[1]),
        0,
        0
      );
      finishTime.setHours(
        parseInt(finishTimeParts[0]),
        parseInt(finishTimeParts[1]),
        0,
        0
      );
      setStart(startTime);
      setFinish(finishTime);
    } else if (!props.editData && props.open) {
      setClientID(0);
      setProjID(0);
      setLocation(0);
      setBreakTime(1);
      setTotalHrs(8);
      setProjFunction(0);
      setComments("");
      setOvernightStay(false);
      setDate(new Date());
      // set start and finish times (timepicker only accepts full date)
      let startTime = new Date();
      startTime.setHours(8, 0, 0, 0);
      setStart(startTime);
      let finishTime = new Date();
      finishTime.setHours(17, 0, 0, 0);
      setFinish(finishTime);
    }
  }, [props.editData, props.open]);

  // shortcut buttons
  const handleShortcut = e => {
    e.stopPropagation(); // stop the click listener from picking this up
    let startTime = new Date();
    let finishTime = new Date();
    let btnDataID = getDataIDFromEvent(e);

    let shortcutTimes = [];

    switch (btnDataID) {
      case "fdShortcut":
        shortcutTimes = [8, 0, 17, 0];
        setBreakTime(1);
        break;
      case "mornShortcut":
        shortcutTimes = [8, 0, 12, 30];
        setBreakTime(0.5);
        break;
      case "aftnShortcut":
        shortcutTimes = [12, 30, 17, 0];
        setBreakTime(0.5);
        break;
      default:
        return false;
    }

    startTime.setHours(shortcutTimes[0], shortcutTimes[1], 0, 0);
    finishTime.setHours(shortcutTimes[2], shortcutTimes[3], 0, 0);

    setStart(startTime);
    setFinish(finishTime);
  };

  // stores form data
  useEffect(() => {
    props.setformData({
      number: projID,
      location: location,
      function: projFunction,
      job_date: formatDate(date, "yyyy-mm-dd"),
      start:
        `${start.getHours()}`.padStart(2, "0") +
        ":" +
        `${start.getMinutes()}`.padStart(2, "0"),
      finish:
        `${finish.getHours()}`.padStart(2, "0") +
        ":" +
        `${finish.getMinutes()}`.padStart(2, "0"),
      breaks: breakTime,
      hours: totalHrs,
      comments: comments,
      overnight_stay: overnightStay
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projID,
    location,
    projFunction,
    date,
    start,
    finish,
    breakTime,
    totalHrs,
    comments,
    overnightStay,
  ]);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "540px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            <ProjectSelect
              clientList={props.clientList}
              formLoading={props.formLoading}
              clientID={clientID}
              setClientID={setClientID}
              statusFilters={[1, 5]}
              projID={projID}
              setProjID={setProjID}
              lhsFieldWidth={2}
              rhsFieldWidth={10}
            />
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Date
                </FormLabel>
              </GridItem>
              <GridItem sm={5}>
                <ThemeProvider theme={customTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ float: "left" }}
                      margin="normal"
                      label="Date"
                      format="dd/MM/yyyy"
                      value={date}
                      onChange={date => setDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </GridItem>

              <GridItem xs={5}>
                <div
                  className={formClasses.inlineChecks}
                  style={{ marginTop: "25px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="singleDayCheck"
                        tabIndex={-1}
                        onClick={e => setOvernightStay(e.target.checked)}
                        checked={overnightStay}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot
                    }}
                    label="Overnight Stay"
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Times
                </FormLabel>
              </GridItem>
              <GridItem xs={5} sm={4}>
                <ThemeProvider theme={customTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      style={{ float: "left" }}
                      margin="normal"
                      id="startTime"
                      label="Start Time"
                      value={start}
                      onChange={e => setStart(e)}
                      KeyboardButtonProps={{
                        "aria-label": "change time"
                      }}
                      minutesStep={15}
                      ampm={false}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </GridItem>
              <GridItem xs={5} sm={4}>
                <ThemeProvider theme={customTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      style={{ float: "left" }}
                      margin="normal"
                      id="finishTime"
                      label="Finish Time"
                      onChange={e => setFinish(e)}
                      value={finish}
                      KeyboardButtonProps={{
                        "aria-label": "change time"
                      }}
                      minutesStep={15}
                      ampm={false}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </GridItem>
              <GridItem xs={2}>
                <NightIcon
                  start={start}
                  finish={finish}
                  breakTime={breakTime}
                  setTotalHrs={setTotalHrs}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Shortcuts
                </FormLabel>
              </GridItem>
              <GridItem xs={10}>
                <Button
                  data-id="fdShortcut"
                  color="primary"
                  size="sm"
                  style={{ marginTop: "32px", float: "left" }}
                  className={buttonClasses.marginRight}
                  onClick={handleShortcut}
                >
                  Full Day
                </Button>
                <Button
                  data-id="mornShortcut"
                  color="primary"
                  size="sm"
                  style={{ marginTop: "32px", float: "left" }}
                  className={buttonClasses.marginRight}
                  onClick={handleShortcut}
                >
                  Morning
                </Button>
                <Button
                  data-id="aftnShortcut"
                  color="primary"
                  size="sm"
                  style={{ marginTop: "32px", float: "left" }}
                  className={buttonClasses.marginRight}
                  onClick={handleShortcut}
                >
                  Afternoon
                </Button>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Location
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomSelect
                  options={props.formChoiceFields.LOCATION_CHOICES}
                  isDisabled={props.formLoading}
                  value={location}
                  setValue={setLocation}
                  required={true}
                />
              </GridItem>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Break
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomSelect
                  options={props.formChoiceFields.BREAKTIME_CHOICES}
                  isDisabled={props.formLoading}
                  value={breakTime}
                  setValue={setBreakTime}
                  required={true}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Total
                </FormLabel>
              </GridItem>
              <GridItem xs={2}>
                <CustomInput
                  id="totalHrs"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Nickname"
                  inputProps={{
                    disabled: true,
                    type: "text",
                    value: totalHrs,
                    onChange: e => {
                      setTotalHrs(e.target.value);
                    },
                    maxLength: 30
                  }}
                />
              </GridItem>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Function
                </FormLabel>
              </GridItem>
              <GridItem xs={6}>
                <CustomSelect
                  options={props.formChoiceFields.FUNCTION_CHOICES}
                  isDisabled={props.formLoading}
                  value={projFunction}
                  setValue={setProjFunction}
                  required={true}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Comments
                </FormLabel>
              </GridItem>
              <GridItem xs={10}>
                <CustomInput
                  id="comments"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Nickname"
                  inputProps={{
                    type: "text",
                    value: comments,
                    onChange: e => {
                      setComments(e.target.value);
                    },
                    maxLength: 499
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
}
