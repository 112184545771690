import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";

import { useMediaQuery } from "react-responsive";

import { getDialogProps } from "global_functions/_common/getDialogProps";

// redux store
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// styles
import { useDispatch } from "react-redux";
import showNotification from "global_functions/redux_action_creators/showNotification";
import GridItem from "components/Grid/GridItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "components/CustomButtons/Button";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import notificationStyles from "../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle";

const useNotifyStyles = makeStyles(notificationStyles);

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AddEditDtEntryDialog(props) {
  const [formLoading, setFormLoading] = useState(null);

  const dispatch = useDispatch();

  // form fields
  const [formChoiceFields, setFormChoiceFields] = useState("");
  const [clientList, setClientList] = useState("");

  const [editData, setEditData] = useState(""); // data for form pre-filling on load
  const [formData, setFormData] = useState({}); // data submitted

  const notifyClasses = useNotifyStyles();

  // set baseURL
  const apiURL = process.env.REACT_APP_API_V1_URL;
  let baseURL;
  // for 'detail view' tables, URL is generated based on ID and cannot be stored in a file so is passed as prop
  if (props.detailURLSuffix) {
    baseURL = apiURL + props.detailURLSuffix;
  } else {
    baseURL = apiURL + props.urlSuffix;
  }

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  // below hook runs on every form is open or closes
  useEffect(() => {
    // cleanUpForm();
    setFormLoading(false); // false when adding and closing, only true for editing (set manually below)
    if (props.addEditModal && props.editDelID) {
      // opening an 'edit' form
      document.addEventListener("keydown", captureKeyDown, false);
      setFormLoading(true);
      axios
        .get(baseURL + props.editDelID, getTokenConfig(store.getState))
        .then(res => setEditData(res.data))
        .then(() => setFormLoading(false));
    } else if (props.addEditModal && !props.editDelID) {
      // opening an add form
      document.addEventListener("keydown", captureKeyDown, false);
      setEditData("");
    } else {
      // closing the form
      document.removeEventListener("keydown", captureKeyDown, false);
      setEditData("");
    }

    // eslint-disable-next-line
    }, [props.addEditModal, props.editDelID, baseURL]);

  function handleFormSubmit(e) {
    e.preventDefault();
    let reqMethod = "";
    let reqSuccessMsg = "";

    if (props.editDelID) {
      // if edit id is specified, set edit url and method
      baseURL += props.editDelID;
      reqMethod = "put";
      reqSuccessMsg = "Entry updated";
    } else {
      // if edit id is 0 then adding new entry
      reqMethod = "post";
      reqSuccessMsg = "Entry added";
    }

    setFormLoading(true);

    axios
      .request({
        url: baseURL,
        method: reqMethod,
        data: formData,
        headers: getTokenConfig(store.getState).headers
      })
      .then(res =>
        dispatch({ type: props.tableRefreshType, payload: res.data })
      )
      .then(() => {
        dispatch(showNotification(reqSuccessMsg, "success"));
        props.setAddEditModal(false);
        setFormLoading(false);
        setEditData(""); // clear form after submitting
      })
      .catch(err => {
        let errorMsg;
        if (err.response.data.errors) errorMsg = err.response.data.errors;
        else if (
          typeof err.response.data === "object" &&
          err.response.data !== null
        )
          errorMsg = JSON.stringify(err.response.data);
        else errorMsg = "Error has occurred.";

        dispatch(showNotification(errorMsg, "error"));
        setFormLoading(false);
      });
  }

  // runs once to load client filter (if required)
  useEffect(() => {
    if (props.filterInForm) {
      setFormLoading(true);
      axios
        .get(
          apiURL + "clients/?data_format=" + props.filterInForm,
          getTokenConfig(store.getState)
        )
        .then(res => setClientList(res.data))
        .then(() => setFormLoading(false));
    }
  }, [apiURL, props.filterInForm]);

  // runs once to load form choice field options
  useEffect(() => {
    if (props.choiceFieldForm) {
      setFormLoading(true);
      axios
        .get(
          apiURL + "form-fields/" + props.choiceFieldForm,
          getTokenConfig(store.getState)
        )
        .then(res => setFormChoiceFields(res.data))
        .then(() => setFormLoading(false));
    }
  }, [apiURL, props.filterInForm, props.choiceFieldForm]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} className={notifyClasses.center}>
        <Dialog
          {...getDialogProps(isTabletOrMobile, notifyClasses)}
          fullWidth={true}
          open={props.addEditModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => props.setAddEditModal(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={notifyClasses.modalHeader}
          >
            <Button
              justIcon
              className={notifyClasses.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => props.setAddEditModal(false)}
            >
              <Close className={notifyClasses.modalClose} />
            </Button>
            <h4 className={notifyClasses.modalTitle}>
              {props.editDelID ? <>{props.editTitle}</> : <>{props.addTitle}</>}
            </h4>
          </DialogTitle>
          {props.children &&
            React.cloneElement(props.children, {
              // fields that all forms have
              setformData: setFormData,
              open: props.addEditModal,
              formLoading: formLoading,
              handleFormSubmit: handleFormSubmit,
              // other fields (will come up as undefined for forms where they're not used)
              clientList: clientList,
              editData: editData,
              formChoiceFields: formChoiceFields
            })}
          <DialogActions className={notifyClasses.modalFooter}>
            <Button
              color="primary"
              simple
              type="submit"
              form="addEditForm"
              disabled={props.formLoading}
            >
              Save
            </Button>
            <Button
              onClick={() => props.setAddEditModal(false)}
              color="transparent"
              simple
              disabled={props.formLoading}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}
