import React from "react";

// redux
import { useSelector } from "react-redux";
import { RootStateType } from "redux/reducers";
// template
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// constants
import { boxHeight } from "../TopStats";
import { salesPipelineMain } from "views/DataTables/SalesPipeline/salesPipelineMain";
// styles
import { useStatsStyles } from "../TopStats";

const QuoteStats: React.FC<QuoteStats> = ({ statsData, statsLoading }) => {
  const reduxPageFilter = useSelector(
    (state: RootStateType) =>
      state.salesPipeline.tables.salesPipelineMain.pageFilter
  );
  const TAB_VALUES = salesPipelineMain.filterTabValues;

  const pageFilter =
    TAB_VALUES && reduxPageFilter ? TAB_VALUES[reduxPageFilter] : 0;

  const classes = useStatsStyles();

  return (
    <GridItem xs={4} sm={4} md={2}>
      <GridContainer
        className={classes.stats}
        // @ts-ignore
        style={{ fontSize: "15px", minHeight: boxHeight, textAlign: "center" }}
      >
        <GridItem xs={12}>
          <h6 style={{ textAlign: "center" }}>Quote Totals</h6>
        </GridItem>
        <GridItem xs={12}>
          <h6
            style={{
              fontWeight: pageFilter === 0 ? "bold" : "normal",
              textAlign: "center"
            }}
          >
            Total Quoted
          </h6>
          <p style={{ margin: 0 }}>
            {!statsLoading && "total_quoted" in statsData
              ? statsData.total_quoted
              : "Loading..."}
          </p>
        </GridItem>
        <GridItem xs={12}>
          <h6
            style={{
              fontWeight: pageFilter === 2 ? "bold" : "normal",
              textAlign: "center"
            }}
          >
            Sent
          </h6>
          <p style={{ margin: 0 }}>
            {!statsLoading && "quote_sent" in statsData
              ? statsData.quote_sent
              : "Loading..."}
          </p>
        </GridItem>
        <GridItem xs={12}>
          <h6
            style={{
              fontWeight: pageFilter === 3 ? "bold" : "normal",
              textAlign: "center"
            }}
          >
            PO Received
          </h6>
          <p style={{ margin: 0 }}>
            {!statsLoading && "po_received" in statsData
              ? statsData.po_received
              : "Loading..."}
          </p>
        </GridItem>
        <GridItem xs={12}>
          <h6
            style={{
              fontWeight: pageFilter === 6 ? "bold" : "normal",
              textAlign: "center"
            }}
          >
            Declined
          </h6>
          <p style={{ margin: 0 }}>
            {!statsLoading && "declined" in statsData
              ? statsData.declined
              : "Loading..."}
          </p>
        </GridItem>
        <GridItem xs={12}>
          <h6
            style={{
              fontWeight: pageFilter === 7 ? "bold" : "normal",
              textAlign: "center"
            }}
          >
            Archived
          </h6>
          <p>
            {!statsLoading && "received_outstanding" in statsData
              ? statsData.received_outstanding
              : "Loading..."}
          </p>
        </GridItem>
      </GridContainer>
    </GridItem>
  );
};

export default QuoteStats;

interface QuoteStats {
  statsData: any;
  statsLoading: boolean;
}
