import React, { useState } from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  SET_PUBLIC_HOLS_DB_DT_PAGE,
  SET_PUBLIC_HOLS_DB_DT_ROW,
  SET_PUBLIC_HOLS_DB_DT_TRIG_REFRESH
} from "redux/dispatch_types";

// custom components
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
// custom functions
import showNotification from "global_functions/redux_action_creators/showNotification";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import identifyClick from "global_functions/_common/identifyClick";
import useEventListener from "global_functions/_common/useEventListener";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
// material-ui components
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
// dialogs and form
import DeleteDialog from "views/Dialogs/_Common/DeleteDialog";
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import AddEditPublicHolidayForm from "views/Forms/AddEditPublicHolidayForm";
// table
import { publicHolsMain } from "views/DataTables/PublicHolidays/publicHolsMain";
// styles
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

export default function PublicHolidaysPage() {
  const reduxPageProps = useSelector(state => state.publicHols);

  const dispatch = useDispatch();

  const classes = useStyles();
  const [dataTable, setDataTable] = useState("");

  const [addEditModal, setAddEditModal] = useState(false);
  const [editDelId, setEditDelId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");

  useDataTableRefresh(dataTable, publicHolsMain);

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_PUBLIC_HOLS_DB_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_PUBLIC_HOLS_DB_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        default:
          return false;
      }
    }
  };

  const handleAddClick = e => {
    e.stopPropagation();
    e.preventDefault();
    setEditDelId(0);
    setAddEditModal(true);
  };

  const handleEditClick = e => {
    e.stopPropagation();
    e.preventDefault();
    setEditDelId(dataTable.rows({ selected: true }).data()[0].id);
    setAddEditModal(true);
  };

  const handleDeleteClick = e => {
    e.stopPropagation();
    e.preventDefault();
    if (reduxPageProps.tables.publicHolsMain.selectedRow) {
      setEditDelId(dataTable.rows({ selected: true }).data()[0].id);
      setDeleteName(
        dataTable.rows({ selected: true }).data()[0].description +
          " (" +
          dataTable.rows({ selected: true }).data()[0].date +
          ")"
      );
      setDeleteModal(true);
    } else {
      dispatch(showNotification("Please select an entry first", "warning"));
    }
  };

  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Public Holidays</h4>
                <h4 className={classes.cardCategoryWhite}>
                  View & Manage Public Holidays
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <Datatable
                tableParams={publicHolsMain}
                setDataTable={setDataTable}
              />
            </CardBody>
            <CardFooter>
              <div className={classes.cardContentRight}>
                <Button
                  color="success"
                  className={classes.marginRight}
                  onClick={handleAddClick}
                >
                  <Add className={classes.icons} /> Add
                </Button>
                <Button
                  color="warning"
                  className={classes.marginRight}
                  onClick={handleEditClick}
                >
                  <Edit className={classes.icons} /> Edit
                </Button>
                <Button
                  color="danger"
                  className={classes.marginRight}
                  onClick={handleDeleteClick}
                >
                  <Delete className={classes.icons} /> Delete
                </Button>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelId}
          tableRefreshType={SET_PUBLIC_HOLS_DB_DT_TRIG_REFRESH}
          addTitle="Add Public Holiday"
          editTitle="Edit Public Holiday"
          urlSuffix="public_holidays/"
        >
          <AddEditPublicHolidayForm />
        </AddEditDtEntryDialog>
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="public holiday database"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={reduxPageProps.tables.publicHolsMain.selectedRow}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_PUBLIC_HOLS_DB_DT_TRIG_REFRESH}
          urlSuffix={"public_holidays/"}
        />
      </GridContainer>
    </div>
  );
}
