import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const moveItem = { icon: "Storage", tooltip: "Move item" };

export const warehouseItemsInContainerMain = {
  section: "warehouseItemsInContainer",
  reduxTableName: "warehouseItemsInContainerMain",
  tableParams: {
    autoWidth: true,
    responsive: true,
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    buttonsColumn: {
      id: 9,
      genIcons: () => {
        return [moveItem];
      }
    },
    columns: [
      { data: "id", title: "ID", className: "dt-body-center", width: 100 }, // 0
      { data: "manufacturer", title: "Manufacturer", width: 500 }, // 1
      { data: "model", title: "Model", width: 500 }, // 2
      { data: "serial_no", title: "Serial", width: 500 }, // 3
      {
        data: "type_str",
        title: "Type",
        width: 500,
        className: "dt-body-center"
      }, // 4
      { data: "qty", title: "Qty", width: 200, className: "dt-body-center" }, // 5
      {
        data: "location_str",
        title: "Location",
        width: 500,
        className: "dt-body-center"
      }, // 6
      { data: "comments", title: "Comments", width: 750 }, // 7
      {
        data: "status",
        title: "Status",
        className: "dt-body-center",
        width: 200
      }, // 8
      {
        data: null,
        title: "Actions",
        className: "dt-body-center",
        width: 1000,
        responsivePriority: 1
      } // 9
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },

    aaSorting: [[0, "asc"]]
  }
};
