import React, { useEffect, useState, Dispatch } from "react";
import axios from "axios";

import store from "redux/store";

import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";

// template
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import FormLabel from "@material-ui/core/FormLabel";
// types
import {
  GridSizeType,
  FormChoiceField
} from "../../../global_functions/types/shared";
// custom
import CustomSelect from "components/CustomSelect/CustomSelect";
// styles
import { makeStyles } from "@material-ui/core/styles";
import formsStyles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const apiUrl = process.env.REACT_APP_API_V1_URL;

// @ts-ignore
const useFormStyles = makeStyles(formsStyles);

const ProjectSelect: React.FC<ProjectSelectProps> = ({
  projID,
  setProjID,
  clientID,
  setClientID,
  customUrlSuffix,
  statusFilters,
  lhsFieldWidth,
  rhsFieldWidth,
  formLoading,
  clientList
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [projList, setProjList] = useState([]);

  const formClasses = useFormStyles();

  // when selected client id changes, filter projects
  useEffect(() => {
    if (clientID || clientID === 0) {
      setIsLoading(true);
      axios
        .get(
          customUrlSuffix
            ? `${apiUrl}projects/${customUrlSuffix}&client_id=${clientID}&serializer=dropdown`
            : `${apiUrl}projects/?status_filters=${statusFilters}&client_id=${clientID}&serializer=dropdown`,
          getTokenConfig(store.getState)
        )
        .then(res => {
          const projOptions = res.data.map((clientObj: ClientObjType) => {
            return {
              value: clientObj.id,
              label: clientObj.description
            };
          });
          setProjList(projOptions);
        })
        .then(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientID]);

  return (
    <>
      <GridContainer>
        <GridItem xs={lhsFieldWidth}>
          <FormLabel className={formClasses.labelHorizontal}>Client</FormLabel>
        </GridItem>
        <GridItem xs={rhsFieldWidth}>
          {/*<Select options={clientList}*/}
          {/*        onChange={handleClientSelect}*/}
          {/*        placeholder={"Select client..."}*/}
          {/*        disabled={isLoading}/>*/}
          <CustomSelect
            options={clientList}
            isDisabled={formLoading || isLoading}
            value={clientID}
            setValue={setClientID}
            required={true}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={lhsFieldWidth}>
          <FormLabel className={formClasses.labelHorizontal}>Project</FormLabel>
        </GridItem>
        <GridItem xs={rhsFieldWidth}>
          <CustomSelect
            options={projList}
            isDisabled={formLoading || isLoading}
            value={projID}
            setValue={setProjID}
            required={true}
          />
        </GridItem>
      </GridContainer>
    </>
  );
};

export default ProjectSelect;

interface ProjectSelectProps {
  projID: number;
  setProjID: Dispatch<number>;
  clientID: number;
  setClientID: Dispatch<number>;
  customUrlSuffix?: string;
  statusFilters: number[] | null;
  lhsFieldWidth: GridSizeType;
  rhsFieldWidth: GridSizeType;
  formLoading: boolean;
  clientList: FormChoiceField[];
}

interface ClientObjType {
  id: number;
  description: string;
}
