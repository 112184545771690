import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// icon
import Cancel from "@material-ui/icons/Cancel";

import { dangerColor } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(theme => ({
  rejectButton: {
    backgroundColor: dangerColor[0],
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#f9a19a" },
    marginTop: 10,
    marginRight: 10
  }
}));

const RejectButton: React.FC<RejectButton> = ({ onClick, tooltip }) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip}>
      <IconButton className={classes.rejectButton} onClick={onClick}>
        <Cancel style={{ color: "#f9f9f9" }} />
      </IconButton>
    </Tooltip>
  );
};

export default RejectButton;

interface RejectButton {
  onClick: () => void;
  tooltip: string;
}
