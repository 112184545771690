import axios from "axios";

import showNotification from "global_functions/redux_action_creators/showNotification";
import showAuthError from "../showAuthError";
import {
  SET_USER_DB_CLOSE_ADD_DIALOG,
  SET_USER_DB_DT_TRIG_REFRESH
} from "../../../redux/dispatch_types";

const apiUrl = process.env.REACT_APP_API_V1_URL;

export const registerUserFromAdmin = (
  firstName,
  lastName,
  email,
  position,
  password,
  password2,
  clientId
) => (dispatch, getState) => {
  // check if passwords match
  if (password !== password2) {
    const errMsg = {
      response: {
        data: { password: ["provided passwords do not match"] },
        status: ""
      }
    };
    dispatch(showAuthError(errMsg));
  } else {
    // Request Body
    const body = JSON.stringify({
      username: email,
      first_name: firstName,
      last_name: lastName,
      email: email,
      userprofile: { position: position, client: clientId },
      password: password
    });

    axios
      .post(apiUrl + "accounts/register/", body)
      .then(resp => {
        dispatch(showNotification("Account registered", "success"));
        dispatch({
          type: SET_USER_DB_DT_TRIG_REFRESH,
          payload: resp.data.user
        });
      })
      .then(() => dispatch({ type: SET_USER_DB_CLOSE_ADD_DIALOG }))
      .catch(err => {
        dispatch(showAuthError(err));
      });
  }
};
