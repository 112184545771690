import {
  SET_SALES_PIPELINE_DT_PAGE,
  SET_SALES_PIPELINE_DT_TRIG_REFRESH,
  SET_SALES_PIPELINE_DT_ROW,
  SET_SALES_PIPELINE_FILTER,
  SET_SALES_PIPELINE_STATS_YEAR_INC,
  SET_SALES_PIPELINE_STATS_YEAR_DEC,
  SET_SALES_PIPELINE_STATS_SHOW,
  SET_SALES_PIPELINE_STATS_HIDE
} from "redux/dispatch_types";

import {
  ReduxTableType,
  ReduxActionType
} from "../../../../global_functions/types/shared";

const initialState: SalespPipelineStateType = {
  tables: {
    salesPipelineMain: {
      pageFilter: 0,
      selectedRow: null,
      triggerDtRefresh: {},
      viewedPage: 0
    }
  },
  stats: {
    open: false,
    year: new Date().getFullYear()
  }
};

export default function(
  state = initialState,
  action: ReduxActionType
): SalespPipelineStateType {
  switch (action.type) {
    case SET_SALES_PIPELINE_FILTER:
      return {
        ...state,
        tables: {
          ...state.tables,
          salesPipelineMain: {
            ...state.tables.salesPipelineMain,
            pageFilter: action.payload,
            viewedPage: 0
          }
        }
      };
    case SET_SALES_PIPELINE_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          salesPipelineMain: {
            ...state.tables.salesPipelineMain,
            selectedRow: action.payload
          }
        }
      };
    case SET_SALES_PIPELINE_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          salesPipelineMain: {
            ...state.tables.salesPipelineMain,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_SALES_PIPELINE_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          salesPipelineMain: {
            ...state.tables.salesPipelineMain,
            viewedPage: action.payload
          }
        }
      };

    case SET_SALES_PIPELINE_STATS_YEAR_DEC:
      // hard-cored min year (little to no data from 2020, certainly not from earlier)
      if (state.stats.year >= 2021) {
        return {
          ...state,
          stats: {
            ...state.stats,
            year: state.stats.year - 1
          }
        };
      } else return state;
    case SET_SALES_PIPELINE_STATS_YEAR_INC:
      // can't exceed current year
      if (state.stats.year < new Date().getFullYear()) {
        return {
          ...state,
          stats: {
            ...state.stats,
            year: state.stats.year + 1
          }
        };
      } else return state;
    case SET_SALES_PIPELINE_STATS_SHOW:
      return {
        ...state,
        stats: {
          ...state.stats,
          open: true
        }
      };
    case SET_SALES_PIPELINE_STATS_HIDE:
      return {
        ...state,
        stats: {
          ...state.stats,
          open: false
        }
      };
    default:
      return state;
  }
}

// typescript declarations
type SalespPipelineStateType = {
  tables: SalesPipelineTableType;
  stats: {
    year: number;
    open: boolean;
  };
};

type SalesPipelineTableType = {
  salesPipelineMain: ReduxTableType;
};
