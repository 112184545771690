// date formatter
// formats are: small letters equal digits, large letters equal words/letters

const shortMonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export default function formatDate(date, format) {
  if (date && typeof date.getMonth === "function") {
    // check if date object was provided
    // date strings
    let monthStr = (date.getMonth() + 1).toString();
    let dayStr = date.getDate().toString();
    let yearStr = date.getFullYear().toString();
    let hoursStr = date.getHours().toString();
    let minutesStr = date.getMinutes().toString();

    // date integers
    let monthInt = date.getMonth() + 1;

    if (format === "yyyy-mm-dd") {
      return [yearStr, pad(monthStr), pad(dayStr)].join("-");
    } else if (format === "hh-mm") {
      return pad(hoursStr) + ":" + pad(minutesStr);
    } else if (format === "dd MM yyyy") {
      let monthNameShortStr = shortMonthNames[monthInt - 1];
      return `${dayStr} ${monthNameShortStr} ${yearStr}`;
    } else if (format === "MM yyyy") {
      let monthNameShortStr = shortMonthNames[monthInt - 1];
      return `${monthNameShortStr} ${yearStr}`;
    }
  } else return "";
}

function pad(numberStr) {
  if (numberStr.length < 2) return "0" + numberStr;
  else return numberStr;
}
