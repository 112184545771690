import React, { useEffect, useState, useCallback } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  SET_QUOTE_BREAKDOWN_DT_TRIG_REFRESH,
  SET_QUOTE_BREAKDOWN_DT_PAGE,
  SET_QUOTE_BREAKDOWN_DT_ROW,
  SET_QUOTE_BREAKDOWN_URL
} from "../../../redux/dispatch_types";
import store from "redux/store";
// axios
import axios from "axios";
import { getTokenConfig } from "../../../global_functions/redux_action_creators/auth/getTokenConfig";
// template
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardFooter from "../../../components/Card/CardFooter";
import CardText from "../../../components/Card/CardText";
import CardBody from "../../../components/Card/CardBody";
// custom components
import AddButton from "../../../components/CustomButtons/AddButton";
import AddEditDtEntryDialog from "../../Dialogs/_Common/AddEditDtEntryDialog";
import AddEditQuoteBreakdownEntryForm from "../../Forms/AddEditQuoteBreakdownEntryForm";
import BackButton from "../../../components/CustomButtons/BackButton";
import DeleteDialog from "../../Dialogs/_Common/DeleteDialog";
// custom functions
import identifyClick from "../../../global_functions/_common/identifyClick";
import useEventListener from "../../../global_functions/_common/useEventListener";
import useFetchDocument from "../../../global_functions/_common/useFetchDocument";
// datatables
import Datatable from "../../../components/_Custom/_Common/DataTables/DataTable";
import { quoteBreakdownMain } from "../../DataTables/SalesPipeline/quoteBreakdown";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
// styles
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react";
import ReportButton from "../../../components/CustomButtons/ReportButton";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const apiUrl = process.env.REACT_APP_API_V1_URL;

const useStyles = makeStyles(styles);

const QuoteBreakdownPage = props => {
  const classes = useStyles();

  const [dataTable, setDataTable] = useState(null);

  const [editDelId, setEditDelId] = useState(0);
  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");

  // quote info
  const [breakdownTotal, setBreakdownTotal] = useState("");
  const [breakdownVat, setBreakdownVat] = useState("");
  const [quoteId, setQuoteId] = useState("");
  const [quoteProjName, setQuoteProjName] = useState("");

  // report button
  const [viewReady, setViewReady] = useState(false);

  // pdf report
  const [pdfParams, setPdfParams] = useState(false);

  const reduxPageProps = useSelector(state => state.quoteBreakdown);

  const breakdownId = props.match.params.slug;

  const handleAddClick = e => {
    e.stopPropagation();
    setEditDelId(0);
    setAddEditModal(true);
  };

  const dispatch = useDispatch();

  // memoized function for refresh breakdown stats
  const refreshQuoteInfo = useCallback(() => {
    // breakdowns
    axios
      .get(
        `${apiUrl}quotes/${breakdownId}?for_breakdown=1`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setBreakdownTotal(res.data.readable_price);
        setBreakdownVat(res.data.get_price * 0.2);
        setQuoteId(res.data.id);
        setQuoteProjName(res.data.project_name);
        setViewReady(res.data.view_ready);
      });
  }, [breakdownId]);

  const trigRefresh = reduxPageProps.tables.quoteBreakdownMain.triggerDtRefresh;

  useEffect(() => {
    // refresh quote info every time datatable is refreshed
    refreshQuoteInfo();
  }, [refreshQuoteInfo, trigRefresh]);

  useFetchDocument(pdfParams);

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "dtButton":
          if (clickRelevantType.params[0] === "Edit") {
            // dataTable.rows().deselect();
            let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
            setEditDelId(dtData.id);
            setAddEditModal(true);
          } else if (clickRelevantType.params[0] === "Delete") {
            let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
            setEditDelId(dtData.id);
            let delName = `${dtData.company_service}`;
            setDeleteName(delName);
            setDeleteModal(true);
          }
          break;
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({
            type: SET_QUOTE_BREAKDOWN_DT_ROW,
            payload: selectedRow
          });
          break;
        case "pagination":
          dispatch({
            type: SET_QUOTE_BREAKDOWN_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        default:
          return false;
      }
    }
  };

  const handleReportClick = () => {
    setPdfParams({
      url: `${apiUrl}documents/?dataset=sales_quote&quote_id=${quoteId}`,
      fileName: `Quote ${quoteId} - ${quoteProjName}.pdf`,
      fetchMsg: "Fetching quote PDF..."
    });
  };

  useEffect(() => {
    // sets refresh url for page on load
    dispatch({
      type: SET_QUOTE_BREAKDOWN_URL,
      payload: breakdownId
    });
  }, [breakdownId, dispatch]);

  useDataTableRefresh(dataTable, quoteBreakdownMain);

  useEventListener("click", handleClickEvent, window);

  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>
                  Quote {quoteId} Breakdown
                </h4>
                <h4 className={classes.cardCategoryWhite}>{quoteProjName}</h4>
              </CardText>
              <GridContainer>
                <GridItem xs={12}>
                  <BackButton />
                  {viewReady ? (
                    <ReportButton
                      tooltip="Generate quote PDF"
                      handleReportClick={handleReportClick}
                    />
                  ) : (
                    <></>
                  )}
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <Datatable
                    setDataTable={setDataTable}
                    tableParams={quoteBreakdownMain}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={10}>
                  <br />
                  <Typography variant="h5" align="right">
                    {`Quote Total: ${breakdownTotal} (+ £${
                      breakdownVat ? breakdownVat.toFixed(2) : "0.00"
                    } VAT)`}
                  </Typography>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <div className={classes.cardContentRight}>
                <AddButton
                  handleAddClick={handleAddClick}
                  tooltip="Add Breakdown Item"
                />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <AddEditDtEntryDialog
        addEditModal={addEditModal}
        setAddEditModal={setAddEditModal}
        editDelID={editDelId}
        tableRefreshType={SET_QUOTE_BREAKDOWN_DT_TRIG_REFRESH}
        addTitle="Add Breakdown Entry"
        editTitle="Edit Breakdown Entry"
        urlSuffix={reduxPageProps.tables.quoteBreakdownMain.refreshURL}
        choiceFieldForm="quote_breakdown_form"
      >
        <AddEditQuoteBreakdownEntryForm />
      </AddEditDtEntryDialog>
      <DeleteDialog
        dataTable={dataTable}
        deleteFrom="the quote"
        deleteModal={deleteModal}
        deleteName={deleteName}
        selectedRow={editDelId}
        setDeleteModal={setDeleteModal}
        tableRefreshType={SET_QUOTE_BREAKDOWN_DT_TRIG_REFRESH}
        urlSuffix={reduxPageProps.tables.quoteBreakdownMain.refreshURL}
      />
    </>
  );
};

export default QuoteBreakdownPage;
