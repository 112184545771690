import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import { useDispatch } from "react-redux";
// date & time
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { customTheme } from "global_functions/_common/customTheme";
// material ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormLabel from "@material-ui/core/FormLabel";
// custom functions
import showNotification from "global_functions/redux_action_creators/showNotification";
import getDataIDFromEvent from "global_functions/_common/getDataIDFromEvent";
import formatDate from "global_functions/_common/formatDate";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import ProjectSelect from "components/_Custom/_Common/ProjectSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
// components
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
// styles
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import buttonStyles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import NightIcon from "components/_Custom/_Common/NightIcon";

const useButtonStyles = makeStyles(buttonStyles);
const useNotifyStyles = makeStyles(notificationStyles);
const useFormStyles = makeStyles(formsStyles);

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const apiUrl = process.env.REACT_APP_API_V1_URL;

export default function AddEditBookingDialog(props) {
  const notifyClasses = useNotifyStyles();
  const formClasses = useFormStyles();
  const buttonClasses = useButtonStyles();

  const dispatch = useDispatch();

  // loaders
  const [clientListLoading, setClientListLoading] = useState(false);
  const [userListLoading, setUserListLoading] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [editFormLoading, setEditFormLoading] = useState(false);

  const [clientList, setClientList] = useState([]);
  const [usersList, setUserList] = useState([]);

  const [multiDayBooking, setMultiDayBooking] = useState(false);

  // form fields
  const [clientID, setClientID] = useState("");
  const [projId, setProjId] = useState("");
  const [selUsers, setSelUsers] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [finishTime, setFinishTime] = useState(new Date());
  const [comments, setComments] = useState("");
  const [shiftBookings, setShiftBookings] = useState(false);
  const [incBh, setIncBh] = useState(false);
  const [incWe, setIncWe] = useState(false);
  const [editSeries, setEditSeries] = useState(false);

  const formLoading =
    clientListLoading || userListLoading || formSubmitting || editFormLoading;

  // runs once to load project filter in modal
  useEffect(() => {
    setClientListLoading(true);
    setUserListLoading(true);

    axios
      .get(
        apiUrl + "clients/?data_format=filter_active_only",
        getTokenConfig(store.getState)
      )
      .then(res => setClientList(res.data))
      .then(() => {
        setClientListLoading(false);
      });

    axios
      .get(
        apiUrl + "form-fields/resplan_addedit",
        getTokenConfig(store.getState)
      )
      .then(res => setUserList(res.data.user_list))
      .then(() => {
        setUserListLoading(false);
      });
  }, []);

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  // runs every time dialog opens or closes
  useEffect(() => {
    if (props.addEditDialog) {
      // only runs when opening
      document.addEventListener("keydown", captureKeyDown, false);

      // clear checkboxes for both add and edit dialogs (always clear upon open)
      setShiftBookings(false);
      setIncBh(false);
      setIncWe(false);
      setEditSeries(false);

      if (props.bookingId && props.bookingType === "standard") {
        // if editing a booking
        setEditFormLoading(true);
        axios
          .get(
            apiUrl +
              "calendar-bookings/" +
              props.bookingId +
              "?format_type=edit_form",
            getTokenConfig(store.getState)
          )
          .then(res => {
            setSelUsers([res.data.resourceId]);
            setComments(res.data.comments);
            setClientID(res.data.client_id);
            setProjId(res.data.job_id);

            // set date (first range in the edited series)
            let startDate = new Date(res.data.series[0][0]);
            let endDate = new Date(res.data.series[0][1]);

            setStartDate(startDate);
            setEndDate(endDate);

            // set start and finish times (timepicker only accepts full date)
            let startTime = new Date();
            let finishTime = new Date();
            let startTimeParts = res.data.start.split(":");
            let finishTimeParts = res.data.finish.split(":");
            startTime.setHours(
              parseInt(startTimeParts[0]),
              parseInt(startTimeParts[1]),
              0,
              0
            );
            finishTime.setHours(
              parseInt(finishTimeParts[0]),
              parseInt(finishTimeParts[1]),
              0,
              0
            );
            setStartTime(startTime);
            setFinishTime(finishTime);
          })
          .then(() => setEditFormLoading(false));
      } else if (props.selectObj) {
        // if adding a booking, no data is fetched, user & times set from selectObj
        setClientID("");
        setProjId("");

        let clickedUser = parseInt(props.selectObj.resource.id);
        setSelUsers([clickedUser]);

        setComments("");
        // set start and finish dates based on dragging
        setStartDate(props.selectObj.start);
        setEndDate(props.selectObj.end);
        // set start and finish times (timepicker only accepts full date)
        // if day view then set based on dragging, else go for default
        if (
          props.calendarRef.current.calendar.view.type === "resourceTimelineDay"
        ) {
          setStartTime(props.selectObj.start);
          setFinishTime(props.selectObj.end);
        } else {
          let tempStartTime = new Date();
          let tempFinishTime = new Date();
          tempStartTime.setHours(8, 0, 0, 0);
          tempFinishTime.setHours(17, 0, 0, 0);
          setStartTime(tempStartTime);
          setFinishTime(tempFinishTime);
        }
      }
    } else {
      document.removeEventListener("keydown", captureKeyDown, false);
    }
  }, [
    props.selectObj,
    props.addEditDialog,
    props.calendarRef,
    props.bookingId,
    props.bookingType,
    captureKeyDown
  ]);

  // shortcut buttons
  const handleShortcut = e => {
    e.stopPropagation();
    let startTime = new Date();
    let finishTime = new Date();
    let btnDataID = getDataIDFromEvent(e);
    let shortcutTimes = [];

    switch (btnDataID) {
      case "fdShortcut":
        shortcutTimes = [8, 0, 17, 0];
        break;
      case "amShortcut":
        shortcutTimes = [8, 0, 12, 30];
        break;
      case "pmShortcut":
        shortcutTimes = [12, 30, 17, 0];
        break;
      default:
        return false;
    }

    startTime.setHours(shortcutTimes[0], shortcutTimes[1], 0, 0);
    finishTime.setHours(shortcutTimes[2], shortcutTimes[3], 0, 0);

    setStartTime(startTime);
    setFinishTime(finishTime);
  };

  // set multi day row visibility if dates are different
  useEffect(() => {
    let endDateLater = endDate - startDate > 0;
    let datesDifferent = !(
      startDate.getFullYear() === endDate.getFullYear() &&
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getDate() === endDate.getDate()
    );

    setMultiDayBooking(endDateLater && datesDifferent);
  }, [startDate, endDate]);

  // submit form
  function handleFormSubmit(e) {
    e.preventDefault();

    let reqUrl;
    let reqMethod = "";
    let reqSuccessMsg = "";

    let reqData = {
      job_no: projId,
      resource_id: selUsers,
      date: formatDate(startDate, "yyyy-mm-dd"),
      end_date: formatDate(endDate, "yyyy-mm-dd"),
      start:
        `${startTime.getHours()}`.padStart(2, "0") +
        ":" +
        `${startTime.getMinutes()}`.padStart(2, "0"),
      finish:
        `${finishTime.getHours()}`.padStart(2, "0") +
        ":" +
        `${finishTime.getMinutes()}`.padStart(2, "0"),
      comments: comments,
      shift: shiftBookings,
      inc_weekends: incWe,
      inc_bh: incBh,
      edit_series: editSeries
    };

    if (props.bookingId) {
      // if booking id is specified, set booking url and method
      reqUrl = apiUrl + "calendar-bookings/" + props.bookingId;
      reqMethod = "put";
      reqSuccessMsg = "Booking updated";
      reqData.event_edit_type = "edit_form";
    } else {
      // if edit id is 0 then adding new entry
      reqUrl = apiUrl + "calendar-bookings/";
      reqMethod = "post";
      reqSuccessMsg = "Booking added";
    }

    setFormSubmitting(true);

    // submit form
    axios
      .request({
        url: reqUrl,
        method: reqMethod,
        data: reqData,
        headers: getTokenConfig(store.getState).headers
      })
      .then(() => {
        dispatch(showNotification(reqSuccessMsg, "success"));
        props.setAddEditDialog(false);
        setFormSubmitting(false);
        props.calendarRef.current.calendar.refetchEvents();
      })
      .catch(err => {
        let errorMsg;
        err.response.data.errors
          ? (errorMsg = err.response.data.errors)
          : (errorMsg = "Error has occurred.");
        dispatch(showNotification(errorMsg, "error"));
        setFormSubmitting(false);
      });
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} className={notifyClasses.center}>
        <Dialog
          classes={{
            root: notifyClasses.center + " " + notifyClasses.modalRoot,
            paper: notifyClasses.modal
          }}
          fullWidth={true}
          open={props.addEditDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => props.setAddEditDialog(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={notifyClasses.modalHeader}
          >
            <Button
              justIcon
              className={notifyClasses.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => props.setAddEditDialog(false)}
            >
              <Close className={notifyClasses.modalClose} />
            </Button>
            <h4 className={notifyClasses.modalTitle}>
              {props.editDelID ? "Edit Booking" : "Add Booking"}
            </h4>
          </DialogTitle>
          {formLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "600px",
                width: "500px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <>
              <form id="addEditBookingForm" onSubmit={handleFormSubmit}>
                <ProjectSelect
                  clientList={clientList}
                  formLoading={formLoading}
                  clientID={clientID}
                  setClientID={setClientID}
                  statusFilters={[1, 5]}
                  lhsFieldWidth={3}
                  rhsFieldWidth={8}
                  projID={projId}
                  setProjID={setProjId}
                />
                <GridContainer>
                  <GridItem xs={3}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      User
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8}>
                    <CustomSelect
                      isMulti
                      options={usersList}
                      setValue={setSelUsers}
                      value={selUsers}
                      required={true}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={3}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      Date(s)
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={4}>
                    <ThemeProvider theme={customTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          style={{ float: "left" }}
                          margin="normal"
                          label="Start Date"
                          format="dd/MM/yyyy"
                          value={startDate}
                          onChange={date => setStartDate(date)}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                  </GridItem>
                  <GridItem xs={4}>
                    <ThemeProvider theme={customTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          style={{ float: "left" }}
                          margin="normal"
                          label="End Date"
                          format="dd/MM/yyyy"
                          value={endDate}
                          onChange={date => setEndDate(date)}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                  </GridItem>
                </GridContainer>
                {multiDayBooking && (
                  <GridContainer>
                    <GridItem xs={4} lg={3}>
                      <FormLabel
                        className={
                          formClasses.labelHorizontal +
                          " " +
                          formClasses.labelHorizontalRadioCheckbox
                        }
                      >
                        &nbsp;
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={7} lg={8}>
                      <div className={formClasses.inlineChecks}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="incWeCheckbox"
                              tabIndex={-1}
                              onClick={e => setIncWe(e.target.checked)}
                              checked={incWe}
                              checkedIcon={
                                <Check className={formClasses.checkedIcon} />
                              }
                              icon={
                                <Check className={formClasses.uncheckedIcon} />
                              }
                              classes={{
                                checked: formClasses.checked,
                                root: formClasses.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: formClasses.label,
                            root: formClasses.labelRoot
                          }}
                          label="Include weekends"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="incBhCheckbox"
                              tabIndex={-1}
                              onClick={e => setIncBh(e.target.checked)}
                              checked={incBh}
                              checkedIcon={
                                <Check className={formClasses.checkedIcon} />
                              }
                              icon={
                                <Check className={formClasses.uncheckedIcon} />
                              }
                              classes={{
                                checked: formClasses.checked,
                                root: formClasses.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: formClasses.label,
                            root: formClasses.labelRoot
                          }}
                          label="Include bank hols"
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                )}
                <GridContainer>
                  <GridItem xs={3}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      Times
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={4}>
                    <ThemeProvider theme={customTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          style={{ float: "left" }}
                          margin="normal"
                          id="startTime"
                          label="Start Time"
                          value={startTime}
                          onChange={e => setStartTime(e)}
                          KeyboardButtonProps={{
                            "aria-label": "change time"
                          }}
                          minutesStep={15}
                          ampm={false}
                        />
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                  </GridItem>
                  <GridItem xs={4}>
                    <ThemeProvider theme={customTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          style={{ float: "left" }}
                          margin="normal"
                          id="finishTime"
                          label="Finish Time"
                          onChange={e => setFinishTime(e)}
                          value={finishTime}
                          KeyboardButtonProps={{
                            "aria-label": "change time"
                          }}
                          minutesStep={15}
                          ampm={false}
                        />
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={6} />
                  <GridItem xs={5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="shiftCheckbox"
                          tabIndex={-1}
                          onClick={e => setShiftBookings(e.target.checked)}
                          checked={shiftBookings}
                          checkedIcon={
                            <Check className={formClasses.checkedIcon} />
                          }
                          icon={<Check className={formClasses.uncheckedIcon} />}
                          classes={{
                            checked: formClasses.checked,
                            root: formClasses.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot
                      }}
                      label="Shift if overlaps"
                    />
                    {!formLoading && (
                      <NightIcon
                        start={startTime}
                        finish={finishTime}
                        breakTime={0}
                      />
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={3}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      Shortcuts
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={9}>
                    <Button
                      data-id="fdShortcut"
                      color="primary"
                      size="sm"
                      style={{ marginTop: "32px", float: "left" }}
                      className={buttonClasses.marginRight}
                      onClick={handleShortcut}
                    >
                      Full Day
                    </Button>
                    <Button
                      data-id="amShortcut"
                      color="primary"
                      size="sm"
                      style={{ marginTop: "32px", float: "left" }}
                      className={buttonClasses.marginRight}
                      onClick={handleShortcut}
                    >
                      Morning
                    </Button>
                    <Button
                      data-id="pmShortcut"
                      color="primary"
                      size="sm"
                      style={{ marginTop: "32px", float: "left" }}
                      className={buttonClasses.marginRight}
                      onClick={handleShortcut}
                    >
                      Afternoon
                    </Button>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={3}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      Comments
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8}>
                    <CustomInput
                      id="comments"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helpText="Nickname"
                      inputProps={{
                        type: "text",
                        value: comments,
                        onChange: e => {
                          setComments(e.target.value);
                        },
                        maxLength: 30
                      }}
                    />
                  </GridItem>
                </GridContainer>
                {multiDayBooking && (
                  <GridContainer>
                    <GridItem xs={7} />
                    <GridItem xs={5}>
                      <div className={formClasses.inlineChecks}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="editSeriesCheckbox"
                              tabIndex={-1}
                              onClick={e => setEditSeries(e.target.checked)}
                              checked={editSeries}
                              checkedIcon={
                                <Check className={formClasses.checkedIcon} />
                              }
                              icon={
                                <Check className={formClasses.uncheckedIcon} />
                              }
                              classes={{
                                checked: formClasses.checked,
                                root: formClasses.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: formClasses.label,
                            root: formClasses.labelRoot
                          }}
                          label="Edit series"
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                )}
              </form>
            </>
          )}
          <DialogActions className={notifyClasses.modalFooter}>
            <Button
              color="primary"
              simple
              type="submit"
              form="addEditBookingForm"
              disabled={formLoading}
            >
              Save
            </Button>
            <Button
              onClick={() => props.setAddEditDialog(false)}
              color="transparent"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}
