import {
  SET_HOLS_ADMIN_MAIN_DT_FILTER,
  SET_HOLS_ADMIN_MAIN_DT_PAGE,
  SET_HOLS_ADMIN_MAIN_DT_TRIG_REFRESH,
  SET_HOLS_ADMIN_MAIN_DT_ROW,
  SET_HOLS_ADMIN_SICKIE_DT_PAGE,
  SET_HOLS_ADMIN_SICKIE_DT_ROW,
  SET_HOLS_ADMIN_STATS_DT_PAGE,
  SET_HOLS_ADMIN_STATS_DT_ROW,
  SET_HOLS_ADMIN_STATS_DT_TRIG_REFRESH,
  SET_HOLS_ADMIN_SICKIE_REFRESH_URL,
  SET_HOLS_ADMIN_STATS_REFRESH_URL
} from "redux/dispatch_types";

const initialState = {
  tables: {
    holsAdminMain: {
      selectedRow: null,
      triggerDtRefresh: {},
      viewedPage: 0,
      pageFilter: 0
    },
    holsAdminSummary: {
      selectedRow: null,
      viewedPage: 0,
      triggerDtRefresh: {}
    },
    holsAdminSickLeave: {
      selectedRow: null,
      viewedPage: 0
    }
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_HOLS_ADMIN_MAIN_DT_FILTER:
      return {
        ...state,
        tables: {
          ...state.tables,
          holsAdminMain: {
            ...state.tables.holsAdminMain,
            pageFilter: action.payload,
            viewedPage: 0
          }
        }
      };
    case SET_HOLS_ADMIN_MAIN_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          holsAdminMain: {
            ...state.tables.holsAdminMain,
            selectedRow: action.payload
          }
        }
      };
    case SET_HOLS_ADMIN_MAIN_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          holsAdminMain: {
            ...state.tables.holsAdminMain,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_HOLS_ADMIN_MAIN_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          holsAdminMain: {
            ...state.tables.holsAdminMain,
            viewedPage: action.payload
          }
        }
      };
    case SET_HOLS_ADMIN_STATS_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          holsAdminSummary: {
            ...state.tables.holsAdminSummary,
            selectedRow: action.payload
          }
        }
      };
    case SET_HOLS_ADMIN_STATS_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          holsAdminSummary: {
            ...state.tables.holsAdminSummary,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_HOLS_ADMIN_STATS_REFRESH_URL:
      return {
        ...state,
        tables: {
          ...state.tables,
          holsAdminSummary: {
            ...state.tables.holsAdminSummary,
            refreshURL: `datasets/?type=holiday_dataset&user_id=all&year=${action.payload}`
          }
        }
      };
    case SET_HOLS_ADMIN_STATS_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          holsAdminSummary: {
            ...state.tables.holsAdminSummary,
            viewedPage: action.payload
          }
        }
      };
    case SET_HOLS_ADMIN_SICKIE_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          holsAdminSickLeave: {
            ...state.tables.holsAdminSickLeave,
            viewedPage: action.payload
          }
        }
      };
    case SET_HOLS_ADMIN_SICKIE_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          holsAdminSickLeave: {
            ...state.tables.holsAdminSickLeave,
            selectedRow: action.payload
          }
        }
      };
    case SET_HOLS_ADMIN_SICKIE_REFRESH_URL:
      return {
        ...state,
        tables: {
          ...state.tables,
          holsAdminSickLeave: {
            ...state.tables.holsAdminSickLeave,
            refreshURL: `datasets/?type=sick_day_dataset&user_id=all&year=${action.payload}`
          }
        }
      };

    default:
      return state;
  }
}
