import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import BackButton from "components/CustomButtons/BackButton";

import UserProfileForm from "views/Forms/UserProfileForm";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

export default function UserDBDetailPage(props) {
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} lg={10} xl={8}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitleWhite}>User Profile</h4>
              <h4 className={classes.cardCategoryWhite}>Single User Profile</h4>
            </CardText>
            <GridContainer>
              <GridItem xs={12}>
                <BackButton />
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <UserProfileForm
              formLoading={false}
              userID={props.match.params.slug}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
