import React, { useEffect, useState } from "react";

import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";

import formatDate from "global_functions/_common/formatDate";
// date tools
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
// @material-ui
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// styles
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import { customTheme } from "global_functions/_common/customTheme";

const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);

export default function AddHolsUserForm(props) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  // form fields
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(() => {
    // initial state is today's date + 1
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);
    return endDate;
  });

  const [comments, setComments] = useState("");
  const [singleDayCheck, setSingleDayCheck] = useState(false);
  const [timeOfDay, setTimeOfDay] = useState(1);

  useEffect(() => {
    if (props.open) {
      // reset form whenever opening
      let todayDate = new Date();
      let nextDate = new Date();
      nextDate.setDate(nextDate.getDate() + 1);

      setStartDate(todayDate);
      setEndDate(nextDate);

      setComments("");
      setSingleDayCheck(false);
      setTimeOfDay(1);
    }
  }, [props.open]);

  // runs whenever form fields change
  useEffect(() => {
    // get dates only (for comparisons)
    const startDateOnly = new Date(startDate.getTime());
    const endDateOnly = new Date(endDate.getTime());
    startDateOnly.setHours(0, 0, 0, 0);
    endDateOnly.setHours(0, 0, 0, 0);

    // if two days are selected, set single check (will trigger the 'else' below after render)
    if (startDateOnly.getTime() === endDateOnly.getTime() && !singleDayCheck) {
      setSingleDayCheck(true); // set checkbox that will trigger a re render
    } else {
      props.setformData({
        start_date: formatDate(startDate, "yyyy-mm-dd"),
        end_date: singleDayCheck
          ? formatDate(startDate, "yyyy-mm-dd")
          : formatDate(endDate, "yyyy-mm-dd"),
        comments: comments,
        time_of_day: timeOfDay,
        user: "self"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, comments, timeOfDay, singleDayCheck]);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "120px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            <GridContainer>
              <GridItem xs={1} />
              <GridItem xs={5}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={customTheme}>
                    <KeyboardDatePicker
                      style={{ float: "left" }}
                      margin="normal"
                      label="Start Date"
                      format="dd/MM/yyyy"
                      value={startDate}
                      onChange={date => setStartDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </GridItem>
              <GridItem xs={5}>
                {!singleDayCheck ? (
                  <ThemeProvider theme={customTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        label="End Date"
                        format="dd/MM/yyyy"
                        value={endDate}
                        onChange={date => setEndDate(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                ) : (
                  <>
                    <div
                      className={
                        formClasses.checkboxAndRadio +
                        " " +
                        formClasses.checkboxAndRadioHorizontal
                      }
                    >
                      <FormControlLabel
                        style={{ float: "left" }}
                        control={
                          <Radio
                            checked={timeOfDay === 1}
                            onChange={e =>
                              setTimeOfDay(parseInt(e.target.value))
                            }
                            value={1}
                            name="radio button enabled"
                            aria-label="A"
                            icon={
                              <FiberManualRecord
                                className={formClasses.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={formClasses.radioChecked}
                              />
                            }
                            classes={{
                              checked: formClasses.radio,
                              root: formClasses.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: formClasses.label,
                          root: formClasses.labelRoot
                        }}
                        label="Full Day"
                      />
                      <FormControlLabel
                        style={{ float: "left" }}
                        control={
                          <Radio
                            checked={timeOfDay === 2}
                            onChange={e =>
                              setTimeOfDay(parseInt(e.target.value))
                            }
                            value={2}
                            name="radio button enabled"
                            aria-label="B"
                            icon={
                              <FiberManualRecord
                                className={formClasses.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={formClasses.radioChecked}
                              />
                            }
                            classes={{
                              checked: formClasses.radio,
                              root: formClasses.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: formClasses.label,
                          root: formClasses.labelRoot
                        }}
                        label="Morning"
                      />
                      <FormControlLabel
                        style={{ float: "left" }}
                        control={
                          <Radio
                            checked={timeOfDay === 3}
                            onChange={e =>
                              setTimeOfDay(parseInt(e.target.value))
                            }
                            value={3}
                            name="radio button enabled"
                            aria-label="C"
                            icon={
                              <FiberManualRecord
                                className={formClasses.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={formClasses.radioChecked}
                              />
                            }
                            classes={{
                              checked: formClasses.radio,
                              root: formClasses.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: formClasses.label,
                          root: formClasses.labelRoot
                        }}
                        label="Afternoon"
                      />
                    </div>
                  </>
                )}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={1} lg={1} />
              <GridItem xs={8} lg={4}>
                <div className={formClasses.inlineChecks}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="singleDayCheck"
                        tabIndex={-1}
                        onClick={e => {
                          if (e.target.checked) {
                            setEndDate(startDate);
                          } else {
                            // when switching back to range, set end date to startDate + 1
                            let endDateTmp = new Date(startDate.getTime());
                            endDateTmp.setDate(endDateTmp.getDate() + 1);
                            setEndDate(endDateTmp);
                          }
                          setSingleDayCheck(e.target.checked);
                        }}
                        checked={singleDayCheck}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot
                    }}
                    label="Single Day"
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Comments
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <CustomInput
                  id="comments"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: comments,
                    onChange: e => {
                      setComments(e.target.value);
                    },
                    maxLength: 250
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
}
