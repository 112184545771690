import React, { useEffect, useState } from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";

import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";

import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import { makeStyles } from "@material-ui/core/styles";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);

export default function AddEditProjectForm(props) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  const [clientId, setClientId] = useState("");
  const [clientJobNo, setClientJobNo] = useState("");
  const [projName, setProjName] = useState("");
  const [projNickname, setProjNickname] = useState("");
  const [projType, setProjType] = useState("");
  const [projStatus, setProjStatus] = useState("");
  const [projNumber, setProjNumber] = useState("");
  const [chargeType, setChargeType] = useState("");

  useEffect(() => {
    // if form has 'edit data', fills in form, otherwise cleans it
    if (props.editData && props.open) {
      setClientId(props.editData.client);
      setClientJobNo(props.editData.client_job_no);
      setProjName(props.editData.description);
      setProjNickname(props.editData.nickname);
      setProjType(props.editData.type);
      setProjStatus(props.editData.status);
      setChargeType(props.editData.charge);

      // get project number
      let regexTest = new RegExp("^[0-9]{3}-.{2}-"); // for removing 'ABCD-EF-' before job numbers
      let jobNumber = props.editData.number;
      jobNumber = jobNumber.replace(regexTest, ""); // Remove the '1234-XX-' from job number for editing
      if (parseInt(jobNumber)) {
        // if the project number can be turned into an integer
        let x = parseInt(jobNumber);
        jobNumber = x.toString(); // remove leading 0s
      }
      setProjNumber(jobNumber);
    } else if (!props.editData && props.open) {
      setProjName("");
      setClientId("");
      setClientJobNo("");
      setProjNickname("");
      setProjType("");
      setProjStatus("");
      setProjNumber("");
      setChargeType("");
    }
  }, [props.editData, props.open]);

  // aggregates form into a single variable for submission
  useEffect(() => {
    props.setformData({
      client: clientId,
      client_job_no: clientJobNo,
      description: projName,
      number: projNumber,
      nickname: projNickname,
      type: projType,
      status: projStatus,
      charge: chargeType
    });
    // eslint-disable-next-line
    }, [clientId, clientJobNo, projName, projNumber, projNickname, projType, projStatus, chargeType]);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "540px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            <GridContainer>
              <GridItem xs={5}>&nbsp;</GridItem>
              <GridItem xs={4}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Project Number (auto if blank)
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  id="projNo"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Project Number"
                  inputProps={{
                    type: "text",
                    value: projNumber,
                    onChange: e => {
                      setProjNumber(e.target.value);
                    },
                    maxLength: 12
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Client
                </FormLabel>
              </GridItem>
              <GridItem xs={10}>
                <CustomSelect
                  options={props.clientList}
                  isDisabled={props.formLoading || props.editData}
                  value={clientId}
                  setValue={setClientId}
                  required={true}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Name
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomInput
                  id="projName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Project name"
                  inputProps={{
                    type: "text",
                    value: projName,
                    onChange: e => {
                      setProjName(e.target.value);
                    },
                    required: true,
                    maxLength: 50
                  }}
                />
              </GridItem>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Nickname
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomInput
                  id="projNickname"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Nickname"
                  inputProps={{
                    type: "text",
                    value: projNickname,
                    onChange: e => {
                      setProjNickname(e.target.value);
                    },
                    maxLength: 30
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Status
                </FormLabel>
              </GridItem>
              <GridItem xs={5}>
                <CustomSelect
                  options={props.formChoiceFields.STATUS_CHOICES}
                  isDisabled={props.formLoading}
                  value={projStatus}
                  setValue={setProjStatus}
                  required={true}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Project Type
                </FormLabel>
              </GridItem>
              <GridItem xs={10}>
                <CustomSelect
                  options={props.formChoiceFields.TYPE_CHOICES}
                  isDisabled={props.formLoading}
                  value={projType}
                  setValue={setProjType}
                  required={true}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Charge
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomSelect
                  options={props.formChoiceFields.CHARGE_CHOICES}
                  isDisabled={props.formLoading}
                  value={chargeType}
                  setValue={setChargeType}
                  required={true}
                />
              </GridItem>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Client Job No (Optional)
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomInput
                  id="clientJobNo"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Client job number"
                  inputProps={{
                    type: "text",
                    value: clientJobNo,
                    onChange: e => {
                      setClientJobNo(e.target.value);
                    },
                    required: false,
                    maxLength: 20
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
}
