import {
  SET_WAREHOUSE_ITEMS_DT_PAGE,
  SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH,
  SET_WAREHOUSE_ITEMS_DT_ROW,
  SET_WAREHOUSE_ITEMS_REFRESH_URL,
  SET_WAREHOUSE_ITEMS_DT_FILTER
} from "redux/dispatch_types";

const initialState = {
  tables: {
    warehouseItemsMain: {
      selectedRow: null,
      triggerDtRefresh: {},
      viewedPage: 0,
      pageFilter: 0,
      refreshURL: ""
    }
  },
  entryStatus: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_WAREHOUSE_ITEMS_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          warehouseItemsMain: {
            ...state.tables.warehouseItemsMain,
            selectedRow: action.payload
          }
        }
      };
    case SET_WAREHOUSE_ITEMS_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          warehouseItemsMain: {
            ...state.tables.warehouseItemsMain,
            viewedPage: action.payload
          }
        }
      };
    case SET_WAREHOUSE_ITEMS_REFRESH_URL:
      return {
        ...state,
        tables: {
          ...state.tables,
          warehouseItemsMain: {
            ...state.tables.warehouseItemsMain,
            refreshURL: action.payload
          }
        }
      };
    case SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          warehouseItemsMain: {
            ...state.tables.warehouseItemsMain,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_WAREHOUSE_ITEMS_DT_FILTER:
      return {
        ...state,
        tables: {
          ...state.tables,
          warehouseItemsMain: {
            ...state.tables.warehouseItemsMain,
            pageFilter: action.payload,
            viewedPage: 0
          }
        }
      };
    default:
      return state;
  }
}
