import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const deleteButton = { icon: "Delete", tooltip: "" };
const editButton = { icon: "Edit", tooltip: "" };

export const clientDBMain = {
  section: "clientDB",
  refreshURL: "clients/",
  reduxTableName: "clientDBMain",
  tableParams: {
    autoWidth: true,
    responsive: true,
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    buttonsColumn: {
      id: 5,
      genIcons: () => {
        return [editButton, deleteButton];
      }
    },
    columns: [
      { data: "id", title: "ID" }, // 0
      { data: "name", title: "Name", responsivePriority: 1 }, // 1
      { data: "address", title: "Address" }, // 2
      { data: "contact_name", title: "Contact Name" }, // 3
      { data: "contact_no", title: "Contact No" }, // 4
      { data: null, title: "Actions", className: "dt-body-center" } // 5
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "asc"]]
  }
};
