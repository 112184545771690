import React from "react";
// template
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem";
// material ui
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
// styles
import formsStyles from "../../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useFormStyles = makeStyles(formsStyles);

const PermissionsBox = ({
  handleToggle,
  isBookingsAdmin,
  isBookingsUser,
  isStatsAdmin,
  isClientDbAdmin,
  isExpensesAdmin,
  isExpensesUser,
  isHolidaysAdmin,
  isHolidaysUser,
  isHrhsAdmin,
  isHrhsUser,
  isProcurementAdmin,
  isProcurementUser,
  isProjectDbAdmin,
  isSalesPipelineAdmin,
  isSettingsAdmin,
  isReportsUser,
  isTimesheetsAdmin,
  isTimesheetsUser,
  isUserDbAdmin,
  isVehicleTrackingAdmin,
  isWarehouseAdmin,
  isWarehouseUser
}) => {
  const formClasses = useFormStyles();

  return (
    <>
      <GridContainer>
        <GridItem xs={4} lg={2}>
          <FormLabel
            className={
              formClasses.labelHorizontal +
              " " +
              formClasses.labelHorizontalRadioCheckbox
            }
          >
            Permissions
          </FormLabel>
        </GridItem>
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="bookingsAdminCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isBookingsAdmin}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Bookings Admin"
            />
          </div>
        </GridItem>
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="bookingsUserCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isBookingsUser}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Bookings User"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={4} lg={2} />
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="clientDbUserCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isClientDbAdmin}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Client DB Admin"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={4} lg={2} />
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="expensesAdminCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isExpensesAdmin}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Expenses Admin"
            />
          </div>
        </GridItem>
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="expensesUserCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isExpensesUser}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Expenses User"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={4} lg={2} />
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="holsAdminCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isHolidaysAdmin}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Holidays Admin"
            />
          </div>
        </GridItem>
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="holsUserCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isHolidaysUser}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Holidays User"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={4} lg={2} />
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="hrhsAdminCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isHrhsAdmin}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="HRHS Admin"
            />
          </div>
        </GridItem>
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="hrhsUserCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isHrhsUser}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="HRHS User"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={4} lg={2} />
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="procAdminCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isProcurementAdmin}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Procurement Admin"
            />
          </div>
        </GridItem>
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="procUserCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isProcurementUser}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Procurement User"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={4} lg={2} />
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="projDbUserCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isProjectDbAdmin}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Projects DB Admin"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={4} lg={2} />
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="reportsUserCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isReportsUser}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Reports User"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={4} lg={2} />
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="settingsAdminCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isSettingsAdmin}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Settings Admin"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={4} lg={2} />
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="salesPipelineAdminCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isSalesPipelineAdmin}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Sales Pipeline Admin"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={4} lg={2} />
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="statsAdminCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isStatsAdmin}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Stats Admin"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={4} lg={2} />
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="tsAdminCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isTimesheetsAdmin}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Timesheets Admin"
            />
          </div>
        </GridItem>
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="tsUserCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isTimesheetsUser}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Timesheets User"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={4} lg={2} />
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="userDbUserCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isUserDbAdmin}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="User DB Admin"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={4} lg={2} />
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="vehicleTrackingUserCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isVehicleTrackingAdmin}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Vehicle Tracking Admin"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={4} lg={2} />
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="warehouseAdminCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isWarehouseAdmin}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Warehouse Admin"
            />
          </div>
        </GridItem>
        <GridItem xs={4} lg={3}>
          <div className={formClasses.inlineChecks}>
            <FormControlLabel
              control={
                <Checkbox
                  id="warehouseUserCheckbox"
                  tabIndex={-1}
                  onClick={handleToggle}
                  checked={isWarehouseUser}
                  checkedIcon={<Check className={formClasses.checkedIcon} />}
                  icon={<Check className={formClasses.uncheckedIcon} />}
                  classes={{
                    checked: formClasses.checked,
                    root: formClasses.checkRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Warehouse User"
            />
          </div>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default PermissionsBox;
