import React, { useEffect, useState } from "react";

import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import CustomInput from "components/CustomInput/CustomInput";

import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormLabel from "@material-ui/core/FormLabel";

import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { customTheme } from "global_functions/_common/customTheme";
import formatDate from "global_functions/_common/formatDate";

const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);

export default function AddEditPublicHolidayForm(props) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  // form fields
  const [phName, setPhName] = useState("");
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (props.editData && props.open) {
      setPhName(props.editData.description);
      let dateParts = props.editData.sortable_date.split("-");
      setDate(new Date(dateParts[0], dateParts[1] - 1, dateParts[2]));
    } else if (!props.editData && props.open) {
      setPhName("");
      setDate(new Date());
    }
  }, [props.editData, props.open]);

  useEffect(() => {
    props.setformData({
      description: phName,
      date: formatDate(date, "yyyy-mm-dd")
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phName, date]);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "120px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            <>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Name
                  </FormLabel>
                </GridItem>
                <GridItem xs={9}>
                  <CustomInput
                    id="phName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: phName,
                      onChange: e => {
                        setPhName(e.target.value);
                      },
                      required: true,
                      maxLength: 30
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Date
                  </FormLabel>
                </GridItem>
                <GridItem xs={9}>
                  <ThemeProvider theme={customTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        style={{ float: "left" }}
                        margin="normal"
                        label="Date"
                        format="dd/MM/yyyy"
                        value={date}
                        onChange={date => setDate(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </GridItem>
              </GridContainer>
            </>
          </form>
        </DialogContent>
      )}
    </>
  );
}
