import {
  SET_DEPARTMENTS_DT_PAGE,
  SET_DEPARTMENTS_DT_TRIG_REFRESH,
  SET_DEPARTMENTS_DT_ROW
} from "../../../dispatch_types";

import {
  ReduxTableType,
  ReduxActionType
} from "../../../../global_functions/types/shared";

const initialState: DepartmentsStateType = {
  tables: {
    departmentsMain: {
      selectedRow: null,
      triggerDtRefresh: {},
      viewedPage: 0
    }
  }
};

export default function(state = initialState, action: ReduxActionType) {
  switch (action.type) {
    case SET_DEPARTMENTS_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          departmentsMain: {
            ...state.tables.departmentsMain,
            selectedRow: action.payload
          }
        }
      };
    case SET_DEPARTMENTS_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          departmentsMain: {
            ...state.tables.departmentsMain,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_DEPARTMENTS_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          departmentsMain: {
            ...state.tables.departmentsMain,
            viewedPage: action.payload
          }
        }
      };
    default:
      return state;
  }
}

// typescript declarations
type DepartmentsStateType = {
  tables: { departmentsMain: ReduxTableType };
};
