import { primaryColor } from "assets/jss/material-dashboard-pro-react.js";
import Select from "react-select";
import React from "react";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

import Input from "@material-ui/core/Input";

// import makeStyles from "@material-ui/core/styles/makeStyles";

const reactSelectStyles = {
  option: (provided, state) => {
    return {
      ...provided,
      color: state.isFocused || state.isSelected ? "white" : "black"
      // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    };
  },

  control: (provided, state) => {
    return {
      ...provided,
      ...customSelectStyle.selectFormControl,
      borderBottomColor: state.isFocused ? primaryColor : "none",
      borderBottomWidth: "1px",
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      paddingTop: "20px"
    };
  },

  menu: provided => ({ ...provided, zIndex: 9999 }),

  indicatorSeparator: provided => null
};

export default function CustomSelect(props) {
  // const classes = useStyles();
  const handleHiddenBoxChange = () => {
    // this is to get rid of error message from react that complains about the hidden field not having an on change handler
    console.log();
  };

  return (
    <>
      <Select
        isMulti={!!props.isMulti}
        value={
          props.isMulti && props.options
            ? props.options.filter(obj => props.value.includes(obj.value))
            : props.options &&
              props.options.filter(obj => obj.value === props.value)
        }
        isDisabled={props.isDisabled}
        options={props.options}
        // className={classes.select}
        placeholder="--------"
        styles={reactSelectStyles}
        onChange={e => {
          // only get IDs (component returns object)
          if (props.isMulti) {
            let idArray = [];
            e && e.map(selOption => idArray.push(selOption.value));
            props.setValue(idArray);
          } else props.setValue(e.value);
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: primaryColor,
            primary25: primaryColor[3]
          }
        })}
      />
      {!props.disabled &&
      props.options && ( // this hidden input is required because react-select does not have a 'required' property
          <Input
            tabIndex={-1}
            autoComplete="off"
            onChange={handleHiddenBoxChange} // fake function to get rid of warning
            style={{
              opacity: 0,
              width: 0,
              height: 0,
              position: "absolute"
            }}
            value={
              props.isMulti && props.options
                ? props.options.filter(obj => props.value.includes(obj.value))
                : props.options &&
                  props.options.filter(obj => obj.value === props.value)
            }
            required={props.required}
          />
        )}
    </>
  );
}
