import React, { useEffect, useState } from "react";
// date utils
import DateFnsUtils from "@date-io/date-fns";
// material ui
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
// custom
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import { customTheme } from "global_functions/_common/customTheme";
import formatDate from "global_functions/_common/formatDate";
// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import ProjectSelect from "components/_Custom/_Common/ProjectSelect";
// styles
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);

export default function AddEditWarehouseScheduleEntryForm(props) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  // form fields
  const [addressee, setAddressee] = useState("");
  const [sender, setSender] = useState("");
  const [details, setDetails] = useState("");
  const [reference, setReference] = useState("");
  const [clientID, setClientID] = useState(0);
  const [projID, setProjID] = useState(0);
  const [location, setLocation] = useState(0);

  const [rangeStartDate, setRangeStartDate] = useState(new Date());
  const [rangeEndDate, setRangeEndDate] = useState(new Date());

  useEffect(() => {
    if (props.editData && props.open) {
      setLocation(props.editData.location);
      setReference(props.editData.reference);
      setAddressee(props.editData.addressee);
      setSender(props.editData.sender);
      setDetails(props.editData.details);
      setClientID(props.editData.client_id);
      setProjID(props.editData.number);
      // set range start and end dates
      let rangeStartDateParts = props.editData.expected_date_start.split("-");
      let rangeEndDateParts = props.editData.expected_date_end.split("-");
      setRangeStartDate(
        new Date(
          rangeStartDateParts[0],
          rangeStartDateParts[1] - 1,
          rangeStartDateParts[2]
        )
      );
      setRangeEndDate(
        new Date(
          rangeEndDateParts[0],
          rangeEndDateParts[1] - 1,
          rangeEndDateParts[2]
        )
      );
    } else if (!props.editData && props.open) {
      setLocation(0);
      setAddressee("");
      setSender("");
      setReference("");
      setDetails("");
      setClientID(0);
      setProjID(0);
      // set dates
      setRangeStartDate(new Date());
      setRangeEndDate(new Date());
    }
  }, [props.editData, props.open]);

  useEffect(() => {
    props.setformData({
      details: details,
      reference: reference,
      number: projID,
      expected_date_start: formatDate(rangeStartDate, "yyyy-mm-dd"),
      expected_date_end: formatDate(rangeEndDate, "yyyy-mm-dd"),
      location: location,
      addressee: addressee,
      sender: sender
    });
    // eslint-disable-next-line
    }, [details, reference, clientID, rangeStartDate, rangeEndDate, location, projID, addressee, sender]);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "350px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Sender
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: sender,
                    onChange: e => {
                      setSender(e.target.value);
                    },
                    maxLength: 200
                  }}
                  helpText=""
                />
              </GridItem>
              <GridItem xs={2}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Addressee
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: addressee,
                    onChange: e => {
                      setAddressee(e.target.value);
                    },
                    maxLength: 200
                  }}
                  helpText=""
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Details
                </FormLabel>
              </GridItem>
              <GridItem xs={10}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: details,
                    onChange: e => {
                      setDetails(e.target.value);
                    },
                    maxLength: 200
                  }}
                  helpText=""
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Reference
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: reference,
                    onChange: e => {
                      setReference(e.target.value);
                    },
                    maxLength: 200
                  }}
                  helpText=""
                />
              </GridItem>
            </GridContainer>
            <ProjectSelect
              clientList={props.clientList}
              formLoading={props.formLoading}
              clientID={clientID}
              setClientID={setClientID}
              statusFilters={[1, 5]}
              projID={projID}
              setProjID={setProjID}
              lhsFieldWidth={2}
              rhsFieldWidth={10}
            />
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Date(s)
                </FormLabel>
              </GridItem>
              <GridItem xs={5}>
                <ThemeProvider theme={customTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ float: "left" }}
                      margin="normal"
                      label="From Date"
                      format="dd/MM/yyyy"
                      value={rangeStartDate}
                      onChange={date => setRangeStartDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </GridItem>
              <GridItem xs={5}>
                <ThemeProvider theme={customTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ float: "left" }}
                      margin="normal"
                      label="To Date"
                      format="dd/MM/yyyy"
                      value={rangeEndDate}
                      onChange={date => setRangeEndDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
}
