import React, { useState } from "react";

import { useDispatch } from "react-redux";

import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import useEventListener from "global_functions/_common/useEventListener";
import identifyClick from "global_functions/_common/identifyClick";

import {
  SET_LOG_ENTRY_DT_PAGE,
  SET_LOG_ENTRY_DT_ROW
} from "redux/dispatch_types";

// table
import { logEntryMain } from "views/DataTables/Logs/logEntryMain";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

export default function LogsPage() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [dataTable, setDataTable] = useState("");

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_LOG_ENTRY_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_LOG_ENTRY_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        default:
          return false;
      }
    }
  };

  useEventListener("click", handleClickEvent, window);

  useDataTableRefresh(dataTable, logEntryMain);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>System Logs</h4>
                <h4 className={classes.cardCategoryWhite}>
                  Resource Planner Logs
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setDataTable}
                tableParams={logEntryMain}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
