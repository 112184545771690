export function getDialogProps(isTabletOrMobile, classes) {
  const desktopDialogClasses = {
    root: classes.center + " " + classes.modalRoot,
    paper: classes.modal
  };

  const mobileDialogClasses = {
    root: classes.center + " " + classes.modalRoot
  };

  if (isTabletOrMobile)
    return {
      classes: mobileDialogClasses,
      fullScreen: true
    };
  else
    return {
      classes: desktopDialogClasses,
      fullScreen: false
    };
}
