import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// icon
import Print from "@material-ui/icons/Print";
// color
import { primaryColor } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(theme => ({
  addButton: {
    backgroundColor: primaryColor[1],
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#a1b5be" },
    marginTop: 10,
    marginRight: 10
  }
}));

export default function PrintButton(props) {
  const classes = useStyles();

  return (
    <Tooltip title="Print">
      <IconButton className={classes.addButton} onClick={props.onClick}>
        <Print style={{ color: "#f9f9f9" }} />
      </IconButton>
    </Tooltip>
  );
}
