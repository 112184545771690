import React, { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";

// redux
import {
  SET_SALES_PIPELINE_STATS_YEAR_INC,
  SET_SALES_PIPELINE_STATS_YEAR_DEC
} from "redux/dispatch_types";
// template
import GridItem from "components/Grid/GridItem";
// custom components
import YearCarousel from "components/_Custom/_Common/YearCarousel";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// styles
import { useSelector } from "react-redux";
import { RootStateType } from "redux/reducers";

const SalesPipelineChart: React.FC<SalesPipelineChart> = ({
  rawChartData,
  chartLoading
}) => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const processedChartData = chartDataTpl; // copying the template as new chart data, which will then be modified
    // set retrieved data values to newChartData
    processedChartData.datasets[0].data = rawChartData[0];
    processedChartData.datasets[1].data = rawChartData[1];
    processedChartData.datasets[2].data = rawChartData[2];
    processedChartData.labels = rawChartData[3];

    setChartData(processedChartData);

    // set new chart data as current chart data
  }, [rawChartData]);

  const statsYear = useSelector(
    (state: RootStateType) => state.salesPipeline.stats.year
  );

  return (
    <GridItem xs={12} sm={12} md={6}>
      <YearCarousel
        setYear={statsYear}
        incReduxType={SET_SALES_PIPELINE_STATS_YEAR_INC}
        decReduxType={SET_SALES_PIPELINE_STATS_YEAR_DEC}
        minYear={2020}
        maxYear="current"
      />
      {chartLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <div className="chart" style={{ height: "300px" }}>
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          <Bar data={chartData} options={chartOptions} />
        </div>
      )}
    </GridItem>
  );
};

export default SalesPipelineChart;

const chartDataTpl: ChartDataTpl = {
  labels: [],
  datasets: [
    {
      label: "Sent",
      data: [],
      backgroundColor: "rgba(54, 162, 235, 0.2)",
      borderColor: "rgba(54, 162, 235, 1)",
      borderWidth: 2
    },
    {
      label: "Declined",
      data: [],
      backgroundColor: "rgba(255, 99, 132, 0.2)",
      borderColor: "rgba(255, 99, 132, 1)",
      borderWidth: 2
    },
    {
      label: "Approved",
      data: [],
      backgroundColor: "rgba(33, 145, 81, 0.2)",
      borderColor: "rgba(33, 145, 81, 1)",
      borderWidth: 2
    }
  ]
};

const chartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true,
    position: "bottom"
  },
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          // full params below are (value, index, values) but index and values aren't used
          callback: (value: string): string => {
            return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          }
        }
      }
    ],
    xAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true
        }
      }
    ]
  },
  tooltips: {
    mode: "label",
    callbacks: {
      label: (tooltipItem: TooltipItem, data: ChartDataTpl): string => {
        const tooltipItemFloat = parseFloat(tooltipItem.value).toFixed(2);
        return `${
          data.datasets[tooltipItem.datasetIndex].label
        }: £${tooltipItemFloat
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      }
    }
  }
};

type ChartDataTpl = {
  labels: string[];
  datasets: ChartDatasetType[];
};

type ChartDatasetType = {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
};

type TooltipItem = {
  datasetIndex: number;
  value: string;
};

type ChartOptions = {
  responsive: boolean;
  maintainAspectRatio: boolean;
  legend: {
    display: boolean;
    position: string;
  };
  scales: {
    yAxes: AxisType[];
    xAxes: AxisType[];
  };
  tooltips: {
    mode: string;
    callbacks: {
      label: (tooltipItem: TooltipItem, data: ChartDataTpl) => string;
    };
  };
};

type AxisType = {
  stacked: boolean;
  ticks: {
    beginAtZero: boolean;
    callback?: (value: string) => string;
  };
};

type SalesPipelineChart = {
  rawChartData: any;
  chartLoading: boolean;
};
