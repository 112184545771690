import React, { useEffect, useRef, useState } from "react";

import axios from "axios";

// redux
import { useSelector } from "react-redux";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
// components
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomAccordion from "components/CustomAccordion/CustomAccordion.js";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
// types
import { RootStateType } from "../../../../../../redux/reducers";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
// @ts-ignore
const useStyles = makeStyles(styles);

const FutureBookingsUser: React.FC = () => {
  const isInitialMount = useRef(true);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [accordionData, setAccordionData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_V1_URL;

  const currentUserId = useSelector(
    (state: RootStateType) => state.auth.user?.id
  );

  // initial load (get accordion data and update it)
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${apiUrl}datasets/?type=future_bookings_user_ranged&user_id=${currentUserId}`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setAccordionData(res.data);
      });
  }, [apiUrl, currentUserId]);

  // Once data updates, set loading to false
  useEffect(() => {
    if (isInitialMount.current) {
      // ignore initial load
      isInitialMount.current = false;
    } else {
      setIsLoading(false);
    }
  }, [accordionData]);

  return (
    <GridItem xs={12} sm={12} md={8} lg={6} xl={4}>
      {/*@ts-ignore*/}
      <Card style={{ minHeight: "300px" }}>
        {" "}
        {/*to make it similar to ChartAdmin component when there are no/few bookings*/}
        <CardHeader>
          <h4 className={classes.cardTitle}>My Bookings</h4>
        </CardHeader>
        <CardBody>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <>
              {!(accordionData.length === 0) ? (
                <>
                  <CustomAccordion
                    collapses={accordionData.map((booking: BookingType) => {
                      return {
                        title: `${booking.client} - ${booking.proj_name}`,
                        content: (
                            <div>
                                <p>{booking.address ? `Address: ${booking.address}` : ""}</p>
                                <ul>
                                  {/*@ts-ignore*/}
                                  {booking.dates.map((dateArr, index) => {
                                    return (
                                      <li key={`fbList${index}`}>
                                        {dateArr[0] === dateArr[1]
                                          ? `${dateArr[0]}`
                                          : `${dateArr[0]} - ${dateArr[1]}`}
                                      </li>
                                    );
                                  })}
                                </ul>
                            </div>
                        )
                      };
                    })}
                  />
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "300px"
                  }}
                >
                  <div>You currently have no future bookings.</div>
                </div>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </GridItem>
  );
}

export default FutureBookingsUser;

type BookingType = {
  'client': string;
  'proj_name': string;
  'dates': any[];
  'address': string;
}
