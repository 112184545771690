import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
// custom functions
import { getDialogProps } from "global_functions/_common/getDialogProps";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import CustomAccordion from "components/CustomAccordion/CustomAccordion";
// core components
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
// material ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";

const useStyles = makeStyles(styles);
const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ItemHistoryDialog(props) {
  const classes = useStyles();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [itemHistoryArray, setItemHistoryArray] = useState([]);
  const [loadingInProgress, setLoadingInProgress] = useState(false);

  useEffect(() => {
    if (props.itemHistoryDialog) {
      setLoadingInProgress(true);
      axios
        .get(
          `${apiUrl}projects/0/items/${props.itemId}`,
          getTokenConfig(store.getState)
        )
        .then(res => {
          setItemHistoryArray(res.data.history);
        })
        .then(() => setLoadingInProgress(false));
    }
  }, [props.itemHistoryDialog, props.itemId]);

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={props.itemHistoryDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.setItemHistoryDialog(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => props.setItemHistoryDialog(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Warehouse Item History</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {loadingInProgress ? (
            <CustomLoadingSpinner />
          ) : itemHistoryArray.length ? (
            <CustomAccordion
              collapses={itemHistoryArray.map((item, no) => {
                return {
                  title: `${item.time_stamp_str}: ${item.action_str}`,
                  content: (
                    <>
                      <p style={{ textAlign: "left" }}>
                        {`Action by: ${item.user_name} (user ID ${item.user_id})`}
                        <br />
                        {item.comments && `Action Comments: ${item.comments}, `}
                        {item.total_affected > 1
                          ? `Total Affected Items: ${item.total_affected}`
                          : ""}
                        <br />
                        {"Changes:"}
                        <br />
                        {Object.entries(item.changes).map((obj, i) => {
                          let rowEndSymbol = "";

                          // add comma or full stop based on item index
                          Object.entries(item.changes).length === i + 1
                            ? (rowEndSymbol = ".")
                            : (rowEndSymbol = ", ");

                          if (obj[1] !== null) {
                            return `${obj[0]}: ${obj[1]}${rowEndSymbol}`;
                          } else {
                            return `${obj[0]}: none${rowEndSymbol}`;
                          }
                        })}
                      </p>
                      {/* Full history loop*/}
                      {/*{ Object.entries(item.item_state_log).map((obj) => {*/}
                      {/*    if (obj[1] !== null) {*/}
                      {/*        return `${obj[0]}: ${obj[1]} / `*/}
                      {/*    } else {*/}
                      {/*        return ""*/}
                      {/*    }*/}
                      {/*})}*/}
                    </>
                  )
                };
              })}
            />
          ) : (
            // below should neve really be shown as history for item is created upon creation of item
            <p>This item currently has no history associated to it.</p>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="transparent"
            onClick={() => props.setItemHistoryDialog(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
