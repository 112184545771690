import React, { useCallback, useEffect, useState } from "react";

import axios from "axios";

// redux
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// components
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
// material-ui
import Info from "@material-ui/icons/Info";
import Edit from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import GridContainer from "components/Grid/GridContainer";
// constants
import { ANNUAL_LEAVE_PROJ_ID } from "../../../global_functions/systemConstants";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
const useStyles = makeStyles(styles);
// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const apiUrl = process.env.REACT_APP_API_V1_URL;

const customFormHeader = {
  textAlign: "left",
  marginBottom: "0px",
  marginTop: "10px"
};

const BookingInfoDialog = props => {
  const classes = useStyles();

  const [modalLoading, setModalLoading] = useState(false);

  const [staffMember, setStaffMember] = useState("");
  const [projectPO, setProjectPO] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [series, setSeries] = useState([]);
  const [project, setProject] = useState("");
  const [productivity, setProductivity] = useState("");
  const [comments, setComments] = useState("");
  const [lastUpdatedBy, setLastUpdatedBy] = useState("");
  const [isHoliday, setIsHoliday] = useState(false);
  const [isAnnualLeave, setIsAnnualLeave] = useState(false);

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (props.infoModal) {
      document.addEventListener("keydown", captureKeyDown, false);
    } else {
      document.removeEventListener("keydown", captureKeyDown, false);
    }
  }, [props.infoModal, captureKeyDown]);

  // load modal info
  useEffect(() => {
    if (props.infoModal) {
      // run on opening only
      setModalLoading(true);

      if (props.bookingType === "standard") {
        axios
          .get(
            apiUrl +
              "calendar-bookings/" +
              props.bookingID +
              "?format_type=info_modal",
            getTokenConfig(store.getState)
          )
          .then(res => {
            setStaffMember(res.data.booking_user);
            setProjectPO(res.data.quote_status_str);
            setEventDate(res.data.date_and_time);
            setSeries(res.data.series);
            setProject(res.data.proj_name);
            setProductivity(res.data.wasted ? "Wasted" : "Productive");
            setComments(res.data.comments);
            setIsHoliday(res.data.is_holiday);
            setIsAnnualLeave(res.data.proj_id === ANNUAL_LEAVE_PROJ_ID);
            setLastUpdatedBy(res.data.booker);
            setModalLoading(false);
          });
      } else if (props.bookingType === "timesheet") {
        axios
          .get(
            apiUrl +
              "timesheet-bundles/0/timesheets/" +
              props.bookingID +
              "?format_type=resplan_modal",
            getTokenConfig(store.getState)
          )
          .then(res => {
            setStaffMember(res.data.ts_user);
            setEventDate(res.data.date_and_time);
            setProject(res.data.proj_name);
            setComments(res.data.comments);

            // clear nont-applicable fields
            setProjectPO("");
            setSeries([]);
            setLastUpdatedBy("");

            setModalLoading(false);
          });
      }
    }
  }, [props.infoModal, props.bookingID, props.bookingType]);

  const getModalTitle = () => {
    if (props.bookingType === "standard" && !isHoliday)
      return "Booking Information";
    else if (props.bookingType === "standard" && isHoliday) {
      return "Holiday Booking Information";
    } else if (props.bookingType === "timesheet") {
      return "Timesheet Information";
    } else return "Error retrieving booking type";
  };

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        fullWidth={true}
        open={props.infoModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.setInfoModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => props.setInfoModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{getModalTitle()}</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {modalLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
                width: "500px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <>
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <Info
                    color="primary"
                    fontSize="large"
                    style={{ marginTop: "10px" }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <p
                    className={classes.modalSectionTitle}
                    style={customFormHeader}
                  >
                    {" "}
                    Staff Member:{" "}
                  </p>
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {staffMember}{" "}
                  </p>
                </GridItem>
                {props.bookingType === "standard" && !isHoliday && (
                  <GridItem xs={12} sm={3}>
                    <p
                      className={classes.modalSectionTitle}
                      style={customFormHeader}
                    >
                      {" "}
                      Project PO:{" "}
                    </p>
                    <p
                      className={classes.cardSubtitle}
                      style={{ textAlign: "left" }}
                    >
                      {" "}
                      {projectPO}{" "}
                    </p>
                  </GridItem>
                )}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={2} />
                <GridItem xs={12} sm={10}>
                  <p
                    className={classes.modalSectionTitle}
                    style={customFormHeader}
                  >
                    {" "}
                    Event Date:{" "}
                  </p>
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {eventDate}{" "}
                  </p>
                </GridItem>
              </GridContainer>
              {props.bookingType === "standard" && (
                <GridContainer>
                  <GridItem xs={12} sm={2} />
                  <GridItem xs={12} sm={10}>
                    <p
                      className={classes.modalSectionTitle}
                      style={customFormHeader}
                    >
                      {" "}
                      Series:{" "}
                    </p>
                    <ul
                      className={classes.cardSubtitle}
                      style={{ textAlign: "left" }}
                    >
                      {series.map((entry, index) => {
                        if (entry[0] === entry[1]) {
                          return (
                            <li key={`seriesLI${index}`}>{`${entry[0]}`}</li>
                          );
                        } else {
                          return (
                            <li key={`seriesLI${index}`}>{`${entry[0]} - ${
                              entry[1]
                            }`}</li>
                          );
                        }
                      })}
                    </ul>
                  </GridItem>
                </GridContainer>
              )}
              <GridContainer>
                <GridItem xs={12} sm={2} />
                <GridItem xs={12} sm={10}>
                  <p
                    className={classes.modalSectionTitle}
                    style={customFormHeader}
                  >
                    {" "}
                    Project:{" "}
                  </p>
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {project}{" "}
                  </p>
                </GridItem>
              </GridContainer>
              {props.bookingType === "standard" && !isHoliday && (
                <GridContainer>
                  <GridItem xs={12} sm={2} />
                  <GridItem xs={12} sm={10}>
                    <p
                      className={classes.modalSectionTitle}
                      style={customFormHeader}
                    >
                      {" "}
                      Wasted Status:{" "}
                    </p>
                    <p
                      className={classes.cardSubtitle}
                      style={{ textAlign: "left" }}
                    >
                      {" "}
                      {productivity}
                      &nbsp;&nbsp;
                      <IconButton
                        onClick={() => {
                          props.setInfoModal(false);
                          props.setWastedDialog(true);
                        }}
                      >
                        <Edit color="primary" fontSize="small" />
                      </IconButton>
                    </p>
                  </GridItem>
                </GridContainer>
              )}
              {comments && (
                <GridContainer>
                  <GridItem xs={12} sm={2} />
                  <GridItem xs={12} sm={10}>
                    <p
                      className={classes.modalSectionTitle}
                      style={customFormHeader}
                    >
                      {" "}
                      Comments:{" "}
                    </p>
                    <p
                      className={classes.cardSubtitle}
                      style={{ textAlign: "left" }}
                    >
                      {" "}
                      {comments}{" "}
                    </p>
                  </GridItem>
                </GridContainer>
              )}
              {props.bookingType === "standard" && (
                <GridContainer>
                  <GridItem xs={12} sm={2} />
                  <GridItem xs={12} sm={10}>
                    <p
                      className={classes.modalSectionTitle}
                      style={customFormHeader}
                    >
                      {" "}
                      Booked (last updated) by:{" "}
                    </p>
                    <p
                      className={classes.cardSubtitle}
                      style={{ textAlign: "left" }}
                    >
                      {" "}
                      {lastUpdatedBy}{" "}
                    </p>
                  </GridItem>
                </GridContainer>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {props.bookingType === "standard" &&
          !isAnnualLeave && ( // only standard bookings that are not annual leave can be deleted or edited
              <>
                <Button
                  disabled={modalLoading}
                  color="danger"
                  onClick={() => {
                    props.setInfoModal(false);
                    props.setDeleteBookingDialog(true);
                  }}
                  simple
                >
                  Delete
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    props.setInfoModal(false);
                    props.setAddEditDialog(true);
                  }}
                  simple
                >
                  Edit
                </Button>
              </>
            )}
          <Button
            color="transparent"
            onClick={() => props.setInfoModal(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
};

export default BookingInfoDialog;
