import React from "react";

import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

// core components
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
  greyIconButton: {
    backgroundColor: "#ebebeb",
    marginTop: 10,
    marginRight: 10
  }
}));

// component provides back button that goes back or to override URL if provided
const BackButton: React.FC<BackButtonType> = ({ overrideUrl }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Tooltip title="Go back">
      <IconButton
        className={classes.greyIconButton}
        onClick={
          overrideUrl ? () => history.push(overrideUrl) : () => history.goBack()
        }
      >
        <KeyboardArrowLeft />
      </IconButton>
    </Tooltip>
  );
};

export default BackButton;

type BackButtonType = {
  overrideUrl?: string;
};
