import React from "react";

import { useHistory } from "react-router-dom";

import { primaryColor } from "assets/jss/material-dashboard-pro-react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";

import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";

const useStyles = makeStyles(styles);

export default function ErrorPage() {
  const classes = useStyles();
  const history = useHistory();

  const handleLinkClick = e => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/");
  };

  return (
    <GridContainer>
      <GridItem md={1} lg={3} />
      <GridItem
        xs={12}
        md={10}
        lg={6}
        style={{ backgroundColor: primaryColor[1], textAlign: "center" }}
      >
        <div>
          <h1 className={classes.title}>404</h1>
          <h2 className={classes.subTitle}>Page not found.</h2>
          <h4 className={classes.description}>
            Click&nbsp;
            <a
              href="/"
              onClick={handleLinkClick}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              here
            </a>
            &nbsp;to return to site.
          </h4>
          <br />
        </div>
      </GridItem>
      <GridItem md={1} lg={3} />
    </GridContainer>
  );
}
