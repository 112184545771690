import React, { useState } from "react";

import { useHistory } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  SET_CERTIFICATE_ALL_USERS_DT_FILTER,
  SET_CERTIFICATE_ALL_USERS_DT_PAGE,
  SET_CERTIFICATE_ALL_USERS_DT_ROW
} from "redux/dispatch_types";
// custom components
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import TrainingInfoModal from "../../../Dialogs/HRHS/TrainingInfoModal";
import TableFilter from "../../../../components/_Custom/_Common/TableFilter";
import CustomLoadingSpinner from "../../../../components/_Custom/_Common/CustomLoadingSpinner";
// custom functions & hooks
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import useEventListener from "global_functions/_common/useEventListener";
import identifyClick from "global_functions/_common/identifyClick";
// template components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
// table params
import { certificateAllUsersMain } from "views/DataTables/HRHS/Certificates/certificateAllUsersMain";
// types
import { RootStateType } from "../../../../redux/reducers";
// styles
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from "@material-ui/core/styles";
const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardContentRight: {
    padding: "15px 20px 15px 0px",
    position: "relative"
  }
};
// @ts-ignore
const useStyles = makeStyles(styles);

const CertificatesAllUsersPage: React.FC = () => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const [dataTable, setDataTable] = useState<DataTables.Api | undefined>(
    undefined
  );
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [editDelId, setEditDelId] = useState(null);

  const history = useHistory();

  useDataTableRefresh(dataTable, certificateAllUsersMain);

  const handleClickEvent = (e: React.MouseEvent): void => {
    const clickRelevantType = identifyClick(e);

    if (dataTable && clickRelevantType && "type" in clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({
            type: SET_CERTIFICATE_ALL_USERS_DT_ROW,
            payload: selectedRow
          });
          break;
        case "pagination":
          dispatch({
            type: SET_CERTIFICATE_ALL_USERS_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          if (
            "params" in clickRelevantType &&
            Array.isArray(clickRelevantType["params"])
          ) {
            const dtData = dataTable
              .rows(clickRelevantType.params[1])
              .data()[0];
            if (clickRelevantType.params[0] === "Visibility") {
              dataTable.rows().deselect();
              history.push(`/admin/hrhs-certificates/${dtData.id}`);
            } else if (clickRelevantType.params[0] === "InfoBlue") {
              setEditDelId(dtData.id);
              setInfoModalOpen(true);
              dataTable.rows().deselect();
            }
          }
          break;
        default:
          return;
      }
    }
  };

  const reduxPageFilter = useSelector(
    (state: RootStateType) =>
      state.certificateAllUsers.tables.certificateAllUsersMain.pageFilter
  );

  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Training</h4>
                <h4 className={classes.cardCategoryWhite}>
                  User Training Progress
                </h4>
              </CardText>
              <div className={classes.cardContentRight}>
                {/*@ts-ignore*/}
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => history.push("/admin/hrhs-certificate-types")}
                >
                  Certificates DB
                </Button>
                {/*@ts-ignore*/}
                <Button
                  size="sm"
                  color="info"
                  onClick={() =>
                    history.push("/admin/hrhs-certificate-requirements")
                  }
                >
                  Requirements per Position
                </Button>
              </div>
              {dataTable && certificateAllUsersMain.filterTabNames ? (
                <TableFilter
                  color="primary"
                  activeFilter={reduxPageFilter}
                  dispatchType={SET_CERTIFICATE_ALL_USERS_DT_FILTER}
                  dataTable={dataTable}
                  tabs={certificateAllUsersMain.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                tableParams={certificateAllUsersMain}
                setDataTable={setDataTable}
              />
            </CardBody>
          </Card>
        </GridItem>
        <TrainingInfoModal
          open={infoModalOpen}
          setOpen={setInfoModalOpen}
          userId={editDelId}
        />
      </GridContainer>
    </div>
  );
};

export default CertificatesAllUsersPage;
