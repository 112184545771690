import {
  SET_TS_ENTRIES_ADMIN_DT_PAGE,
  SET_TS_ENTRIES_ADMIN_DT_ROW,
  SET_TS_ENTRIES_ADMIN_REFRESH_URL,
  SET_TS_ENTRIES_ADMIN_DT_TRIG_REFRESH
} from "redux/dispatch_types";

const initialState = {
  tables: {
    tsEntriesAdminMain: {
      selectedRow: null,
      viewedPage: 0,
      refreshURL: "",
      triggerDtRefresh: {}
    }
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TS_ENTRIES_ADMIN_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          tsEntriesAdminMain: {
            ...state.tables.tsEntriesAdminMain,
            selectedRow: action.payload
          }
        }
      };
    case SET_TS_ENTRIES_ADMIN_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          tsEntriesAdminMain: {
            ...state.tables.tsEntriesAdminMain,
            viewedPage: action.payload
          }
        }
      };
    case SET_TS_ENTRIES_ADMIN_REFRESH_URL:
      return {
        ...state,
        tables: {
          ...state.tables,
          tsEntriesAdminMain: {
            ...state.tables.tsEntriesAdminMain,
            refreshURL: action.payload
          }
        }
      };
    case SET_TS_ENTRIES_ADMIN_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          tsEntriesAdminMain: {
            ...state.tables.tsEntriesAdminMain,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    default:
      return state;
  }
}
