import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
// redux
import { useDispatch } from "react-redux";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import showNotification from "global_functions/redux_action_creators/showNotification";
// template
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// material ui
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// custom componentts
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import { makeStyles } from "@material-ui/core/styles";
// styles
import formsStyles from "../../../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useFormStyles = makeStyles(formsStyles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

const NewLocationOptions = ({
  locationScanType,
  setLocationScanType,
  newEngineerDesk,
  setNewEngineerDesk,
  newItemBay,
  setNewItemBay,
  newItemShelf,
  setNewItemShelf
}) => {
  const formClasses = useFormStyles();

  const dispatch = useDispatch();

  const [locationOptionsLoading, setLocationOptionsLoading] = useState(false);
  const [locationOptions, setLocationOptions] = useState({});

  // when page opens, load form location options
  useEffect(() => {
    setLocationOptionsLoading(true);
    axios
      .get(
        apiUrl + "form-fields/scan_warehouse_item_form",
        getTokenConfig(store.getState)
      )
      .then(res => setLocationOptions(res.data))
      .then(() => setLocationOptionsLoading(false))
      .catch(() =>
        dispatch(
          showNotification(
            "Error loading form options. Please refresh and try again.",
            "error"
          )
        )
      );
  }, [dispatch]);

  // generate select values for the 8 engineer desks
  const newEngineerDeskChoices = useMemo(() => {
    let options = [];
    for (let i = 0; i <= 7; i++) {
      options.push({ label: (i + 1).toString(), value: i + 1 });
    }
    return options;
  }, []);

  return !locationOptionsLoading ? (
    <>
      <GridContainer>
        <GridItem>
          <FormLabel className={formClasses.labelHorizontal}>
            Scan item(s) to:
          </FormLabel>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem>
          <div
            className={
              formClasses.checkboxAndRadio +
              " " +
              formClasses.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              style={{ float: "left" }}
              control={
                <Radio
                  checked={locationScanType === 0}
                  onChange={e => setLocationScanType(parseInt(e.target.value))}
                  value={0}
                  name="radio button enabled"
                  aria-label="A"
                  icon={
                    <FiberManualRecord className={formClasses.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={formClasses.radioChecked} />
                  }
                  classes={{
                    checked: formClasses.radio,
                    root: formClasses.radioRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Bays/External Storage"
            />
            <FormControlLabel
              style={{ float: "left" }}
              control={
                <Radio
                  checked={locationScanType === 1}
                  onChange={e => setLocationScanType(parseInt(e.target.value))}
                  value={1}
                  name="radio button enabled"
                  aria-label="B"
                  icon={
                    <FiberManualRecord className={formClasses.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={formClasses.radioChecked} />
                  }
                  classes={{
                    checked: formClasses.radio,
                    root: formClasses.radioRoot
                  }}
                />
              }
              classes={{
                label: formClasses.label,
                root: formClasses.labelRoot
              }}
              label="Engineer Desks"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <>
          {locationScanType === 0 ? (
            <GridContainer>
              <GridItem xs={1} />
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Bay
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomSelect
                  options={locationOptions.BAY_CHOICES}
                  isDisabled={locationOptionsLoading}
                  value={newItemBay}
                  setValue={setNewItemBay}
                />
              </GridItem>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Shelf
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomSelect
                  options={locationOptions.SHELF_CHOICES}
                  isDisabled={locationOptionsLoading}
                  value={newItemShelf}
                  setValue={setNewItemShelf}
                />
              </GridItem>
            </GridContainer>
          ) : locationScanType === 1 ? (
            <GridContainer>
              <GridItem xs={1} />
              <GridItem xs={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Desk No.
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomSelect
                  options={newEngineerDeskChoices}
                  isDisabled={locationOptionsLoading}
                  value={newEngineerDesk}
                  setValue={setNewEngineerDesk}
                />
              </GridItem>
            </GridContainer>
          ) : (
            <></>
          )}
        </>
      </GridContainer>
    </>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "200px",
        width: "500px"
      }}
    >
      <CustomLoadingSpinner />
    </div>
  );
};

export default NewLocationOptions;