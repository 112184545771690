// fetches PDF from a given link and pops out an 'open'/'save' dialog with provided filename

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import store from "redux/store";

import showNotification from "global_functions/redux_action_creators/showNotification";

import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import { TOGGLE_SPINNER_CURSOR } from "redux/dispatch_types";

async function useFetchDocument(docParams) {
  const dispatch = useDispatch();
  const [docGenerated, setDocGenerated] = useState(false);

  useEffect(() => {
    if (docParams && docParams.url && docParams.fileName) {
      // first time the hook will run with initial value of ''
      let fetchUrl = docParams.url;
      let fileName = docParams.fileName;

      // get configuration
      let apiCallConfig = getTokenConfig(store.getState);
      apiCallConfig.method = "get";

      // show fetching message if included in params
      if ("fetchMsg" in docParams) {
        dispatch(showNotification(docParams.fetchMsg, "info"));
      }

      // fetch the document
      dispatch({ type: TOGGLE_SPINNER_CURSOR, payload: 1 }); // set loading cursor
      fetch(fetchUrl, apiCallConfig)
        .then(res => {
          if (res.ok) {
            return res.blob().then(resBlob => {
              // sometimes no error on server (so response OK) but report comes back with no size
              if (!resBlob.size) {
                dispatch(
                  showNotification(
                    "Error fetching. Please refresh and try again.",
                    "error"
                  )
                );
                dispatch({ type: TOGGLE_SPINNER_CURSOR, payload: 0 });
                return;
              }
              // IE doesn't allow using a blob object directly as link href
              // instead it is necessary to use msSaveOrOpenBlob
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(resBlob);
                return;
              }

              // For other browsers:
              // Create a link pointing to the ObjectURL containing the blob.
              const data = window.URL.createObjectURL(resBlob);
              let link = document.createElement("a");
              link.href = data;
              link.download = fileName;
              link.click();
              setTimeout(function() {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
              }, 100);
              setDocGenerated(true);
              dispatch({ type: TOGGLE_SPINNER_CURSOR, payload: 0 });
            });
          } else {
            dispatch(
              showNotification(
                "Error fetching. Please refresh and try again.",
                "error"
              )
            );
            dispatch({ type: TOGGLE_SPINNER_CURSOR, payload: 0 });
          }
        })
        .catch(err => {
          dispatch(
            showNotification(
              "Error fetching. Please refresh and try again.",
              "error"
            )
          );
          dispatch({ type: TOGGLE_SPINNER_CURSOR, payload: 0 });
        });
    }
  }, [docParams, dispatch]);

  // this returns whether PDF was generated or not. If PDF is generated, setDocGenerated back
  //  to false before turning true, as it will stay true every run otherwise and return 'true' every run
  if (docGenerated) {
    setDocGenerated(false);
    return true;
  } else {
    return false;
  }
}

export default useFetchDocument;
