import React, { useCallback, useEffect, useState } from "react";

import axios from "axios";

import { useDispatch } from "react-redux";
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import GridContainer from "../../../Grid/GridContainer";
// @material-ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import showNotification from "global_functions/redux_action_creators/showNotification";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formsStyles);

// API URL
const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function WastedBookingDialog(props) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const formClasses = useFormStyles();

  const [applyToAll, setApplyToAll] = useState(false);
  const [wastedStatus, setWastedStatus] = useState("");

  // loaders
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const modalLoading = loadingInfo || formSubmitting;

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  const handleFormSubmit = e => {
    e.preventDefault();

    let reqData = {
      new_wasted_status:
        wastedStatus === "productive" ? "bookingNotWasted" : "bookingWasted",
      all_bookings: applyToAll,
      event_edit_type: "wasted_status_change"
    };

    setFormSubmitting(true);
    axios
      .put(
        apiUrl + "calendar-bookings/" + props.bookingId,
        reqData,
        getTokenConfig(store.getState)
      )
      .then(res => {
        dispatch(showNotification("Wasted status changed", "success"));
        props.calendarRef.current.calendar.refetchEvents();
        props.setWastedDialog(false);
        setFormSubmitting(false);
      })
      .catch(err => {
        let errorMsg;
        err.response.data.errors
          ? (errorMsg = err.response.data.errors)
          : (errorMsg = "Error has occurred.");
        dispatch(showNotification(errorMsg, "error"));
        setFormSubmitting(false);
      });
  };

  useEffect(() => {
    if (props.wastedDialog) {
      document.addEventListener("keydown", captureKeyDown, false);
    } else {
      document.removeEventListener("keydown", captureKeyDown, false);
    }
  }, [props.wastedDialog, captureKeyDown]);

  // load current wasted status upon dialog opening
  useEffect(() => {
    setApplyToAll(false);
    if (props.wastedDialog) {
      setLoadingInfo(true);
      axios
        .get(
          apiUrl +
            "calendar-bookings/" +
            props.bookingId +
            "?format_type=info_modal",
          getTokenConfig(store.getState)
        )
        .then(res => {
          setWastedStatus(res.data.wasted ? "wasted" : "productive");
        })
        .then(() => setLoadingInfo(false));
    }
  }, [props.wastedDialog, props.bookingId]);

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        fullWidth={true}
        open={props.wastedDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.set(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => props.setWastedDialog(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Set Wasted Status</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {modalLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
                width: "500px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <form id="wastedStatusChangeForm" onSubmit={handleFormSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                  >
                    Wasted Status*
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={7}>
                  <div
                    className={
                      formClasses.checkboxAndRadio +
                      " " +
                      formClasses.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={wastedStatus === "wasted"}
                          onChange={e => setWastedStatus(e.target.value)}
                          value="wasted"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={formClasses.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={formClasses.radioChecked}
                            />
                          }
                          classes={{
                            checked: formClasses.radio,
                            root: formClasses.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot
                      }}
                      label="Wasted"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={wastedStatus === "productive"}
                          onChange={e => setWastedStatus(e.target.value)}
                          value="productive"
                          name="radio button enabled"
                          aria-label="B"
                          icon={
                            <FiberManualRecord
                              className={formClasses.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={formClasses.radioChecked}
                            />
                          }
                          classes={{
                            checked: formClasses.radio,
                            root: formClasses.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot
                      }}
                      label="Productive"
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={1} />
                <GridItem xs={12} lg={10}>
                  <div className={formClasses.inlineChecks}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="setAllCheckbox"
                          tabIndex={-1}
                          onClick={e => setApplyToAll(e.target.checked)}
                          checked={applyToAll}
                          checkedIcon={
                            <Check className={formClasses.checkedIcon} />
                          }
                          icon={<Check className={formClasses.uncheckedIcon} />}
                          classes={{
                            checked: formClasses.checked,
                            root: formClasses.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot
                      }}
                      label="Set to all bookings for this client on this day"
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={1} />
                <GridItem xs={12} sm={10}>
                  <br />
                  <p
                    className={classes.cardSubtitle}
                    style={{ textAlign: "left" }}
                  >
                    * A wasted booking is a booking where work could not take
                    place due to reasons beyond the control of SRW
                  </p>
                </GridItem>
              </GridContainer>
            </form>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            disabled={modalLoading}
            color="danger"
            type="submit"
            form="wastedStatusChangeForm"
            simple
          >
            Submit
          </Button>
          <Button
            disabled={modalLoading}
            color="transparent"
            onClick={() => props.setWastedDialog(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
