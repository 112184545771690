import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const conflictIcon = { icon: "Info", tooltip: "View conflicts" };
const approveIcon = { icon: "CheckCircle", tooltip: "Approve request" };
const rejectIcon = { icon: "Cancel", tooltip: "Reject request" };
const deleteIcon = { icon: "Delete", tooltip: "Delete request" };
const cancelIcon = { icon: "Block", tooltip: "Cancel request" };

export const holsAdminMain = {
  section: "holsAdmin",
  reduxTableName: "holsAdminMain",
  refreshURL: "holiday-requests/?page=admin_holidays&page_filter=",
  filterTabValues: [1, 2, 3],
  filterTabNames: ["Pending", "Approved", "Declined"],
  tableParams: {
    autoWidth: true,
    buttonsColumn: {
      id: 9,
      genIcons: rowData => {
        let iconArray = [conflictIcon];
        if (rowData.status === "Pending") {
          iconArray.push(approveIcon, rejectIcon);
        } else if (rowData.status === "Approved") {
          iconArray.push(cancelIcon);
        } else if (
          rowData.status === "Cancelled" ||
          rowData.status === "Rejected"
        ) {
          iconArray.push(deleteIcon);
        }

        return iconArray;
      }
    },
    dom: "Bfrtip",
    select: { style: "single" },
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    columns: [
      {
        data: "id",
        title: "ID",
        responsivePriority: 1,
        className: "dt-body-center"
      }, // 0
      {
        data: "submission_date",
        title: "Submission Date",
        visible: false,
        searchable: false,
        className: "dt-body-center"
      }, // 1
      { data: "user", title: "User" }, // 2
      {
        data: { _: "start_date.display", sort: "start_date.@data-order" },
        title: "Start Date"
      }, // 3
      {
        data: { _: "end_date.display", sort: "end_date.@data-order" },
        title: "End Date"
      }, // 4
      {
        data: "time_of_day",
        title: "Time of Day",
        className: "dt-body-center"
      }, // 5
      {
        data: "working_days",
        title: "Working Days",
        className: "dt-body-center"
      }, // 6
      { data: "comments", title: "Comments" }, // 7
      { data: "status", title: "Status", className: "dt-body-center" }, // 8
      {
        data: null,
        title: "Actions",
        responsivePriority: 3,
        className: "dt-body-center",
        width: 120
      } // 9
    ],
    aaSorting: [[0, "desc"]]
  }
};
