import React, { useEffect, useState } from "react";

import axios from "axios";
// redux
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// custom components
import { WidgetTemplate } from "../WidgetTemplate";
// icons
import FileCopy from "@material-ui/icons/FileCopy";

const TimesheetsWidgetAdmin: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState({
    unapproved_timesheets: 0,
    late_timesheets: 0
  });
  const apiUrl = process.env.REACT_APP_API_V1_URL;

  // initial load
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        apiUrl + "datasets/?type=timesheets_widget_admin",
        getTokenConfig(store.getState)
      )
      .then(res => {
        setCardData(res.data);
        setIsLoading(false);
      });
  }, [apiUrl]);

  return (
    <>
      <WidgetTemplate
        bottomText="Timesheets Section"
        bottomUrl="/#/admin/timesheets/"
        icon={<FileCopy />}
        iconColor="info"
        isLoading={isLoading}
        statsText=" to approve"
        statsText2={`${cardData.late_timesheets} late`}
        title="Timesheets"
        value={cardData.unapproved_timesheets}
        value2={cardData.late_timesheets}
      />
    </>
  );
};

export default TimesheetsWidgetAdmin;
