import {
  SET_POSITIONS_DT_PAGE,
  SET_POSITIONS_DT_TRIG_REFRESH,
  SET_POSITIONS_DT_ROW,
  SET_POSITIONS_REFRESH_URL
} from "../../../dispatch_types";

import {
  ReduxTableType,
  ReduxActionType
} from "../../../../global_functions/types/shared";

const initialState: PositionsStateType = {
  tables: {
    positionsMain: {
      selectedRow: null,
      triggerDtRefresh: {},
      viewedPage: 0
    }
  }
};

export default function(state = initialState, action: ReduxActionType) {
  switch (action.type) {
    case SET_POSITIONS_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          positionsMain: {
            ...state.tables.positionsMain,
            selectedRow: action.payload
          }
        }
      };
    case SET_POSITIONS_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          positionsMain: {
            ...state.tables.positionsMain,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_POSITIONS_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          positionsMain: {
            ...state.tables.positionsMain,
            viewedPage: action.payload
          }
        }
      };
    case SET_POSITIONS_REFRESH_URL:
      return {
        ...state,
        tables: {
          ...state.tables,
          positionsMain: {
            ...state.tables.positionsMain,
            refreshURL: action.payload
          }
        }
      };
    default:
      return state;
  }
}

// typescript declarations
type PositionsStateType = {
  tables: { positionsMain: ReduxTableType };
};
