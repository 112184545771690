import React from "react";
import { useSelector } from "react-redux";

import { RootStateType } from "../../../redux/reducers";

import {
  NotificationManager,
  NotificationContainer
} from "react-notifications";

const ReduxAlerts: React.FC<{}> = () => {
  const auth = useSelector((state: RootStateType) => state.auth);
  const notifications = useSelector(
    (state: RootStateType) => state.notifications
  );

  React.useEffect(() => {
    switch (notifications.notifyType) {
      case "success":
        NotificationManager.success(notifications.message);
        break;
      case "info":
        NotificationManager.info(notifications.message);
        break;
      case "warning":
        NotificationManager.warning(notifications.message);
        break;
      case "error":
        NotificationManager.error(notifications.message);
        break;
      default:
        return;
    }
  }, [notifications]);

  // auth errors
  React.useEffect(() => {
    if (auth.authError.msg.customMsg) {
      // custom frontend errors come back under 'custom' object key
      NotificationManager.error(auth.authError.msg.customMsg);
    } else if (auth.authError.msg.error_msg) {
      // custom backend errors come back under 'error_msg' key
      NotificationManager.error(auth.authError.msg.error_msg);
    } else if (auth.authError.msg.password) {
      NotificationManager.error(
        `Password: ${auth.authError.msg.password.join()}`
      );
    } else if (auth.authError.msg.email) {
      NotificationManager.error(`Error: ${auth.authError.msg.email.join()}`);
    } else if (auth.authError.msg.username) {
      NotificationManager.error(
        `Username: ${auth.authError.msg.username.join()}`
      );
    } else if (
      auth.authError.msg.userprofile &&
      auth.authError.msg.userprofile.department
    ) {
      NotificationManager.error(
        `Department: ${auth.authError.msg.userprofile.department.join()}`
      );
    } else if (auth.authError.msg.non_field_errors) {
      NotificationManager.error(
        `Error: ${auth.authError.msg.non_field_errors.join()}`
      );
    } else if (auth.authError.msg.registration_validation) {
      NotificationManager.error(
        `Error: ${auth.authError.msg.registration_validation.join()}`
      );
    }
  }, [auth.authError]);

  return <NotificationContainer />;
};

export default ReduxAlerts;
