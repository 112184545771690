import React, { useEffect, useState } from "react";

import axios from "axios";

import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";

// redux
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import { useSelector } from "react-redux";

// template
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

const apiURL = process.env.REACT_APP_API_V1_URL;

export default function UserHolsStats() {
  const [futureHols, setFutureHols] = useState(false);
  const [pastHols, setPastHols] = useState(false);
  const [holsStats, setHolsStats] = useState(false);

  const currentUserId = useSelector(state => state.auth.user.id);

  useEffect(() => {
    axios
      .get(
        `${apiURL}datasets/?type=holiday_dataset&user_id=${currentUserId}`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setHolsStats(res.data[0]);
      });

    axios
      .get(
        `${apiURL}datasets/?type=past_hols_this_year&user_id=${currentUserId}`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setPastHols(res.data);
      });

    axios
      .get(
        `${apiURL}datasets/?type=future_hols_this_year&user_id=${currentUserId}`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setFutureHols(res.data);
      });
  }, [currentUserId]);

  return (
    <>
      {futureHols && pastHols && holsStats ? (
        <GridContainer>
          <GridItem xs={12} sm={4} lg={12}>
            <h6>Stats:</h6>
            <ul>
              <li>Allowance: {holsStats.allowance}</li>
              <li>Remaining: {holsStats.remaining}</li>
              <li>Holidays Booked: {holsStats.future_hols}</li>
              <li>Holidays Taken: {holsStats.past_hols}</li>
            </ul>
          </GridItem>
          {pastHols.length > 0 && (
            <GridItem xs={12} sm={4} lg={12}>
              <h6>Past Holidays:</h6>
              <ul>
                {pastHols.map((key, index) => {
                  return (
                    <li key={`ph${index}`}>
                      {key[0] === key[1] ? key[0] : `${key[0]} - ${key[1]}`}
                    </li>
                  );
                })}
              </ul>
            </GridItem>
          )}
          {futureHols.length > 0 && (
            <GridItem xs={12} sm={4} lg={12}>
              <h6>Future Holidays:</h6>
              <ul>
                {futureHols.map((key, index) => {
                  return (
                    <li key={`ph${index}`}>
                      {key[0] === key[1] ? key[0] : `${key[0]} - ${key[1]}`}
                    </li>
                  );
                })}
              </ul>
            </GridItem>
          )}
        </GridContainer>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      )}
    </>
  );
}
