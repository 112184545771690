import React, { useState } from "react";

import ArrowButton from "components/CustomButtons/ArrowButton";

import { useDispatch } from "react-redux";

// template
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const YearCarousel: React.FC<YearCarousel> = ({
  setYear,
  incReduxType,
  decReduxType,
  minYear,
  maxYear
}) => {
  const dispatch = useDispatch();

  const [todayYear] = useState(new Date().getFullYear());

  return (
    <GridContainer>
      <GridItem xs={4} />
      <GridItem
        xs={1}
        // @ts-ignore
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "row"
        }}
      >
        <ArrowButton
          fontSize="small"
          tooltip="Previous year"
          direction="left"
          onClick={() => dispatch({ type: decReduxType, payload: null })}
          disabled={
            minYear === "current" && setYear <= todayYear
              ? true
              : minYear !== "current" && setYear <= minYear
              ? true
              : false
          }
        />
      </GridItem>
      <GridItem
        xs={2}
        // @ts-ignore
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "row"
        }}
      >
        <h5 style={{ textAlign: "center", display: "flex", margin: 0 }}>
          {setYear}
        </h5>
      </GridItem>
      <GridItem
        xs={1}
        // @ts-ignore
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "row"
        }}
      >
        <ArrowButton
          fontSize="small"
          tooltip="Next year"
          direction="right"
          onClick={() => dispatch({ type: incReduxType, payload: null })}
          disabled={
            maxYear === "current" && setYear >= todayYear
              ? true
              : maxYear !== "current" && setYear >= maxYear
              ? true
              : false
          }
        />
      </GridItem>
      <GridItem xs={4} />
    </GridContainer>
  );
};

export default YearCarousel;

interface YearCarousel {
  setYear: number;
  incReduxType: string;
  decReduxType: string;
  minYear: number | "current";
  maxYear: number | "current";
}
