import React from "react";

import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import ResourceCalendar from "components/_Custom/PageSpecific/ResourcePlanner/ResourceCalendar";
import CalendarToolbar from "components/_Custom/PageSpecific/ResourcePlanner/CalendarToolbar";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

export default function ResourcePlannerPage() {
  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Resource Planner</h4>
                <h4 className={classes.cardCategoryWhite}>
                  Manage staff bookings
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <CalendarToolbar />
              <ResourceCalendar />
            </CardBody>
            <CardFooter />
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
