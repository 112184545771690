import React, { useEffect, useState } from "react";

import axios from "axios";

import { Bar } from "react-chartjs-2";

// redux
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// material -ui
import Timeline from "@material-ui/icons/Timeline";
// template
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";

// @ts-ignore
const useStyles = makeStyles(styles);

const ChartAdmin: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState({});
  const classes = useStyles();
  const apiUrl = process.env.REACT_APP_API_V1_URL;

  // initial load
  useEffect(() => {
    setIsLoading(true);
    const fetchUrl = `${apiUrl}datasets/?type=company_performance_chart`;
    axios.get(fetchUrl, getTokenConfig(store.getState)).then(res => {
      const respChartData = res.data.chart_data; // extracting chart data from ajax response
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const newChartData = chartDataTpl; // copying the template as new chart data, which will then be modified
      // set retrieved data values to newChartData
      newChartData.datasets[0].data = respChartData[0];
      newChartData.datasets[1].data = respChartData[1];
      newChartData.datasets[2].data = respChartData[2];
      newChartData.labels = respChartData[3];

      // set new chart data as current chart data
      setChartData(newChartData);

      setIsLoading(false);
    });
  }, [apiUrl]);

  return (
    <GridItem xs={12} sm={12} md={8} lg={6} xl={4}>
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <Timeline />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            Company Performance{" "}
            <small>- Work type (chargeable/internal) and holidays</small>
          </h4>
        </CardHeader>
        <CardBody>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <div className="chart" style={{ height: "300px" }}>
              {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
              <Bar data={chartData} options={chartOptions} />
            </div>
          )}
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default ChartAdmin;

const chartDataTpl: ChartDataTpl = {
  labels: [],
  datasets: [
    {
      label: "Chargeable",
      data: [],
      backgroundColor: "rgba(255, 99, 132, 0.2)",
      borderColor: "rgba(255, 99, 132, 1)",
      borderWidth: 2
    },
    {
      label: "Internal",
      data: [],
      backgroundColor: "rgba(54, 162, 235, 0.2)",
      borderColor: "rgba(54, 162, 235, 1)",
      borderWidth: 2
    },
    {
      label: "Holiday",
      data: [],
      backgroundColor: "rgba(255, 206, 86, 0.2)",
      borderColor: "rgba(255, 206, 86, 1)",
      borderWidth: 2
    }
  ]
};

const chartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true,
    position: "bottom"
  },
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          // full params below are (value, index, values) but index and values aren't used
          callback: (value: string): string => {
            return `${value}%`;
          }
        }
      }
    ],
    xAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true
        }
      }
    ]
  },
  tooltips: {
    mode: "label",
    callbacks: {
      label: (tooltipItem: TooltipItem, data: ChartDataTpl): string => {
        return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.value}%`;
      }
    }
  }
};

type ChartDataTpl = {
  labels: string[];
  datasets: ChartDatasetType[];
};

type ChartDatasetType = {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
};

type TooltipItem = {
  datasetIndex: number;
  value: string;
};

type ChartOptions = {
  responsive: boolean;
  maintainAspectRatio: boolean;
  legend: {
    display: boolean;
    position: string;
  };
  scales: {
    yAxes: AxisType[];
    xAxes: AxisType[];
  };
  tooltips: {
    mode: string;
    callbacks: {
      label: (tooltipItem: TooltipItem, data: ChartDataTpl) => string;
    };
  };
};

type AxisType = {
  stacked: boolean;
  ticks: {
    beginAtZero: boolean;
    callback?: (value: string) => string;
  };
};
