import React, { useEffect, useState } from "react";
// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomSelect/CustomSelect";
// import Button from "../../components/CustomButtons/Button";
// Custom components & functions
import formatDate from "../../global_functions/_common/formatDate";
import ProjectSelect from "components/_Custom/_Common/ProjectSelect";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// @material-ui
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
// import Clear from "@material-ui/icons/Clear";
// date
import DateFnsUtils from "@date-io/date-fns";
// styles
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
// theme
import { customTheme } from "../../global_functions/_common/customTheme";
import { primaryColor } from "../../assets/jss/material-dashboard-pro-react";
import { Typography } from "@material-ui/core";

const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);
// const useButtonStyles = makeStyles(rowButtonStyles);

export default function AddEditQuoteForm(props) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();
  // const rowButtonClasses = useButtonStyles();

  // client & project filter
  const [clientId, setClientId] = useState("");
  const [projId, setProjId] = useState("");

  // other fields
  const [quoteStatus, setQuoteStatus] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [variation, setVariation] = useState("");
  const [quoteService, setQuoteService] = useState("");
  const [poNumber, setPoNumber] = useState("");
  const [projManager, setProjManager] = useState("");
  const [outstandingAmount, setOutstandingAmount] = useState("");
  const [date, setDate] = useState(new Date());
  const [approver, setApprover] = useState(null);

  useEffect(() => {
    if (props.editData && props.open) {
      setClientId(props.editData.client);
      setProjId(props.editData.number);
      setOutstandingAmount(props.editData.outstanding || "");
      setProjManager(props.editData.pm);
      setPoNumber(props.editData.po_number);
      setQuoteService(props.editData.service);
      setServiceDescription(props.editData.service_description);
      setQuoteStatus(props.editData.status);
      setVariation(props.editData.variation);
      setApprover(props.editData.approver);
      setDate(() => {
        if (props.editData.date) {
          return new Date(props.editData.date);
        } else {
          return null;
        }
      });
      // set service
      // on old site, services arrays were saved in string so need to parseInt first, eventually won't be required
      const servicesStr = props.editData.service;
      const servicesInt = servicesStr.map(entry => parseInt(entry));
      setQuoteService(servicesInt);
    } else if (!props.editData && props.open) {
      setClientId("");
      setProjId("");
      setOutstandingAmount("");
      setProjManager("");
      setPoNumber("");
      setQuoteService("");
      setServiceDescription("");
      setQuoteStatus("");
      setVariation("");
      setDate(null);
      setApprover(null);
    }
  }, [props.editData, props.open]);

  useEffect(() => {
    props.setformData({
      variation: variation,
      number: projId,
      client: clientId,
      status: quoteStatus,
      po_number: poNumber,
      pm: projManager,
      service_description: serviceDescription,
      outstanding: outstandingAmount,
      service: quoteService,
      approver: approver,
      date: formatDate(date, "yyyy-mm-dd")
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    variation,
    date,
    projId,
    clientId,
    quoteStatus,
    poNumber,
    projManager,
    serviceDescription,
    outstandingAmount,
    quoteService,
    props.setformData,
    approver
  ]);

  // clearing date with button click
  // const handleClearDateClick = e => {
  //   e.stopPropagation();
  //   setDate(null);
  // };

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "540px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            {/*<Typography*/}
            {/*  variant="h6"*/}
            {/*  align="left"*/}
            {/*  style={{*/}
            {/*    color: primaryColor[0],*/}
            {/*    marginLeft: 10,*/}
            {/*    marginTop: 0,*/}
            {/*    marginBottom: 0,*/}
            {/*    marginRight: 0,*/}
            {/*    fontSize: "0.8em",*/}
            {/*    borderBottom: "2px solid rgb(96, 125, 139)"*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Main Fields*/}
            {/*</Typography>*/}
            <ProjectSelect
              clientList={props.clientList}
              formLoading={props.formLoading}
              clientID={clientId}
              setClientID={setClientId}
              statusFilters={[1, 2]}
              projID={projId}
              setProjID={setProjId}
              lhsFieldWidth={2}
              rhsFieldWidth={10}
            />
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Services
                </FormLabel>
              </GridItem>
              <GridItem xs={10}>
                <CustomSelect
                  options={props.formChoiceFields.SERVICE_CHOICES}
                  isDisabled={props.formLoading}
                  value={quoteService}
                  setValue={setQuoteService}
                  // required={true}
                  isMulti={true}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Status
                </FormLabel>
              </GridItem>
              <GridItem xs={5}>
                <CustomSelect
                  options={props.formChoiceFields.STATUS_CHOICES}
                  isDisabled={props.formLoading}
                  value={quoteStatus}
                  setValue={setQuoteStatus}
                  required={true}
                />
              </GridItem>
              <GridItem xs={5}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={customTheme}>
                    <KeyboardDatePicker
                      style={{ float: "left" }}
                      margin="normal"
                      required={true}
                      label="Date"
                      format="dd/MM/yyyy"
                      value={date}
                      onChange={date => setDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </GridItem>
            </GridContainer>
            <Typography
              variant="h6"
              align="left"
              style={{
                color: primaryColor[0],
                marginLeft: 10,
                marginTop: 10,
                marginBottom: 0,
                marginRight: 0,
                fontSize: "0.8em",
                borderBottom: "2px solid rgb(96, 125, 139)"
              }}
            >
              PDF Fields
            </Typography>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  FAO
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Project Manager"
                  inputProps={{
                    type: "text",
                    value: projManager,
                    onChange: e => {
                      setProjManager(e.target.value);
                    },
                    maxLength: 20
                  }}
                />
              </GridItem>
              <GridItem xs={1}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Sig.
                </FormLabel>
              </GridItem>
              <GridItem xs={5}>
                <CustomSelect
                  options={props.formChoiceFields.APPROVER_CHOICES}
                  isDisabled={props.formLoading}
                  value={approver}
                  setValue={setApprover}
                  // required={true}
                  isMulti={false}
                />
              </GridItem>
              {/*<GridItem xs={1}>*/}
              {/*  <Button*/}
              {/*    className={rowButtonClasses.actionButton}*/}
              {/*    style={{ backgroundColor: primaryColor[1] }}*/}
              {/*    onClick={handleClearDateClick}*/}
              {/*  >*/}
              {/*    <Clear className={rowButtonClasses.icon} />*/}
              {/*  </Button>*/}
              {/*</GridItem>*/}
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Scope
                </FormLabel>
              </GridItem>
              <GridItem xs={10}>
                {/*<InputLabel style={{ color: "#AAAAAA" }}>About me</InputLabel>*/}
                <CustomInput
                  // labelText="Enter scope"
                  id="about-me"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    multiline: true,
                    rows: 2,
                    value: serviceDescription,
                    onChange: e => {
                      setServiceDescription(e.target.value);
                    }
                  }}
                />
              </GridItem>
            </GridContainer>
            <Typography
              variant="h6"
              align="left"
              style={{
                color: primaryColor[0],
                marginLeft: 10,
                marginTop: 20,
                marginBottom: 0,
                marginRight: 0,
                fontSize: "0.8em",
                borderBottom: "2px solid rgb(96, 125, 139)"
              }}
            >
              Advanced Fields
            </Typography>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  PO Number
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Project name"
                  inputProps={{
                    type: "text",
                    value: poNumber,
                    onChange: e => {
                      setPoNumber(e.target.value);
                    },
                    maxLength: 20
                  }}
                />
              </GridItem>
              <GridItem xs={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Outstanding
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Outstanding"
                  inputProps={{
                    type: "number",
                    value: outstandingAmount,
                    onChange: e => {
                      setOutstandingAmount(e.target.value);
                    },
                    maxLength: 30
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Variation (auto if blank)
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Project Number"
                  inputProps={{
                    type: "number",
                    value: variation,
                    onChange: e => {
                      setVariation(e.target.value);
                    },
                    maxLength: 12
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
}
