import React from "react";

import { primaryColor } from "assets/jss/material-dashboard-pro-react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// icon
import CloudUpload from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles(theme => ({
  addButton: {
    backgroundColor: primaryColor[0],
    "&:hover, &.Mui-focusVisible": { backgroundColor: primaryColor[3] },
    marginTop: 10,
    marginRight: 10
  }
}));

export default function UploadButton({ handleUploadClick, tooltip }) {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip}>
      <IconButton className={classes.addButton} onClick={handleUploadClick}>
        <CloudUpload style={{ color: "#f9f9f9" }} />
      </IconButton>
    </Tooltip>
  );
}
