import {
  SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_PAGE,
  SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_TRIG_REFRESH,
  SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_ROW,
  SET_WAREHOUSE_ITEMS_IN_CONTAINER_REFRESH_URL,
  SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_FILTER
} from "redux/dispatch_types";

const initialState = {
  tables: {
    warehouseItemsInContainerMain: {
      selectedRow: null,
      triggerDtRefresh: {},
      viewedPage: 0,
      refreshURL: ""
    }
  },
  entryStatus: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          warehouseItemsInContainerMain: {
            ...state.tables.warehouseItemsInContainerMain,
            selectedRow: action.payload
          }
        }
      };
    case SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          warehouseItemsInContainerMain: {
            ...state.tables.warehouseItemsInContainerMain,
            viewedPage: action.payload
          }
        }
      };
    case SET_WAREHOUSE_ITEMS_IN_CONTAINER_REFRESH_URL:
      return {
        ...state,
        tables: {
          ...state.tables,
          warehouseItemsInContainerMain: {
            ...state.tables.warehouseItemsInContainerMain,
            refreshURL: action.payload
          }
        }
      };
    case SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_TRIG_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          warehouseItemsInContainerMain: {
            ...state.tables.warehouseItemsInContainerMain,
            selectedRow: action.payload.id,
            triggerDtRefresh: {}
          }
        }
      };
    case SET_WAREHOUSE_ITEMS_IN_CONTAINER_DT_FILTER:
      return {
        ...state,
        tables: {
          ...state.tables,
          warehouseItemsInContainerMain: {
            ...state.tables.warehouseItemsInContainerMain,
            pageFilter: action.payload,
            viewedPage: 0
          }
        }
      };
    default:
      return state;
  }
}
