import React, { useEffect, useState } from "react";

// components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// templates
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomSelect from "components/CustomSelect/CustomSelect";
// material-ui
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
// types
import { AddEditFormTypes } from "../../types/views/FormTypes";
// styles
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
// @ts-ignore
const useFormStyles = makeStyles(formsStyles);
// @ts-ignore
const useNotifyStyles = makeStyles(notificationStyles);

const AddEditCertificateRequirementForm: React.FC<
  AddEditCertificateRequirementFormType
> = ({
  editData,
  setformData,
  formLoading,
  handleFormSubmit,
  open,
  formChoiceFields
}) => {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  // form fields
  const [reqCerts, setReqCerts] = useState([]);

  useEffect(() => {
    if (editData && open) {
      // @ts-ignore
      setReqCerts(editData.required_certs_ids);
    }
  }, [editData, open]);

  useEffect(() => {
    setformData({
      req_certs: reqCerts
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqCerts]);

  return (
    <>
      {formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "120px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={handleFormSubmit}>
            <GridContainer>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel className={formClasses.labelHorizontal}>
                    Required Certificates
                  </FormLabel>
                </GridItem>
                <GridItem xs={8}>
                  <CustomSelect
                    isMulti={true}
                    options={formChoiceFields.cert_type_list}
                    isDisabled={formLoading}
                    value={reqCerts}
                    setValue={setReqCerts}
                  />
                </GridItem>
                <GridItem xs={1} />
              </GridContainer>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
};

export default AddEditCertificateRequirementForm;

type EditDataType = {
  required_certs_ids: number[];
};

interface AddEditCertificateRequirementFormType extends AddEditFormTypes {
  editData: EditDataType | null;
}
