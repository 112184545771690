import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const viewDocIcon = { icon: "Visibility", tooltip: "" };

export const projectsWithSheqDocumentsMain = {
  section: "projectsWithSheqDocuments",
  reduxTableName: "projectsWithSheqDocumentsMain",
  filterTabValues: [0, 1, 5, 3],
  filterTabNames: ["All", "Active", "On-going", "Complete"],
  refreshURL: "projects/?with_sheq_documents=1&filter=",
  tableParams: {
    autoWidth: true,
    buttonsColumn: {
      id: 5,
      genIcons: () => {
        return [viewDocIcon];
      }
    },
    responsive: true,
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    columns: [
      { data: "number", title: "Project Number", className: "dt-body-center" }, // 0
      {
        data: "description",
        title: "Project Name",
        className: "dt-body-center"
      }, // 1
      {
        data: "client_name",
        title: "Client",
        className: "dt-body-center"
      }, // 2
      { data: "doc_no", title: "Documents", className: "dt-body-center" }, // 3
      { data: "status_str", title: "Status", className: "dt-body-center" }, // 4
      { data: null, title: "Actions", className: "dt-body-center" } // 5
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "asc"]]
  }
};
