import getDataIDFromEvent from "./getDataIDFromEvent";

export default function identifyClick(e) {
  // check if the button that was clicked has the required data-id (report button click on datatable)
  let dataID;

  dataID = getDataIDFromEvent(e);

  if (dataID) {
    let paramsArray = dataID.split("-");
    if (paramsArray.length === 3 && paramsArray[0] === "dticon") {
      return { type: "dtButton", params: [paramsArray[1], paramsArray[2]] };
    }
  }

  // check if it is a pagination click
  else if (e.target.getAttribute("class") === "paginate_button ")
    return {
      type: "pagination",
      tableID: e.target.getAttribute("aria-controls")
    };
  // check if it is a datatable cell click
  else if (e.target.nodeName === "TD")
    return {
      type: "cellClick",
      tableID: e.target.parentElement.parentElement.parentElement.id
    };
  else return false;
}
