import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// types
import { GridSizeType } from "../../global_functions/types/shared";

const styles = {
  grid: {
    padding: "0 15px !important"
  }
};

const useStyles = makeStyles(styles);

const GridItem: React.FC<GridItemTypes> = ({
  className,
  children,
  xs,
  sm,
  md,
  lg,
  xl,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid
      item
      className={classes.grid + " " + className}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      {...rest}
    >
      {children}
    </Grid>
  );
};

export default GridItem;

interface GridItemTypes {
  className?: string;
  children?: React.ReactNode;
  xs?: GridSizeType;
  sm?: GridSizeType;
  md?: GridSizeType;
  lg?: GridSizeType;
  xl?: GridSizeType;
}
