import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

export const holsAdminSummary = {
  reduxTableName: "holsAdminSummary",
  section: "holsAdmin",
  tableParams: {
    autoWidth: true,
    dom: "Bfrtip",
    select: { style: "single" },
    processing: true,
    responsive: true,
    language: {
      processing: DtLoadingSpinner
    },
    columns: [
      {
        data: "id",
        title: "ID",
        responsivePriority: 1,
        visible: false,
        searchable: false
      }, // 0
      { data: "user", title: "User", responsivePriority: 1 }, // 1
      {
        data: "past_hols",
        title: "Taken",
        responsivePriority: 3,
        className: "dt-body-center"
      }, // 2
      { data: "future_hols", title: "Future", className: "dt-body-center" }, // 3
      {
        data: "total_booked",
        title: "Total Booked",
        className: "dt-body-center",
        visible: false,
        searchable: false
      }, // 4
      {
        data: "allowance",
        title: `Allowance`,
        responsivePriority: 2,
        className: "dt-body-center"
      }, // 5
      { data: "remaining", title: "Remaining", className: "dt-body-center" } // 6
    ],
    // searching: false,
    // paging: false,
    info: false,
    aaSorting: [[1, "asc"]]
  }
};
