import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// icon
import Add from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
  addButton: {
    backgroundColor: "#4caf50",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#a4d7a6" },
    marginTop: 10,
    marginRight: 10
  }
}));

export default function AddButton({ handleAddClick, tooltip }) {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip}>
      <IconButton className={classes.addButton} onClick={handleAddClick}>
        <Add style={{ color: "#f9f9f9" }} />
      </IconButton>
    </Tooltip>
  );
}
