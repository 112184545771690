import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

export const expEntriesAdminMain = {
  section: "expEntriesAdmin",
  reduxTableName: "expEntriesAdminMain",
  tableParams: {
    autoWidth: true,
    buttonsColumn: {
      id: 9,
      genIcons: rowData => {
        if (!rowData.receipt_count) {
          return [{ icon: "FilterNone", tooltip: "Manage receipts" }];
        } else if (rowData.receipt_count <= 9) {
          return [
            {
              icon: "Filter" + rowData.receipt_count.toString(),
              tooltip: "Manage receipts"
            }
          ];
        } else {
          return [{ icon: "Filter9Plus", tooltip: "Manage receipts" }];
        }
      }
    },
    responsive: true,
    searching: false,
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    columns: [
      { data: "id", title: "DB IDs", visible: false, searchable: false }, // 0
      {
        data: { _: "date_incurred.display", sort: "date_incurred.@data-order" },
        title: "Date",
        width: 140
      }, // 1
      { data: "number", title: "Job", width: 400 }, // 2
      { data: "expense_type", title: "Vendor", width: 120 }, // 3
      { data: "vendor", title: "Type" }, // 4
      { data: "amount", title: "Amount", width: 120 }, // 5
      { data: "vat", title: "VAT", width: 120 }, // 6
      { data: "before_vat", title: "Ex-VAT", width: 120 }, // 7
      { data: "comments", title: "Comments", width: 400 }, // 8
      {
        data: null,
        title: "Receipts",
        width: 120,
        responsivePriority: 1,
        className: "dt-body-center"
      } // 9
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "desc"]]
  }
};
