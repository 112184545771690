import React, { useEffect, useState } from "react";

import axios from "axios";

import { useDispatch } from "react-redux";
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// components
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// @material-ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import showNotification from "global_functions/redux_action_creators/showNotification";
import formatDate from "global_functions/_common/formatDate";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formsStyles);

// API URL
const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function MoveResizeBookingDialog(props) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const formClasses = useFormStyles();

  const [mrDialog, setMrDialog] = useState(false);
  const [eventId, setEventId] = useState(0);
  const [eventTitle, setEventTitle] = useState("");

  const [series, setSeries] = useState([]);
  const [showSeriesCheckbox, setShowSeriesCheckbox] = useState(false);
  const [moveSeries, setMoveSeries] = useState(false);

  const [newStartDate, setNewStartDate] = useState("");
  const [newStartDateReadable, setNewStartDateReadable] = useState("");
  const [newStartTime, setNewStartTime] = useState("");
  const [newEndTime, setNewEndTime] = useState("");

  const [oldStartDate, setOldStartDate] = useState("");

  const [moveNotResize, setMoveNotResize] = useState(false);

  const [bookingUserName, setBookingUserName] = useState("");
  const [bookingUserId, setBookingUserId] = useState("");

  const [dialogLoading, setDialogLoading] = useState(false);

  const customFormHeaderStyle = {
    textAlign: "left",
    marginBottom: "0px",
    marginTop: "10px"
  };

  useEffect(() => {
    if (Object.keys(props.mrInfo).length !== 0) {
      let eventType = props.checkCalendarEventType(props.mrInfo.event);
      if (eventType === "timesheet") {
        // error & refetch if timesheet
        dispatch(
          showNotification("Unable to edit timesheet entries.", "warning")
        );
        props.calendarRef.current.calendar.refetchEvents();
      } else if (eventType === "bank_holiday") {
        // error & refetch if bank holiday
        dispatch(
          showNotification("Unable to edit bank holiday entries.", "warning")
        );
        props.calendarRef.current.calendar.refetchEvents();
      } else if (eventType === "standard") {
        // set params & variables shared by move & resize
        let newEventStartDate = formatDate(
          props.mrInfo.event.start,
          "yyyy-mm-dd"
        );
        let newEventEndDate = formatDate(props.mrInfo.event.end, "yyyy-mm-dd");
        setNewStartTime(formatDate(props.mrInfo.event.start, "hh-mm"));
        setNewEndTime(formatDate(props.mrInfo.event.end, "hh-mm"));
        setNewStartDate(formatDate(props.mrInfo.event.start, "yyyy-mm-dd"));
        setNewStartDateReadable(
          formatDate(props.mrInfo.event.start, "dd MM yyyy")
        );
        setEventId(props.mrInfo.event.id);
        setEventTitle(props.mrInfo.event.title);

        if ("oldResource" in props.mrInfo) {
          // moving (resizing does not have olResource property)
          setMoveNotResize(true);
          let oldEventStartDate = formatDate(
            props.mrInfo.oldEvent.start,
            "yyyy-mm-dd"
          );
          let oldEventEndDate = formatDate(
            props.mrInfo.oldEvent.end,
            "yyyy-mm-dd"
          );
          let newResource = props.mrInfo.newResource;
          let oldResource = props.mrInfo.oldResource;

          let newEventEndDate = formatDate(
            props.mrInfo.event.end,
            "yyyy-mm-dd"
          );

          // if moving within the same day for the same resource, show error and cancel
          if (
            !newResource &&
            !oldResource &&
            (oldEventStartDate === newEventStartDate ||
              oldEventEndDate === newEventEndDate)
          ) {
            dispatch(
              showNotification(
                "Unable to move within the same day for the same resource." +
                  "Please use resize instead.",
                "warning"
              )
            );
            props.calendarRef.current.calendar.refetchEvents();
          } else {
            setMrDialog(true);
            let newEventStartDate = formatDate(
              props.mrInfo.event.start,
              "yyyy-mm-dd"
            );
            setBookingUserName(
              props.mrInfo.oldEvent.getResources()[0]._resource.title
            );
            setBookingUserId(props.mrInfo.event.getResources()[0]._resource.id);
            setNewStartDate(newEventStartDate);
            setOldStartDate(
              formatDate(props.mrInfo.oldEvent.start, "yyyy-mm-dd")
            );
            setMoveSeries(false);
            setShowSeriesCheckbox(false);

            // fetch series info
            axios
              .get(
                apiUrl +
                  "calendar-bookings/" +
                  props.mrInfo.event.id +
                  "?format_type=series_only",
                getTokenConfig(store.getState)
              )
              .then(res => {
                setSeries(res.data);
                // only showing move series when moving to a new resource on the same day
                if (newEventStartDate === oldEventStartDate) {
                  setShowSeriesCheckbox(true);
                }
              })
              .catch(err => {
                let errorMsg;
                err.response.data.errors
                  ? (errorMsg = err.response.data.errors)
                  : (errorMsg = "Error fetching series");
                dispatch(showNotification(errorMsg, "error"));
              });
          }
        } else {
          // resizing
          setMoveNotResize(false);
          setShowSeriesCheckbox(false);
          let oldEventStartDate = formatDate(
            props.mrInfo.prevEvent.start,
            "yyyy-mm-dd"
          );
          let oldEventEndDate = formatDate(
            props.mrInfo.prevEvent.end,
            "yyyy-mm-dd"
          );

          if (
            oldEventStartDate === newEventStartDate &&
            oldEventEndDate === newEventEndDate
          ) {
            // only resizing in the same day
            setMrDialog(true);
            setBookingUserName(
              props.mrInfo.prevEvent.getResources()[0]._resource.title
            );
          } else props.calendarRef.current.calendar.refetchEvents(); // if not in the same day, refetch
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mrInfo, props.calendarRef, props.checkCalendarEventType, dispatch]);

  const handleConfirm = () => {
    let reqData;

    if (moveNotResize) {
      reqData = {
        event_edit_type: "move",
        old_date: oldStartDate,
        new_date: newStartDate,
        move_series: moveSeries,
        new_resource: bookingUserId
      };
    } else {
      reqData = {
        event_edit_type: "resize",
        new_date: newStartDate,
        new_start_time: newStartTime,
        new_end_time: newEndTime
      };
    }

    setDialogLoading(true);
    axios
      .put(
        apiUrl + "calendar-bookings/" + eventId,
        reqData,
        getTokenConfig(store.getState)
      )
      .then(() => {
        dispatch(showNotification("Booking updated.", "success"));
        props.calendarRef.current.calendar.refetchEvents();
        setMrDialog(false);
        setDialogLoading(false);
      })
      .catch(err => {
        let errorMsg;
        err.response.data.errors
          ? (errorMsg = err.response.data.errors)
          : (errorMsg = "Error updating booking.");
        dispatch(showNotification(errorMsg, "error"));
        setDialogLoading(false);
      });
  };

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        fullWidth={true}
        open={mrDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setMrDialog(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>
            {moveNotResize ? "Move Event" : "Resize event"}
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {dialogLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70px",
                width: "500px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          ) : (
            <>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  {moveNotResize ? (
                    <p>
                      {`Are you sure you would like to move ${bookingUserName}'s booking for  
                                                ${eventTitle} to new time slow on ${newStartDateReadable} at
                                                ${newStartTime} - ${newEndTime}`}
                    </p>
                  ) : (
                    <p>
                      {`Are you sure you would like to change ${bookingUserName}'s booking
                                                for ${eventTitle} on ${newStartDateReadable} so that its timeslot
                                                becomes ${newStartTime} - ${newEndTime}`}
                    </p>
                  )}
                </GridItem>
              </GridContainer>

              {showSeriesCheckbox &&
                (series.length &&
                  (series.length > 1 || series[0][0] !== series[0][1])) && (
                  <>
                    <GridContainer>
                      <GridItem xs={12} sm={2} />
                      <GridItem xs={12} sm={10}>
                        <p
                          className={classes.modalSectionTitle}
                          style={customFormHeaderStyle}
                        >
                          {" "}
                          Series:{" "}
                        </p>
                        <ul
                          className={classes.cardSubtitle}
                          style={{ textAlign: "left" }}
                        >
                          {series.map((item, index) => {
                            return (
                              <li key={`series-${index}`}>{`${item[0]} - ${
                                item[1]
                              }`}</li>
                            );
                          })}
                        </ul>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={2} />
                      <GridItem xs={12} lg={4}>
                        <div className={formClasses.inlineChecks}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={e => setMoveSeries(e.target.checked)}
                                checked={moveSeries}
                                checkedIcon={
                                  <Check className={formClasses.checkedIcon} />
                                }
                                icon={
                                  <Check
                                    className={formClasses.uncheckedIcon}
                                  />
                                }
                                classes={{
                                  checked: formClasses.checked,
                                  root: formClasses.checkRoot
                                }}
                              />
                            }
                            classes={{
                              label: formClasses.label,
                              root: formClasses.labelRoot
                            }}
                            label="Move Series"
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </>
                )}
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            disabled={dialogLoading}
            color="primary"
            simple
            onClick={handleConfirm}
          >
            Confirm
          </Button>
          <Button
            disabled={dialogLoading}
            color="transparent"
            onClick={() => {
              setMrDialog(false);
              props.calendarRef.current.calendar.refetchEvents();
            }}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
