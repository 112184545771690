import React, { useEffect, useState } from "react";

import axios from "axios";
// redux
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// custom components
import { WidgetTemplate } from "../WidgetTemplate";
// icons
import { School } from "@material-ui/icons";

const TrainingWidget: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState({
    missing_or_invalid: 0
  });
  const apiUrl = process.env.REACT_APP_API_V1_URL;

  // initial load
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        apiUrl + "datasets/?type=training_widget_admin",
        getTokenConfig(store.getState)
      )
      .then(res => {
        setCardData(res.data);
        setIsLoading(false);
      });
  }, [apiUrl]);

  return (
    <WidgetTemplate
      bottomText="Certificates Section"
      bottomUrl="/#/admin/hrhs-certificates"
      icon={<School />}
      iconColor="info"
      isLoading={isLoading}
      statsText=" miss certs"
      title="Training"
      value={cardData.missing_or_invalid}
    />
  );
};

export default TrainingWidget;
