// functions
import formatDate from "../../../../global_functions/_common/formatDate";
// types
import { VisibilityObject } from "../../../../views/Pages/ReportsPage";

const apiUrl = process.env.REACT_APP_API_V1_URL;

const generateUrl: GenerateReportUrl = (
  visibilityObj,
  reportType,
  reportUserId,
  date,
  whClientId,
  whBayId,
  projectId,
  genExcel
) => {
  let fetchUrl = `${apiUrl}documents/?dataset=${reportType}`;

  // getting report user (if needed for report)
  if (visibilityObj.userFieldEnabled) {
    fetchUrl += `&user=${reportUserId}`;
  }

  // getting report month (if needed for report)
  if (visibilityObj.monthFieldEnabled) {
    const startDate = new Date(date);
    const endDate = new Date(date);
    startDate.setDate(1);
    endDate.setMonth(startDate.getMonth() + 1);
    endDate.setDate(0);
    fetchUrl += `&start_date=${formatDate(
      startDate,
      "yyyy-mm-dd"
    )}&end_date=${formatDate(endDate, "yyyy-mm-dd")}`;
  }

  // getting warehouse client (if needed for report)
  if (visibilityObj.whClientsFieldEnabled) {
    fetchUrl += `&wh_client=${whClientId}`;
  }

  // getting warehouse bay (if needed for report)
  if (visibilityObj.whBayChoiceFieldEnabled) {
    fetchUrl += `&wh_bay_id=${whBayId}`;
  }

  // get project
  if (
    visibilityObj.projFieldWithWhChoicesEnabled ||
    visibilityObj.projFieldWithAllChoicesEnabled
  ) {
    fetchUrl += `&proj_id=${projectId}`;
  }

  // if excel selected, add the flag
  if (genExcel) fetchUrl += "&gen_excel=1";

  return fetchUrl;
};

export default generateUrl;

type GenerateReportUrl = (
  visibilityObj: VisibilityObject,
  reportType: string,
  reportUserId: number,
  date: Date,
  whClientId: number,
  whBayId: number,
  projectId: number,
  genExcel: boolean
) => string;
