import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const qrIcon = { icon: "CropFree", tooltip: "Generate QR code" };
const duplicateIcon = { icon: "FileCopy", tooltip: "Duplicate entry" };
const editIcon = { icon: "Edit", tooltip: "Edit entry" };
const deleteIcon = { icon: "Delete", tooltip: "Delete entry" };
const historyIcon = { icon: "History", tooltip: "View item history" };
const moveItemIcon = { icon: "Storage", tooltip: "Move to/from container" };
const splitBoxIcon = { icon: "CallSplit", tooltip: "Remove items from box" };
const fastCheckOutIcon = {
  icon: "ArrowRight",
  tooltip: "Move to pre-checkout"
};

export const warehouseItemsMainV2 = {
  section: "warehouseItems",
  reduxTableName: "warehouseItemsMain",
  filterTabValues: [
    "?filter=6",
    "?filter=1",
    "?filter=2",
    "?filter=5",
    "?filter=3",
    "?filter=4"
  ],
  filterTabNames: [
    "All",
    "Pre-Check-in",
    "Checked In",
    "Pre-Check-Out",
    "Checked Out",
    "Deleted"
  ],
  tableParams: {
    autoWidth: true,
    responsive: true,
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    buttonsColumn: {
      id: 11,
      genIcons: inst => {
        let iconArray = [
          editIcon,
          deleteIcon,
          duplicateIcon,
          moveItemIcon,
          historyIcon,
          qrIcon
        ];
        let rowIcons;
        // assign icons based on status
        if (inst.status_id === 4) {
          // deleted items only show history
          rowIcons = [historyIcon];
        } else if (inst.status_id === 2) {
          // items with 'checked in' status have the default array + quick move to checkout
          iconArray.splice(4, 0, fastCheckOutIcon);
          rowIcons = iconArray;
        } else {
          // all other statuses have default array
          rowIcons = iconArray;
        }
        // assign split box icon
        if (inst.type === 1 && inst.qty > 1) rowIcons.splice(4, 0, splitBoxIcon);

        return rowIcons;
      }
    },
    columns: [
      { data: "id", title: "ID", className: "dt-body-center", width: 100 }, // 0
      { data: "manufacturer", title: "Manufacturer", width: 500 }, // 1
      { data: "model", title: "Model", width: 500 }, // 2
      { data: "serial_no", title: "Serial", width: 500 }, // 3
      {
        data: "type_str",
        title: "Type",
        width: 500,
        className: "dt-body-center"
      }, // 4
      {
        data: { _: "container.display", sort: "container.@data-order" },
        title: "Container",
        width: 200,
        className: "dt-body-center"
      }, // 5
      { data: "qty", title: "Qty", width: 200, className: "dt-body-center" }, // 6
      { data: "client_and_project", title: "Project", width: 1000 }, // 7
      {
        data: "location_str",
        title: "Location",
        width: 500,
        className: "dt-body-center"
      }, // 8
      { data: "comments", title: "Comments", width: 750 }, // 9
      {
        data: "status",
        title: "Status",
        className: "dt-body-center",
        width: 200
      }, // 10
      {
        data: null,
        title: "Actions",
        className: "dt-body-center",
        width: 1200,
        responsivePriority: 1
      } // 11
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },

    aaSorting: [[0, "asc"]]
  }
};
