import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

export const bookingsUserMain = {
  section: "bookingsUser",
  reduxTableName: "bookingsUserMain",
  refreshURL: "",
  tableParams: {
    autoWidth: true,
    responsive: true,
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    columns: [
      { data: "id", visible: false, searchable: false },
      { data: { _: "date.display", sort: "date.@data-order" }, title: "Date" },
      { data: "client", title: "Client" },
      { data: "project", title: "Project" },
      { data: "start", title: "Start", className: "dt-body-center" },
      { data: "finish", title: "Finish", className: "dt-body-center" },
      { data: "address", title: "Address", className: "dt-body-center" },
      { data: "comments", title: "Comments" }
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "asc"]]
  }
};
