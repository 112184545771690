import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

export const quoteBreakdownMain = {
  section: "quoteBreakdown",
  reduxTableName: "quoteBreakdownMain",
  tableParams: {
    pageLength: 10,
    autoWidth: true,
    responsive: true,
    select: {
      style: "single"
    },
    // bPaginate: false,
    dom: "Bfrtip",
    searching: false,
    info: false,
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "Quote breakdown has not been added yet."
    },
    buttonsColumn: {
      id: 6,
      genIcons: () => {
        return [
          { icon: "Delete", tooltip: "Delete Entry" },
          { icon: "Edit", tooltip: "Edit entry" }
        ];
      }
    },
    columns: [
      { data: "id", title: "#", visible: false },
      {
        data: "company_service",
        title: "Service",
        orderable: false,
        width: 300,
        className: "dt-body-center"
      },
      {
        data: "price",
        title: "Price",
        orderable: false,
        width: 50,
        className: "dt-body-center"
      }, // 1
      {
        data: "qty",
        title: "Qty",
        orderable: false,
        width: 50,
        className: "dt-body-center"
      }, // 2
      {
        data: "total",
        title: "Total",
        orderable: false,
        width: 50,
        className: "dt-body-center"
      }, // 3
      {
        data: "comment",
        title: "Comment",
        orderable: false,
        width: 500,
        className: "dt-body-center"
      }, // 4
      {
        data: "id",
        title: "Delete",
        orderable: false,
        width: 50,
        className: "dt-body-center"
      } // 5
    ]
  }
};
