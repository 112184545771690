import React from "react";
// template components
import GridItem from "../../../../Grid/GridItem";
import Card from "../../../../Card/Card";
import CardHeader from "../../../../Card/CardHeader";
import CardIcon from "../../../../Card/CardIcon";
import CardFooter from "../../../../Card/CardFooter";
// custom components
import CustomLinearProgress from "../../../../CustomLinearProgress/CustomLinearProgress";
import StatsText from "../StatsText";
import { makeStyles } from "@material-ui/core/styles";
// styles
import styles from "../../../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
// @ts-ignore
const useStyles = makeStyles(styles);

export const WidgetTemplate: React.FC<WidgetTemplateType> = ({
  isLoading,
  value,
  value2,
  bottomText,
  bottomUrl,
  title,
  icon,
  iconColor,
  statsText,
  statsText2
}) => {
  const classes = useStyles();
  return (
    <GridItem xs={12} sm={6} md={6} lg={3}>
      {/*@ts-ignore*/}
      <Card style={{ marginBottom: "14px" }}>
        <CardHeader color="primary" stats icon>
          <CardIcon color={iconColor}>{icon}</CardIcon>
          <p className={classes.cardCategory} style={{ fontSize: "14px" }}>
            {title}
          </p>
          <h3 className={classes.cardTitle}>
            {isLoading ? (
              <CustomLinearProgress />
            ) : (
              <StatsText
                value={value}
                value2={value2}
                text={statsText}
                text2={statsText2}
              />
            )}
          </h3>
        </CardHeader>
        {/*@ts-ignore*/}
        <CardFooter stats style={{ marginTop: 0 }}>
          <div className={classes.stats}>
            {icon}
            <a href={bottomUrl}>{bottomText}</a>
          </div>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

type WidgetTemplateType = {
  isLoading: boolean;
  value: number | string;
  value2?: number | string;
  bottomUrl: string;
  bottomText: string;
  title: string;
  icon: any;
  iconColor: string;
  statsText: string;
  statsText2?: string;
};
