import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// custom functions
import useEventListener from "global_functions/_common/useEventListener";
import useFetchDocument from "global_functions/_common/useFetchDocument";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import identifyClick from "global_functions/_common/identifyClick";
// custom dialog
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import DeleteDialog from "views/Dialogs/_Common/DeleteDialog";
import ProjectContactInfoModal from "../../Dialogs/Projects/ProjectContactInfoModal";
// custom components
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import AddButton from "../../../components/CustomButtons/AddButton";
import TableFilter from "components/_Custom/_Common/TableFilter";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// template components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
// style
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
// table
import { projectDBMain } from "views/DataTables/Projects/projectDBMain";
// form for adding and editing projects
import AddEditProjectForm from "views/Forms/AddEditProjectForm";

import {
  SET_PROJECT_DB_DT_PAGE,
  SET_PROJECT_DB_DT_TRIG_REFRESH,
  SET_PROJECT_DB_DT_ROW,
  SET_PROJECT_DB_FILTER
} from "redux/dispatch_types";

const apiUrl = process.env.REACT_APP_API_V1_URL;

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

export default function ProjectDBPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const reduxPageProps = useSelector(state => state.projectDB);

  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState(false);
  const [dataTable, setDataTable] = useState("");
  const [editDelId, setEditDelId] = useState(null);
  const [contactInfoModal, setContactInfoModal] = useState(false);

  // state variables that store report URL and fileName
  const [reportParams, setReportParams] = useState("");

  useDataTableRefresh(dataTable, projectDBMain);

  useFetchDocument(reportParams);

  // handlers
  const handleAddClick = () => {
    setEditDelId(0);
    if (reduxPageProps.tables.projectDBMain.pageFilter) {
      // if a filter is being viewed, change it to 0 so added entries can be seen
      dispatch({ type: SET_PROJECT_DB_FILTER, payload: 0 });
    }
    setAddEditModal(true);
  };

  const handleEditClick = dtData => {
    setEditDelId(dtData.id);
    setAddEditModal(true);
  };

  const handleDeleteClick = dtData => {
    setEditDelId(dtData.id);
    let delName = `${dtData.number}: ${dtData.client} - ${dtData.description}`;
    setDeleteName(delName);
    setDeleteModal(true);
  };

  const handleContactInfoClick = (dtData) => {
    setEditDelId(dtData.id);
    setContactInfoModal(true);
  };

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_PROJECT_DB_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_PROJECT_DB_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];

          if (clickRelevantType.params[0] === "Assignment") {
            dataTable.rows().deselect();

            setReportParams({
              url: apiUrl + "documents/?dataset=project_report&proj_id=" + dtData.id,
              fileName:
                "Job Report: " +
                dtData.number +
                "- " +
                dtData.client +
                " - " +
                dtData.description +
                ".pdf",
              fetchMsg: "Fetching job report..."
            });
          } else if (clickRelevantType.params[0] === "Edit") {
            dataTable.rows().deselect();
            handleEditClick(dtData);
          } else if (clickRelevantType.params[0] === "Delete") {
            dataTable.rows().deselect();
            handleDeleteClick(dtData);
          } else if (clickRelevantType.params[0] === "ContactMailTwoToneIcon") {
            handleContactInfoClick(dtData);
          }
          break;
        default:
          return false;
      }
    }
  };

  // add event listener for datatable clicks
  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Project Database</h4>
                <h4 className={classes.cardCategoryWhite}>
                  Manage SRW Projects
                </h4>
              </CardText>
              {dataTable ? (
                <TableFilter
                  color="primary"
                  activeFilter={reduxPageProps.tables.projectDBMain.pageFilter}
                  dispatchType={SET_PROJECT_DB_FILTER}
                  dataTable={dataTable}
                  tabs={projectDBMain.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setDataTable}
                tableParams={projectDBMain}
              />
            </CardBody>
            <CardFooter>
              <div className={classes.cardContentRight}>
                <AddButton
                  handleAddClick={handleAddClick}
                  tooltip="Add Project"
                />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelId}
          tableRefreshType={SET_PROJECT_DB_DT_TRIG_REFRESH}
          urlSuffix="projects/"
          filterInForm="filter_all_clients"
          choiceFieldForm="project-form"
          editTitle="Edit Project"
          addTitle="Add Project"
        >
          <AddEditProjectForm />
        </AddEditDtEntryDialog>
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="the project database"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={editDelId}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_PROJECT_DB_DT_TRIG_REFRESH}
          urlSuffix={"projects/"}
        />
        <ProjectContactInfoModal
          modal={contactInfoModal}
          setModal={setContactInfoModal}
          projId={parseInt(editDelId)}
        />
      </GridContainer>
    </div>
  );
}
