import React from "react";
// redux
import { useSelector } from "react-redux";
// template components
import GridContainer from "../../../../Grid/GridContainer";
// custom components
import ClientStats from "./ClientStats";
import ClientDashboardProjectsTable from "./ClientDashboardProjectsTable";
import ClientDashboardItemsTable from "./ClientDashboardItemsTable";

export default function ClientDashboard() {
  const { viewingItems } = useSelector(state => state.clientDashboard);

  return (
    <div>
      <GridContainer>
        <ClientStats />
        {viewingItems ? (
          <ClientDashboardItemsTable />
        ) : (
          <ClientDashboardProjectsTable />
        )}
      </GridContainer>
    </div>
  );
}
