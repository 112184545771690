import React, { useEffect, useState } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  SET_CLIENT_DASHBOARD_PROJ_DT_DT_PAGE,
  SET_CLIENT_DASHBOARD_PROJ_DT_DT_ROW,
  SET_CLIENT_DASHBOARD_PROJ_DT_FILTER,
  SET_CLIENT_DASHBOARD_PROJ_DT_REFRESH_URL,
  SET_CLIENT_DASHBOARD_VIEW_ITEMS
} from "../../../../../redux/dispatch_types";
// template
import CardBody from "../../../../Card/CardBody";
import CardFooter from "../../../../Card/CardFooter";
import GridItem from "../../../../Grid/GridItem";
import Card from "../../../../Card/Card";
import CardHeader from "../../../../Card/CardHeader";
import CardText from "../../../../Card/CardText";
// custom components
import TableFilter from "../../../_Common/TableFilter";
import Datatable from "../../../_Common/DataTables/DataTable";
import { clientDashboardProjects } from "../../../../../views/DataTables/Dashboard/clientDashboardProjects";
import CustomLoadingSpinner from "../../../_Common/CustomLoadingSpinner";
// functions
import identifyClick from "../../../../../global_functions/_common/identifyClick";
import useDataTableRefresh from "../../../../../global_functions/dataTables/useDatatableRefresh";
import useEventListener from "../../../../../global_functions/_common/useEventListener";
// material ui
import { makeStyles } from "@material-ui/core/styles";
// styles
import styles from "../../../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { cardTitle } from "../../../../../assets/jss/material-dashboard-pro-react";
const useStyles = makeStyles({
  ...styles,
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
});

const ClientDashboardProjectsTable = () => {
  const [dataTable, setDataTable] = useState("");

  const classes = useStyles();

  const client_company_id = useSelector(
    state => state.auth.user.client_company_id
  );

  const pageFilter = useSelector(
    state => state.clientDashboard.tables.clientDashboardProjects.pageFilter
  );

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({
            type: SET_CLIENT_DASHBOARD_PROJ_DT_DT_ROW,
            payload: selectedRow
          });
          break;
        case "pagination":
          dispatch({
            type: SET_CLIENT_DASHBOARD_PROJ_DT_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
          if (clickRelevantType.params[0] === "Visibility") {
            dispatch({
              type: SET_CLIENT_DASHBOARD_VIEW_ITEMS,
              payload: dtData.id
            });
            dataTable.rows().deselect();
          }
          break;
        default:
          return false;
      }
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_CLIENT_DASHBOARD_PROJ_DT_REFRESH_URL,
      payload: `projects/?with_warehouse_items=1&from_client_dashboard=1&client_id=${client_company_id}&filter=`
    });
  }, [client_company_id, dispatch]);

  useDataTableRefresh(dataTable, clientDashboardProjects);

  useEventListener("click", handleClickEvent, window);

  return (
    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" text>
          <CardText color="info">
            <h4 className={classes.cardTitleWhite}>Projects</h4>
            <h4 className={classes.cardCategoryWhite}>
              Company Projects with Warehouse Items
            </h4>
          </CardText>
          {dataTable ? (
            <TableFilter
              color="primary"
              activeFilter={pageFilter}
              dispatchType={SET_CLIENT_DASHBOARD_PROJ_DT_FILTER}
              dataTable={dataTable}
              tabs={clientDashboardProjects.filterTabNames}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "400px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          )}
        </CardHeader>
        <CardBody>
          <Datatable
            setDataTable={setDataTable}
            tableParams={clientDashboardProjects}
          />
        </CardBody>
        <CardFooter />
      </Card>
    </GridItem>
  );
};

export default ClientDashboardProjectsTable;
