import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
// redux
import { useDispatch } from "react-redux";
import showNotification from "../../../global_functions/redux_action_creators/showNotification";
import { getTokenConfig } from "../../../global_functions/redux_action_creators/auth/getTokenConfig";
import store from "../../../redux/store";
// custom components & functions
import PrintButton from "components/CustomButtons/PrintButton";
import { getDialogProps } from "global_functions/_common/getDialogProps";
import printIframe from "global_functions/_common/printIframe";
import CustomIframe from "components/_Custom/_Common/CustomIframe";
import GridContainer from "components/Grid/GridContainer";
//material-ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// other components
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
const labelWidth = "200px";
const frameHeight = "360px";
const qrCodeWidth = "120px";
const qrStyles = {
  smallText: {
    width: labelWidth,
    fontSize: 10,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    marginBottom: 0,
    marginTop: 0
  },
  largeText: {
    width: labelWidth,
    fontSize: 12,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    marginBottom: 0,
    marginTop: 0
  },
  hrStyle: {
    width: labelWidth,
    marginLeft: 0
  },
  canvasStyle: {
    width: qrCodeWidth,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block"
  }
};
const useStyles = makeStyles(styles);
const useCheckboxStyles = makeStyles(customCheckboxRadioSwitch);
// images
const logo = require("assets/img/srw_logo.png");
// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let QRCode = require("qrcode.react");

export default function QrCodeDialog({
  qrModal,
  setQrModal,
  qrItemId,
  qrType
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const checkboxClasses = useCheckboxStyles();

  const [includeLogo, setIncludeLogo] = useState(false);
  const [qrItemDetails, setQrItemDetails] = useState({});
  const [qrInfoLoading, setQrInfoLoading] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const apiUrl = process.env.REACT_APP_API_V1_URL;

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  const handlePrintClick = () => {
    printIframe("print-frame");
  };

  // on show load item details
  useEffect(() => {
    if (qrModal) {
      // set url based on qr code type (item vs rack)
      let qrInfoUrl = "";
      if (qrType === "Item") {
        qrInfoUrl = `${apiUrl}projects/0/items/${qrItemId}`;
      } else if (qrType === "Container") {
        qrInfoUrl = `${apiUrl}projects/0/containers/${qrItemId}`;
      }

      setQrInfoLoading(true);
      axios
        .get(qrInfoUrl, getTokenConfig(store.getState))
        .then(res => {
          setQrItemDetails(res.data);
          setQrInfoLoading(false);
        })
        .catch(() => {
          dispatch(
            showNotification(
              "Error loading item info. Check your connection and try again.",
              "error"
            )
          );
        });
    }
  }, [qrModal, apiUrl, qrItemId, dispatch, qrType]);

  useEffect(() => {
    if (qrModal) {
      document.addEventListener("keydown", captureKeyDown, false);
    } else {
      document.removeEventListener("keydown", captureKeyDown, false);
    }
  }, [qrModal, captureKeyDown]);

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={qrModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setQrModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setQrModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{qrType} QR Code</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={8}>
              <CustomIframe
                id="print-frame"
                title="printFrame"
                style={{ border: "none", height: frameHeight, width: "210px" }}
              >
                {!qrInfoLoading ? (
                  <>
                    <p style={qrStyles.largeText}>
                      {qrType === "Item"
                        ? `${qrItemDetails.manufacturer} ${qrItemDetails.model}`
                        : qrType === "Container"
                        ? `${qrItemDetails.name}`
                        : ""}
                    </p>
                    <hr style={qrStyles.hrStyle} />
                    <p style={qrStyles.smallText}>
                      {`${qrType} ID: ${qrItemDetails.id}`}
                      <br />
                      {qrType === "Item" &&
                        `Serial: ${qrItemDetails.serial_no}`}
                      {qrItemDetails.comments && (
                        <>
                          <br />
                          {`Comments: ${qrItemDetails.comments}`}
                        </>
                      )}
                    </p>
                    <hr style={qrStyles.hrStyle} />
                    <div>
                      <QRCode
                        value={
                          qrType === "Item"
                            ? `srw_warehouse_item_${qrItemDetails.id}`
                            : qrType === "Container"
                            ? `srw_warehouse_container_${qrItemDetails.id}`
                            : ""
                        }
                        style={qrStyles.canvasStyle}
                      />
                    </div>
                    <hr style={qrStyles.hrStyle} />
                    <p
                      style={qrStyles.largeText}
                    >{`${qrItemDetails.client_name}`}</p>
                    <p style={qrStyles.smallText}>
                      <i>{`${qrItemDetails.project_name}`}</i>
                    </p>
                    <p
                      style={qrStyles.smallText}
                    >{`${qrItemDetails.project_number}`}</p>
                    {includeLogo && (
                      <>
                        <hr style={qrStyles.hrStyle} />
                        <img
                          src={logo}
                          alt="logo"
                          style={{ width: labelWidth }}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <p>Loading item info.....</p>
                  </>
                )}
              </CustomIframe>
            </GridItem>
            <GridItem xs={3}>
              <PrintButton iframeId="print-frame" onClick={handlePrintClick} />
              <div className={checkboxClasses.inlineChecks}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="includeLogoCheckbox"
                      tabIndex={-1}
                      onClick={e => setIncludeLogo(e.target.checked)}
                      checked={includeLogo}
                      checkedIcon={
                        <Check className={checkboxClasses.checkedIcon} />
                      }
                      icon={<Check className={checkboxClasses.uncheckedIcon} />}
                      classes={{
                        checked: checkboxClasses.checked,
                        root: checkboxClasses.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: checkboxClasses.label,
                    root: checkboxClasses.labelRoot
                  }}
                  label="SRW Logo"
                />
              </div>
            </GridItem>
            <GridItem xs={1} />
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button color="transparent" onClick={() => setQrModal(false)} simple>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
