import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import { makeStyles } from "@material-ui/core/styles";

import axios from "axios";

import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
// custom dialog
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import DeleteDialog from "views/Dialogs/_Common/DeleteDialog";
import AddEditCertificateForm from "views/Forms/AddEditCertificateForm";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import AddButton from "components/CustomButtons/AddButton";
// tables
import { certificateOneUserMain } from "views/DataTables/HRHS/Certificates/certificateOneUserMain";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
// @material-ui/icons
import useEventListener from "global_functions/_common/useEventListener";
import identifyClick from "global_functions/_common/identifyClick";

import {
  SET_CERTIFICATE_ONE_USER_DT_PAGE,
  SET_CERTIFICATE_ONE_USER_DT_TRIG_REFRESH,
  SET_CERTIFICATE_ONE_USER_DT_ROW,
  SET_CERTIFICATE_ONE_USER_REFRESH_URL
} from "redux/dispatch_types";
import ManageImgPdfUploadsDialog from "views/Dialogs/Uploads/ManageImgPdfUploadsDialog";
import BackButton from "components/CustomButtons/BackButton";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import showNotification from "global_functions/redux_action_creators/showNotification";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

export default function CertificateOneUserPage(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState(false);
  const [dataTable, setDataTable] = useState("");
  const [editDelID, setEditDelID] = useState(null);
  const [scanId, setScanId] = useState(0);

  const [userNameLoading, setUserNameLoading] = useState(false);
  const [userName, setUserName] = useState("");

  const [manageUploadsDialog, setManageUploadsDialog] = useState(false);

  const userId = props.match.params.slug;

  const apiUrl = process.env.REACT_APP_API_V1_URL;

  useEffect(() => {
    // sets refresh url for page on load
    dispatch({
      type: SET_CERTIFICATE_ONE_USER_REFRESH_URL,
      payload: "users/" + userId + "/certificates/"
    });

    // get user name for top card
    setUserNameLoading(true);
    axios
      .get(`${apiUrl}users/${userId}`, getTokenConfig(store.getState))
      .then(resp => {
        setUserName(resp.data.full_name);
        setUserNameLoading(false);
      })
      .catch(() => {
        setUserNameLoading(false);
        dispatch(
          showNotification(
            "Error getting user info. Please check your connection.",
            "error"
          )
        );
      });
  }, [userId, dispatch, apiUrl]);

  useDataTableRefresh(dataTable, certificateOneUserMain);

  // handlers
  const handleAddClick = () => {
    setEditDelID(0);
    setAddEditModal(true);
  };

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({
            type: SET_CERTIFICATE_ONE_USER_DT_ROW,
            payload: selectedRow
          });
          break;
        case "pagination":
          dispatch({
            type: SET_CERTIFICATE_ONE_USER_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];

          setScanId(dtData.id);

          if (clickRelevantType.params[0].substring(0, 6) === "Filter") {
            setManageUploadsDialog(true);
          } else if (clickRelevantType.params[0] === "Edit") {
            dataTable.rows().deselect();
            setEditDelID(dtData.id);
            setAddEditModal(true);
          } else if (clickRelevantType.params[0] === "Delete") {
            dataTable.rows().deselect();
            setEditDelID(dtData.id);
            setDeleteName(dtData.name);
            setDeleteModal(true);
          }
          break;

        default:
          return false;
      }
    }
  };

  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                {userNameLoading ? (
                  <h4 className={classes.cardTitleWhite}>Loading...</h4>
                ) : (
                  <h4
                    className={classes.cardTitleWhite}
                  >{`${userName}'s Certificates`}</h4>
                )}
                <h4 className={classes.cardCategoryWhite}>
                  Single User Certificates
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <BackButton />
              <Datatable
                tableParams={certificateOneUserMain}
                setDataTable={setDataTable}
              />
            </CardBody>
            <CardFooter>
              <div className={classes.cardContentRight}>
                <AddButton
                  handleAddClick={handleAddClick}
                  tooltip="Add Certificate for User"
                />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelID}
          userId={userId}
          detailURLSuffix={`users/${userId}/certificates/`}
          tableRefreshType={SET_CERTIFICATE_ONE_USER_DT_TRIG_REFRESH}
          filterInForm={false}
          choiceFieldForm="add_edit_cert_form"
          editTitle="Edit Certificate"
          addTitle="Add Certificate"
        >
          <AddEditCertificateForm userId={userId} />
        </AddEditDtEntryDialog>
        <ManageImgPdfUploadsDialog
          manageUploadsDialog={manageUploadsDialog}
          setManageUploadsDialog={setManageUploadsDialog}
          refreshUrl={`users/${userId}/certificates/`}
          tableRefreshType={SET_CERTIFICATE_ONE_USER_DT_TRIG_REFRESH}
          parentId={scanId}
          showControls={true}
          title="Manage Certificate Scans"
        />
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="the certificate database"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={editDelID}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_CERTIFICATE_ONE_USER_DT_TRIG_REFRESH}
          urlSuffix={`users/${userId}/certificates/`}
        />
      </GridContainer>
    </div>
  );
}
