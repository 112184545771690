import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

export const userDBMain = {
  section: "userDB",
  refreshURL: "users/",
  reduxTableName: "userDBMain",
  filterTabValues: [
    "?is_active=1&is_staff_member=1",
    "?is_active=1&is_client=1",
    "?is_active=0",
    "?is_new=1"
  ],
  filterTabNames: ["Staff", "Clients", "Deactivated", "New"],
  tableParams: {
    autoWidth: true,
    responsive: true,
    dom: "Bfrtip",
    buttonsColumn: {
      id: 7,
      genIcons: () => {
        return [{ icon: "Person", tooltip: "" }];
      }
    },
    columns: [
      {
        data: "id",
        title: "ID",
        responsivePriority: 1,
        width: 50,
        className: "dt-body-center"
      }, // 0
      {
        data: "full_name",
        title: "Full Name",
        width: 200,
        className: "dt-body-center"
      }, // 1
      {
        data: "position",
        title: "Position",
        width: 200,
        className: "dt-body-center"
      }, // 3
      {
        data: { _: "date_joined.display", sort: "date_joined.@data-order" },
        title: "Date Joined",
        width: 100,
        className: "dt-body-center"
      }, // 3
      {
        data: "username",
        title: "Username",
        width: 200,
        className: "dt-body-center"
      }, // 4
      {
        data: "email",
        title: "Email",
        visible: false,
        searchable: false,
        width: 100,
        className: "dt-body-center"
      }, // 5
      {
        data: "is_active",
        title: "Active",
        visible: false,
        searchable: false,
        width: 100,
        className: "dt-body-center"
      }, // 6
      {
        data: null,
        title: "Profile",
        responsivePriority: 1,
        className: "dt-body-center",
        width: 100
      } // 7
    ],
    select: {
      style: "single"
    },
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "asc"]]
  }
};
