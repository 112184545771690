import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// icon
import { CompareArrows } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  consistencyCheckButton: {
    backgroundColor: "#607d8b",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#adbec6" },
    marginTop: 10,
    marginRight: 10
  }
}));

const ConsistencyCheckButton: React.FC<ConsistencyCheckButtonType> = ({
  handleAddClick,
  tooltip
}) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip}>
      <IconButton
        className={classes.consistencyCheckButton}
        onClick={handleAddClick}
      >
        <CompareArrows style={{ color: "#f9f9f9" }} />
      </IconButton>
    </Tooltip>
  );
};

export default ConsistencyCheckButton;

type ConsistencyCheckButtonType = {
  tooltip: string;
  handleAddClick: () => void;
};
