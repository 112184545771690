import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";

import CompletionDialog from "views/Dialogs/_Common/CompletionDialog";
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "components/CustomButtons/BackButton";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
// custom functions
import useEventListener from "global_functions/_common/useEventListener";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import identifyClick from "global_functions/_common/identifyClick";
import useFetchDocument from "global_functions/_common/useFetchDocument";

import {
  SET_TS_ENTRIES_ADMIN_DT_PAGE,
  SET_TS_ENTRIES_ADMIN_DT_ROW,
  SET_TS_ENTRIES_ADMIN_REFRESH_URL,
  SET_TS_ENTRIES_ADMIN_DT_TRIG_REFRESH
} from "redux/dispatch_types";

import CompletionButton from "components/CustomButtons/CompletionButton";
import ReportButton from "components/CustomButtons/ReportButton";
import ConfirmationDialog from "views/Dialogs/_Common/ConfirmationDialog";
import ApproveButton from "components/CustomButtons/ApproveButton";
import RejectButton from "components/CustomButtons/RejectButton";
// tables
import { tsEntriesAdminMain } from "views/DataTables/Timesheets/tsEntriesAdminMain";

const apiUrl = process.env.REACT_APP_API_V1_URL;

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  infoIconButton: {
    backgroundColor: "#26c6da",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#90e1ec" },
    marginTop: 10,
    marginRight: 10
  }
};

const useStyles = makeStyles(styles);

export default function TsEntryAdminPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dataTable, setDataTable] = useState("");

  const [showCompletionDialog, setShowCompletionDialog] = useState(false);

  const [bundleCompletion, setBundleCompletion] = useState(false);

  const [bundleInfoLoading, setBundleInfoLoading] = useState(false);
  const [completionInfoLoading, setCompletionInfoLoading] = useState(false);

  const tsBundleID = props.match.params.slug;

  const [bundleStatus, setBundleStatus] = useState("");
  const [bundleName, setBundleName] = useState("");
  const [bundleEntries, setBundleEntries] = useState(0);
  const [bundleUser, setBundleUser] = useState(0);
  const [bundleDate, setBundleDate] = useState(0);

  const [reportParams, setReportParams] = useState("");

  const [submitConfirmationDialog, setSubmitConfirmationDialog] = useState(
    false
  );
  const [rejectConfirmationDialog, setRejectConfirmationDialog] = useState(
    false
  );

  const reduxPageProps = useSelector(state => state.tsEntriesAdmin);

  // sets refresh url for page on load
  useEffect(() => {
    dispatch({
      type: SET_TS_ENTRIES_ADMIN_REFRESH_URL,
      payload: "timesheet-bundles/" + tsBundleID + "/timesheets/"
    });

    // get bundle details
    axios
      .get(
        apiUrl + "timesheet-bundles/" + tsBundleID,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setBundleStatus(res.data.status);
        setBundleName(res.data.sub_month.display);
        setBundleEntries(res.data.ts_entries);
        setBundleDate(res.data.sub_month.display);
        setBundleUser(res.data.sub_user);
      });
  }, [tsBundleID, dispatch]);

  // every time table is refreshed, refresh stats & completion
  useEffect(() => {
    setBundleInfoLoading(true);
    axios
      .get(
        `${apiUrl}timesheet-bundles/${tsBundleID}`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setBundleStatus(res.data.status);
        setBundleName(res.data.sub_month.display);
        setBundleEntries(res.data.ts_entries);
        setBundleDate(res.data.sub_month.display);
        setBundleUser(res.data.sub_user);
        setBundleInfoLoading(false);
      });

    setCompletionInfoLoading(true);
    axios
      .get(
        `${apiUrl}datasets/?type=timesheet_bundle_completion&ts_bundle_id=${tsBundleID}`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setBundleCompletion(res.data.percHours);
        setCompletionInfoLoading(false);
      });
  }, [reduxPageProps.tables.tsEntriesAdminMain.triggerDtRefresh, tsBundleID]);

  useDataTableRefresh(dataTable, tsEntriesAdminMain);

  const handleCompletionClick = () => {
    setShowCompletionDialog(true);
  };

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_TS_ENTRIES_ADMIN_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_TS_ENTRIES_ADMIN_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        default:
          return false;
      }
    }
  };

  const handleReportClick = e => {
    e.stopPropagation();
    setReportParams({
      url: `${apiUrl}documents/?dataset=timesheet_monthly_report&ts_bundle_id=${tsBundleID}`,
      fileName: `${bundleUser} timesheet report for ${bundleDate}.pdf`,
      fetchMsg: "Fetching timesheet report..."
    });
  };

  useEventListener("click", handleClickEvent, window);

  useFetchDocument(reportParams);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>{bundleName}</h4>
                <h4 className={classes.cardCategoryWhite}>
                  User: {bundleUser}
                </h4>
                <h4 className={classes.cardCategoryWhite}>
                  Status : {bundleInfoLoading ? "Loading..." : bundleStatus}
                  <br />
                  Completion:{" "}
                  {completionInfoLoading ? "Loading..." : bundleCompletion}
                </h4>
              </CardText>
              <GridContainer>
                <GridItem xs={12}>
                  <BackButton />
                  {bundleStatus === "Submitted" && (
                    <ApproveButton
                      handleApprove={() => setSubmitConfirmationDialog(true)}
                    />
                  )}
                  {(bundleStatus === "Submitted" ||
                    bundleStatus === "Approved") && (
                    <RejectButton
                      onClick={() => setRejectConfirmationDialog(true)}
                      tooltip="Reject timesheet bundle"
                    />
                  )}
                  <CompletionButton
                    handleCompletionClick={handleCompletionClick}
                    greyedOut={!bundleEntries}
                  />
                  <ReportButton
                    tooltip="Generate timesheet report"
                    handleReportClick={handleReportClick}
                    greyedOut={!bundleEntries}
                  />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setDataTable}
                tableParams={tsEntriesAdminMain}
              />
            </CardBody>
          </Card>
        </GridItem>
        <CompletionDialog
          showDialog={showCompletionDialog}
          setShowDialog={setShowCompletionDialog}
          tsBundleId={tsBundleID}
        />
        {/* reject bundle confirmation*/}
        <ConfirmationDialog
          confirmationDialog={rejectConfirmationDialog}
          setConfirmationDialog={setRejectConfirmationDialog}
          confirmationMessage="Are you sure you would like to reject this bundle?"
          confirmationTitle="Reject Submission"
          confirmURL={`timesheet-bundles/${tsBundleID}`}
          confirmPayload={{ new_status: 4 }}
          successMsg="Bundle Rejected"
          errorMsg="Error rejecting bundle."
          tableRefreshType={SET_TS_ENTRIES_ADMIN_DT_TRIG_REFRESH}
        />
        {/* approve bundle confirmation*/}
        <ConfirmationDialog
          confirmationDialog={submitConfirmationDialog}
          setConfirmationDialog={setSubmitConfirmationDialog}
          confirmationMessage="Are you sure you would like to approve this bundle?"
          confirmationTitle="Approve Bundle"
          confirmURL={`timesheet-bundles/${tsBundleID}`}
          confirmPayload={{ new_status: 3 }}
          successMsg="Bundle Approved"
          errorMsg="Error approving bundle."
          tableRefreshType={SET_TS_ENTRIES_ADMIN_DT_TRIG_REFRESH}
        />
      </GridContainer>
    </div>
  );
}
