import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

import { DataTableParamsType } from "../../../../global_functions/types/dataTables";

const editButton = { icon: "Edit", tooltip: "Edit requirement" };

export const certificateReqsMain: DataTableParamsType = {
  section: "certificateReqs",
  reduxTableName: "certificateReqsMain",
  refreshURL: "departments/0/positions/?view=requirements",
  tableParams: {
    autoWidth: true,
    buttonsColumn: {
      id: 3,
      genIcons: () => {
        return [editButton];
      }
    },
    dom: "Bfrtip",
    select: { style: "single" },
    processing: true,
    responsive: true,
    language: {
      processing: DtLoadingSpinner
    },
    columns: [
      {
        data: "id",
        title: "ID",
        responsivePriority: 2,
        className: "dt-body-center",
        width: 100,
      },
      {
        data: "long_name",
        title: "Position Name",
        responsivePriority: 2,
        width: 300
      }, // 1
      {
        data: "required_certs",
        title: "Required Certificates",
        responsivePriority: 2,
        width: 300
      }, // 2
      {
        data: null,
        title: "Actions",
        className: "dt-body-center",
        responsivePriority: 1,
        width: 100
      } // 3
    ],
    aaSorting: [[1, "asc"]]
  }
};
