import React, { useState } from "react";
// redux
import {
  SET_PROCUREMENT_REQUESTS_DT_TRIG_REFRESH,
  SET_PROCUREMENT_REQUESTS_DT_PAGE,
  SET_PROCUREMENT_REQUESTS_DT_ROW,
  SET_PROCUREMENT_REQUESTS_FILTER
} from "redux/dispatch_types";
import { useDispatch, useSelector } from "react-redux";
// custom functions & hooks
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import useEventListener from "global_functions/_common/useEventListener";
import identifyClick from "global_functions/_common/identifyClick";
import showNotification from "../../global_functions/redux_action_creators/showNotification";
import useFetchDocument from "../../global_functions/_common/useFetchDocument";
import formatDate from "../../global_functions/_common/formatDate";
// custom components
import AddButton from "components/CustomButtons/AddButton";
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import DeleteDialog from "views/Dialogs/_Common/DeleteDialog";
import CustomLoadingSpinner from "../../components/_Custom/_Common/CustomLoadingSpinner";
import TableFilter from "../../components/_Custom/_Common/TableFilter";
import AddEditProcurementRequestForm from "../Forms/AddEditProcurementRequestForm";
import ManageImgPdfUploadsDialog from "../Dialogs/Uploads/ManageImgPdfUploadsDialog";
import ConfirmationDialog from "../Dialogs/_Common/ConfirmationDialog";
import ReportButton from "../../components/CustomButtons/ReportButton";
// table
import { procurementReqUser } from "../DataTables/ProcurementRequests/procurementReqUser";
import { procurementReqAdmin } from "../DataTables/ProcurementRequests/procurementReqAdmin";
// template
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
// types
import { RootStateType } from "../../redux/reducers";
import { ReportParams } from "../../global_functions/types/shared";
// styles
import { makeStyles } from "@material-ui/core/styles";
import {
  cardTitle,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardContentRight: {
    padding: "15px 20px 15px 0px",
    position: "relative"
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  }
};

// @ts-ignore
const useStyles = makeStyles(styles);

const ProcurementRequestPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [dataTable, setDataTable] = useState<DataTables.Api | undefined>(
    undefined
  );
  const [editDelId, setEditDelId] = useState(0);

  const [pdfParams, setPdfParams] = useState<ReportParams>({
    url: "",
    fileName: "",
    fetchMsg: ""
  });

  const [userCanUpload, setUserCanUpload] = useState(false);

  const [manageUploadsDialog, setManageUploadsDialog] = useState(false);

  // confirmation dialog
  const [confirmationParams, setConfirmationParams] = useState<
    ConfirmationParamsType
  >(["", "", "", "", null]);
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const [statusChangeReqId, setStatusChangeReqId] = useState<string>("");

  const userObj = useSelector((state: RootStateType) => state.auth.user);
  const isAdmin = userObj ? userObj.userprofile.is_procurement_admin : false;

  const viewedFilter = useSelector(
    (state: RootStateType) =>
      state.procurementRequests.tables.procurementReqMain.pageFilter
  );

  const datatableParams = isAdmin ? procurementReqAdmin : procurementReqUser;

  const apiUrl = process.env.REACT_APP_API_V1_URL;

  const handleClickEvent = (e: React.MouseEvent): void => {
    const clickRelevantType = identifyClick(e);

    if (dataTable && clickRelevantType && "type" in clickRelevantType) {
      let selectedRow;

      switch (clickRelevantType.type) {
        case "cellClick":
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({
            type: SET_PROCUREMENT_REQUESTS_DT_ROW,
            payload: selectedRow
          });
          break;
        case "pagination":
          dispatch({
            type: SET_PROCUREMENT_REQUESTS_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          if (
            "params" in clickRelevantType &&
            Array.isArray(clickRelevantType["params"])
          ) {
            const dtData = dataTable
              .rows(clickRelevantType.params[1])
              .data()[0];

            if (clickRelevantType.params[0] === "Link") {
              navigator.clipboard.writeText(dtData.url);
              dispatch(
                showNotification(`Copied to clipboard: ${dtData.url}`, "info")
              );
              dataTable.rows().deselect();
            } else if (clickRelevantType.params[0] === "Edit") {
              setEditDelId(dtData.id);
              setAddEditModal(true);
              dataTable.rows().deselect();
            } else if (clickRelevantType.params[0] === "Delete") {
              setEditDelId(dtData.id);
              setDeleteName(`request ID ${dtData.id}`);
              setDeleteModal(true);
              dataTable.rows().deselect();
            } else if (clickRelevantType.params[0] === "CheckCircle") {
              setStatusChangeReqId(dtData.id);
              setConfirmationParams([
                "Are you sure you would like to approve this procurement request?",
                "Approve Request",
                "Request Approved",
                "Error approving request.",
                2
              ]);
              setConfirmationDialog(true);
              dataTable.rows().deselect();
            } else if (clickRelevantType.params[0] === "Cancel") {
              setStatusChangeReqId(dtData.id);
              setConfirmationParams([
                "Are you sure you would like to reject this procurement request?",
                "Reject Request",
                "Request Rejected",
                "Error rejecting request.",
                3
              ]);
              setConfirmationDialog(true);
              dataTable.rows().deselect();
            } else if (clickRelevantType.params[0] === "ArrowBack") {
              setStatusChangeReqId(dtData.id);
              setConfirmationParams([
                "Are you sure you would like to return this procurement request?",
                "Return Request",
                "Request Returned",
                "Error returning request.",
                1
              ]);
              setConfirmationDialog(true);
              dataTable.rows().deselect();
            } else if (clickRelevantType.params[0] === "SystemUpdateAltIcon") {
              setStatusChangeReqId(dtData.id);
              setConfirmationParams([
                "Are you sure you would like to mark this procurement request as received?",
                "Receive Request",
                "Request Marked as Received",
                "Error marking request as received.",
                5
              ]);
              setConfirmationDialog(true);
              dataTable.rows().deselect();
            } else if (clickRelevantType.params[0] === "ShoppingCart") {
              setStatusChangeReqId(dtData.id);
              setConfirmationParams([
                "Are you sure you would like to mark this procurement request as purchased?",
                "Mark Request as Purchased",
                "Request Marked as Purchased",
                "Error marking request as purchased.",
                6
              ]);
              setConfirmationDialog(true);
              dataTable.rows().deselect();
            } else if (
              clickRelevantType.params[0].substring(0, 6) === "Filter"
            ) {
              setUserCanUpload(dtData.user_can_upload_file);
              setEditDelId(parseInt(dtData.id));
              setManageUploadsDialog(true);
              dataTable.rows().deselect();
            }
          }
          break;
        default:
          return;
      }
    }
  };

  const handleReportClick = () => {
    setPdfParams({
      url: `${apiUrl}documents/?dataset=procurement_pending_report`,
      fileName: `Pending Procurement Requests, ${formatDate(
        new Date(),
        "dd MM yyyy"
      )}`,
      fetchMsg: "Fetching pending request report..."
    });
  };

  useDataTableRefresh(dataTable, datatableParams);

  useEventListener("click", handleClickEvent, window);

  useFetchDocument(pdfParams);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Procurement Requests</h4>
                <h4 className={classes.cardCategoryWhite}>
                  {isAdmin
                    ? "Manage Procurement Requests"
                    : "Track Your Procurement Requests"}
                </h4>
              </CardText>
              {isAdmin ? (
                <GridContainer>
                  <GridItem xs={12}>
                    <ReportButton
                      tooltip="Generate Pending Procurement Request report"
                      handleReportClick={handleReportClick}
                      greyedOut={false}
                    />
                  </GridItem>
                </GridContainer>
              ) : (
                <></>
              )}
              {dataTable ? (
                <TableFilter
                  color="primary"
                  activeFilter={viewedFilter}
                  dispatchType={SET_PROCUREMENT_REQUESTS_FILTER}
                  dataTable={dataTable}
                  tabs={datatableParams.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                tableParams={datatableParams}
                setDataTable={setDataTable}
              />
            </CardBody>
            <CardFooter>
              <div className={classes.cardContentRight}>
                <AddButton
                  handleAddClick={() => {
                    setEditDelId(0);
                    setAddEditModal(true);
                  }}
                  tooltip="Add Procurement Request"
                />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelId}
          tableRefreshType={SET_PROCUREMENT_REQUESTS_DT_TRIG_REFRESH}
          urlSuffix="procurement-requests/"
          filterInForm="filter_active_only"
          choiceFieldForm={null}
          editTitle="Edit Procurement Request"
          addTitle="Add Procurement Request"
        >
          {/*@ts-ignore*/}
          <AddEditProcurementRequestForm />
        </AddEditDtEntryDialog>
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="the procurement request database"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={editDelId}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_PROCUREMENT_REQUESTS_DT_TRIG_REFRESH}
          urlSuffix={"procurement-requests/"}
        />
        <ManageImgPdfUploadsDialog
          manageUploadsDialog={manageUploadsDialog}
          setManageUploadsDialog={setManageUploadsDialog}
          refreshUrl={"procurement-requests/"}
          tableRefreshType={SET_PROCUREMENT_REQUESTS_DT_TRIG_REFRESH}
          parentId={editDelId}
          showControls={userCanUpload}
          title={userCanUpload ? "Manage Uploads" : "View Uploads"}
        />
        <ConfirmationDialog
          confirmationDialog={confirmationDialog}
          setConfirmationDialog={setConfirmationDialog}
          confirmationMessage={confirmationParams[0]}
          confirmationTitle={confirmationParams[1]}
          confirmURL={`procurement-requests/${
            statusChangeReqId ? parseInt(statusChangeReqId) : "invalid"
          }`}
          confirmPayload={{ new_status: confirmationParams[4] }}
          successMsg={confirmationParams[2]}
          errorMsg={confirmationParams[3]}
          tableRefreshType={SET_PROCUREMENT_REQUESTS_DT_TRIG_REFRESH}
          addMessage={true}
        />
      </GridContainer>
    </div>
  );
};

export default ProcurementRequestPage;

export type ConfirmationParamsType = [
  string,
  string,
  string,
  string,
  number | null,
  boolean?
];
