import React, { useEffect, useState } from "react";
// components
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// material ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import store from "redux/store";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_V1_URL;

const useStyles = makeStyles(styles);

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function FullScreenIframeDialog(props) {
  const classes = useStyles();
  const [fileLoading, setFileLoading] = useState(false);
  const [fetchedFile, setFetchedFile] = useState("");

  useEffect(() => {
    if (props.previewUrl) {
      setFileLoading(true);

      let config = getTokenConfig(store.getState);
      config.responseType = "arraybuffer";

      axios
        .get(apiUrl.slice(0, -1) + props.previewUrl, config) // removing the trailing slash from api URL (need prettier fix?)
        .then(response => {
          let data = `data:${
            response.headers["content-type"]
          };base64,${new Buffer(response.data, "binary").toString("base64")}`;
          setFetchedFile(data);
          setFileLoading(false);
        });
    }
  }, [props.previewUrl]);

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot
          // paper: classes.modal
        }}
        fullScreen={true}
        fullWidth={true}
        open={props.fullScreenIframeDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.setFullScreenIframeDialog(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => props.setFullScreenIframeDialog(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Full Screen View</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {!fileLoading ? (
            <iframe
              title="Full Screen View"
              width="100%"
              height="100%"
              src={fetchedFile}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "540px"
              }}
            >
              <CustomLoadingSpinner />
            </div>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="transparent"
            onClick={() => props.setFullScreenIframeDialog(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
