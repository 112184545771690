import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

let duplicateIcon = { icon: "FileCopy", tooltip: "Duplicate entry" };
let editIcon = { icon: "Edit", tooltip: "Edit entry" };
let deleteIcon = { icon: "Delete", tooltip: "Delete entry" };

export const tsEntriesUserMain = {
  section: "tsEntriesUser",
  reduxTableName: "tsEntriesUserMain",
  tableParams: {
    select: { style: "single" },
    autoWidth: true,
    responsive: true,
    buttonsColumn: {
      id: 10,
      genIcons: rowData => {
        if (rowData.bundle_status === 1 || rowData.bundle_status === 4) {
          return [editIcon, duplicateIcon, deleteIcon];
        } else {
          return [];
        }
      }
    },
    dom: "Bfrtip",
    columns: [
      { data: "id", title: "ID", visible: false, searchable: false }, // 0
      {
        data: { _: "job_date.display", sort: "job_date.@data-order" },
        title: "Job Date",
        responsivePriority: 1,
        width: 400
      }, // 1
      { data: "number", title: "Job", responsivePriority: 2, width: 400 }, // 2
      { data: "location", title: "Location" }, // 3
      { data: "function", title: "Function" }, // 4
      { data: "hours", title: "Hours", responsivePriority: 3 }, // 5
      { data: "start", title: "Start" }, // 6
      { data: "finish", title: "Finish" }, // 7
      { data: "breaks", title: "Break" }, // 8
      { data: "comments", title: "Comments", width: 400 }, // 9
      {
        data: null,
        title: "Actions",
        width: 150,
        responsivePriority: 1,
        className: "dt-body-center"
      } // 10
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "desc"], [7, "desc"]]
  }
};
