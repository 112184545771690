import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

// icon constants
const viewBundleIcon = { icon: "Visibility", tooltip: "" };
const approveBundleIcon = { icon: "CheckCircle", tooltip: "Approve bundle" };
const rejectBundleIcon = { icon: "Cancel", tooltip: "Reject bundle" };
const reportButtonIcon = {
  icon: "Assignment",
  tooltip: "Generate expense report"
};
const reportGreyedIcon = {
  icon: "AssignmentGreyedOut",
  tooltip: "Generate expense report"
};

export const expBundlesAdminMain = {
  section: "expBundlesAdmin",
  reduxTableName: "expBundlesAdminMain",
  refreshURL: "expense-bundles/?page=admin_expense_bundles&filter=",
  filterTabValues: [2, 3, 1],
  filterTabNames: ["Submitted", "Approved", "Open"],
  tableParams: {
    autoWidth: true,
    responsive: true,
    buttonsColumn: {
      id: 7,
      genIcons: rowData => {
        let return_array;
        switch (rowData.status) {
          case "Submitted":
            return_array = [
              viewBundleIcon,
              approveBundleIcon,
              rejectBundleIcon
            ];
            break;
          case "Approved":
            return_array = [viewBundleIcon, rejectBundleIcon];
            break;
          case "Open":
          case "Rejected":
            return_array = [viewBundleIcon];
            break;
          default:
            return false;
        }
        if (rowData.has_entries) return_array.push(reportButtonIcon);
        else return_array.push(reportGreyedIcon);

        return return_array;
      }
    },
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    columns: [
      { data: "id", title: "ID", width: 75, className: "dt-body-center" }, // 0
      {
        data: {
          _: "date_submitted.display",
          sort: "date_submitted.@data-order"
        },
        title: "Date Submitted",
        width: 150,
        className: "dt-body-center"
      }, // 1
      { data: "user", title: "User", width: 250 }, // 2
      {
        data: "name",
        title: "Bundle Name",
        width: 250,
        className: "dt-body-center"
      }, // 3
      {
        data: "status",
        title: "Status",
        width: 180,
        className: "dt-body-center"
      }, // 4
      {
        data: { _: "date_approved.display", sort: "date_approved.@data-order" },
        title: "Date Approved",
        width: 250
      }, // 5
      { data: "totals_string", title: "Amount", width: 300 }, // 6
      {
        data: null,
        title: "Actions",
        width: 300,
        responsivePriority: 1,
        className: "dt-body-center"
      } // 7
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "desc"]]
  }
};
