import React from "react";

import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import { loginUser } from "global_functions/redux_action_creators/auth/loginUser";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
// import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
// import Danger from "components/Typography/Danger.js";

const useStyles = makeStyles(styles);

function LoginPage({ location }) {

  const [password, setPassword] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  // const [formError, setFormError] = React.useState("");

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  function handleFormSubmit(e) {
    e.preventDefault();
    dispatch(loginUser(username, password));
  }

  if (isAuthenticated) {
    return (
      <Redirect
        to={location?.state?.originalUrl ? location.state.originalUrl : "/"}
      />
    );
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleFormSubmit}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
                {/*<div className={classes.socialLine}>*/}
                {/*  {[*/}
                {/*    "fab fa-facebook-square",*/}
                {/*    "fab fa-twitter",*/}
                {/*    "fab fa-google-plus"*/}
                {/*  ].map((prop, key) => {*/}
                {/*    return (*/}
                {/*      <Button*/}
                {/*        color="transparent"*/}
                {/*        justIcon*/}
                {/*        key={key}*/}
                {/*        className={classes.customButtonClass}*/}
                {/*      >*/}
                {/*        <i className={prop} />*/}
                {/*      </Button>*/}
                {/*    );*/}
                {/*  })}*/}
                {/*</div>*/}
              </CardHeader>
              <CardBody>
                {/*{ errorMsg }*/}
                <CustomInput
                  labelText="Username"
                  id="username"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    fontSize: "16px",
                    onChange: e => setUsername(e.target.value),
                    required: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                {/*<CustomInput*/}
                {/*  labelText="Email..."*/}
                {/*  id="email"*/}
                {/*  formControlProps={{*/}
                {/*    fullWidth: true*/}
                {/*  }}*/}
                {/*  inputProps={{*/}
                {/*    endAdornment: (*/}
                {/*      <InputAdornment position="end">*/}
                {/*        <Email className={classes.inputAdornmentIcon} />*/}
                {/*      </InputAdornment>*/}
                {/*    )*/}
                {/*  }}*/}
                {/*/>*/}
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    fontSize: "16px",
                    onChange: e => setPassword(e.target.value),
                    required: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  type="submit"
                  color="primary"
                  simple
                  size="lg"
                  onClick={handleFormSubmit}
                  block
                >
                  Let{"'"}s Go
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

LoginPage.propTypes = {
  isAuthenticated: PropTypes.bool
};

export default LoginPage;
