import React, { useEffect, useState } from "react";

import axios from "axios";
// redux
import { getTokenConfig } from "../../../../../../global_functions/redux_action_creators/auth/getTokenConfig";
import store from "../../../../../../redux/store";
// custom
import { WidgetTemplate } from "../WidgetTemplate";
// material-ui
import WbSunny from "@material-ui/icons/WbSunny";

const HolidaysWidgetAdmin: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState({
    outstanding_hol_reqs: 0
  });
  const apiUrl = process.env.REACT_APP_API_V1_URL;

  // initial load
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        apiUrl + "datasets/?type=holidays_widget_admin",
        getTokenConfig(store.getState)
      )
      .then(res => {
        setCardData(res.data);
        setIsLoading(false);
      });
  }, [apiUrl]);

  return (
    <WidgetTemplate
      bottomText="Holiday Section"
      bottomUrl="/#/admin/holidays-and-leave/"
      icon={<WbSunny />}
      iconColor="warning"
      isLoading={isLoading}
      statsText=" pending"
      title="Holidays & Leave"
      value={cardData.outstanding_hol_reqs}
    />
  );
};

export default HolidaysWidgetAdmin;
