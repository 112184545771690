import React, { useEffect, useState } from "react";
// redux
import { useDispatch } from "react-redux";
import showNotification from "../../global_functions/redux_action_creators/showNotification";
// material-ui
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// template
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
// custom components & functions
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import ProjectSelect from "../../components/_Custom/_Common/ProjectSelect";
import useSetVat from "../../global_functions/_common/useSetVat";
// typescript types
import { AddEditFormTypes } from "../../types/views/FormTypes";
// styles
import { makeStyles } from "@material-ui/core/styles";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
// @ts-ignore
const useFormStyles = makeStyles(formsStyles);
// @ts-ignore
const useNotifyStyles = makeStyles(notificationStyles);

const AddEditProcurementRequestForm: React.FC<
  AddEditProcurementRequestFormType
> = ({
  editData,
  setformData,
  formLoading,
  handleFormSubmit,
  open,
  clientList
}) => {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  // form fields
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [clientId, setClientId] = useState(0);
  const [projId, setProjId] = useState(0);
  const [qty, setQty] = useState(0);
  const [vendor, setVendor] = useState("");

  const [amount, setAmount] = useState(0);
  const [vat, setVat] = useState(0);
  const [vatType, setVatType] = useState<"custom" | "exempt" | "standard">(
    "standard"
  );

  const dispatch = useDispatch();

  useSetVat(amount, vatType, setVat); // sets vat based on selected vat type

  // validate vat
  useEffect(() => {
    if (vat > amount) {
      dispatch(
        showNotification("Vat cannot be larger than total amount", "error")
      );
      setVat(0);
    }
  }, [amount, vat, dispatch]);

  // form opening/closing
  useEffect(() => {
    if (editData && open) {
      setDescription(editData.description);
      setProjId(editData.project);
      setQty(editData.qty);
      setVendor(editData.vendor);
      setClientId(editData.client_id);
      setUrl(editData.url);

      const amount = editData.amount;
      const vat = editData.vat;

      setAmount(amount);
      setVat(vat);

      // set vat type based on percentage
      if (vat === 0) {
        setVatType("exempt");
      } else {
        let vatPercentage = vat / amount;
        vatPercentage = Math.round(vatPercentage * 1e2) / 1e2;
        if (vatPercentage === 0.17) {
          setVatType("standard");
        } else {
          setVatType("custom");
        }
      }
    } else if (!editData && open) {
      setDescription("");
      setClientId(0);
      setProjId(0);
      setQty(1);
      setVendor("");
      setVat(0);
      setVatType("standard");
      setAmount(0);
      setUrl("");
    }
  }, [editData, open]);

  useEffect(() => {
    setformData({
      description: description,
      project: projId,
      qty: qty,
      vendor: vendor,
      amount: amount,
      vat: vat,
      url: url
    });
    // eslint-disable-next-line
    }, [description, projId, qty, amount, vat, url, vendor]);

  return (
    <>
      {formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={handleFormSubmit}>
            <ProjectSelect
              clientList={clientList}
              formLoading={formLoading}
              clientID={clientId}
              setClientID={setClientId}
              statusFilters={[0]}
              projID={projId}
              setProjID={setProjId}
              lhsFieldWidth={3}
              rhsFieldWidth={9}
            />
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Vendor
                </FormLabel>
              </GridItem>
              <GridItem xs={5}>
                <CustomInput
                  id="vendorName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: vendor,
                    onChange: (e: React.FormEvent<HTMLInputElement>) => {
                      setVendor(e.currentTarget.value);
                    },
                    required: false,
                    maxLength: 100
                  }}
                />
              </GridItem>
              <GridItem xs={1}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Qty.
                </FormLabel>
              </GridItem>{" "}
              <GridItem xs={3}>
                <CustomInput
                  id="amount"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    value: qty,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      setQty(parseFloat(e.target.value)),
                    required: true,
                    min: 0,
                    step: 0.1
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Description
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <CustomInput
                  id="clientLegalName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: description,
                    onChange: (e: React.FormEvent<HTMLInputElement>) => {
                      setDescription(e.currentTarget.value);
                    },
                    required: false,
                    maxLength: 1000
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel className={formClasses.labelHorizontal}>
                  URL
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    value: url,
                    onChange: (e: React.FormEvent<HTMLInputElement>) => {
                      setUrl(e.currentTarget.value);
                    },
                    required: false,
                    maxLength: 1000
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel className={formClasses.labelHorizontal}>
                  Unit Price (inc. VAT)
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  id="amount"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    value: amount,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      setAmount(parseFloat(e.target.value)),
                    required: true,
                    maxLength: 10,
                    min: 0,
                    step: 0.01
                  }}
                />
              </GridItem>
              <GridItem xs={3}>
                <FormLabel className={formClasses.labelHorizontal}>
                  VAT Amount
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  id="totalVAT"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    ...{
                      type: "number",
                      value: vat,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        setVat(parseFloat(e.target.value)),
                      maxLength: 10,
                      min: 0,
                      step: 0.01
                    },
                    ...(vatType !== "custom"
                      ? { disabled: true }
                      : { disabled: false })
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                >
                  VAT Type
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <div
                  className={
                    formClasses.checkboxAndRadio +
                    " " +
                    formClasses.checkboxAndRadioHorizontal
                  }
                  style={{ float: "left" }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={vatType === "standard"}
                        onChange={() => setVatType("standard")}
                        value="standard"
                        name="radio button enabled"
                        aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={formClasses.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={formClasses.radioChecked}
                          />
                        }
                        classes={{
                          checked: formClasses.radio,
                          root: formClasses.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot
                    }}
                    label="Standard"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={vatType === "exempt"}
                        onChange={() => setVatType("exempt")}
                        value="exempt"
                        name="radio button enabled"
                        aria-label="B"
                        icon={
                          <FiberManualRecord
                            className={formClasses.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={formClasses.radioChecked}
                          />
                        }
                        classes={{
                          checked: formClasses.radio,
                          root: formClasses.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot
                    }}
                    label="Exempt"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={vatType === "custom"}
                        onChange={() => setVatType("custom")}
                        value="custom"
                        name="radio button enabled"
                        aria-label="B"
                        icon={
                          <FiberManualRecord
                            className={formClasses.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={formClasses.radioChecked}
                          />
                        }
                        classes={{
                          checked: formClasses.radio,
                          root: formClasses.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot
                    }}
                    label="Custom"
                  />
                </div>
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
};

export default AddEditProcurementRequestForm;

type EditDataType = {
  description: string;
  project: number;
  client_id: number;
  vendor: string;
  amount: number;
  vat: number;
  qty: number;
  url: string;
};

interface AddEditProcurementRequestFormType extends AddEditFormTypes {
  editData: EditDataType | null;
  clientList: any;
}
