import React, { useEffect, useState } from "react";

import axios from "axios";
// redux
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// custom components
import { WidgetTemplate } from "../WidgetTemplate";
// icons
import AccountBalance from "@material-ui/icons/AccountBalance";

const ExpensesWidgetAdmin: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState({
    unapproved_expenses: 0
  });
  const apiUrl = process.env.REACT_APP_API_V1_URL;

  // initial load
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        apiUrl + "datasets/?type=expenses_widget_admin",
        getTokenConfig(store.getState)
      )
      .then(res => {
        setCardData(res.data);
        setIsLoading(false);
      });
  }, [apiUrl]);

  return (
    <WidgetTemplate
      bottomText="Expenses Section"
      bottomUrl="/#/admin/expenses/"
      icon={<AccountBalance />}
      iconColor="success"
      isLoading={isLoading}
      statsText=" to approve"
      title="Expenses"
      value={cardData.unapproved_expenses}
    />
  );
};

export default ExpensesWidgetAdmin;
