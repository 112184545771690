import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// icon
import TrendingUp from "@material-ui/icons/TrendingUp";

const useStyles = makeStyles(theme => ({
  addButton: {
    backgroundColor: "#26c6da",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#90e1ec" },
    marginTop: 10,
    marginRight: 10
  }
}));

const StatsButton: React.FC<StatsButton> = ({
  onClick,
  tooltip,
  fontSize,
  disabled
}) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip}>
      <IconButton
        className={classes.addButton}
        onClick={
          disabled
            ? () => {
                return null;
              }
            : onClick
        }
      >
        <TrendingUp fontSize={fontSize} style={{ color: "#f9f9f9" }} />
      </IconButton>
    </Tooltip>
  );
};

export default StatsButton;

interface StatsButton {
  onClick: () => void;
  tooltip: string;
  fontSize: "large" | "small";
  disabled: boolean;
}
