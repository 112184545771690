import React, { useCallback, useEffect, useState, Dispatch } from "react";

import axios from "axios";

// redux
import { useDispatch } from "react-redux";
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import showNotification from "global_functions/redux_action_creators/showNotification";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// template
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
// @material-ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import FormLabel from "@material-ui/core/FormLabel";

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// @ts-ignore
const useFormStyles = makeStyles(formsStyles);
// @ts-ignore
const useStyles = makeStyles(styles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="down" ref={ref} {...props} />;
});

const ConfirmationDialog: React.FC<ConfirmationDialogType> = ({
  confirmationDialog,
  setConfirmationDialog,
  confirmPayload,
  addMessage,
  confirmationTitle,
  tableRefreshType,
  confirmURL,
  successMsg,
  confirmationMessage,
  errorMsg
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const formClasses = useFormStyles();

  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [message, setMessage] = useState("");

  const captureKeyDown = useCallback(e => {
    // captures backspace and prevents closing of modal
    if (!e.target.type && e.keyCode === 8) {
      // all form elements are inputs that have the 'type' property, capture if not an input (8 is backspace)
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (confirmationDialog) {
      setMessage("");
      document.addEventListener("keydown", captureKeyDown, false);
    } else {
      document.removeEventListener("keydown", captureKeyDown, false);
    }
  }, [confirmationDialog, captureKeyDown]);

  const handleSubmission = () => {
    setUpdateInProgress(true);

    const reqPayload = confirmPayload;
    if (addMessage) {
      reqPayload["status_chng_msg"] = message;
    }

    axios
      .request({
        url: `${apiUrl}${confirmURL}`,
        method: "put",
        data: reqPayload,
        headers: getTokenConfig(store.getState).headers
      })
      .then(() => {
        dispatch(showNotification(successMsg, "success"));
        setUpdateInProgress(false);
        setConfirmationDialog(false);

        // if more than one table needs to be refreshed, array is passed
        if (Array.isArray(tableRefreshType)) {
          for (let i = 0; i < tableRefreshType.length; i++) {
            dispatch({ type: tableRefreshType[i], payload: { id: null } });
          }
        } else {
          dispatch({ type: tableRefreshType, payload: { id: null } });
        }
      })
      .catch(() => dispatch(showNotification(errorMsg, "error")));
  };

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        fullWidth={true}
        open={confirmationDialog}
        // @ts-ignore
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setConfirmationDialog(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          {/*@ts-ignore*/}
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setConfirmationDialog(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{confirmationTitle}</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {updateInProgress ? (
            <CustomLoadingSpinner />
          ) : (
            <>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <p>
                    <i>{confirmationMessage}</i>
                  </p>
                </GridItem>
              </GridContainer>
              {addMessage && (
                <GridContainer>
                  <GridItem xs={12} sm={5}>
                    <FormLabel className={formClasses.labelHorizontal}>
                      Add message (optional)
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: message,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setMessage(e.target.value);
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {/*@ts-ignore*/}
          <Button
            onClick={handleSubmission}
            color="primary"
            simple
            type="submit"
          >
            Confirm
          </Button>
          {/*@ts-ignore*/}
          <Button
            onClick={() => setConfirmationDialog(false)}
            color="transparent"
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
};

export default ConfirmationDialog;

type ConfirmationDialogType = {
  confirmationDialog: boolean;
  setConfirmationDialog: Dispatch<boolean>;
  confirmPayload: any;
  addMessage?: boolean;
  confirmationTitle: string;
  tableRefreshType: string | string[];
  confirmURL: string;
  successMsg: string;
  confirmationMessage: string;
  errorMsg: string;
};
