import React, { useEffect, useMemo, useState } from "react";
// custom components
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import ProjectSelect from "components/_Custom/_Common/ProjectSelect";
// components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
// material ui
import FormLabel from "@material-ui/core/FormLabel";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// styles
import { makeStyles } from "@material-ui/core/styles";
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);

export default function AddEditWarehouseItemContainerForm(props) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  // form fields
  const [itemType, setItemType] = useState(1);
  const [itemLocation, setItemLocation] = useState(null);
  const [engineerDesk, setEngineerDesk] = useState(null);
  const [clientId, setClientId] = useState("");
  const [projId, setProjId] = useState("");
  const [containerName, setContainerName] = useState("");
  const [containerStatusId, setContainerStatusId] = useState("");
  const [comments, setComments] = useState("");
  const [itemBay, setItemBay] = useState(0);
  const [itemShelf, setItemShelf] = useState(0);

  useEffect(() => {
    if (props.editData && props.open) {
      setItemType(props.editData.container_type);
      setProjId(props.editData.project);
      setClientId(props.editData.client_id);
      setContainerName(props.editData.name);
      setContainerStatusId(props.editData.status);
      setComments(props.editData.comments);
      setItemBay(props.editData.location_bay);
      setItemShelf(props.editData.location_shelf);
      setItemLocation(props.editData.location);
      setEngineerDesk(props.editData.location_desk_no);
    } else if (!props.editData && props.open) {
      setItemType(0); // default status is '-'
      setProjId("");
      setClientId("");
      setContainerName("");
      setContainerStatusId(0);
      setComments("");
      setItemBay(0);
      setItemShelf(0);
      setItemLocation(null);
      setEngineerDesk(null);
    }
  }, [props.editData, props.open, props.projectId]);

  useEffect(() => {
    props.setformData({
      container_type: itemType,
      project: projId,
      name: containerName,
      status: containerStatusId,
      comments: comments,
      location_bay: itemBay,
      location_shelf: itemShelf,
      location: itemLocation,
      location_desk_no: engineerDesk
    });
    // eslint-disable-next-line
    }, [itemType, clientId, projId, itemType, containerName, containerStatusId, comments, itemBay, itemShelf, itemLocation, engineerDesk]);

  // generate select values for the 8 engineer desks
  const engineerDeskChoices = useMemo(() => {
    let options = [];
    for (let i = 0; i <= 7; i++) {
      options.push({ label: (i + 1).toString(), value: i + 1 });
    }
    return options;
  }, []);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "150px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            {/*if not project id and client number is provided, the list for all items is being viewed and*/}
            {/*project select box needs to be provided*/}
            {!props.projectId && (
              <ProjectSelect
                clientList={props.clientList}
                formLoading={props.formLoading}
                clientID={clientId}
                setClientID={setClientId}
                statusFilters={[0]}
                projID={projId}
                setProjID={setProjId}
                lhsFieldWidth={3}
                rhsFieldWidth={9}
              />
            )}
            <GridContainer>
              <GridContainer>
                <GridItem xs={3}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Status
                  </FormLabel>
                </GridItem>
                <GridItem xs={9}>
                  <CustomSelect
                    options={props.formChoiceFields.STATUS_CHOICES}
                    isDisabled={props.formLoading}
                    value={containerStatusId}
                    setValue={setContainerStatusId}
                    required={true}
                  />
                </GridItem>
              </GridContainer>
              <GridItem xs={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Container Type
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <CustomSelect
                  options={props.formChoiceFields.CONTAINER_CHOICES}
                  isDisabled={props.formLoading}
                  value={itemType}
                  setValue={setItemType}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Container Name
                </FormLabel>
              </GridItem>
              <GridItem xs={9}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    type: "text",
                    value: containerName,
                    onChange: e => {
                      setContainerName(e.target.value);
                    },
                    maxLength: 50
                  }}
                  helpText=""
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Location
                </FormLabel>
              </GridItem>

              <GridItem xs={9}>
                <div
                  className={
                    formClasses.checkboxAndRadio +
                    " " +
                    formClasses.checkboxAndRadioHorizontal
                  }
                  style={{ float: "left", marginTop: "24px" }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={itemLocation === 0}
                        onChange={() => setItemLocation(0)}
                        value={0}
                        name="radio button enabled"
                        aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={formClasses.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={formClasses.radioChecked}
                          />
                        }
                        classes={{
                          checked: formClasses.radio,
                          root: formClasses.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot
                    }}
                    label="Bays/External Storage"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={itemLocation === 1}
                        onChange={() => setItemLocation(1)}
                        value={1}
                        name="radio button enabled"
                        aria-label="B"
                        icon={
                          <FiberManualRecord
                            className={formClasses.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={formClasses.radioChecked}
                          />
                        }
                        classes={{
                          checked: formClasses.radio,
                          root: formClasses.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot
                    }}
                    label="Engineer Desk"
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <>
                {itemLocation === 0 ? (
                  <GridContainer>
                    <GridItem xs={3}>
                      <FormLabel
                        className={formClasses.labelHorizontal}
                        fontSize="1px"
                      >
                        Location Detail
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={3}>
                      <CustomSelect
                        options={props.formChoiceFields.BAY_CHOICES}
                        isDisabled={props.formLoading}
                        value={itemBay}
                        setValue={setItemBay}
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <FormLabel
                        className={formClasses.labelHorizontal}
                        fontSize="1px"
                      >
                        Shelf
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={3}>
                      <CustomSelect
                        options={props.formChoiceFields.SHELF_CHOICES}
                        isDisabled={props.formLoading}
                        value={itemShelf}
                        setValue={setItemShelf}
                      />
                    </GridItem>
                  </GridContainer>
                ) : itemLocation ? (
                  <GridContainer>
                    <GridItem xs={3}>
                      <FormLabel
                        className={formClasses.labelHorizontal}
                        fontSize="1px"
                      >
                        Desk No.
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={3}>
                      <CustomSelect
                        options={engineerDeskChoices}
                        isDisabled={props.formLoading}
                        value={engineerDesk}
                        setValue={setEngineerDesk}
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  <></>
                )}
              </>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
}
