import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import store from "redux/store";
import { makeStyles } from "@material-ui/core/styles";
// custom functions
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import useEventListener from "global_functions/_common/useEventListener";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import showNotification from "global_functions/redux_action_creators/showNotification";
import identifyClick from "global_functions/_common/identifyClick";
import useFetchDocument from "global_functions/_common/useFetchDocument";
// table
import { tsEntriesUserMain } from "views/DataTables/Timesheets/tsEntriesUserMain";
// custom components
import TsEntryUserForm from "views/Forms/TsEntryUserForm";
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import BackButton from "components/CustomButtons/BackButton";
import CompletionDialog from "views/Dialogs/_Common/CompletionDialog";
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import DeleteDialog from "views/Dialogs/_Common/DeleteDialog";
import CompletionButton from "components/CustomButtons/CompletionButton";
import ReportButton from "components/CustomButtons/ReportButton";
import AddButton from "components/CustomButtons/AddButton";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import {
  SET_TS_ENTRIES_USER_DT_PAGE,
  SET_TS_ENTRIES_USER_DT_TRIG_REFRESH,
  SET_TS_ENTRIES_USER_DT_ROW,
  SET_TS_ENTRIES_USER_REFRESH_URL
} from "redux/dispatch_types";
import SubmitButton from "components/CustomButtons/SubmitButton";
import ConfirmationDialog from "views/Dialogs/_Common/ConfirmationDialog";
import CardFooter from "components/Card/CardFooter";
import RejectButton from "components/CustomButtons/RejectButton";

const apiUrl = process.env.REACT_APP_API_V1_URL;

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

export default function TsEntryUserPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const reduxPageProps = useSelector(state => state.tsEntriesUser);

  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState(false);
  const [dataTable, setDataTable] = useState("");
  const [editDelId, setEditDelId] = useState(null);

  const [bundleStatus, setBundleStatus] = useState("");
  const [bundleName, setBundleName] = useState("");
  const [bundleEntries, setBundleEntries] = useState(0);
  const [bundleUser, setBundleUser] = useState(0);
  const [bundleDate, setBundleDate] = useState(0);
  const [bundleCompletion, setBundleCompletion] = useState("");

  const [bundleInfoLoading, setBundleInfoLoading] = useState(false);
  const [completionInfoLoading, setCompletionInfoLoading] = useState(false);

  const [showCompletionDialog, setShowCompletionDialog] = useState(false);
  const [submitConfirmationDialog, setSubmitConfirmationDialog] = useState(
    false
  );
  const [cancelConfirmationDialog, setCancelConfirmationDialog] = useState(
    false
  );

  const tsBundleID = props.match.params.slug;

  const [reportParams, setReportParams] = useState("");

  // sets refresh url for page on load
  useEffect(() => {
    dispatch({
      type: SET_TS_ENTRIES_USER_REFRESH_URL,
      payload: "timesheet-bundles/" + tsBundleID + "/timesheets/"
    });
  }, [tsBundleID, dispatch]);

  // every time table is refreshed, refresh stats & completion
  useEffect(() => {
    setBundleInfoLoading(true);
    axios
      .get(
        `${apiUrl}timesheet-bundles/${tsBundleID}`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setBundleStatus(res.data.status);
        setBundleName(res.data.sub_month.display);
        setBundleEntries(res.data.ts_entries);
        setBundleDate(res.data.sub_month.display);
        setBundleUser(res.data.sub_user);
        setBundleInfoLoading(false);
      });

    setCompletionInfoLoading(true);
    axios
      .get(
        `${apiUrl}datasets/?type=timesheet_bundle_completion&ts_bundle_id=${tsBundleID}`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setBundleCompletion(res.data.percHours);
        setCompletionInfoLoading(false);
      });
  }, [reduxPageProps.tables.tsEntriesUserMain.triggerDtRefresh, tsBundleID]);

  useDataTableRefresh(dataTable, tsEntriesUserMain);

  // handlers
  const handleAddClick = () => {
    setEditDelId(0);
    setAddEditModal(true);
  };

  const handleCompletionClick = () => {
    setShowCompletionDialog(true);
  };

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_TS_ENTRIES_USER_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_TS_ENTRIES_USER_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];

          if (clickRelevantType.params[0] === "FileCopy") {
            dataTable.rows().deselect();
            axios
              .post(
                apiUrl + reduxPageProps.tables.tsEntriesUserMain.refreshURL,
                { id: dtData.id, duplicate: true },
                getTokenConfig(store.getState)
              )
              .then(res => {
                dispatch(showNotification(res.data.statusText, "success"));
                dispatch({
                  type: SET_TS_ENTRIES_USER_DT_TRIG_REFRESH,
                  payload: res.data.id
                });
                setEditDelId(res.data.id);
                setAddEditModal(true);
              })
              .catch(err => {
                dispatch(
                  showNotification(err.response.data.errors[0], "error")
                );
              });
          } else if (clickRelevantType.params[0] === "Edit") {
            dataTable.rows().deselect();
            handleEditClick(dtData);
          } else if (clickRelevantType.params[0] === "Delete") {
            dataTable.rows().deselect();
            handleDeleteClick(dtData);
          }
          break;
        default:
          return false;
      }
    }
  };

  // add event listener for datatable clicks
  useEventListener("click", handleClickEvent, window);

  const handleEditClick = dtData => {
    setEditDelId(dtData.id);
    setAddEditModal(true);
  };

  const handleDeleteClick = dtData => {
    setEditDelId(dtData.id);
    let delName = `timesheet for ${dtData.number} on ${dtData.job_date.display}`;
    setDeleteName(delName);
    setDeleteModal(true);
  };

  const handleReportClick = e => {
    e.stopPropagation();
    setReportParams({
      url: `${apiUrl}documents/?dataset=timesheet_monthly_report&ts_bundle_id=${tsBundleID}`,
      fileName: `${bundleUser} timesheet report for ${bundleDate}.pdf`,
      fetchMsg: "Fetching timesheet report..."
    });
  };

  useFetchDocument(reportParams);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>{bundleName}</h4>
                <h4 className={classes.cardCategoryWhite}>
                  Status : {bundleInfoLoading ? "Loading..." : bundleStatus}
                  <br />
                  Completion:{" "}
                  {completionInfoLoading ? "Loading..." : bundleCompletion}
                </h4>
              </CardText>
              <GridContainer>
                <GridItem xs={12}>
                  <BackButton />
                  {(bundleStatus === "Open" || bundleStatus === "Rejected") && (
                    <SubmitButton
                      handleSubmit={() => setSubmitConfirmationDialog(true)}
                      greyedOut={false}
                    />
                  )}
                  {bundleStatus === "Submitted" && (
                    <RejectButton
                      onClick={() => setCancelConfirmationDialog(true)}
                      tooltip="Unsubmit timesheet bundle"
                    />
                  )}
                  <CompletionButton
                    handleCompletionClick={handleCompletionClick}
                    greyedOut={!bundleEntries}
                  />
                  <ReportButton
                    tooltip="Generate timesheet report"
                    handleReportClick={handleReportClick}
                    greyedOut={!bundleEntries}
                  />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setDataTable}
                tableParams={tsEntriesUserMain}
              />
            </CardBody>
            <CardFooter>
              {(bundleStatus === "Open" || bundleStatus === "Rejected") && (
                <AddButton
                  handleAddClick={handleAddClick}
                  tooltip="Add Timesheet Entry"
                />
              )}
            </CardFooter>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelId}
          detailURLSuffix={reduxPageProps.tables.tsEntriesUserMain.refreshURL}
          tableRefreshType={SET_TS_ENTRIES_USER_DT_TRIG_REFRESH}
          filterInForm="filter_active_only"
          choiceFieldForm="ts_entry_form"
          editTitle="Edit Timesheet"
          addTitle="Add Timesheet"
        >
          <TsEntryUserForm />
        </AddEditDtEntryDialog>
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="your timesheet bundle"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={editDelId}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_TS_ENTRIES_USER_DT_TRIG_REFRESH}
          urlSuffix={reduxPageProps.tables.tsEntriesUserMain.refreshURL}
        />
        <CompletionDialog
          showDialog={showCompletionDialog}
          setShowDialog={setShowCompletionDialog}
          tsBundleId={tsBundleID}
        />
        <ConfirmationDialog
          confirmationDialog={cancelConfirmationDialog}
          setConfirmationDialog={setCancelConfirmationDialog}
          confirmationMessage="Are you sure you would like to unsubmit this bundle?"
          confirmationTitle="Cancel Submission"
          confirmURL={`timesheet-bundles/${tsBundleID}`}
          confirmPayload={{ new_status: 1 }}
          successMsg="Bundle Unsubmitted"
          errorMsg="Error submitting bundle."
          tableRefreshType={SET_TS_ENTRIES_USER_DT_TRIG_REFRESH}
        />
        {/*submit bundle confirmation*/}
        <ConfirmationDialog
          confirmationDialog={submitConfirmationDialog}
          setConfirmationDialog={setSubmitConfirmationDialog}
          confirmationMessage="Are you sure you would like to submit this bundle?"
          confirmationTitle="Submit Bundle"
          confirmURL={`timesheet-bundles/${tsBundleID}`}
          confirmPayload={{ new_status: 2 }}
          successMsg="Bundle Submitted"
          errorMsg="Error submitting bundle."
          tableRefreshType={SET_TS_ENTRIES_USER_DT_TRIG_REFRESH}
        />
      </GridContainer>
    </div>
  );
}
