import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// icon
import Build from "@material-ui/icons/Build";

import { primaryColor } from "../../assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(theme => ({
  settingsButton: {
    backgroundColor: primaryColor[1],
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#a1b5be" },
    marginTop: 10,
    marginRight: 10
  }
}));

export default function SettingsButton({ handleSettingsClick, tooltip }) {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip}>
      <IconButton
        className={classes.settingsButton}
        onClick={handleSettingsClick}
      >
        <Build style={{ color: "#f9f9f9" }} />
      </IconButton>
    </Tooltip>
  );
}
