import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

const editIcon = { icon: "Edit", tooltip: "Edit entry" };
const deleteIcon = { icon: "Delete", tooltip: "Delete entry" };
const noReceiptsIcon = { icon: "FilterNone", tooltip: "Manage receipts" };
const manyReceiptsIcon = { icon: "Filter9Plus", tooltip: "Manage receipts" };
const duplicateIcon = { icon: "FileCopy", tooltip: "Duplicate entry" };

export const expEntriesUserMain = {
  section: "expEntriesUser",
  reduxTableName: "expEntriesUserMain",
  tableParams: {
    autoWidth: true,
    buttonsColumn: {
      id: 9,
      genIcons: rowData => {
        let return_array = [];
        // get receipt number icons
        if (!rowData.receipt_count) {
          return_array.push(noReceiptsIcon);
        } else if (rowData.receipt_count <= 9) {
          return_array.push({
            icon: "Filter" + rowData.receipt_count.toString(),
            tooltip: "Manage receipts"
          });
        } else {
          return_array.push(manyReceiptsIcon);
        }

        // add delete and edit icons to open and rejected bundle entries
        if (rowData.parent_status === 1 || rowData.parent_status === 4) {
          return_array.push(editIcon, duplicateIcon, deleteIcon);
        }

        return return_array;
      }
    },
    responsive: true,
    searching: false,
    dom: "Bfrtip",
    select: {
      style: "single"
    },
    columns: [
      { data: "id", title: "DB IDs", visible: false, searchable: false }, // 0
      {
        data: { _: "date_incurred.display", sort: "date_incurred.@data-order" },
        title: "Date Incurred",
        width: 140,
        className: "dt-body-center"
      }, // 1
      { data: "number", title: "Job", width: 400 }, // 2
      { data: "vendor", title: "Vendor", width: 150, responsivePriority: 2 }, // 3
      { data: "expense_type", title: "Type", width: 120 }, // 4
      {
        data: "amount",
        title: "Amount",
        width: 120,
        className: "dt-body-center"
      }, // 5
      { data: "vat", title: "VAT", width: 120, className: "dt-body-center" }, // 6
      {
        data: "before_vat",
        title: "Ex-VAT",
        width: 120,
        className: "dt-body-center"
      }, // 7
      { data: "comments", title: "Comments", width: 400 }, // 8
      {
        data: null,
        title: "Receipts",
        width: 250,
        responsivePriority: 1,
        className: "dt-body-center"
      } // 9
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "desc"]]
  }
};
