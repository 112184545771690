import React, { useEffect, useState, useCallback } from "react";

import formatDate from "global_functions/_common/formatDate";
import usePrevious from "global_functions/_common/usePrevious";
// date tools
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
// custom components
import ProjectSelect from "components/_Custom/_Common/ProjectSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
// @material-ui
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Tooltip from "@material-ui/core/Tooltip";
import Info from "@material-ui/icons/Info";
// styles
import formsStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import { customTheme } from "global_functions/_common/customTheme";

const useFormStyles = makeStyles(formsStyles);
const useNotifyStyles = makeStyles(notificationStyles);

export default function AddEditClientForm(props) {
  const formClasses = useFormStyles();
  const notifyClasses = useNotifyStyles();

  // form fields
  const [clientID, setClientID] = useState("");
  const [projID, setProjID] = useState("");
  const [expType, setExpType] = useState(0);
  const [vendor, setVendor] = useState("");
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [totalVAT, setTotalVAT] = useState("0");
  const [comments, setComments] = useState("");
  const [vatType, setVatType] = useState("standard");

  // enable/disable fields
  const [amountFieldEnabled, setAmountFieldEnabled] = useState(true);
  const [vendorFieldEnabled, setVendorFieldEnabled] = useState(true);
  const [milesFieldVisible, setMilesFieldVisible] = useState(true);
  const [vatAmountFieldVisible, setVatAmountFieldVisible] = useState(true);

  const mileageRate = 0.3;

  const prevExpType = usePrevious(expType); // getting previous expense type value

  const [miles, setMiles] = useState(0); // private mileage rate calculator field (not submitted with form)
  const [vatSwitchable, setVatSwitchable] = useState(true); // for some expense types, VAT is set and can't be changed

  // changing VAT type checkbox (if exempt, set vat to 0, if standard set it to correct calculation, if custom leave as is)
  const handleVatTypeChange = useCallback(
    e => {
      let newValue = e.target.value;
      setVatType(newValue);
      if (newValue === "standard") {
        let newVAT = Math.round((amount - amount / 1.2) * 1e2) / 1e2;
        setTotalVAT(newVAT);
      } else if (newValue === "exempt") {
        setTotalVAT(0);
      }
    },
    [amount]
  );

  // handle expense amount change (adjusting VAT on change if vat is standard)
  const handleAmountChange = e => {
    let enteredAmount = e.target.value;
    setAmount(enteredAmount);

    if (vatType === "standard") {
      let newVAT =
        Math.round((enteredAmount - enteredAmount / 1.2) * 1e2) / 1e2;
      setTotalVAT(newVAT);
    }
  };

  // handle mileage change (used for calculating amount)
  const handleMilesChange = e => {
    let totalAmount = (parseInt(e.target.value) * mileageRate).toFixed(2);
    setMiles(e.target.value);
    setAmount(totalAmount.toString());

    // if mileage is entered, set comments to the calculation
    if (e.target.value) {
      setComments(
        `${e.target.value} miles at ${mileageRate} per mile is £${totalAmount}`
      );
    } else {
      setComments("");
    }
  };

  // gets called every load of the form
  useEffect(() => {
    if (props.editData && props.open) {
      // if edit form is loaded, use edit data to populate the form
      setClientID(props.editData.client_id);
      setProjID(props.editData.number);
      setVendor(props.editData.vendor);
      setExpType(props.editData.expense_type);
      setComments(props.editData.comments);
      // set date
      let dateIncurredParts = props.editData.date_incurred.split("-");
      setDate(
        new Date(
          dateIncurredParts[0],
          dateIncurredParts[1] - 1,
          dateIncurredParts[2]
        )
      );

      // set VAT
      let expAmount = props.editData.amount;
      let vatAmount = props.editData.vat;
      setAmount(expAmount);
      setTotalVAT(vatAmount);

      if (vatAmount === 0) {
        setVatType("exempt");
      } else {
        let vatPercentage = vatAmount / expAmount;
        vatPercentage = Math.round(vatPercentage * 1e2) / 1e2;
        if (vatPercentage === 0.17) {
          setVatType("standard");
        } else {
          setVatType("custom");
        }
      }
    } else if (!props.editData && props.open) {
      // if open form is loaded, clear fields
      // clear date
      setClientID(0);
      setProjID(0);
      setExpType(0);
      setVendor("");
      setAmount("");
      setTotalVAT("");
      setComments("");
      setVatType("standard");
      setMiles(0);
      setDate(new Date());
    }
  }, [props.editData, props.open]);

  useEffect(() => {
    // set form data when any of the fields changes
    props.setformData({
      number: projID,
      vendor: vendor,
      date_incurred: formatDate(date, "yyyy-mm-dd"),
      expense_type: expType,
      amount: amount,
      vat: totalVAT,
      comments: comments
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projID, expType, vendor, date, amount, totalVAT, comments]);

  // whenever expense type changes, set fields apprropriately
  useEffect(() => {
    // set VAT appropriately
    if (expType === 12) {
      // 12 - Private Mileage, set exempt and do not allow vat switching
      handleVatTypeChange({ target: { value: "exempt" } });
      setVatSwitchable(false);
      setAmountFieldEnabled(false);
      setVendorFieldEnabled(false);
      setMilesFieldVisible(true);
      setVatAmountFieldVisible(false);
    } else if (expType === 1) {
      handleVatTypeChange({ target: { value: "exempt" } }); // 1 - fuel, set default of exempt but allow vat switching
      setVatSwitchable(true);
      setAmountFieldEnabled(true);
      setVendorFieldEnabled(true);
      setMilesFieldVisible(false);
      setVatAmountFieldVisible(true);
    } else {
      handleVatTypeChange({ target: { value: "standard" } }); // others default to standard
      setVatSwitchable(true);
      setAmountFieldEnabled(true);
      setVendorFieldEnabled(true);
      setMilesFieldVisible(false);
      setVatAmountFieldVisible(true);
    }

    // if switching to Private Mileage, set vendor as Private Mileage
    if (expType === 12 && prevExpType !== 12) {
      setVendor("Private Mileage");
    }

    // if switching away from Private Mileage, clear comments and vendor
    // (in private mileage, comments & vendor are set automatically)
    if (expType !== 12 && prevExpType === 12) {
      setComments("");
      setVendor("");
    }
  }, [expType, prevExpType, handleVatTypeChange]);

  return (
    <>
      {props.formLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "120px",
            width: "500px"
          }}
        >
          <CustomLoadingSpinner />
        </div>
      ) : (
        <DialogContent
          id="classic-modal-slide-description"
          className={notifyClasses.modalBody}
        >
          <form id="addEditForm" onSubmit={props.handleFormSubmit}>
            <ProjectSelect
              clientList={props.clientList}
              formLoading={props.formLoading}
              clientID={clientID}
              setClientID={setClientID}
              statusFilters={[1, 5]}
              projID={projID}
              setProjID={setProjID}
              lhsFieldWidth={2}
              rhsFieldWidth={10}
            />
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Type
                </FormLabel>
              </GridItem>
              <GridItem xs={10}>
                <CustomSelect
                  options={props.formChoiceFields.TYPE_CHOICES}
                  isDisabled={props.formLoading}
                  value={expType}
                  setValue={setExpType}
                  required={true}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Vendor
                </FormLabel>
              </GridItem>
              <GridItem xs={10} sm={4}>
                <CustomInput
                  id="vendorName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Client name"
                  inputProps={{
                    type: "text",
                    value: vendor,
                    onChange: e => {
                      setVendor(e.target.value);
                    },
                    required: true,
                    maxLength: 30,
                    disabled: !vendorFieldEnabled
                  }}
                />
              </GridItem>
              <GridItem xs={2} sm={1} />
              <GridItem xs={10} sm={5}>
                <ThemeProvider theme={customTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ float: "left" }}
                      margin="normal"
                      label="Date"
                      format="dd/MM/yyyy"
                      value={date}
                      onChange={date => setDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </GridItem>
            </GridContainer>
            {vatSwitchable ? (
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <Tooltip title="Please make sure that you enter the correct VAT information. Purchases from some smaller vendors, most of travel, some food items, etc. are not VAT chargeable, others can have a custom VAT percentage. VAT information is usually clearly stated on the invoice or can be easily confirmed online or from vendor. Entering incorrect VAT information may delay the payment.">
                    <FormLabel
                      className={
                        formClasses.labelHorizontal +
                        " " +
                        formClasses.labelHorizontalRadioCheckbox
                      }
                    >
                      VAT&nbsp;
                      <Info color="primary" fontSize="small" />
                    </FormLabel>
                  </Tooltip>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div
                    className={
                      formClasses.checkboxAndRadio +
                      " " +
                      formClasses.checkboxAndRadioHorizontal
                    }
                    style={{ float: "left" }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={vatType === "standard"}
                          onChange={handleVatTypeChange}
                          value="standard"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={formClasses.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={formClasses.radioChecked}
                            />
                          }
                          classes={{
                            checked: formClasses.radio,
                            root: formClasses.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot
                      }}
                      label="Standard"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={vatType === "exempt"}
                          onChange={handleVatTypeChange}
                          value="exempt"
                          name="radio button enabled"
                          aria-label="B"
                          icon={
                            <FiberManualRecord
                              className={formClasses.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={formClasses.radioChecked}
                            />
                          }
                          classes={{
                            checked: formClasses.radio,
                            root: formClasses.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot
                      }}
                      label="Exempt"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={vatType === "custom"}
                          onChange={handleVatTypeChange}
                          value="custom"
                          name="radio button enabled"
                          aria-label="B"
                          icon={
                            <FiberManualRecord
                              className={formClasses.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={formClasses.radioChecked}
                            />
                          }
                          classes={{
                            checked: formClasses.radio,
                            root: formClasses.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot
                      }}
                      label="Custom"
                    />
                  </div>
                </GridItem>
              </GridContainer>
            ) : (
              <GridContainer>
                <GridItem xs={2}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    VAT
                  </FormLabel>
                </GridItem>
                <GridItem xs={9}>
                  <FormLabel
                    className={formClasses.labelHorizontalFloatRight}
                    fontSize="1px"
                  >
                    VAT is {vatType} for this expense type
                  </FormLabel>
                </GridItem>
              </GridContainer>
            )}
            {milesFieldVisible && (
              <GridContainer>
                <GridItem xs={2}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    fontSize="1px"
                  >
                    Miles
                  </FormLabel>
                </GridItem>
                <GridItem xs={3}>
                  <CustomInput
                    id="amount"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helpText="Amount"
                    inputProps={{
                      type: "number",
                      value: miles,
                      onChange: e => {
                        handleMilesChange(e);
                      },
                      required: true,
                      maxLength: 10,
                      min: 0,
                      max: 9999,
                      step: 0.01
                    }}
                  />
                </GridItem>
                <GridItem xs={7}>
                  <FormLabel
                    className={formClasses.labelHorizontalFloatRight}
                    fontSize="1px"
                  >
                    Current rate per mile is {mileageRate}
                  </FormLabel>
                </GridItem>
              </GridContainer>
            )}
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Amount
                </FormLabel>
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  id="amount"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Amount"
                  inputProps={{
                    type: "number",
                    value: amount,
                    onChange: e => {
                      handleAmountChange(e);
                    },
                    required: true,
                    maxLength: 10,
                    min: 0,
                    step: 0.01,
                    disabled: !amountFieldEnabled
                  }}
                />
              </GridItem>
              {vatAmountFieldVisible && (
                <>
                  <GridItem xs={3}>
                    <FormLabel
                      className={formClasses.labelHorizontal}
                      fontSize="1px"
                    >
                      VAT Amount
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={3}>
                    <CustomInput
                      id="totalVAT"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helpText="Total VAT"
                      inputProps={{
                        ...{
                          type: "number",
                          value: totalVAT,
                          onChange: e => setTotalVAT(e.target.value),
                          maxLength: 10,
                          min: 0,
                          step: 0.01
                        },
                        ...(vatType !== "custom"
                          ? { disabled: true }
                          : { disabled: false })
                      }}
                    />
                  </GridItem>
                </>
              )}
            </GridContainer>
            <GridContainer>
              <GridItem xs={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  fontSize="1px"
                >
                  Comments
                </FormLabel>
              </GridItem>
              <GridItem xs={10}>
                <CustomInput
                  id="comments"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText="Client name"
                  inputProps={{
                    type: "text",
                    value: comments,
                    onChange: e => {
                      setComments(e.target.value);
                    },
                    maxLength: 499
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      )}
    </>
  );
}
