import React, { useEffect, useState } from "react";

import axios from "axios";

import { useHistory } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import store from "redux/store";

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// custom dialog & form
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import DeleteDialog from "views/Dialogs/_Common/DeleteDialog";
import AddEditWarehouseItemContainerForm from "views/Forms/AddEditWarehouseItemContainerForm";
// tables
import { warehouseItemContainersMain } from "views/DataTables/Warehouse/Containers/warehouseItemContainersMain";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
// custom components
import QrCodeDialog from "../../../Dialogs/Warehouse/QrCodeDialog";
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import TableFilter from "../../../../components/_Custom/_Common/TableFilter";
import CustomLoadingSpinner from "../../../../components/_Custom/_Common/CustomLoadingSpinner";
// functions
import useEventListener from "global_functions/_common/useEventListener";
import identifyClick from "global_functions/_common/identifyClick";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import formatDate from "global_functions/_common/formatDate";

import {
  SET_WAREHOUSE_ITEM_CONTAINERS_DT_PAGE,
  SET_WAREHOUSE_ITEM_CONTAINERS_DT_TRIG_REFRESH,
  SET_WAREHOUSE_ITEM_CONTAINERS_DT_ROW,
  SET_WAREHOUSE_ITEM_CONTAINERS_REFRESH_URL,
  SET_WAREHOUSE_ITEM_CONTAINERS_DT_FILTER,
  SET_WAREHOUSE_ITEMS_DT_FILTER
} from "redux/dispatch_types";

// buttons
import BackButton from "components/CustomButtons/BackButton";
import AddButton from "components/CustomButtons/AddButton";

// styles
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import formsStyles from "../../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import useFetchDocument from "../../../../global_functions/_common/useFetchDocument";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formsStyles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

export default function WarehouseItemContainersPage(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const formClasses = useFormStyles();

  const history = useHistory();

  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [qrCodeModal, setQrCodeModal] = useState(false);

  const [deleteName, setDeleteName] = useState("");
  const [dataTable, setDataTable] = useState("");
  const [editDelId, setEditDelId] = useState(null);
  const [qrItemId, setQrItemId] = useState(false);

  const [reportParams, setReportParams] = useState("");

  const reduxPageProps = useSelector(state => state.warehouseItemContainers);

  const parentProjectId = props.match.params.slug;

  // for showing client and project info at the top of the table
  const [projectDescription, setProjectDescription] = useState("");
  const [clientName, setClientName] = useState("");
  const [projectNumber, setProjectNumber] = useState("");
  const [projectId, setProjectId] = useState(""); // for providing to add/edit form

  useDataTableRefresh(dataTable, warehouseItemContainersMain);

  // sets refresh url for page on load
  useEffect(() => {
    dispatch({
      type: SET_WAREHOUSE_ITEM_CONTAINERS_REFRESH_URL,
      payload: "projects/" + parentProjectId + "/containers/"
    });
  }, [dispatch, parentProjectId]);

  useEffect(() => {
    // get container project to show at the top of the table if viewing for specific projects
    if (parentProjectId !== "0") {
      axios
        .get(
          apiUrl + "projects/" + parentProjectId,
          getTokenConfig(store.getState)
        )
        .then(res => {
          setProjectDescription(res.data.description);
          setClientName(res.data.client_name);
          setProjectNumber(res.data.number);
          setProjectId(res.data.id);
        });
    } else {
      // if viewing all items (project = 0), show generic title
      setProjectDescription("All Projects");
      setClientName("All Warehouse Containers");
      setProjectNumber("All Clients");
      setProjectId(0);
    }
  }, [parentProjectId]);

  // handlers
  const handleAddClick = () => {
    setEditDelId(0);
    setAddEditModal(true);
  };

  const handleEditClick = dtData => {
    setEditDelId(dtData.id);
    setAddEditModal(true);
  };

  const handleDeleteClick = dtData => {
    setEditDelId(dtData.id);
    const delName = `container id ${dtData.id}`;
    setDeleteName(delName);
    setDeleteModal(true);
  };

  const handleClickEvent = e => {
    const clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({
            type: SET_WAREHOUSE_ITEM_CONTAINERS_DT_ROW,
            payload: selectedRow
          });
          break;
        case "pagination":
          dispatch({
            type: SET_WAREHOUSE_ITEM_CONTAINERS_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          const dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
          dataTable.rows().deselect();
          if (clickRelevantType.params[0] === "Edit") {
            dataTable.rows().deselect();
            handleEditClick(dtData);
          } else if (clickRelevantType.params[0] === "Delete") {
            dataTable.rows().deselect();
            handleDeleteClick(dtData);
          } else if (clickRelevantType.params[0] === "Visibility") {
            history.push(
              `/admin/projects-with-warehouse-items/${parentProjectId}/containers/${dtData.id}/items`
            );
          } else if (clickRelevantType.params[0] === "CropFree") {
            setQrItemId(dtData.id);
            setQrCodeModal(true);
          } else if (clickRelevantType.params[0] === "Assignment") {
            setReportParams({
              url: `${apiUrl}documents/?dataset=container_contents_report&container_id=${dtData.id}`,
              fileName: `Container ${dtData.name} (ID ${
                dtData.id
              }) Contents - (${formatDate(new Date(), "dd MM yyyy")})`,
              fetchMsg: "Fetching container contents report..."
            });
          }
          break;
        default:
          return false;
      }
    }
  };

  useEventListener("click", handleClickEvent, window);

  useFetchDocument(reportParams);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>
                  Containers for {projectDescription}
                </h4>
                <h4 className={classes.cardCategoryWhite}>
                  {clientName} ({projectNumber})
                </h4>
              </CardText>
              <GridContainer>
                <GridItem xs={12}>
                  <BackButton
                    overrideUrl={`/admin/projects-with-warehouse-items/`}
                  />
                </GridItem>
              </GridContainer>
              {dataTable ? (
                <>
                  <TableFilter
                    color="primary"
                    activeFilter={
                      reduxPageProps.tables.warehouseItemContainersMain
                        .pageFilter
                    }
                    dispatchType={SET_WAREHOUSE_ITEM_CONTAINERS_DT_FILTER}
                    dataTable={dataTable}
                    tabs={warehouseItemContainersMain.filterTabNames}
                  />
                  <GridContainer>
                    <div
                      className={
                        formClasses.checkboxAndRadio +
                        " " +
                        formClasses.checkboxAndRadioHorizontal
                      }
                      style={{ marginLeft: "30px", marginBottom: "20px" }}
                    >
                      <FormControlLabel
                        style={{ float: "left" }}
                        control={
                          <Radio
                            checked={false}
                            value={1}
                            onChange={() => {
                              dispatch({
                                type: SET_WAREHOUSE_ITEMS_DT_FILTER,
                                payload:
                                  reduxPageProps.tables
                                    .warehouseItemContainersMain.pageFilter
                              });
                              history.push(
                                `/admin/projects-with-warehouse-items/${projectId}`
                              );
                            }}
                            name="radio button enabled"
                            aria-label="A"
                            icon={
                              <FiberManualRecord
                                className={formClasses.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={formClasses.radioChecked}
                              />
                            }
                            classes={{
                              checked: formClasses.radio,
                              root: formClasses.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: formClasses.label,
                          root: formClasses.labelRoot
                        }}
                        label="Items"
                      />
                      <FormControlLabel
                        style={{ float: "left" }}
                        control={
                          <Radio
                            checked={true}
                            value={2}
                            name="radio button enabled"
                            aria-label="B"
                            icon={
                              <FiberManualRecord
                                className={formClasses.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={formClasses.radioChecked}
                              />
                            }
                            classes={{
                              checked: formClasses.radio,
                              root: formClasses.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: formClasses.label,
                          root: formClasses.labelRoot
                        }}
                        label="Containers"
                      />
                    </div>
                  </GridContainer>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                tableParams={warehouseItemContainersMain}
                setDataTable={setDataTable}
              />
            </CardBody>
            <CardFooter>
              <div className={classes.cardContentRight}>
                <AddButton
                  handleAddClick={handleAddClick}
                  tooltip="Add Warehouse Container"
                />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelId}
          dataTable={dataTable}
          detailURLSuffix={
            reduxPageProps.tables.warehouseItemContainersMain.refreshURL
          }
          tableRefreshType={SET_WAREHOUSE_ITEM_CONTAINERS_DT_TRIG_REFRESH}
          addTitle="Add Container"
          editTitle="Edit "
          filterInForm="filter_all_clients"
          choiceFieldForm="warehouse_item_containers_form"
        >
          <AddEditWarehouseItemContainerForm projectId={projectId} />
        </AddEditDtEntryDialog>
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="the current project"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={editDelId}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_WAREHOUSE_ITEM_CONTAINERS_DT_TRIG_REFRESH}
          urlSuffix={
            reduxPageProps.tables.warehouseItemContainersMain.refreshURL
          }
        />
        <QrCodeDialog
          qrModal={qrCodeModal}
          setQrModal={setQrCodeModal}
          qrItemId={qrItemId}
          qrType="Container"
        />
      </GridContainer>
    </div>
  );
}
