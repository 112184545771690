import { combineReducers } from "redux";
// common
import auth from "./_common/auth";
import notifications from "./_common/notifications";
import spinningCursor from "redux/reducers/_common/spinningCursor";
// page-specific
import clientDB from "./pages/clientDB";
import certificateAllUsers from "redux/reducers/pages/HRHS/Certificates/certificateAllUsers";
import certificateOneUser from "redux/reducers/pages/HRHS/Certificates/certificateOneUser";
import certificateTypes from "redux/reducers/pages/HRHS/Certificates/certificateTypes";
import certificateReqs from "redux/reducers/pages/HRHS/Certificates/certificateReqs";
import companyServices from "./pages/SalesPipeline/companyServices";
import projectDB from "./pages/projectDB";
import tsEntriesUser from "redux/reducers/pages/Timesheets/tsEntriesUser";
import tsBundleUser from "redux/reducers/pages/Timesheets/tsBundleUser";
import userDB from "./pages/userDB";
import tsBundlesAdmin from "redux/reducers/pages/Timesheets/tsBundlesAdmin";
import tsEntriesAdmin from "redux/reducers/pages/Timesheets/tsEntriesAdmin";
import expBundlesUser from "redux/reducers/pages/Expenses/expBundlesUser";
import expEntriesUser from "redux/reducers/pages/Expenses/expEntriesUser";
import expEntriesAdmin from "redux/reducers/pages/Expenses/expEntriesAdmin";
import expBundlesAdmin from "redux/reducers/pages/Expenses/expBundlesAdmin";
import holidayAllowances from "./pages/Holidays/holidayAllowances";
import holsUser from "redux/reducers/pages/Holidays/holsUser";
import holsAdmin from "redux/reducers/pages/Holidays/holsAdmin";
import resPlanner from "./pages/resPlanner";
import quoteBreakdown from "./pages/SalesPipeline/quoteBreakdown";
import salesPipeline from "./pages/SalesPipeline/salesPipeline";
import bookingsUser from "./pages/bookingsUser";
import publicHols from "./pages/settings/publicHols";
import warehouseItemContainers from "./pages/Warehouse/warehouseItemContainers";
import warehouseItemsInContainer from "./pages/Warehouse/warehouseItemsInContainer";
import procurementRequests from "./pages/procurementRequests";
import projectsWithWarehouseItems from "redux/reducers/pages/Warehouse/projectsWithWarehouseItems";
import warehouseDeliverySchedule from "redux/reducers/pages/Warehouse/warehouseDeliverySchedule";
import warehouseItems from "redux/reducers/pages/Warehouse/warehouseItems";
import logEntries from "./pages/settings/logEntries";
import projectsWithSheqDocuments from "./pages/HRHS/projectsWithSheqDocuments";
import sheqDocs from "./pages/HRHS/sheqDocs";
import vehicleTracking from "./pages/Inventory/vehicleTracking";
import clientDashboard from "./pages/dashboard/clientDashboard";
import userAdminDashboard from "./pages/dashboard/userAdminDashboard";
import departments from "./pages/settings/departments";
import positions from "./pages/settings/positions";

const rootReducer = combineReducers({
  auth,
  bookingsUser,
  certificateTypes,
  certificateAllUsers,
  certificateOneUser,
  certificateReqs,
  clientDashboard,
  clientDB,
  companyServices,
  departments,
  expBundlesAdmin,
  expBundlesUser,
  expEntriesAdmin,
  expEntriesUser,
  holsAdmin,
  holidayAllowances,
  holsUser,
  logEntries,
  notifications,
  positions,
  procurementRequests,
  projectDB,
  projectsWithSheqDocuments,
  projectsWithWarehouseItems,
  publicHols,
  quoteBreakdown,
  resPlanner,
  salesPipeline,
  sheqDocs,
  spinningCursor,
  tsBundlesAdmin,
  tsBundleUser,
  tsEntriesAdmin,
  tsEntriesUser,
  userAdminDashboard,
  userDB,
  vehicleTracking,
  warehouseItemContainers,
  warehouseItemsInContainer,
  warehouseDeliverySchedule,
  warehouseItems
});

export default rootReducer;

export type RootStateType = ReturnType<typeof rootReducer>;
