import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
import store from "redux/store";
import {
  SET_WAREHOUSE_ITEMS_DT_PAGE,
  SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH,
  SET_WAREHOUSE_ITEMS_DT_ROW,
  SET_WAREHOUSE_ITEMS_REFRESH_URL,
  SET_WAREHOUSE_ITEMS_DT_FILTER,
  SET_WAREHOUSE_ITEM_CONTAINERS_DT_FILTER // to sync filters when switching between containers and items
} from "redux/dispatch_types";
// custom functions
import identifyClick from "global_functions/_common/identifyClick";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import useEventListener from "global_functions/_common/useEventListener";
import showNotification from "../../../../global_functions/redux_action_creators/showNotification";
import useFetchDocument from "../../../../global_functions/_common/useFetchDocument";
import formatDate from "global_functions/_common/formatDate";
// custom components
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import TableFilter from "components/_Custom/_Common/TableFilter";
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
// custom buttons
import DeleteButton from "components/CustomButtons/DeleteButton";
import BackButton from "components/CustomButtons/BackButton";
import AddButton from "components/CustomButtons/AddButton";
import UploadButton from "components/CustomButtons/UploadButton";
import ChangeStatusMultipleButton from "components/CustomButtons/ChangeStatusMultipleButton";
import QrButton from "components/CustomButtons/QrButton";
import ReportButton from "components/CustomButtons/ReportButton";
// custom dialog & form
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import DeleteDialog from "views/Dialogs/_Common/DeleteDialog";
import QrCodeDialog from "views/Dialogs/Warehouse/QrCodeDialog";
import AddEditWarehouseItemForm from "views/Forms/AddEditWarehouseItemForm";
import ItemHistoryDialog from "views/Dialogs/Warehouse/ItemHistoryDialog";
import MultiCopyDialog from "views/Dialogs/Warehouse/MultiCopyDialog";
import UploadCsvDialog from "views/Dialogs/Warehouse/UploadCsvDialog";
import DeleteAllPreCheckInDialog from "views/Dialogs/Warehouse/DeleteAllPreCheckInDialog";
import ChangeStatusMultipleDialog from "views/Dialogs/Warehouse/ChangeStatusMultipleDialog";
import PrintQrCodesDialog from "views/Dialogs/Warehouse/PrintQrCodesDialog";
import MoveItemToFromContainerDialog from "../../../Dialogs/Warehouse/MoveItemToFromContainerDialog";
import DeliveryNoteParamsDialog from "views/Dialogs/Warehouse/DeliveryNoteParamsDialog";
import SpecifyNumberDialog from "../../../Dialogs/Warehouse/SpecifyNumberDialog";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
// table
import { warehouseItemsMainV1 } from "views/DataTables/Warehouse/Items/warehouseItemsMainV1"; // single project
import { warehouseItemsMainV2 } from "views/DataTables/Warehouse/Items/warehouseItemsMainV2"; // all projects
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
//styles
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import formsStyles from "../../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};
const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formsStyles);

const apiUrl = process.env.REACT_APP_API_V1_URL;

export default function WarehouseItemsPage(props) {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const dispatch = useDispatch();

  const history = useHistory();

  const reduxPageProps = useSelector(state => state.warehouseItems);

  const [qrCodeModal, setQrCodeModal] = useState(false);
  const [qrItemId, setQrItemId] = useState("");
  const [itemHistoryDialog, setItemHistoryDialog] = useState(false);
  const [containerId, setContainerId] = useState(null);

  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState(false);
  const [dataTable, setDataTable] = useState("");
  const [editDelId, setEditDelId] = useState(null);
  const [deleteAllPreCheckInModal, setDeleteAllPreCheckinModal] = useState(
    false
  );
  const [changeStatusMultipleModal, setChangeStatusMultipleModal] = useState(
    false
  );
  const [multiCopyModal, setMultiCopyModal] = useState(false);
  const [uploadCsvModal, setUploadCsvModal] = useState(false);
  const [printAllQrsModal, setPrintAllQrsModal] = useState(false);
  const [moveItemModal, setMoveItemModal] = useState(false);
  const [deliveryNoteModal, setDeliveryNoteModal] = useState(false);

  const [specifyNumberModal, setSpecifyNumberModal] = useState(false);
  const [specifyNumberModalAction, setSpecifyNumberModalAction] = useState("");
  const [specifyNumberModalItemNo, setSpecifyNumberModalItemNo] = useState(0);

  // for showing container info at the top
  const [noContainers, setNoContainers] = useState(null);
  const [containerInfoLoading, setContainerInfoLoading] = useState(false);

  // for showing client and project info at the top of the table
  const [projectDescription, setProjectDescription] = useState("");
  const [clientName, setClientName] = useState("");
  const [projectNumber, setProjectNumber] = useState("");
  const [projectId, setProjectId] = useState(""); // for providing to add/edit form

  const [clientId, setClientId] = useState(0); // for pre-filling delivery note

  const [reportParams, setReportParams] = useState("");

  const parentProjectId = props.match.params.slug;

  // filters in backend and frontend are different because frontend goes in 0, 1, 2, ... which does not correspond
  // to backend, below is the translation
  const filterOrder = [6, 1, 2, 5, 3, 4];
  const viewedFilterBackend =
    filterOrder[reduxPageProps.tables.warehouseItemsMain.pageFilter];

  // the view is used for viewing both: items for single project and items for all projects
  // table parameters for these are slightly different (all projects include client name in table)
  let tableParams;
  parentProjectId !== "0"
    ? (tableParams = warehouseItemsMainV1)
    : (tableParams = warehouseItemsMainV2);

  // sets refresh url for page on load
  useEffect(() => {
    dispatch({
      type: SET_WAREHOUSE_ITEMS_REFRESH_URL,
      payload: "projects/" + parentProjectId + "/items/"
    });
  }, [parentProjectId, dispatch]);

  // get container info for project to display at the top
  useEffect(() => {
    setContainerInfoLoading(true);
    axios
      .get(
        `${apiUrl}projects/${parentProjectId}/containers/?filter=6`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setNoContainers(res.data.length);
        setContainerInfoLoading(false);
      });
  }, [parentProjectId, dispatch]);

  // get bundle client to show at the top of the table if viewing for specific projects
  useEffect(() => {
    if (parentProjectId !== "0") {
      axios
        .get(
          apiUrl + "projects/" + parentProjectId,
          getTokenConfig(store.getState)
        )
        .then(res => {
          setProjectDescription(res.data.description);
          setClientName(res.data.client_name);
          setProjectNumber(res.data.number);
          setProjectId(res.data.id);
          setClientId(parseInt(res.data.client));
        });
    } else {
      // if viewing all items (project = 0), show generic title
      setProjectDescription("All Projects");
      setClientName("All Warehouse Items");
      setProjectNumber("All Clients");
      setProjectId(0);
      setClientId(0);
    }
  }, [parentProjectId, dispatch]);

  // handlers
  const handleAddClick = e => {
    e.stopPropagation();
    setEditDelId(0);
    setAddEditModal(true);
  };

  const handleEditClick = dtData => {
    setEditDelId(dtData.id);
    setAddEditModal(true);
  };

  const handleDeleteClick = dtData => {
    setEditDelId(dtData.id);
    let delName = `${dtData.manufacturer} ${dtData.model} (item id ${dtData.id})`;
    setDeleteName(delName);
    setDeleteModal(true);
  };

  const handleReportClick = e => {
    e.stopPropagation();
    setReportParams({
      url: `${apiUrl}documents/?dataset=project_stock_report&proj_id=${projectId}`,
      fileName: `Stock Report for ${clientName} - ${projectNumber} (${formatDate(
        new Date(),
        "dd MM yyyy"
      )})`,
      fetchMsg: "Fetching stock report..."
    });
  };

  useFetchDocument(reportParams);

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_WAREHOUSE_ITEMS_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_WAREHOUSE_ITEMS_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
          dataTable.rows().deselect();
          if (clickRelevantType.params[0] === "CropFree") {
            setQrItemId(dtData.id);
            setQrCodeModal(true);
          } else if (clickRelevantType.params[0] === "FileCopy") {
            dataTable.rows().deselect();
            setEditDelId(dtData.id);
            setMultiCopyModal(true);
          } else if (clickRelevantType.params[0] === "Edit") {
            dataTable.rows().deselect();
            handleEditClick(dtData);
          } else if (clickRelevantType.params[0] === "Delete") {
            dataTable.rows().deselect();
            handleDeleteClick(dtData);
          } else if (clickRelevantType.params[0] === "History") {
            dataTable.rows().deselect();
            setEditDelId(dtData.id);
            setItemHistoryDialog(true);
          } else if (clickRelevantType.params[0] === "CallSplit") {
            setEditDelId(dtData.id);
            setSpecifyNumberModal(true);
            setSpecifyNumberModalAction("remove_from_box");
            setSpecifyNumberModalItemNo(dtData.qty);
          } else if (clickRelevantType.params[0] === "Storage") {
            setEditDelId(dtData.id);
            setContainerId(parseInt(dtData.container["@data-order"]));
            setMoveItemModal(true);
          } else if (clickRelevantType.params[0] === "ArrowRight") {
            if (dtData.type === 0) {
              // if single item, move to pre check out with a single call
              axios
                .put(
                  `${apiUrl}projects/0/items/${dtData.id}`,
                  {
                    edit_type: "quick_move_to_pre_check_out"
                  },
                  getTokenConfig(store.getState)
                )
                .then(res => {
                  dispatch(showNotification(res.data.statusText, "success"));
                  dispatch({
                    type: SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH,
                    payload: { id: null }
                  });
                })
                .catch(err => {
                  let errorMsg = "";
                  if (err.response.data.errors) {
                    errorMsg = `Error: ${err.response.data.errors}`;
                  } else {
                    errorMsg = `Error changing status`;
                  }
                  dispatch(showNotification(errorMsg, "error"));
                });
            } else {
              setEditDelId(dtData.id);
              setSpecifyNumberModal(true);
              setSpecifyNumberModalAction("quick_move_to_pre_check_out");
              setSpecifyNumberModalItemNo(dtData.qty);
            }
          }
          break;
        default:
          return false;
      }
    }
  };

  useDataTableRefresh(dataTable, tableParams);

  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>
                  Items for {projectDescription}
                </h4>
                <h4 className={classes.cardCategoryWhite}>
                  {clientName} ({projectNumber})
                </h4>
              </CardText>
              <GridContainer>
                <GridItem xs={12}>
                  <BackButton
                    overrideUrl={`/admin/projects-with-warehouse-items/`}
                  />
                  <ReportButton
                    tooltip="Generate stock report"
                    handleReportClick={handleReportClick}
                  />
                </GridItem>
              </GridContainer>
              {dataTable ? (
                <>
                  <TableFilter
                    color="primary"
                    activeFilter={
                      reduxPageProps.tables.warehouseItemsMain.pageFilter
                    }
                    dispatchType={SET_WAREHOUSE_ITEMS_DT_FILTER}
                    dataTable={dataTable}
                    tabs={tableParams.filterTabNames}
                  />

                  <GridContainer>
                    <div
                      className={
                        formClasses.checkboxAndRadio +
                        " " +
                        formClasses.checkboxAndRadioHorizontal
                      }
                      style={{ marginLeft: "30px", marginBottom: "20px" }}
                    >
                      <FormControlLabel
                        style={{ float: "left" }}
                        control={
                          <Radio
                            checked={true}
                            value={1}
                            name="radio button enabled"
                            aria-label="A"
                            icon={
                              <FiberManualRecord
                                className={formClasses.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={formClasses.radioChecked}
                              />
                            }
                            classes={{
                              checked: formClasses.radio,
                              root: formClasses.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: formClasses.label,
                          root: formClasses.labelRoot
                        }}
                        label="Items"
                      />
                      <FormControlLabel
                        style={{ float: "left" }}
                        control={
                          <Radio
                            checked={false}
                            onChange={() => {
                              dispatch({
                                type: SET_WAREHOUSE_ITEM_CONTAINERS_DT_FILTER,
                                payload:
                                  reduxPageProps.tables.warehouseItemsMain
                                    .pageFilter
                              });
                              history.push(
                                `/admin/projects-with-warehouse-items/${projectId}/containers`
                              );
                            }}
                            value={2}
                            name="radio button enabled"
                            aria-label="B"
                            icon={
                              <FiberManualRecord
                                className={formClasses.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={formClasses.radioChecked}
                              />
                            }
                            classes={{
                              checked: formClasses.radio,
                              root: formClasses.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: formClasses.label,
                          root: formClasses.labelRoot
                        }}
                        label={
                          "Containers" +
                          (containerInfoLoading ? " - " : ` (${noContainers})`)
                        }
                      />
                    </div>
                  </GridContainer>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setDataTable}
                tableParams={tableParams}
              />
            </CardBody>
            <CardFooter>
              <div className={classes.cardContentRight}>
                <AddButton
                  handleAddClick={handleAddClick}
                  tooltip="Add Warehouse Item"
                />
                {/* qr button only appears when viewing items of a specific project (not all) with all except 'deleted' status*/}
                {parseInt(projectId) !== 0 &&
                  viewedFilterBackend !== 4 &&
                  viewedFilterBackend !== 6 && (
                    <QrButton
                      tooltip="Generate QR Codes"
                      handleGenQrClick={() => setPrintAllQrsModal(true)}
                    />
                  )}
                {/* upload csv can only appear when viewing items of a specific project (not all) with in pre-check-in status*/}
                {parseInt(projectId) !== 0 && viewedFilterBackend === 1 && (
                  <UploadButton
                    handleUploadClick={() => setUploadCsvModal(true)}
                    tooltip="Upload CSV file"
                  />
                )}
                {/* change multiple item status can appear in a specific project for 'pre-check-in' and 'pre-check-out' status*/}
                {parseInt(projectId) !== 0 &&
                  (viewedFilterBackend === 1 || viewedFilterBackend === 5) && (
                    <ChangeStatusMultipleButton
                      tooltip={
                        viewedFilterBackend === 1
                          ? "Check in all pre-check-in items"
                          : "Check out all pre-check-out items"
                      }
                      handleCheckInClick={() =>
                        setChangeStatusMultipleModal(true)
                      }
                    />
                  )}
                {/* delete can only appear when viewing items of a specific project (not all) with in pre-check-in status*/}
                {parseInt(projectId) !== 0 && viewedFilterBackend === 1 && (
                  <DeleteButton
                    tooltip="Delete all pre-check-in items"
                    handleDeleteClick={() => setDeleteAllPreCheckinModal(true)}
                  />
                )}
                {/* report button can only appear when viewing items of a specific project (not all) with in pre-check-out status*/}
                {parseInt(projectId) !== 0 && viewedFilterBackend === 5 && (
                  <ReportButton
                    tooltip="Generate delivery note"
                    handleReportClick={() => setDeliveryNoteModal(true)}
                  />
                )}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelId}
          dataTable={dataTable}
          detailURLSuffix={reduxPageProps.tables.warehouseItemsMain.refreshURL}
          tableRefreshType={SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH}
          addTitle="Add Item"
          editTitle="Edit Item"
          filterInForm="filter_active_only"
          choiceFieldForm="warehouse_items_form"
        >
          <AddEditWarehouseItemForm projectId={projectId} />
        </AddEditDtEntryDialog>
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="the current bundle"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={editDelId}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH}
          urlSuffix={reduxPageProps.tables.warehouseItemsMain.refreshURL}
        />
        <ItemHistoryDialog
          itemHistoryDialog={itemHistoryDialog}
          setItemHistoryDialog={setItemHistoryDialog}
          itemId={editDelId}
        />
        <QrCodeDialog
          qrModal={qrCodeModal}
          setQrModal={setQrCodeModal}
          qrItemId={qrItemId}
          qrType="Item"
        />
        <MultiCopyDialog
          multiCopyDialog={multiCopyModal}
          setMultiCopyDialog={setMultiCopyModal}
          itemId={editDelId}
          refreshUrl={reduxPageProps.tables.warehouseItemsMain.refreshURL}
        />
        <UploadCsvDialog
          uploadCsvDialog={uploadCsvModal}
          setUploadCsvDialog={setUploadCsvModal}
          projId={parentProjectId}
          uploadUrl={`${apiUrl}projects/${projectId}/items/upload/`}
        />
        <DeleteAllPreCheckInDialog
          deleteModal={deleteAllPreCheckInModal}
          setDeleteModal={setDeleteAllPreCheckinModal}
          projId={projectId}
        />
        <ChangeStatusMultipleDialog
          modal={changeStatusMultipleModal}
          setModal={setChangeStatusMultipleModal}
          projId={projectId}
          currentStatus={viewedFilterBackend}
        />
        <PrintQrCodesDialog
          printAllQrsModal={printAllQrsModal}
          setPrintAllQrsModal={setPrintAllQrsModal}
          projId={projectId}
          itemFilter={viewedFilterBackend}
        />
        <MoveItemToFromContainerDialog
          moveItemFromContainerDialog={moveItemModal}
          setMoveItemFromContainerDialog={setMoveItemModal}
          moveItemId={editDelId}
          projectId={parentProjectId}
          refreshDispatchType={SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH}
          containerId={containerId ? containerId : 0}
        />
        <DeliveryNoteParamsDialog
          projectId={projectId}
          setModal={setDeliveryNoteModal}
          modal={deliveryNoteModal}
          projectNumber={projectNumber}
          clientId={clientId}
        />
        <SpecifyNumberDialog
          boxId={editDelId}
          modal={specifyNumberModal}
          setModal={setSpecifyNumberModal}
          totalInBox={specifyNumberModalItemNo}
          reqDataObj={{ edit_type: specifyNumberModalAction }}
        />
      </GridContainer>
    </div>
  );
}
