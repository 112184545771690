import React, { useEffect, useState, useMemo } from "react";

import axios from "axios";
// redux
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
// template components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
// material ui
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
// custom components
import ProjectSelect from "../../components/_Custom/_Common/ProjectSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
// date
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// custom functions
import useFetchDocument from "global_functions/_common/useFetchDocument";
import getReportParams from "../../components/_Custom/PageSpecific/Reports/reportParamsFunction";
import generateUrl from "../../components/_Custom/PageSpecific/Reports/reportUrl";
import {
  ReportParams,
  FormChoiceField
} from "../../global_functions/types/shared";
// styles
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { customTheme } from "global_functions/_common/customTheme";
import formsStyles from "../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};
// @ts-ignore
const useStyles = makeStyles(styles);
// @ts-ignore
const useFormStyles = makeStyles(formsStyles);
// @ts-ignore
const useCheckboxStyles = makeStyles({ ...customCheckboxRadioSwitch });

const ReportsPage: React.FC = () => {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const checkboxClasses = useCheckboxStyles();

  const [docParams, setDocParams] = useState<ReportParams>({
    url: "",
    fileName: "",
    fetchMsg: ""
  });

  const [reportName, setReportName] = useState<ReportName | "">("");
  const [reportUserId, setReportUserId] = useState(0);
  const [whClientId, setWhClientId] = useState(0);
  const [whBayId, setWhBayId] = useState(0);
  const [projectId, setProjectId] = useState(0);

  const [lateTimesheetBundles, setLateTimesheetBundles] = useState(0);

  const [date, setDate] = useState<Date>(new Date());

  const [genExcel, setGenExcel] = useState(false);

  const [formLoading, setFormLoading] = useState(false);
  const [formChoiceFields, setFormChoiceFields] = useState<
    ReportPageFormOptions
  >({
    user_list: [],
    report_choices: {
      REPORT_TYPE_CHOICES: []
    },
    wh_active_clients: [],
    wh_all_clients: [],
    warehouse_bays: [],
    all_clients: []
  });

  const [visibilityObj, setVisibilityObj] = useState<VisibilityObject>({
    userFieldEnabled: false,
    monthFieldEnabled: false,
    whClientsFieldEnabled: false,
    projFieldWithWhChoicesEnabled: false,
    projFieldWithAllChoicesEnabled: false,
    excelOption: false,
    whBayChoiceFieldEnabled: false
  });

  const apiUrl = process.env.REACT_APP_API_V1_URL;

  const reportParams = useMemo(() => {
    return getReportParams(
      formChoiceFields,
      whClientId,
      whBayId,
      reportUserId,
      date
    );
  }, [formChoiceFields, whClientId, whBayId, reportUserId, date]);

  const fetchUrl = useMemo(() => {
    return generateUrl(
      visibilityObj,
      reportName,
      reportUserId,
      date,
      whClientId,
      whBayId,
      projectId,
      genExcel
    );
  }, [
    visibilityObj,
    reportName,
    reportUserId,
    date,
    whClientId,
    whBayId,
    projectId,
    genExcel
  ]);

  useEffect(() => {
    setFormLoading(true);
    // get form fields
    axios
      .get(`${apiUrl}form-fields/reports_form`, getTokenConfig(store.getState))
      .then(res => setFormChoiceFields(res.data))
      .then(() => setFormLoading(false));

    // get the number of late timesheets
    axios
      .get(
        `${apiUrl}datasets/?type=reports_page_dataset`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setLateTimesheetBundles(res.data.late_timesheet_bundles);
      });
  }, [apiUrl]);

  // reset excel to false when report type changes
  useEffect(() => {
    setGenExcel(false);
  }, [reportName]);

  // enable/disable fields when report type changes
  useEffect(() => {
    if (reportParams && reportName !== "" && reportName in reportParams)
      setVisibilityObj(reportParams[reportName].fieldVisibility);
  }, [reportName, reportParams]);

  const handleFormSubmit = (e: React.SyntheticEvent): void => {
    e.stopPropagation();
    e.preventDefault();

    // set file name for each report
    let fileName;
    if (reportName !== "") {
      const fileNameParam = reportParams[reportName]["name"];
      if (typeof fileNameParam === "string") fileName = fileNameParam;
      else fileName = fileNameParam();

      if (genExcel) fileName += ".xlsx";
      else fileName += ".pdf";

      // set report params to generate report
      setDocParams({
        url: fetchUrl,
        fileName: fileName,
        fetchMsg: "Fetching report..."
      });
    }
  };

  useFetchDocument(docParams);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={9} lg={6}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitleWhite}>Reports</h4>
              <h4 className={classes.cardCategoryWhite}>
                Generate Company Data
              </h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <form id="reportsForm" onSubmit={handleFormSubmit}>
              {lateTimesheetBundles ? (
                <GridContainer>
                  <GridItem xs={12}>
                    <p>
                      Note: {lateTimesheetBundles} timesheet bundle
                      {lateTimesheetBundles > 1 ? "s are" : " is"} late so
                      timesheet-based reports might be incomplete. Please use
                      them carefully.
                    </p>
                  </GridItem>
                </GridContainer>
              ) : (
                <></>
              )}
              <GridContainer>
                <GridItem xs={2}>
                  <FormLabel className={formClasses.labelHorizontal}>
                    Report
                  </FormLabel>
                </GridItem>
                <GridItem xs={7} sm={8}>
                  <CustomSelect
                    options={
                      formChoiceFields.report_choices.REPORT_TYPE_CHOICES
                    }
                    isDisabled={formLoading}
                    value={reportName}
                    setValue={setReportName}
                    required={true}
                  />
                </GridItem>
                {visibilityObj.excelOption && (
                  <GridItem xs={3} sm={2}>
                    <div style={{ marginTop: "20px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="includeLogoCheckbox"
                            tabIndex={-1}
                            // @ts-ignore
                            onClick={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setGenExcel(e.target.checked)
                            }
                            checked={genExcel}
                            checkedIcon={
                              <Check className={checkboxClasses.checkedIcon} />
                            }
                            icon={
                              <Check
                                className={checkboxClasses.uncheckedIcon}
                              />
                            }
                            classes={{
                              checked: checkboxClasses.checked,
                              root: checkboxClasses.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: checkboxClasses.label,
                          root: checkboxClasses.labelRoot
                        }}
                        label="Excel"
                      />
                    </div>
                  </GridItem>
                )}
              </GridContainer>
              {visibilityObj.monthFieldEnabled && (
                <GridContainer>
                  <GridItem xs={2}>
                    <FormLabel className={formClasses.labelHorizontal}>
                      Date
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={10}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={customTheme}>
                        <KeyboardDatePicker
                          style={{ float: "left" }}
                          views={["year", "month"]}
                          margin="normal"
                          label="Date"
                          value={date}
                          // @ts-ignore
                          onChange={date => setDate(date)}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </GridItem>
                </GridContainer>
              )}
              {visibilityObj.userFieldEnabled && (
                <GridContainer>
                  <GridItem xs={2}>
                    <FormLabel className={formClasses.labelHorizontal}>
                      User
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={10}>
                    <CustomSelect
                      options={formChoiceFields.user_list}
                      isDisabled={formLoading}
                      value={reportUserId}
                      setValue={setReportUserId}
                      required={true}
                    />
                  </GridItem>
                </GridContainer>
              )}
              {visibilityObj.whClientsFieldEnabled && (
                <GridContainer>
                  <GridItem xs={2}>
                    <FormLabel className={formClasses.labelHorizontal}>
                      Client
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={10}>
                    <CustomSelect
                      options={formChoiceFields.wh_active_clients}
                      isDisabled={formLoading}
                      value={whClientId}
                      setValue={setWhClientId}
                      required={true}
                    />
                  </GridItem>
                </GridContainer>
              )}
              {visibilityObj.whBayChoiceFieldEnabled && (
                <GridContainer>
                  <GridItem xs={2}>
                    <FormLabel className={formClasses.labelHorizontal}>
                      Location
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={10}>
                    <CustomSelect
                      options={formChoiceFields.warehouse_bays}
                      isDisabled={formLoading}
                      value={whBayId}
                      setValue={setWhBayId}
                      required={true}
                    />
                  </GridItem>
                </GridContainer>
              )}
              {visibilityObj.projFieldWithAllChoicesEnabled && (
                <ProjectSelect
                  clientList={formChoiceFields.all_clients}
                  formLoading={formLoading}
                  clientID={whClientId}
                  setClientID={setWhClientId}
                  statusFilters={[1, 3, 5]}
                  projID={projectId}
                  setProjID={setProjectId}
                  lhsFieldWidth={2}
                  rhsFieldWidth={10}
                />
              )}
              {visibilityObj.projFieldWithWhChoicesEnabled && (
                <ProjectSelect
                  clientList={formChoiceFields.wh_all_clients}
                  formLoading={formLoading}
                  clientID={whClientId}
                  setClientID={setWhClientId}
                  statusFilters={null} // not needed when customUrlSuffix is provided
                  customUrlSuffix="?with_warehouse_items=1&filter=2"
                  projID={projectId}
                  setProjID={setProjectId}
                  lhsFieldWidth={2}
                  rhsFieldWidth={10}
                />
              )}
              <GridContainer>
                <GridItem xs={12}>
                  {/*@ts-ignore*/}
                  <Button color="primary" type="submit" form="reportsForm">
                    Generate
                  </Button>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ReportsPage;

type ReportPageFormOptions = {
  user_list: FormChoiceField[];
  report_choices: ReportChoices;
  wh_active_clients: FormChoiceField[];
  wh_all_clients: FormChoiceField[];
  all_clients: FormChoiceField[];
  warehouse_bays: FormChoiceField[];
};

type ReportChoices = {
  REPORT_TYPE_CHOICES: FormChoiceField[];
};

export type VisibilityObject = {
  [key in FieldName]: boolean;
};

export type ReportName =
  | "all_monthly_bookings_report"
  | "all_user_timesheet_summary_report"
  | "all_user_timesheet_dump"
  | "client_report"
  | "client_stock_report"
  | "project_all_bookings_report"
  | "project_info"
  | "project_checked_out_stock_report"
  | "project_stock_report"
  | "staff_performance_report"
  | "subcontractor_bookings"
  | "user_bookings"
  | "user_info"
  | "warehouse_bay_report"
  | "work_by_type_report";

export type FieldName =
  | "userFieldEnabled"
  | "monthFieldEnabled"
  | "whClientsFieldEnabled"
  | "projFieldWithWhChoicesEnabled"
  | "projFieldWithAllChoicesEnabled"
  | "excelOption"
  | "whBayChoiceFieldEnabled";
