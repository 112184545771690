import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  SET_HOLIDAY_ALLOWANCES_DT_PAGE,
  SET_HOLIDAY_ALLOWANCES_DT_ROW,
  SET_HOLIDAY_ALLOWANCES_DT_TRIG_REFRESH,
  SET_HOLIDAY_ALLOWANCES_VIEWED_YEAR
} from "../../../redux/dispatch_types";
// template components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import CardFooter from "../../../components/Card/CardFooter";
// custom components
import DeleteDialog from "../../Dialogs/_Common/DeleteDialog";
import AddEditDtEntryDialog from "../../Dialogs/_Common/AddEditDtEntryDialog";
import BackButton from "../../../components/CustomButtons/BackButton";
import AddEditHolidayAllowanceForm from "../../Forms/AddEditHolidayAllowanceForm";
import AddButton from "../../../components/CustomButtons/AddButton";
// @material-ui
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
// types
import { RootStateType } from "../../../redux/reducers";
// styles
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { grayColor } from "assets/jss/material-dashboard-pro-react";
// table
import { holidayAllowancesMain } from "../../DataTables/Holidays/holidayAllowancesMain";
// functions
import useDataTableRefresh from "../../../global_functions/dataTables/useDatatableRefresh";
import useEventListener from "../../../global_functions/_common/useEventListener";
import identifyClick from "../../../global_functions/_common/identifyClick";
// styles
import { customTheme } from "../../../global_functions/_common/customTheme";

const styles = {
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardContentRight: {
    padding: "15px 20px 15px 0px",
    position: "relative"
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  }
};

// @ts-ignore
const useStyles = makeStyles(styles);

const HolidayAllowancesPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dataTable, setDataTable] = useState<DataTables.Api | undefined>(
    undefined
  );

  const [addEditModal, setAddEditModal] = useState<boolean>(false);
  const [editDelId, setEditDelId] = useState<number>(0);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteName, setDeleteName] = useState<string>("");

  // set date for year picker based on redux selection
  const reduxYear = useSelector(
    (state: RootStateType) => state.holidayAllowances.viewedYear
  );
  const date = new Date(reduxYear, 0, 1);

  const handleClickEvent = (e: React.MouseEvent): void => {
    const clickRelevantType = identifyClick(e);

    if (dataTable && clickRelevantType && "type" in clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          // eslint-disable-next-line
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({
            type: SET_HOLIDAY_ALLOWANCES_DT_ROW,
            payload: selectedRow
          });
          break;
        case "pagination":
          dispatch({
            type: SET_HOLIDAY_ALLOWANCES_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          if (
            "params" in clickRelevantType &&
            Array.isArray(clickRelevantType["params"])
          ) {
            // eslint-disable-next-line
            const dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
            if (clickRelevantType.params[0] === "Edit") {
              setEditDelId(dtData.id);
              setAddEditModal(true);
              dataTable.rows().deselect();
            } else if (clickRelevantType.params[0] === "Delete") {
              setEditDelId(dtData.id);
              dataTable.rows().deselect();
              setDeleteName(
                `${dtData.user_name_str}'s allowance for year ${dtData.year}`
              );
              setDeleteModal(true);
            }
          }
          break;
        default:
          return;
      }
    }
  };

  useEventListener("click", handleClickEvent, window);

  useDataTableRefresh(dataTable, holidayAllowancesMain);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitleWhite}>Holiday Allowances</h4>
              <h4 className={classes.cardCategoryWhite}>
                Manage User Holiday Allowances
              </h4>
            </CardText>
            <GridItem xs={12}>
              <BackButton overrideUrl="/admin/holidays-and-leave/" />
            </GridItem>
          </CardHeader>
          <CardBody>
            <ThemeProvider theme={customTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ float: "left" }}
                  margin="normal"
                  label="Allowance Year"
                  minDate="2019"
                  format="yyyy"
                  views={["year"]}
                  value={date}
                  onChange={(date: MaterialUiPickersDate) => {
                    if (date) {
                      dispatch({
                        type: SET_HOLIDAY_ALLOWANCES_VIEWED_YEAR,
                        payload: date.getFullYear()
                      });
                    }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
            <Datatable
              setDataTable={setDataTable}
              tableParams={holidayAllowancesMain}
            />
          </CardBody>
          <CardFooter>
            <div className={classes.cardContentRight}>
              <AddButton
                handleAddClick={() => {
                  setEditDelId(0);
                  setAddEditModal(true);
                }}
                tooltip="Add Holiday Allowance"
              />
            </div>
          </CardFooter>
        </Card>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelId}
          tableRefreshType={SET_HOLIDAY_ALLOWANCES_DT_TRIG_REFRESH}
          urlSuffix="holiday-allowances/"
          editTitle="Edit Holiday Allowance"
          addTitle="Add Holiday Allowance"
          choiceFieldForm="holiday_allowance_form"
        >
          {/*@ts-ignore*/}
          <AddEditHolidayAllowanceForm />
        </AddEditDtEntryDialog>
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="the holiday allowances database"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={editDelId}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_HOLIDAY_ALLOWANCES_DT_TRIG_REFRESH}
          urlSuffix={"holiday-allowances/"}
        />
      </GridItem>
    </GridContainer>
  );
};

export default HolidayAllowancesPage;
