import React, { useEffect, useState } from "react";
import axios from "axios";
// redux
import { useSelector } from "react-redux";
import { getTokenConfig } from "../../../../../global_functions/redux_action_creators/auth/getTokenConfig";
import store from "../../../../../redux/store";
// core components
import GridItem from "../../../../Grid/GridItem";
import Card from "../../../../Card/Card";
import CardHeader from "../../../../Card/CardHeader";
import CardIcon from "../../../../Card/CardIcon";
import CardFooter from "../../../../Card/CardFooter";
// material ui
import { LocalShipping } from "@material-ui/icons";
import AccountTree from "@material-ui/icons/AccountTree";
import { makeStyles } from "@material-ui/core/styles";
// custom components
import CustomLinearProgress from "../../../../CustomLinearProgress/CustomLinearProgress";
import StatsText from "../StatsText";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { cardTitle } from "../../../../../assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles({
  ...styles,
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
});

const apiUrl = process.env.REACT_APP_API_V1_URL;

const ClientStats = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState({});

  const currentUserId = useSelector(state => state.auth.user.id);

  // initial load
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${apiUrl}datasets/?type=dash_context_client&user_id=${currentUserId}`,
        getTokenConfig(store.getState)
      )
      .then(res => {
        setCardData(res.data);
        setIsLoading(false);
      });
  }, [currentUserId]);

  return (
    <>
      <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card>
          <CardHeader color="success" stats icon>
            <CardIcon color="info">
              <LocalShipping />
            </CardIcon>
            <p className={classes.cardCategory}>Warehouse Items</p>
            <h3 className={classes.cardTitle}>
              {isLoading ? (
                <CustomLinearProgress />
              ) : (
                <StatsText value={cardData.total_warehouse_items} text="" />
              )}
            </h3>
          </CardHeader>
          <CardFooter stats>
            {/*<div className={classes.stats}>*/}
            {/*  /!*<AccountBalance />*!/*/}
            {/*  <a href="/#/admin/expenses/">Expenses Section</a>*/}
            {/*</div>*/}
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card>
          <CardHeader color="success" stats icon>
            <CardIcon color="success">
              <AccountTree />
            </CardIcon>
            <p className={classes.cardCategory}>Projects with Items</p>
            <h3 className={classes.cardTitle}>
              {isLoading ? (
                <CustomLinearProgress />
              ) : (
                <StatsText value={cardData.projects_with_items} text="" />
              )}
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              {/*<AccountBalance />*/}
              {/*<a href="/#/admin/expenses/">Expenses Section</a>*/}
            </div>
          </CardFooter>
        </Card>
      </GridItem>
    </>
  );
};

export default ClientStats;
