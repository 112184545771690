import React from "react";

// redux
import { useDispatch } from "react-redux";
import showNotification from "global_functions/redux_action_creators/showNotification";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// icon
import Publish from "@material-ui/icons/Publish";

const useStyles = makeStyles(theme => ({
  submitButton: {
    backgroundColor: "#607d8b",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#adbec6" },
    marginTop: 10,
    marginRight: 10
  },
  submitButtonGreyed: {
    backgroundColor: "#bdbdbd",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#bdbdbd" },
    marginTop: 10,
    marginRight: 10
  }
}));

export default function SubmitButton({ handleSubmit, greyedOut }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Tooltip title="Submit bundle">
      {!greyedOut ? (
        <IconButton className={classes.submitButton} onClick={handleSubmit}>
          <Publish style={{ color: "#f9f9f9" }} />
        </IconButton>
      ) : (
        <IconButton
          className={classes.submitButtonGreyed}
          onClick={() => dispatch(showNotification("Empty bundle", "info"))}
        >
          <Publish style={{ color: "#f9f9f9" }} />
        </IconButton>
      )}
    </Tooltip>
  );
}
