import { DtLoadingSpinner } from "components/_Custom/_Common/DataTables/DtLoadingSpinner";

// icon constants
const viewBundleIcon = { icon: "Visibility", tooltip: "" };
const approveBundleIcon = { icon: "CheckCircle", tooltip: "Approve bundle" };
const rejectBundleIcon = { icon: "Cancel", tooltip: "Reject bundle" };
const deleteButton = { icon: "Delete", tooltip: "" };
const reportButtonIcon = {
  icon: "Assignment",
  tooltip: "Generate timesheet report"
};
const completionButton = { icon: "Assessment", tooltip: "View completion" };
const reportGreyedIcon = {
  icon: "AssignmentGreyedOut",
  tooltip: "Generate timesheet report"
};
const completionButtonGreyed = {
  icon: "AssessmentGreyedOut",
  tooltip: "View completion"
};

export const tsBundlesAdminMain = {
  section: "tsBundlesAdmin",
  reduxTableName: "tsBundlesAdminMain",
  refreshURL: "timesheet-bundles/?page=admin_ts_bundles&filter=",
  filterTabValues: [1, 2, 4, 3],
  filterTabNames: ["Open", "Submitted", "Late", "Approved"],
  tableParams: {
    autoWidth: true,
    dom: "Bfrtip",
    responsive: true,
    buttonsColumn: {
      id: 7,
      genIcons: rowData => {
        let returnArray;
        switch (rowData.status) {
          case "Open":
          case "Rejected":
            returnArray = [viewBundleIcon, deleteButton];
            break;
          case "Submitted":
            returnArray = [viewBundleIcon, approveBundleIcon, rejectBundleIcon];
            break;
          case "Approved":
            returnArray = [viewBundleIcon, rejectBundleIcon];
            break;
          default:
            return false;
        }
        // add blue report button to bundles that have entries and grey for ones that don't
        if (rowData.ts_entries)
          returnArray.push(reportButtonIcon, completionButton);
        else returnArray.push(reportGreyedIcon, completionButtonGreyed);

        return returnArray;
      }
    },
    select: {
      style: "single"
    },
    columns: [
      { data: "id", title: "ID", visible: false, searchable: false }, // 0
      {
        data: { _: "sub_month.display", sort: "sub_month.@data-order" },
        title: "Bundle Month",
        width: 350,
        responsivePriority: 2,
        className: "dt-body-center"
      }, // 1
      {
        data: "sub_user",
        title: "User",
        width: 300,
        responsivePriority: 1,
        className: "dt-body-center"
      }, // 2
      {
        data: { _: "open_date.display", sort: "open_date.@data-order" },
        title: "Date Open",
        visible: false,
        searchable: false
      }, // 3
      {
        data: { _: "sub_date.display", sort: "sub_date.@data-order" },
        title: "Submission Date",
        width: 200,
        visible: false,
        searchable: false
      }, // 4
      {
        data: "status",
        title: "Status",
        width: 200,
        className: "dt-body-center"
      }, // 5
      {
        data: { _: "approval_info.display", sort: "approval_info.@data-order" },
        title: "Date Approved",
        width: 300
      }, // 6
      {
        data: null,
        title: "Actions",
        width: 250,
        responsivePriority: 3,
        className: "dt-body-center"
      } // 7
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner
    },
    aaSorting: [[1, "desc"]]
  }
};
