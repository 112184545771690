import React, { useState } from "react";
// redux
import {
  SET_CLIENT_DB_DT_PAGE,
  SET_CLIENT_DB_DT_TRIG_REFRESH,
  SET_CLIENT_DB_DT_ROW
} from "redux/dispatch_types";
import { useDispatch } from "react-redux";
// custom functions & hooks
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import useEventListener from "global_functions/_common/useEventListener";
import identifyClick from "global_functions/_common/identifyClick";
// custom components
import AddButton from "../../../components/CustomButtons/AddButton";
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import DeleteDialog from "views/Dialogs/_Common/DeleteDialog";
import AddEditClientForm from "views/Forms/AddEditClientForm";
// table
import { clientDBMain } from "views/DataTables/Clients/clientDBMain";
// template
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
// styles
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const useStyles = makeStyles(styles);

export default function ClientDBPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState(false);
  const [dataTable, setDataTable] = useState("");
  const [editDelID, setEditDelID] = useState(null);

  useDataTableRefresh(dataTable, clientDBMain);

  // handlers
  const handleAddClick = () => {
    setEditDelID(0);
    setAddEditModal(true);
  };

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_CLIENT_DB_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_CLIENT_DB_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
          if (clickRelevantType.params[0] === "Edit") {
            setEditDelID(dtData.id);
            setAddEditModal(true);
            dataTable.rows().deselect();
          } else if (clickRelevantType.params[0] === "Delete") {
            setEditDelID(dtData.id);
            setDeleteName(dtData.name);
            setDeleteModal(true);
            dataTable.rows().deselect();
          }
          break;
        default:
          return false;
      }
    }
  };

  useEventListener("click", handleClickEvent, window);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Client Database</h4>
                <h4 className={classes.cardCategoryWhite}>All SRW clients</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <Datatable
                tableParams={clientDBMain}
                setDataTable={setDataTable}
              />
            </CardBody>
            <CardFooter>
              <div className={classes.cardContentRight}>
                <AddButton
                  handleAddClick={handleAddClick}
                  tooltip="Add Client Entry"
                />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={editDelID}
          tableRefreshType={SET_CLIENT_DB_DT_TRIG_REFRESH}
          urlSuffix="clients/"
          filterInForm={false}
          choiceFieldForm={null}
          editTitle="Edit Client"
          addTitle="Add Client"
        >
          <AddEditClientForm />
        </AddEditDtEntryDialog>
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="the client database"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={editDelID}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_CLIENT_DB_DT_TRIG_REFRESH}
          urlSuffix={"clients/"}
        />
      </GridContainer>
    </div>
  );
}
