import { AUTH_ERROR_NOTIFICATION } from "redux/dispatch_types";

const showAuthError = err => dispatch => {
  dispatch({
    type: AUTH_ERROR_NOTIFICATION,
    payload: {
      message: err.response.data,
      status: err.response.status
    }
  });
};

export default showAuthError;
