import React, { useState } from "react";

import { useHistory } from "react-router-dom";

// redux
import {
  SET_DEPARTMENTS_DT_TRIG_REFRESH,
  SET_DEPARTMENTS_DT_PAGE,
  SET_DEPARTMENTS_DT_ROW
} from "../../../redux/dispatch_types";
import { useDispatch, useSelector } from "react-redux";
// template
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardText from "../../../components/Card/CardText";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
// table
import { departmentsMain } from "../../DataTables/departmentsMain";
// custom components
import AddEditDtEntryDialog from "../../Dialogs/_Common/AddEditDtEntryDialog";
import Datatable from "../../../components/_Custom/_Common/DataTables/DataTable";
import AddEditDepartmentForm from "../../Forms/AddEditDepartmentForm";
import AddButton from "../../../components/CustomButtons/AddButton";
import EditButton from "../../../components/CustomButtons/EditButton";
import DeleteDialog from "../../Dialogs/_Common/DeleteDialog";
// custom functions
import useDataTableRefresh from "../../../global_functions/dataTables/useDatatableRefresh";
import identifyClick from "../../../global_functions/_common/identifyClick";
import useEventListener from "../../../global_functions/_common/useEventListener";
import showNotification from "../../../global_functions/redux_action_creators/showNotification";
// types
import { RootStateType } from "../../../redux/reducers";
// styles
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardContentRight: {
    padding: "15px 20px 15px 0px",
    position: "relative"
  }
};
// @ts-ignore
const useStyles = makeStyles(styles);

const DepartmentsPage: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const [dataTable, setDataTable] = useState<DataTables.Api | undefined>(
    undefined
  );
  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [editDelId, setEditDelId] = useState(0);

  const { selectedRow } = useSelector(
    (state: RootStateType) => state.departments.tables.departmentsMain
  );

  useDataTableRefresh(dataTable, departmentsMain);

  const handleClickEvent = (e: React.MouseEvent): void => {
    const clickRelevantType = identifyClick(e);

    if (dataTable && clickRelevantType && "type" in clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_DEPARTMENTS_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_DEPARTMENTS_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          if (
            "params" in clickRelevantType &&
            Array.isArray(clickRelevantType["params"])
          ) {
            const dtData = dataTable
              .rows(clickRelevantType.params[1])
              .data()[0];
            if (clickRelevantType.params[0] === "Visibility") {
              history.push(`/admin/departments/${dtData.id}`);
              break;
            } else if (clickRelevantType.params[0] === "Delete") {
              setEditDelId(dtData.id);
              setDeleteName(`${dtData.name}`);
              setDeleteModal(true);
              dataTable.rows().deselect();
            }
          }
          break;
        default:
          return;
      }
    }
  };

  const handleEditClick = () => {
    if (dataTable && selectedRow) {
      const dtRowData = dataTable.rows({ selected: true }).data()[0];
      if (dtRowData.editable) {
        setEditDelId(dtRowData.id);
        setAddEditModal(true);
        dataTable && dataTable.rows().deselect();
      } else {
        dispatch(
          showNotification("This department cannot be edited", "warning")
        );
      }
    }
  };

  useEventListener("click", handleClickEvent, window);

  return (
    <GridContainer>
      <GridItem>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitleWhite}>Departments</h4>
              <h4 className={classes.cardCategoryWhite}>
                Manage Company Departments
              </h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <Datatable
              tableParams={departmentsMain}
              setDataTable={setDataTable}
            />
          </CardBody>
          <CardFooter>
            <div className={classes.cardContentRight}>
              <AddButton
                handleAddClick={() => {
                  setEditDelId(0);
                  setAddEditModal(true);
                }}
                tooltip="Add a Department"
              />
              <EditButton
                handleEditClick={handleEditClick}
                greyedOut={!selectedRow}
                tooltip="Edit Department"
                notSelectedWarning="Please select a department first"
              />
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <AddEditDtEntryDialog
        addEditModal={addEditModal}
        setAddEditModal={setAddEditModal}
        editDelID={editDelId}
        tableRefreshType={SET_DEPARTMENTS_DT_TRIG_REFRESH}
        urlSuffix="departments/"
        filterInForm={false}
        editTitle="Edit Department"
        addTitle="Add a Department"
      >
        {/*@ts-ignore*/}
        <AddEditDepartmentForm />
      </AddEditDtEntryDialog>
      <DeleteDialog
        dataTable={dataTable}
        deleteFrom="the department database"
        deleteModal={deleteModal}
        deleteName={deleteName}
        selectedRow={editDelId}
        setDeleteModal={setDeleteModal}
        tableRefreshType={SET_DEPARTMENTS_DT_TRIG_REFRESH}
        urlSuffix={"departments/"}
      />
    </GridContainer>
  );
};

export default DepartmentsPage;
