import React, { useCallback, useState } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
// custom functions
import { getDialogProps } from "global_functions/_common/getDialogProps";
// redux
import store from "redux/store";
import { getTokenConfig } from "global_functions/redux_action_creators/auth/getTokenConfig";
import { useDispatch } from "react-redux";
import { SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH } from "redux/dispatch_types";
// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
// material-ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
// styles
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import Box from "@material-ui/core/Box";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import showNotification from "global_functions/redux_action_creators/showNotification";
import { useDropzone } from "react-dropzone";
// const customFormHeader = {textAlign: "left", marginBottom: "0px", marginTop: "10px"};
const useStyles = makeStyles(styles);

// transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function UploadCsvDialog({
  uploadCsvDialog,
  setUploadCsvDialog,
  projId,
  uploadUrl
}) {
  const classes = useStyles();

  const [progressPercent, setProgressPercent] = useState(0);
  const [processingMsg, setProcessingMsg] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const dispatch = useDispatch();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  // file uploader
  const uploadFile = formData => {
    // set headers
    let config = getTokenConfig(store.getState);
    config.headers["Content-Type"] = "multipart/form-data"; // replace default header

    // show progress bar and 'processing' label
    config.onUploadProgress = progressEvent => {
      setProgressPercent(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );
      if (progressEvent.loaded / progressEvent.total === 1) {
        setProcessingMsg(true);
      }
    };

    setUploadInProgress(true);
    axios
      .post(uploadUrl, formData, config)
      .then(() => {
        dispatch(showNotification("File processed", "success"));
        dispatch({
          type: SET_WAREHOUSE_ITEMS_DT_TRIG_REFRESH,
          payload: { id: null }
        });
        setProcessingMsg(false);
        setUploadCsvDialog(false);
        setProgressPercent(0);
      })
      .catch(err => {
        let errorMsg;
        err.response.data.errors
          ? (errorMsg = err.response.data.errors)
          : (errorMsg = "Error uploading.");
        dispatch(showNotification(errorMsg, "error"));
        setProcessingMsg(false);
        setProgressPercent(0);
      });
  };

  // dropzone
  const onDrop = useCallback(
    acceptedFiles => {
      let formData = new FormData();

      if (acceptedFiles[0].size < 1000000) {
        formData.append("csv_upload", acceptedFiles[0]);
        uploadFile(formData);
      } else
        dispatch(
          showNotification(
            "Unable to upload file as CSV file size is too large and exceeds the maximum of" +
              "1MB",
            "error"
          )
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadUrl]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv"
  });

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.center}>
      <Dialog
        {...getDialogProps(isTabletOrMobile, classes)}
        fullWidth={true}
        open={uploadCsvDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setUploadCsvDialog(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setUploadCsvDialog(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Upload a CSV file</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <>
            <GridContainer>
              <GridItem xs={12}>
                <p style={{ textAlign: "left" }}>
                  Use the upload area below to upload data from a CSV file. The
                  following fields have to be present (case insensitive):
                </p>
                <ul>
                  <li>Manufacturer</li>
                  <li>Model</li>
                </ul>
                Optionally, below fields can also be included:
                <ul>
                  <li>serial_no</li>
                  <li>location_bay</li>
                  <li>location_shelf</li>
                  <li>comments</li>
                </ul>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <br />
                <Box border={1}>
                  <br />
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <p>Drag 'n' drop CSV files here, or click to select</p>
                    )}
                  </div>
                  {uploadInProgress ? (
                    <CustomLinearProgress
                      variant="determinate"
                      color="primary"
                      value={progressPercent}
                      style={{ marginLeft: "20px", marginRight: "20px" }}
                    />
                  ) : (
                    <br />
                  )}
                  {processingMsg && <p>Processing...</p>}
                </Box>
              </GridItem>
            </GridContainer>
          </>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="transparent"
            onClick={() => setUploadCsvDialog(false)}
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GridItem>
  );
}
