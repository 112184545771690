import { useEffect, Dispatch } from "react";

const useSetVat: UseSetVatParamsType = (amount, vatType, setVat) => {
  useEffect(() => {
    if (vatType === "standard") {
      const newVAT = Math.round((amount - amount / 1.2) * 1e2) / 1e2;
      setVat(newVAT);
    } else if (vatType === "exempt") {
      setVat(0);
    } // if vatType is "custom" change nothing
  }, [vatType, amount, setVat]);
};

export default useSetVat;

type UseSetVatParamsType = (
  amount: number,
  vatType: "custom" | "exempt" | "standard",
  setVat: Dispatch<number>
) => void;
