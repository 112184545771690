import React, { useState } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  SET_TS_BUNDLE_ADMIN_DT_PAGE,
  SET_TS_BUNDLE_ADMIN_DT_TRIG_REFRESH,
  SET_TS_BUNDLE_ADMIN_DT_ROW,
  SET_TS_BUNDLE_ADMIN_FILTER
} from "redux/dispatch_types";
// custom components
import CustomLoadingSpinner from "components/_Custom/_Common/CustomLoadingSpinner";
import DeleteDialog from "../../Dialogs/_Common/DeleteDialog";
import TableFilter from "components/_Custom/_Common/TableFilter";
import AddButton from "components/CustomButtons/AddButton";
import Datatable from "components/_Custom/_Common/DataTables/DataTable.js";
// custom functions
import identifyClick from "global_functions/_common/identifyClick";
import useFetchDocument from "global_functions/_common/useFetchDocument";
import useEventListener from "global_functions/_common/useEventListener";
import useDataTableRefresh from "global_functions/dataTables/useDatatableRefresh";
import showNotification from "global_functions/redux_action_creators/showNotification";
// custom dialogs & form
import ConfirmationDialog from "views/Dialogs/_Common/ConfirmationDialog";
import AddEditDtEntryDialog from "views/Dialogs/_Common/AddEditDtEntryDialog";
import CompletionDialog from "views/Dialogs/_Common/CompletionDialog";
import AddTsBundleAdminForm from "views/Forms/AddTsBundleAdminForm";
// template components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
// datatable params
import { tsBundlesAdminMain } from "../../DataTables/Timesheets/tsBundlesAdminMain";
// styles
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  }
};

const apiUrl = process.env.REACT_APP_API_V1_URL;
const useStyles = makeStyles(styles);

export default function TsBundleAdminPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const history = useHistory();

  const reduxPageProps = useSelector(state => state.tsBundlesAdmin);

  const [dataTable, setDataTable] = useState("");

  const [addEditModal, setAddEditModal] = useState(false);

  const [confirmationParams, setConfirmationParams] = useState([]);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [confirmURL, setConfirmURL] = useState("");

  const [completionDialog, setCompletionDialog] = useState(false);

  const [tsBundleId, setTsBundleId] = useState(0);

  const [reportParams, setReportParams] = useState("");

  // delete modal
  const [editDelId, setEditDelId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState(false);

  useDataTableRefresh(dataTable, tsBundlesAdminMain);

  const handleClickEvent = e => {
    let clickRelevantType = identifyClick(e);

    if (clickRelevantType) {
      switch (clickRelevantType.type) {
        case "cellClick":
          let selectedRow;
          if (dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SET_TS_BUNDLE_ADMIN_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SET_TS_BUNDLE_ADMIN_DT_PAGE,
            payload: dataTable.page.info().page
          });
          break;
        case "dtButton":
          let dtData = dataTable.rows(clickRelevantType.params[1]).data()[0];
          dataTable.rows().deselect();
          switch (clickRelevantType.params[0]) {
            case "Visibility":
              history.push(`/admin/timesheets/${dtData.id}`);
              break;
            case "CheckCircle":
              setConfirmURL(`timesheet-bundles/${dtData.id}`);
              setConfirmationParams([
                "Are you sure you would like to approve this bundle?",
                "Approve Bundle",
                "Bundle approved",
                "Error approving bundle.",
                3
              ]);
              setConfirmationDialog(true);
              break;
            case "Cancel":
              setConfirmURL(`timesheet-bundles/${dtData.id}`);
              setConfirmationParams([
                "Are you sure you would like to reject this bundle?",
                "Reject Bundle",
                "Bundle rejected",
                "Error rejecting bundle.",
                4
              ]);
              setConfirmationDialog(true);
              break;
            case "Assignment":
              dataTable.rows().deselect();

              setReportParams({
                url:
                  apiUrl +
                  "documents/?dataset=timesheet_monthly_report&ts_bundle_id=" +
                  dtData.id,
                fileName: `${dtData.sub_user} timesheet report for ${dtData.sub_month.display}.pdf`,
                fetchMsg: "Fetching timesheet report..."
              });

              break;
            case "AssignmentGreyedOut":
            case "AssessmentGreyedOut":
              dispatch(showNotification("Empty bundle", "info"));
              break;
            case "Assessment":
              setCompletionDialog(true);
              setTsBundleId(dtData.id);
              break;
            case "Delete":
              let delName = `${dtData.sub_user}'s timesheet bundle for ${dtData.sub_month.display}`;
              setDeleteName(delName);
              setDeleteModal(true);
              setEditDelId(dtData.id);
              break;
            default:
              return false;
          }
          break;
        default:
          return false;
      }
    }
  };

  // add event listener for datatable clicks
  useEventListener("click", handleClickEvent, window);

  useFetchDocument(reportParams);

  return (
    <div>
      <GridContainer>
        {/*<GridItem xs={12} lg={10} xl={8}>*/}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Timesheet Bundles</h4>
                <h4 className={classes.cardCategoryWhite}>
                  View and manage timesheet bundles
                </h4>
              </CardText>
              {dataTable ? (
                <TableFilter
                  color="primary"
                  activeFilter={
                    reduxPageProps.tables.tsBundlesAdminMain.pageFilter
                  }
                  dispatchType={SET_TS_BUNDLE_ADMIN_FILTER}
                  dataTable={dataTable}
                  tabs={tsBundlesAdminMain.filterTabNames}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px"
                  }}
                >
                  <CustomLoadingSpinner />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Datatable
                setDataTable={setDataTable}
                tableParams={tsBundlesAdminMain}
              />
            </CardBody>
            <CardFooter>
              <div className={classes.cardContentRight}>
                <AddButton
                  handleAddClick={() => setAddEditModal(true)}
                  tooltip="Add Timesheet Bundle"
                />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <ConfirmationDialog
          confirmationDialog={confirmationDialog}
          setConfirmationDialog={setConfirmationDialog}
          confirmationMessage={confirmationParams[0]}
          confirmationTitle={confirmationParams[1]}
          confirmURL={confirmURL}
          confirmPayload={{ new_status: confirmationParams[4] }}
          successMsg={confirmationParams[2]}
          errorMsg={confirmationParams[3]}
          tableRefreshType={SET_TS_BUNDLE_ADMIN_DT_TRIG_REFRESH}
        />
        <AddEditDtEntryDialog
          addEditModal={addEditModal}
          setAddEditModal={setAddEditModal}
          editDelID={0}
          tableRefreshType={SET_TS_BUNDLE_ADMIN_DT_TRIG_REFRESH}
          urlSuffix="timesheet-bundles/"
          editTitle="Edit Bundle"
          addTitle="Add Bundle"
          choiceFieldForm="ts_bundle_add_form"
        >
          <AddTsBundleAdminForm />
        </AddEditDtEntryDialog>
        <CompletionDialog
          showDialog={completionDialog}
          setShowDialog={setCompletionDialog}
          tsBundleId={tsBundleId}
        />
        <DeleteDialog
          dataTable={dataTable}
          deleteFrom="the timesheet bundle database"
          deleteModal={deleteModal}
          deleteName={deleteName}
          selectedRow={editDelId}
          setDeleteModal={setDeleteModal}
          tableRefreshType={SET_TS_BUNDLE_ADMIN_DT_TRIG_REFRESH}
          urlSuffix={"timesheet-bundles/"}
          warning="Warning: this will delete all timesheets in the bundle (if there are any). The action cannot be undone."
        />
      </GridContainer>
    </div>
  );
}
